import ajax, { Method } from '../../helpers/ajax';
import { showLoading, hideLoading, showAlert } from '../Layout/actionCreators';
import { User, Role } from '../../models';
import { Actions, actionTypes, State } from './types'
import { i18n } from "../../Localization/i18n"

import { alertTypes } from '../../components/common/Alert';

export default {
    loadUserInfoUserRoles: (selectedUserid) => async (dispatch, getState) => {
        dispatch(showLoading());

        const userInfoResponse = await ajax.get('User/SelectedUserInfo/' + selectedUserid);

        if (await ajax.errorOccured(userInfoResponse, "Load user info failed.", dispatch, getState)) return;

        let userInfo = await userInfoResponse.json();

        let { roles } = getState().updateUser;
        if (roles.length == 0) {
            const userRolesResponse = await ajax.get('user/getUserRoles');

            if (await ajax.errorOccured(userRolesResponse, "Load user roles failed.", dispatch, getState))
                return;

            roles = await userRolesResponse.json();
        }

        dispatch(hideLoading());

        dispatch({
            type: actionTypes.LOADED,
            id: Number.parseInt(selectedUserid),
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            phone: userInfo.phone,
            gender: userInfo.gender,
            emailAddress: userInfo.emailAddress,
            picture: userInfo.picture,
            currentRoles: userInfo.currentRoles,
            roles: roles
        });
    },
    loadRoles: () => async (dispatch, getState) => {
        dispatch({ type: actionTypes.RESET_PAGE });

        if (getState().updateUser.roles.length > 0)
            return;

        dispatch(showLoading());
        const userRolesResponse = await ajax.get('user/getUserRoles');

        if (await ajax.errorOccured(userRolesResponse, "Load user roles failed.", dispatch, getState))
            return;

        let roles = await userRolesResponse.json();


        dispatch(hideLoading());
        dispatch({ type: actionTypes.ROLES_LOADED, roles: roles });
    },
    submit: (isAdd: boolean) => async (dispatch, getState) => {
        dispatch({ type: actionTypes.SUBMIT });
        const state: State = getState().updateUser;
        const { id, firstName, lastName, phone, emailAddress, gender, picFile, currentRoles } = state;

        if (firstName == '' ||
            lastName == '' ||
            phone == '' ||
            emailAddress == '')
            return;

        let picture = state.picture;
        if (picFile !== null) {
            const data = new FormData();
            data.append("file", picFile);

            const uploadResponse = await ajax.call(
                'User/Picture',
                Method.POST,
                data,
                true
            );

            if (await ajax.errorOccured(uploadResponse, "Picture upload failed.", dispatch, getState)) return;

            picture = await uploadResponse.json();
            dispatch({ type: actionTypes.CHANGE_FIELD, name: 'picture', value: picture })
            dispatch({ type: actionTypes.CHANGE_FIELD, name: 'picFile', value: null })
        }

        const userInfo: User = {
            id: isAdd ? 0 : id,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            emailAddress: emailAddress,
            picture,
            gender,
            currentRoles: currentRoles
        };

        console.log(userInfo)

        const response = await ajax.post("User/SubmitUserInfo", userInfo);

        if (await ajax.errorOccured(response, "Submit user information failed.", dispatch, getState)) return;

        if (isAdd) dispatch({ type: actionTypes.RESET_PAGE });

        dispatch(showAlert(isAdd ? i18n.t("userAdded", { x: firstName }) : i18n.t("userUpdated"), alertTypes.success));
    },
    addRole: (role: Role) => ({ type: actionTypes.ADD_ROLE, role }),
    removeRole: (role: Role) => ({ type: actionTypes.REMOVE_ROLE, role }),
    onFileChange: (picFile) => ({ type: actionTypes.FILE_CHANGED, picFile }),
    changeField: (name, value) => ({ type: actionTypes.CHANGE_FIELD, name, value })
} as Actions