import React from 'react';
import Layout from '../Layout/Layout';
import HomeIcon from './HomeIcon';
import { useTranslation } from "react-i18next";
import { getUnreadMessagesCount, isMobileMode, setPageTitle } from '../../helpers/utils';
import { getUserInfo, hasPermission, PermissionKey } from '../../helpers/auth';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DashboardIcon from '@mui/icons-material/BarChartRounded';
import MessageIcon from '@mui/icons-material/MessageOutlined';
import UserIcon from '@mui/icons-material/PeopleOutline';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import KnowledgeIcon from '@mui/icons-material/LibraryAddOutlined';
import AnalyticsIcon from '@mui/icons-material/AssessmentOutlined';
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()(() => ({
    welcome: {
        textAlign: 'center'
    },
    divider: {
        marginTop: 20,
        marginBottom: 20
    },
    navigations: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    mobileNavigations: {
        flexDirection: 'column'
    }
}));


interface Props {
}

const Home = (props: Props) => {
    const { t } = useTranslation();

    const { classes } = useStyles(),
        isMobile = isMobileMode(),
        userInfo = getUserInfo();

    setPageTitle(t, "home");

    const messagesCount = getUnreadMessagesCount();

    let navigations = [];

    let key = 0;

    if (hasPermission(PermissionKey.ViewDashboards)) {
        navigations.push(userInfo.dashboards.map((dashboard, i) => {
            key = i;
            return (
                <HomeIcon
                    key={i}
                    title={dashboard.title}
                    link={`/Dashboard/${dashboard.id}`}
                    icon={<DashboardIcon />}
                />
            )
        }));
    }

    if (hasPermission(PermissionKey.ViewMessages))
        navigations.push(<HomeIcon
            key={++key}
            title={t("messages")}
            link={`/Messages`}
            badgeCount={messagesCount}
            icon={<MessageIcon />}
        />);

    if (hasPermission(PermissionKey.SetupPhoneNumbers))
        navigations.push(<HomeIcon
            key={++key}
            title={t("autoReplies")}
            link={`/AutoReplies`}
            icon={<LibraryBooksOutlinedIcon />}
        />);

    if (hasPermission(PermissionKey.ManagerUsers))
        navigations.push(<HomeIcon
            key={++key}
            title={t("manageUsers")}
            link={`/ManageUser`}
            icon={<UserIcon />}
        />);

    if (hasPermission(PermissionKey.ManagePayments))
        navigations.push(<HomeIcon
            key={++key}
            title={t("invoices")}
            link={`/Invoices`}
            icon={<ReceiptIcon />}
        />);

    if (hasPermission(PermissionKey.ManageChatBot))
        navigations.push(<HomeIcon
            key={++key}
            title={t("manageKnowledge")}
            link={`/ManageKnowledge`}
            icon={<KnowledgeIcon />}
        />);

    if (hasPermission(PermissionKey.ViewMessages))
        navigations.push(<HomeIcon
            key={++key}
            title={t("chatbotAnalytics")}
            link={`/Analytics`}
            icon={<AnalyticsIcon />}
        />);

    return (
        <Layout
            title={t("home")}
        >
            <Typography variant="h4" className={classes.welcome}>{t("welcomeTo")}</Typography>
            <Divider variant="middle" className={classes.divider} />
            <div className={`${classes.navigations} ${isMobile ? classes.mobileNavigations : ''}`}>
                {navigations}
            </div>
        </Layout>
    );
}


export default Home;;