import { ActionCreatorsMapObject } from 'redux'

export enum actionTypes {
    LOADING = 'UPLOADDOC_LOADING',
    LOADED = 'UPLOADDOC_LOADED',
    LOAD_FAILED = 'UPLOADDOC_LOAD_FAILED',
    REPORT_CHECKED_UNCHECKED = 'UPLOADDOC_REPORT_CHECKED_UNCHECKED',
    SET_FILE = 'UPLOADDOC_SET_FILE',
    EMPTY_FILE = 'UPLOADDOC_EMPTY_FILE',
    UPLOADED_DOC = 'UPLOADDOC_UPLOADED_DOC',
    UPLOAD_DOC_FAILED = 'UPLOADDOC_UPLOADED_DOC_FAILED',
    CLOSE_SUCCESS = 'UPLOA CHANGE_REPORTDDOC_CLOSE_SUCCESS',
    CLOSE_ERROR = 'UPLOADDOC_CLOSE_ERROR'
};

export interface ReducerAction {
    type: actionTypes | string;
    loading?: boolean;
    isRequiredFilled?: boolean;
    reportArr?: any[];
    selectedReportIdArr?: string[];
    file?: File;
    successMsg?: string;
    errMsg?: string;
}

export interface Actions extends ActionCreatorsMapObject {
    loadReports: () => (dispatch: Dispatch) => void;
    uploadDoc: (selectedReportIdArr: string[], fileData: FormData) => (dispatch: Dispatch) => void;
    handleCheckReport: (selectedReportIdArr: string[], isRequiredFilled: boolean) =>  ReducerAction;
    handleEmptyFile: () => ReducerAction;
    setFile: (chosenFile: any, isRequiredFilled: boolean) => ReducerAction;
    closeSuccess: () => ReducerAction;
    closeError: () => ReducerAction;
}

export interface State {
    loading: boolean;
    isRequiredFilled: boolean;
    reportArr: any[];
    selectedReportIdArr: string[];
    file: File;
    successMsg: string;
    errMsg: string;
}

export const initialState = {
    loading: false,
    isRequiredFilled: false,
    reportArr: [],
    selectedReportIdArr: [],
    file: null,
    successMsg: '',
    errMsg: ''
};

export type Dispatch = (action: ReducerAction) => void