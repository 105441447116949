import * as React from 'react';
import {
    Grid, List, ListItem, ListItemText, Button, TextField, Typography, Dialog, DialogActions, Select, MenuItem, InputLabel,
    DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, FormGroup,
    Paper, Tabs, Tab
} from '@mui/material';
import { getTicketStatusName } from '../../store/Messages/types';
import { dialogModes, Contact, platforms, User, ticketStatus, TicketHistory } from '../../models';
import { useTranslation } from "react-i18next";
import PlatformIcon from './PlatformIcon';
import { compareValues, getFullName } from '../../helpers/utils';
import { getFullTime } from '../../helpers/date';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    statusLabel: {
        backgroundColor: theme.palette.secondary.main,
        color: '#fff',
        borderRadius: 6,
        padding: "2px 4px",
        display: 'inline',
        marginRight: 4,
        fontSize: 10,
        fontWeight: 'bold'
    },
    ticketTabs: {
        backgroundColor: theme.palette.background.default
    },
    selected: {
        backgroundColor: 'rgb(0 174 255 / 0.1)',
    },
}));


interface Props {
    contacts: Contact[],
    agents: User[],
    open: boolean,
    mode: dialogModes,
    comment: string,
    contactName: string,
    ticketHistories: TicketHistory[],
    onChangeField: (name: string, value: string) => void,
    onCancel: () => void,
    onSubmit: () => void
}

const FormDialog = (props: Props) => {
    const { t } = useTranslation();

    const { classes } = useStyles();

    const { open, mode, comment, contactName, contacts, agents, ticketHistories } = props,
        contact = contacts.find(c => c.isActiveForDialog),
        endUserTickets = !contact ? [] : contacts.filter(c => c.endUserId === contact.endUserId),
        currentTicketHistories = contact ? ticketHistories.filter(t => t.ticketId === contact.id) : [];

    const showOptionalLabel = contact && contact.platform === platforms.webChat && contact.optionalLabelTitle !== '' && contact.endUserInfo?.optionalLabel;
    const showWebchatSource = contact && contact.platform === platforms.webChat && contact.endUserInfo?.sourceTitle;

    const [tabValue, setTabValue] = React.useState("ticket_history")

    let title: any = '',
        content: any = '',
        form = null,
        cancelButtonText = t("cancel"),
        showSubmit = true,
        submitDisabled = false;

    function changeField(e) {
        props.onChangeField(e.target.name, e.target.value);
    }

    switch (mode) {
        case dialogModes.close: {
            title = t("closeTicket");
            content = t("closeTicketContentText");
            form = (
                <TextField
                    autoFocus
                    fullWidth
                    margin="dense"
                    name="dialogComment"
                    label={t("comment")}
                    value={comment}
                    onChange={changeField}
                    autoComplete="off"
                />
            );
            submitDisabled = comment === ''
            break;
        }
        case dialogModes.comment: {
            title = t("commentTicket");
            content = t("closeTicketContentText");
            form = (
                <TextField
                    autoFocus
                    fullWidth
                    margin="dense"
                    name="dialogComment"
                    label={t("comment")}
                    value={comment}
                    onChange={changeField}
                    autoComplete="off"
                    required
                />
            );
            submitDisabled = comment === ''
            break;
        }
        case dialogModes.transfer: {
            title = t("transferTicket");
            content = t("transferTicketContentText");

            const selectedAgent = agents.find(a => a.selected);

            form = (
                <>
                    <FormGroup>
                        <InputLabel id="agent-lable">{t("agent")}</InputLabel>
                        <Select
                            id="agent-select"
                            labelId="agent-lable"
                            fullWidth
                            margin="dense"
                            name="agent"
                            displayEmpty
                            value={selectedAgent ? selectedAgent.id : ''}
                            onChange={changeField}
                        >
                            {
                                agents.map((a, i) => (
                                    <MenuItem key={i} value={a.id}>{getFullName(a)}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormGroup>
                    <TextField
                        fullWidth
                        margin="dense"
                        name="dialogComment"
                        label={t("comment")}
                        value={comment}
                        onChange={changeField}
                        autoComplete="off"
                        required
                    />
                </>
            );
            submitDisabled = comment === '' || !selectedAgent
            break;
        }
        case dialogModes.editName: {
            title = t("editName");
            content = t("editContactNameContentText");
            form = (
                <TextField
                    autoFocus
                    fullWidth
                    margin="dense"
                    name="dialogContactName"
                    label={t("contactName")}
                    value={contactName}
                    onChange={changeField}
                    autoComplete="off"
                />
            );
            break;
        }
        case dialogModes.info: {
            if (!contact)
                return null;

            let source = contact.source

            if (contact.platform === platforms.facebook) {
                let jsonSource: any = {}

                try {
                    jsonSource = JSON.parse(source)
                } catch (e) {

                }

                if (jsonSource.name)
                    source = jsonSource.name
            }

            title = (
                <Grid container>
                    <Grid item>
                        <PlatformIcon platform={contact.platform} />
                    </Grid>
                    <Grid item>
                        <Typography
                            component='span'
                            variant='inherit'
                            style={{ marginLeft: 8, lineHeight: '40px' }}
                        >
                            {contact.endUserName || contact.endUserId}
                        </Typography>
                    </Grid>
                </Grid>
            );

            content = (
                <>
                    <List style={{ minWidth: 400 }}>
                        <ListItem>
                            <ListItemText primary={t("ticketNumber")} secondary={(
                                <>
                                    {contact.id}{' '}
                                    {<Typography component="span" className={classes.statusLabel}>{getTicketStatusName(t, contact.status)}</Typography>}
                                </>
                            )} />
                        </ListItem>
                        {
                            contact.agentName &&
                            <ListItem>
                                <ListItemText primary={contact.status === ticketStatus.transferred ? t("transferredTo") : t("claimedBy")} secondary={contact.agentName} />
                            </ListItem>
                        }
                        <ListItem>
                            <ListItemText primary={t("source")} secondary={source} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={t("endUser")} secondary={contact.endUserId + (contact.endUserName ? ` (${contact.endUserName})` : '')} />
                        </ListItem>
                        {showOptionalLabel && <ListItem>
                            <ListItemText primary={contact.optionalLabelTitle} secondary={contact.endUserInfo?.optionalLabel} />
                        </ListItem>}
                        {showWebchatSource && <ListItem>
                            <ListItemText primary={t('sourceTitle')} secondary={contact.endUserInfo?.sourceTitle} />
                        </ListItem>}
                        {showWebchatSource && <ListItem>
                            <ListItemText primary={t('sourceUrl')} secondary={contact.endUserInfo?.sourceUrl} />
                        </ListItem>}
                    </List>


                    <Paper square className={classes.ticketTabs}>
                        <Tabs
                            value={tabValue}
                            onChange={(e, tabValue) => setTabValue(tabValue)}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab value="ticket_history" label={t("ticketHistory")} classes={{ selected: classes.selected }} />
                            {endUserTickets.length > 1 && <Tab value="enduser_tickets" label={t("endUserLastTickets")} classes={{ selected: classes.selected }} />}
                        </Tabs>
                    </Paper>

                    <Table style={tabValue !== "ticket_history" ? { display: 'none' } : {}} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("date")}</TableCell>
                                <TableCell>{t("agent")}</TableCell>
                                <TableCell>{t("status")}</TableCell>
                                <TableCell>{t("comment")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentTicketHistories.sort(compareValues("createdDate", "desc")).map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell>{getFullTime(row.createdDate)}</TableCell>
                                    <TableCell>{row.agentName}</TableCell>
                                    <TableCell>{getTicketStatusName(t, row.status)}</TableCell>
                                    <TableCell>{row.comment}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Table style={tabValue !== "enduser_tickets" ? { display: 'none' } : {}} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("ticketNumber")}</TableCell>
                                <TableCell>{t("lastMessageDate")}</TableCell>
                                <TableCell>{t("status")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {endUserTickets.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">{row.id}</TableCell>
                                    <TableCell>{getFullTime(row.lastMessageDate)}</TableCell>
                                    <TableCell>{getTicketStatusName(t, row.status)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            );
            form = null;
            cancelButtonText = t("close");
            showSubmit = false;
            break;
        }
    }

    return (
        <Dialog
            open={open}
            onClose={props.onCancel}
            fullWidth
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <div>
                    {content}
                </div>
                {form}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancel} color="primary">
                    {cancelButtonText}
                </Button>
                {showSubmit &&
                    <Button disabled={submitDisabled} onClick={props.onSubmit} color="primary" variant="contained">
                        {t("submit")}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}

export default FormDialog;
