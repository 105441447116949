import { ActionCreatorsMapObject } from 'redux'
import { ReducerAction as layoutReducerAction } from '../Layout/types'

export enum actionTypes {
    LOADED = 'DASHBOARD_LOADED',
    RENDERED = 'DASHBOARD_RENDERED',
    RESET_STATE = 'DASHBOARD_RESET_STATE'
}

export interface State {
    dashboardFinishedRendering: boolean,
    configLoaded: boolean,
    dataSource?: string,
    documentationUrl: string,
    accessToken: string,
    embedUrl: string,
    id: string
}

export interface Actions extends ActionCreatorsMapObject {
    loadEmbedConfig: (dashboardId: number) => (dispatch: Dispatch, getSate: any) => void,
    dashboardRendered: () => ReducerAction
}

export const initialState: State = {
    dashboardFinishedRendering: false,
    configLoaded: false,
    dataSource: '',
    documentationUrl: '',
    accessToken: '',
    embedUrl: '',
    id: ''
}

export interface ReducerAction {
    type: actionTypes,
    dataSource?: string,
    documentationUrl?: string,
    accessToken?: string,
    embedUrl?: string,
    id?: string
}

export type Dispatch = (action: ReducerAction | layoutReducerAction) => void