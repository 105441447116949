import React, { useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from '../../store/ManageKnowledge/actionCreators';
import { State, Actions } from '../../store/ManageKnowledge/types';
import Layout from '../Layout/Layout';
import { useTranslation, withTranslation } from "react-i18next";
import { PermissionKey } from "../../helpers/auth";
import { setPageTitle } from "../../helpers/utils";
import Templates from "./Templates";

interface Props {
}

const ManageKnowledge = (props: State & Actions & Props) => {
    const { t } = useTranslation();

    setPageTitle(t, "manageKnowledge");

    const { templates, template, errors, changeField, getChatbotMode, getTemplates,
        changeTemplateField, validateTemplate, submitTemplate, deleteTemplate, publishTemplate } = props;

    useEffect(() => {
        getChatbotMode();
    }, []);

    const templateInfoProps = { template, errors, changeTemplateField, validateTemplate, submitTemplate }
    const templateProps = { templates, getTemplates, deleteTemplate, changeField, publishTemplate, ...templateInfoProps }


    return (
        <Layout
            title={t("manageKnowledge")}
            permissionKey={PermissionKey.ManageChatBot}
        >
                <Templates {...templateProps} />

        </Layout>
    );

}

const manageKnowledgeWithStore = connect(
    (state: any) => state.manageKnowledge,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ManageKnowledge);

export default withTranslation()(manageKnowledgeWithStore);