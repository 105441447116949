import React, { useEffect, useState } from 'react';
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { Add as AddIcon, DeleteOutline as DeleteIcon, Edit as EditIcon, PublishRounded as PublishIcon } from '@mui/icons-material';
import Confirm from '../common/ConfirmModal'
import { useTranslation } from "react-i18next";
import { Template, StatusEnum } from '../../models';
import { getLongDate } from "../../helpers/date";
import { isMobileMode } from "../../helpers/utils";
import { State } from '../../store/ManageKnowledge/types';
import RouterLink from '../common/RouterLink';
import TemplateInfo from "./TemplateInfo";
import { green, amber } from '@mui/material/colors';

interface Props {
    templates: Template[],
    errors: any,
    template: Template,
    changeField: (name: keyof State, value: any) => void,
    getTemplates: () => void,
    publishTemplate: (templateId: number) => void,
    deleteTemplate: (templateId: number) => void,
    changeTemplateField: (name: keyof Template, value: any) => void,
    validateTemplate: () => void,
    submitTemplate: () => object
}

const Templates = (props: Props) => {
    const { t } = useTranslation();

    const { templates, template, errors, changeTemplateField, validateTemplate, submitTemplate, getTemplates, deleteTemplate } = props;

    useEffect(() => {
        getTemplates();
    }, []);

    const [templateId, setTemplateId] = useState(0);

    const [deleteVisible, setDeleteVisible] = useState(false);
    const showDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const templateId = Number.parseInt(e.currentTarget.value);
        setDeleteVisible(true);
        setTemplateId(templateId);
    }
    const handleDelete = (confirmed: boolean) => {
        setDeleteVisible(false);
        if (confirmed) deleteTemplate(templateId);
    }

    const [publishVisible, setPublishVisible] = useState(false);
    const showPublish = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const templateId = Number.parseInt(e.currentTarget.value);
        setPublishVisible(true);
        setTemplateId(templateId);
    }
    const handlePublish = (confirmed: boolean) => {
        setPublishVisible(false);
        if (confirmed) props.publishTemplate(templateId);
    }

    const [infoVisible, setInfoVisible] = useState(false);
    const closeTemplate = () => setInfoVisible(false);
    const openTemplate = () => setInfoVisible(true);

    const newTemplate = () => {
        props.changeField('template', {
            id: 0,
            name: '',
            language: 'en',
        });
        props.changeField('errors', {});

        openTemplate();
    }

    const editTemplate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const templateId = Number.parseInt(e.currentTarget.value);
        const template = templates.find(t => t.id === templateId);
        props.changeField('template', template);
        props.changeField('errors', {});

        openTemplate();
    }

    const templateInfoProps = { template, errors, changeTemplateField, validateTemplate, submitTemplate, closeTemplate }

    return (
        <>
            <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={newTemplate}>
                {t("addTemplate")}
            </Button>
            <Paper sx={{ width: '100%', overflowX: 'auto', mt: 2 }}>
                <Table sx={{ minWidth: isMobileMode() ? 400 : 800 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">{t("name")}</TableCell>
                            <TableCell align="left">{t("language")}</TableCell>
                            <TableCell align="left">{t("status")}</TableCell>
                            <TableCell align="left">{t("createdDate")}</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {templates.map(template => (
                            <TableRow key={template.id}>
                                <TableCell align="left" sx={{ color: 'primary.main' }}>
                                    <RouterLink to={`/Template/${template.id}`}>{template.name}</RouterLink>
                                </TableCell>
                                <TableCell align="left">{template.language}</TableCell>
                                <TableCell align="left" sx={{ color: template.status === StatusEnum.Active ? green[600] : amber[700] }}>
                                    {template.status === StatusEnum.Active ? t('active') : t('inactive')}
                                </TableCell>
                                <TableCell align="left">{getLongDate(t, template.createdDate)}</TableCell>
                                <TableCell align="left">
                                    <Tooltip title={t("edit")}>
                                        <Button value={template.id} onClick={editTemplate} sx={{ minWidth: 50 }}>
                                            <EditIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={t("delete")}>
                                        <Button value={template.id} onClick={showDelete} sx={{ minWidth: 50 }}>
                                            <DeleteIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                    {template.status !== StatusEnum.Active &&
                                        <Tooltip title={t("activate")}>
                                            <Button value={template.id} onClick={showPublish} sx={{ minWidth: 50 }}>
                                                <PublishIcon color="primary" />
                                            </Button>
                                        </Tooltip>}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>

            <TemplateInfo {...templateInfoProps} visible={infoVisible} />

            {deleteVisible && <Confirm onClose={handleDelete} color='error' />}
            {publishVisible && <Confirm onClose={handlePublish} color='info' />}
        </>
    );
}

export default Templates;
