import React, { useEffect, useState } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from '../../store/CompanyProfile/actionCreators';
import { State, Actions } from '../../store/CompanyProfile/types';
import Layout from '../Layout/Layout';
import { Paper, Button } from '@mui/material';
import { useTranslation } from "react-i18next";
import { PermissionKey } from "../../helpers/auth";
import CompanyDetails from "./CompanyDetails"
import EditCompany from "./EditCompany"
import { setPageTitle } from "../../helpers/utils";

interface Props { }

const CompanyProfile = (props: Props & State & Actions) => {
    const { t } = useTranslation();

    const [isEditing, setIsEditing] = useState(false);
    const onEdit = () => setIsEditing(true);
    const onCancelEdit = () => setIsEditing(false);

    useEffect(() => {
        setPageTitle(t, "companyProfile");

        props.loadCompany()
    }, [])

    const { company, companyTypes, errors, changeCompanyField, submit, validate } = props;

    return (
        <Layout title={t("companyProfile")} permissionKey={PermissionKey.ManagerUsers}>
            <Paper sx={{ p: 2 }}>
                {!isEditing && <Button variant="contained" color="primary" sx={{ mb: 1, ml: 1 }} onClick={onEdit}>{t('edit')}</Button>}

                {isEditing
                    ? <EditCompany company={company} companyTypes={companyTypes} errors={errors} changeField={changeCompanyField} submit={submit as any} validate={validate} onCancel={onCancelEdit} />
                    : <CompanyDetails company={company} companyTypes={companyTypes} />
                }

            </Paper>
        </Layout>
    );
}

const companyWithStore = connect(
    (state: any) => state.companyProfile,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(CompanyProfile);

export default companyWithStore;