import React, { useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from '../store/ManageUser/actionCreators';
import { State, Actions } from '../store/ManageUser/types';
import RouterLink from './common/RouterLink';
import Layout from './Layout/Layout';
import { Theme } from '@mui/material/styles';
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableSortLabel, TableRow, Switch } from "@mui/material";
import { Add as AddIcon } from '@mui/icons-material';
import { PermissionKey, getUserInfo } from "../helpers/auth";
import { userStatus } from '../models';
import { isMobileMode, getFullName, setPageTitle } from "../helpers/utils";
import { useTranslation, withTranslation } from "react-i18next";
import { makeStyles } from '../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: isMobileMode() ? 400 : 800,
    },
    button: {
        textTransform: "capitalize",
    },
}));

interface Props { }

const ManageUser = (props: State & Actions & Props) => {
    const { t } = useTranslation();

    useEffect(() => {
        setPageTitle(t, "manageUsers");

        props.loadUsers();
    }, [])

    const sortUsers = (key) => {
        const oldKey = props.sortKey;
        const oldDir = props.sortDir;

        let dir;
        if (key === oldKey) {
            dir = oldDir === 'asc' ? 'desc' : 'asc';
        } else {
            dir = 'asc';
        }

        props.sortUsers(key, dir);
    }

    const { classes } = useStyles();

    const { users, sortKey, sortDir } = props;

    return (
        <Layout
            title={t("manageUsers")}
            permissionKey={PermissionKey.ManagerUsers}
        >
            <RouterLink to="./AddUser">
                <Button variant="contained" color="primary">
                    <AddIcon />
                    {t("newUser")}
                </Button>
            </RouterLink>
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                <TableSortLabel
                                    active={sortKey === 'firstName'}
                                    direction={sortDir}
                                    onClick={() => sortUsers('firstName')}
                                >
                                    {t("name")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="left">
                                <TableSortLabel
                                    active={sortKey === 'emailAddress'}
                                    direction={sortDir}
                                    onClick={() => sortUsers('emailAddress')}
                                >
                                    {t("emailAddress")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="left">
                                <TableSortLabel
                                    active={sortKey === 'phone'}
                                    direction={sortDir}
                                    onClick={() => sortUsers('phone')}
                                >
                                    {t("phoneNumber")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sortKey === 'status'}
                                    direction={sortDir}
                                    onClick={() => sortUsers('status')}
                                >
                                    {t("active")}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(user => {
                            if (user.id === getUserInfo().id) return null;
                            return (
                                <TableRow key={user.id}>
                                    <TableCell align="left">
                                        <RouterLink to={`/UpdateUser/${user.id}`}>
                                            <Button color="primary" className={classes.button}>{getFullName(user)}</Button>
                                        </RouterLink>
                                    </TableCell>
                                    <TableCell align="left">{user.emailAddress}</TableCell>
                                    <TableCell align="left">{user.phone}</TableCell>
                                    <TableCell align="center">
                                        <Switch
                                            size="small"
                                            checked={user.status === userStatus.Active}
                                            onClick={() => props.updateUserStatus(user)}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </Layout>
    );
}

const manageUserWithStore = connect(
    (state: any) => state.manageUser,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ManageUser);

export default withTranslation()(manageUserWithStore);