import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Box } from "@mui/system";

interface Props {
    onChange: (value: string) => void;
}

const Recaptcha = (props: Props) => {

    const onChange = (reCAPTCHA) => {
        props.onChange(reCAPTCHA);
    }

    return (
        <Box sx ={{ mt: 3, display: "flex", justifyContent: "center" }}>
            <ReCAPTCHA
                onChange={onChange}
                sitekey="6LciPaQUAAAAABefab5Sif5PJKIV7SH9uBb99wrg"
            />
        </Box>
    );
}

export default Recaptcha;
