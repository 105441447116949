import { produce } from 'immer'
import { actionTypes, State, initialState, ReducerAction } from './types'

export default (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.LOADED:
                newState.id = action.id;
                newState.firstName = action.firstName;
                newState.lastName = action.lastName;
                newState.phone = action.phone;
                newState.emailAddress = action.emailAddress;
                newState.picture = action.picture;
                newState.gender = action.gender;
                newState.currentRoles = action.currentRoles;
                newState.roles = action.roles;
                break
            case actionTypes.RESET_PAGE:
                newState.id = 0
                newState.firstName = ''
                newState.lastName = ''
                newState.phone = ''
                newState.emailAddress = ''
                newState.picture = ''
                newState.gender = 0
                newState.currentRoles = []
                newState.isSubmitted = false
                break;
            case actionTypes.ROLES_LOADED:
                newState.roles = action.roles;
                break;
            case actionTypes.SUBMIT:
                newState.isSubmitted = true;
                break;
            case actionTypes.FILE_CHANGED:
                newState.picFile = action.picFile;
                break;
            case actionTypes.CHANGE_FIELD:
                newState[action.name] = action.value;
                break;
            case actionTypes.ADD_ROLE:
                newState.currentRoles.push(action.role);
                break;
            case actionTypes.REMOVE_ROLE:
                newState.currentRoles = newState.currentRoles.filter(r => r.id !== action.role.id);
                break;
        }
    })
}