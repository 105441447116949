import { ActionCreatorsMapObject } from 'redux';
import { User, userStatus } from '../../models';
import { ReducerAction as layoutReducerAction } from '../Layout/types'

export enum actionTypes {
    LOADED = 'MANAGEUSER_LOADED',
    UPDATED_USER_STATUS = 'MANAGEUSER_UPDATED_USER_STATUS',
    SORT_USERS = 'MANAGEUSER_SORT_USERS'
}

export interface State {
    users: User[],
    sortKey: string,
    sortDir: "desc" | "asc"
}

export interface Actions extends ActionCreatorsMapObject {
    loadUsers: () => (dispatch: Dispatch, getState: any) => void,
    updateUserStatus: (user: User) => (dispatch: Dispatch, getState: any) => void,
    sortUsers: (key: string, dir: string) => ReducerAction
}

export interface ReducerAction {
    type: actionTypes,
    users?: User[],
    userId?: number,
    status?: userStatus,
    sortKey?: string,
    sortDir?: "desc" | "asc"
}

export type Dispatch = (action: ReducerAction | layoutReducerAction) => void

export const initialState: State = {
    users: [],
    sortKey: "status",
    sortDir: "asc"
}