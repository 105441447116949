import React, { useEffect } from "react";
import { Theme } from "@mui/material/styles";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, IconButton, Typography } from "@mui/material";
import { Add as AddIcon, DeleteOutline as DeleteIcon } from '@mui/icons-material';
import { FacebookPage } from "../../models";
import { isMobileMode } from '../../helpers/utils';
import { getLongDate } from '../../helpers/date';
import { useTranslation } from "react-i18next";
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    tableWrapper: {
        width: '100%',
        overflowX: 'auto',
    },
    addButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    table: {
        minWidth: isMobileMode() ? 400 : 800,
    },
    deleteButton: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    emptyMessage: {
        margin: theme.spacing(1),
        marginLeft: 0,
        backgroundColor: "#ffffff",
        maxWidth: 800,
        color: "#0a0a0a",
        fontSize: "16px",
        lineHeight: "20px"
    },
    helpText: {
        color: theme.palette.grey[500],
    }
}));

interface Props {
    pages: Array<FacebookPage>,
    instagramLogin: () => void,
    getSubscribedPages: () => void,
    deletePage: (pageId: string) => void
}

const InstagramSetup = (props: Props) => {
    const { t } = useTranslation();

    useEffect(() => {
        props.getSubscribedPages()
    }, [])

    const { classes } = useStyles();

    const { pages } = props;

    return <>
        <Typography variant="body2" className={classes.helpText} dangerouslySetInnerHTML={{
            __html: t("instagramDesc", {
                professional: "https://www.facebook.com/help/instagram/138925576505882",
                linked: "https://www.facebook.com/help/1148909221857370",
                tools: "https://www.facebook.com/help/instagram/791161338412168"
            })
        }} />

        <Button
            variant="contained"
            color="primary"
            className={classes.addButton}
            onClick={() => props.instagramLogin()}
        >
            <AddIcon />
            {t("linkInstagram")}
        </Button>
        <div className={classes.tableWrapper}>
            <Table size="small" className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("name")}</TableCell>
                        <TableCell>{t("accountId")}</TableCell>
                        <TableCell>{t("expirationDate")}</TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pages?.length === 0 &&
                        <TableRow><TableCell colSpan={3} align="center">{t("noData")}</TableCell></TableRow>
                    }
                    {pages?.length > 0 && pages.map(page => {
                        const expired = new Date(page.expirationDate) <= new Date();
                        const expiration = expired ? t("expired") : getLongDate(t, page.expirationDate);
                        return (
                            <TableRow key={page.PageID}>
                                <TableCell>{page.name}</TableCell>
                                <TableCell>{page.accountId}</TableCell>
                                <TableCell style={{ color: expired ? 'red' : 'inherit' }}>{expiration}</TableCell>
                                <TableCell align="center">
                                    <Tooltip title={t("delete")}>
                                        <IconButton onClick={() => props.deletePage(page.accountId)} size="large">
                                            <DeleteIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })
                    }
                </TableBody>
            </Table>
        </div>
    </>;
}

export default InstagramSetup;
