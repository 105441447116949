import * as React from 'react';
import { useTranslation } from "react-i18next";
import { getPrettyTime } from '../../helpers/date';
import { Notification } from '../../models'
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconButton, Menu as MUMenu, MenuItem, Badge, Tooltip } from '@mui/material';
import { styled } from '@mui/system';

const ReadIcon = styled(FiberManualRecordIcon)({
    display: "none",
    position: "absolute",
    right: "4px",
    top: "4px",
    color: "#cacccf",
    '&:hover, &:active': {
        color: "#adb2ba"
    }
});

const Description = styled('span')({
    width: "95%"
});

const NotificationDate = styled('span')({
    display: "block",
    color: "#90949c",
    fontSize: "12px",
    position: "absolute",
    right: "4px",
    bottom: "4px"
});

const NotificationItem = styled(MenuItem)<{ isRead: boolean }>(({ isRead }) => ({
    backgroundColor: isRead ? '#FFFFFF' : '#edf2fa',
    width: "450px",
    maxWidth: "100%",
    minHeight: "45px",
    whiteSpace: 'normal',
    borderBottom: '1px solid #dddfe2',
    fontSize: "13px",
    '&:hover svg, &:active svg': {
        display: 'block'
    }
}));

interface Props {
    notifications: Notification[],
    toggleNotificationRead: (id: number) => void,
    handleNotificationRedirect?: (notification: Notification) => void
}

const Notifications = (props: Props) => {
    const { t } = useTranslation();

    const { notifications } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRedirect = (notification: Notification) => {
        props.handleNotificationRedirect(notification);
        handleClose();
    }

    let closedNotificationsIcon = <NotificationsIcon />,
        openNotificationsIcon = <NotificationsActiveIcon />;

    return (
        <>
            {notifications && notifications.length > 0 &&
                <IconButton color="inherit" onClick={handleOpen} sx={{ ml: 1 }}>
                    <Badge badgeContent={notifications ? notifications.filter(n => !n.read).length : 0} color="secondary">
                        {Boolean(anchorEl) ? openNotificationsIcon : closedNotificationsIcon}
                    </Badge>
                </IconButton>}

            <MUMenu
                keepMounted
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ style: { padding: 0 } }}
            >
                {notifications && notifications.map((notification, i) => (
                    <NotificationItem key={i} isRead={notification.read}>
                        <Tooltip title={notification.read ? t("markUnread") : t("markRead")}>
                            <ReadIcon fontSize="small" onClick={() => props.toggleNotificationRead(notification.id)} />
                        </Tooltip>
                        <Description onClick={(e) => handleRedirect(notification)}>
                            {notification.description}
                            <NotificationDate>{getPrettyTime(t, notification.createdDate)}</NotificationDate>
                        </Description>
                    </NotificationItem>
                ))}
            </MUMenu>
        </>
    );
}

export default Notifications;