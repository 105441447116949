import ajax from '../../helpers/ajax';
import { actionTypes, Actions, Dispatch, GetState } from './types'
import { showLoading, hideLoading, showAlert } from '../Layout/actionCreators';
import { Device, StatusEnum } from '../../models';
import { alertTypes } from '../../components/common/Alert';
import { i18n } from "../../Localization/i18n"
import { hasPermission, PermissionKey } from '../../helpers/auth';

function urlB64ToUint8Array(base64String: string) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        // eslint-disable-next-line
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function base64Encode(arrayBuffer: ArrayBuffer) {
    const x: any = new Uint8Array(arrayBuffer)
    return btoa(String.fromCharCode.apply(null, x))
}

const requestPushNotificationPermission = async (dispatch: Dispatch) => {
    const status = await Notification.requestPermission()

    dispatch({ type: actionTypes.SET_NOTIFICATION_PERMISSION, notificationPermission: status })

    return status
}

const registerServiceWorker = async (dispatch: Dispatch) => {
    try {
        const reg = await navigator.serviceWorker.register('/pushNotificationServiceWorker.js')

        // Are Notifications supported in the service worker?
        if (!(reg.showNotification)) {
            dispatch(showAlert('Notifications aren\'t supported on service workers.', alertTypes.error))
            dispatch({ type: actionTypes.SET_BROWSER_SUPPORT, browserSupport: false })
            return false
        }
    }
    catch (err) {
        dispatch(showAlert(`Register for notification error: ${err}`, alertTypes.error))
        return false
    }

    return true
}

const checkSubscription = async (dispatch: Dispatch, device: Device) => {
    try {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready

        try {
            const subscription = await serviceWorkerRegistration.pushManager.getSubscription()

            if (subscription) {
                var p256dh = base64Encode(subscription.getKey('p256dh'))
                var auth = base64Encode(subscription.getKey('auth'))

                if (device.pushAuth !== auth || device.pushP256DH !== p256dh || device.pushEndpoint !== subscription.endpoint) {
                    //update device
                    console.log('Update device')
                    return true
                }
            }
            else {
                return true
            }
        }
        catch (e) {
            dispatch(showAlert(`Unable to subscription details: ${e}`, alertTypes.error))
            dispatch({ type: actionTypes.SET_AUTO_SETUP, autoSetup: false })
            return false
        }
    }
    catch (err) {
        dispatch(showAlert(`Service worker is not ready: ${err}`, alertTypes.error))
        dispatch({ type: actionTypes.SET_AUTO_SETUP, autoSetup: false })
        return false
    }

    return false
}

const addOrUpdateDevice = (update?: boolean) => async (dispatch, getState) => {
    const state = getState().messagingSettings

    try {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready

        try {
            const subscription = await serviceWorkerRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlB64ToUint8Array(state.publicKey)
            })

            var p256dh = base64Encode(subscription.getKey('p256dh'))
            var auth = base64Encode(subscription.getKey('auth'))

            const response = await ajax.post('PushNotification/AddOrUpdateDevice', {
                pushEndpoint: subscription.endpoint,
                pushP256DH: p256dh,
                pushAuth: auth,
                id: update ? state.device.id : 0
            })

            if (await ajax.errorOccured(response, "Create device failed.", dispatch, getState)) return

            const newDevice = await response.json()

            dispatch({ type: actionTypes.SET_DEVICE, device: newDevice })
        }
        catch (e) {
            dispatch(showAlert(`Unable to subscribe to push: ${e}`, alertTypes.error))
        }
    }
    catch (err) {
        dispatch(showAlert(`Service worker is not ready: ${err}`, alertTypes.error))
    }
}

//add or update the device
const checkDevice = async (dispatch: Dispatch, getState: GetState) => {
    const state = getState().messagingSettings

    //if device exists, which means it has been already created
    if (state.device) {
        //Check push manager subscription
        const updateDeviceNeeded = await checkSubscription(dispatch, state.device)

        //if it doesn't exists or is outdatet update it
        if (updateDeviceNeeded)
            await addOrUpdateDevice(true)(dispatch, getState)
    }
    else {
        //add the device
        await addOrUpdateDevice()(dispatch, getState)
    }
}

export default {
    loadInfo: () => async (dispatch, getState) => {
            //Chatbot Settings
        if (hasPermission(PermissionKey.ManageChatBot)) {
            const chatbotResponse = await ajax.get('ChatBot/Settings')
            if (await ajax.errorOccured(chatbotResponse, "Chatbot info load failed.", dispatch, getState)) return
            const { chatbotStatus, chatbotMode, newTicketEmail } = await chatbotResponse.json()
            dispatch({ type: actionTypes.LOAD_SETTINGS, chatbotStatus, chatbotMode, newTicketEmail })
        }

        //Check browser support
        if (typeof Notification === 'undefined' || !('serviceWorker' in navigator)) {
            dispatch({ type: actionTypes.SET_BROWSER_SUPPORT, browserSupport: false })
            dispatch({ type: actionTypes.SET_AUTO_SETUP, autoSetup: false })
            return
        }

        window.addEventListener('beforeinstallprompt', (event) => {
            console.log("beforeinstallprompt triggered", event)

            // Prevent Chrome <= 67 from automatically showing the prompt
            event.preventDefault()
            // Stash the event so it can be triggered later.
            dispatch({ type: actionTypes.SET_INSTALL_EVENT, installEvent: event })
        });

        dispatch({ type: actionTypes.SET_BROWSER_SUPPORT, browserSupport: true })
        dispatch({ type: actionTypes.SET_NOTIFICATION_PERMISSION, notificationPermission: Notification.permission })

        //Register service worker
        const swRegistrationSuccess = await registerServiceWorker(dispatch)
        if (!swRegistrationSuccess) {
            dispatch({ type: actionTypes.SET_AUTO_SETUP, autoSetup: false })
            return
        }

        //Load device info and public key
        const response = await ajax.get('PushNotification/LoadInfo')

        if (await ajax.errorOccured(response, "Load info failed.", dispatch, getState)) return

        const result: { publicKey: string, device: Device } = await response.json()
        let { device } = result

        dispatch({ type: actionTypes.SET_PUBLIC_KEY, publicKey: result.publicKey })
        dispatch({ type: actionTypes.SET_DEVICE, device })

        //Ask for Push notification permission
        const permission = await requestPushNotificationPermission(dispatch)

        if (permission === "granted") {

            await checkDevice(dispatch, getState)
        }
        dispatch({ type: actionTypes.SET_AUTO_SETUP, autoSetup: false })
    },
    requestPushNotificationPermission: () => async (dispatch, getState) => {
        const permission = await requestPushNotificationPermission(dispatch)

        if (permission === "granted") {
            await checkDevice(dispatch, getState)
        }
    },
    addDevice: addOrUpdateDevice,
    testSend: () => async (dispatch, getState) => {
        const state = getState().messagingSettings
        const response = await ajax.post('PushNotification/TestSend', state.device.id)

        if (await ajax.errorOccured(response, "Load info failed.", dispatch, getState)) return

        //const sent: boolean = await response.json()
    },
    enableOrDisableDevice: () => async (dispatch, getState) => {
        const state = getState().messagingSettings
        const response = await ajax.post('PushNotification/UpdateDevice', state.device.id)

        if (await ajax.errorOccured(response, "Load info failed.", dispatch, getState)) return

        dispatch({ type: actionTypes.TOGGLE_DEVICE_ENABLED })
    },
    installApp: () => async (dispatch, getState) => {
        const state = getState().messagingSettings,
            installEvent: any = state.installEvent

        installEvent.prompt();
        // Wait for the user to respond to the prompt
        installEvent.userChoice.then(choice => {
            if (choice.outcome === 'accepted') {
                console.log('User accepted the A2HS prompt');
            } else {
                console.log('User dismissed the A2HS prompt');
            }
            // Clear the saved prompt since it can't be used again
            dispatch({ type: actionTypes.SET_INSTALL_EVENT, installEvent: null })
        });
    },
    handleChatbotStatus: (currentStatus) => async (dispatch, getState) => {
        dispatch(showLoading());

        let status = currentStatus == StatusEnum.Active ? StatusEnum.Inactive : StatusEnum.Active
        const response = await ajax.post("ChatBot/Status", status)
        if (await ajax.errorOccured(response, "An error has occurred, please try again later.", dispatch, getState)) return

        dispatch({ type: actionTypes.CHANGE_FIELD, name: "chatbotStatus", value: status })

        dispatch(hideLoading());
        dispatch(showAlert(i18n.t("changesSaved"), alertTypes.success));
    },
    handleChatbotMode: (mode) => async (dispatch, getState) => {
        dispatch(showLoading());

        const response = await ajax.post("ChatBot/Mode", mode)
        if (await ajax.errorOccured(response, "An error has occurred, please try again later.", dispatch, getState)) return

        dispatch({ type: actionTypes.CHANGE_FIELD, name: "chatbotMode", value: mode })

        dispatch(hideLoading());
        dispatch(showAlert(i18n.t("changesSaved"), alertTypes.success));
    },
    handleTicketEmail: (newTicketEmail) => async (dispatch, getState) => {
        dispatch(showLoading());

        const response = await ajax.post("chatbot/ticket-email", newTicketEmail)
        if (await ajax.errorOccured(response, "An error has occurred, please try again later.", dispatch, getState)) return

        dispatch({ type: actionTypes.CHANGE_FIELD, name: "newTicketEmail", value: newTicketEmail })

        dispatch(hideLoading());
        dispatch(showAlert(i18n.t("changesSaved"), alertTypes.success));
    },
} as Actions