import { ReducerAction, State, initialState, actionTypes, PageMode } from './types';
import { produce } from 'immer'
import { PlanSubscriptionStatus } from '../../models'

export default (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.PAGE_LOADED:
                if (action.currentPlan)
                    action.plans.forEach(p => { p.selected = p.id === action.currentPlan.productPlanId });

                newState.appliedDiscounts = action.appliedDiscounts;
                newState.prevPlan = action.prevPlan;
                newState.currentPlan = action.currentPlan;
                newState.cancellable = action.cancellable;
                newState.nextBillingDate = action.nextBillingDate;
                newState.pageMode = action.pageMode;
                newState.productPlans = action.plans;
                newState.showProgress = action.showProgress;
                newState.monerisProfileId = action.monerisProfileId;
                newState.monerisTestMode = action.monerisTestMode;
                newState.currentCard = action.currentCard;
                newState.taxes = action.taxes;
                break;
            case actionTypes.CHANGE_PROMO_CODE:
                newState.promoCode = action.promoCode;
                break;
            case actionTypes.GO_TO_PAYMENT:
                newState.pageMode = PageMode.PaymentInfo;
                newState.payResultInvoice = null;
                break;
            case actionTypes.SHOW_SELECT_PLAN:
                newState.pageMode = PageMode.SelectPlan;
                break;
            case actionTypes.SET_CREDIT_CARD_SUBMITTED:
                newState.creditCardSubmitted = action.newValue;
                break;
            case actionTypes.SHOW_PAY_RESULT:
                newState.payResultInvoice = action.invoice;
                newState.pageMode = PageMode.PayResult;
                break;
            case actionTypes.SET_CURRENT_CREDIT_CARD:
                newState.creditCardSetupFinished = true;
                newState.creditCardSubmitted = false;
                newState.currentCard = action.currentCard;
                break;
            case actionTypes.SELECT_PLAN:
                newState.productPlans.forEach(p => { p.selected = p.id === action.planId });

                newState.prevPlan = action.prevPlan;
                newState.currentPlan = action.currentPlan;
                newState.pageMode = action.pageMode;
                newState.cancellable = action.cancellable;
                break;
            case actionTypes.CANCEL_PLAN:
                newState.prevPlan = action.prevPlan;
                newState.currentPlan = action.currentPlan;
                newState.productPlans.forEach(p => { p.selected = p.id === action.currentPlan.productPlanId });
                newState.cancellable = action.currentPlan.status === PlanSubscriptionStatus.Active;
                if(action.resetCreditCard){
                    newState.creditCardSetupFinished = false;
                    newState.creditCardSubmitted = false;
                    newState.currentCard = '';
                }
                break;
            case actionTypes.REACTIVATE_PLAN:
                newState.currentPlan.status = PlanSubscriptionStatus.Active;
                newState.cancellable = true;
                break;
            case actionTypes.PROMO_CODE_ADDED: {
                newState.appliedDiscounts.push(action.discount);
                newState.promoCode = '';
                break;
            }
        }
    })
}