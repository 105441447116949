import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { Stepper, Step, StepLabel } from '@mui/material';
import { isMobileMode } from "../../helpers/utils";
import { PageMode } from '../../store/Subscription/types';
import { useTranslation } from "react-i18next";
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    progress: {
        marginBottom: 10,
    },
    mobileProgress: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        borderTop: `1px solid ${theme.palette.grey[500]}`,
        zIndex: 1500,
        boxShadow: '0px 4px 4px 4px rgba(0,0,0,0.2)'
    }
}));

interface Props {
    pageMode: PageMode,
    show: boolean
}

const Progress = (props: Props) => {
    const { t } = useTranslation();

    const { classes } = useStyles();

    const { show, pageMode } = props,
        mobileMode = isMobileMode();

    if (!show) return null;

    return (
        <Stepper className={mobileMode ? classes.mobileProgress : classes.progress} activeStep={pageMode}>
            <Step>
                <StepLabel>{!mobileMode && t("selectPlan")}</StepLabel>
            </Step>
            <Step>
                <StepLabel>{!mobileMode && t("paymentInfo")}</StepLabel>
            </Step>
            <Step>
                <StepLabel>{!mobileMode && t("paymentConfirmation")}</StepLabel>
            </Step>
            <Step>
                <StepLabel>{!mobileMode && t("chatAccounts")}</StepLabel>
            </Step>
        </Stepper>
    );
};

export default Progress;
