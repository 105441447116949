import React, { useState } from 'react';
import { ticketStatus, Contact, menuCommands } from '../../models';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import TextSMSIcon from '@mui/icons-material/Textsms';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import DoneIcon from '@mui/icons-material/Done';
import TransferIcon from '@mui/icons-material/SupervisorAccount';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InfoIcon from '@mui/icons-material/Info';
import CommentIcon from '@mui/icons-material/AddCommentOutlined';
import { useTranslation } from "react-i18next";
import { getUserInfo } from '../../helpers/auth';
import { canClaimTicket } from '../../store/Messages/types';

interface Props {
    contact: Contact,
    isLast?: boolean,
    onSelect?: (contactId: number) => void,
    onMenuItemClick: (contactId: number, command: menuCommands) => void
}

const ContactItemMenu = (props: Props) => {
    const { t } = useTranslation();

    const [anchor, setAnchor] = useState(null);

    const { contact } = props;

    function openMenu(e) {
        setAnchor(e.currentTarget);
    }

    function closeMenu() {
        setAnchor(null);
    }

    function clickMenuItem(e, command) {
        props.onMenuItemClick(contact.id, command);
        closeMenu();
    }

    if (!contact) return null;

    const menuItems = [{ icon: <InfoIcon />, command: menuCommands.info, text: t("ticketInfo") }];

    if (canClaimTicket(contact)) menuItems.push({ icon: <TextSMSIcon />, command: menuCommands.claim, text: t("claim") });

    const isAssignedToMe = contact.status === ticketStatus.assigned && contact.agentId === getUserInfo().id;

    if (isAssignedToMe) {
        menuItems.push({ icon: <PermIdentityIcon />, command: menuCommands.editName, text: t("editName") });
        menuItems.push({ icon: <CommentIcon />, command: menuCommands.comment, text: t("commentTicket") });
        menuItems.push({ icon: <DoneIcon />, command: menuCommands.close, text: t("close") });
        menuItems.push({ icon: <TransferIcon />, command: menuCommands.transfer, text: t("transferTicket") });
    }

    return (
        <>
            <MoreVertIcon onClick={openMenu} color='action' />

            <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={closeMenu}>
                {menuItems.map((m, i) => (
                    <MenuItem key={i} onClick={e => clickMenuItem(e, m.command)}>
                        <ListItemIcon>
                            {m.icon}
                        </ListItemIcon>
                        <ListItemText primary={m.text} />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default ContactItemMenu;
