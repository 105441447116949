import { ActionCreatorsMapObject } from 'redux';
import { DateRange, endUserInfo, DayMessages, Topic } from '../../models';
import { addDays } from '../../helpers/date';
import { ReducerAction as layoutReducerAction } from '../Layout/types'

export enum actionTypes {
    CHANGE_FIELD = 'CHAT_ANALYTICS_CHANGE_FIELD',
    LOADED = 'CHAT_ANALYTICS_LOADED',
    SELECTED_DATE = "CHAT_ANALYTICS_SELECTED_DATE",
}

export interface State {
    allMessagesCount: number,
    smsCount: number | null,
    facebookCount: number | null,
    instagramCount: number | null,
    webchatCount: number | null,
    newTicketsCount: number,
    continuingTicketsCount: number,
    uniqueNewTicketsCount: number,
    dateFilter: DateRange,
    endUserInfo: endUserInfo[],
    dayMessages: DayMessages[],
    topicCounts: Topic[],
    chatbotLoading: boolean,
}

export function write<K extends keyof State>(arg: State, key: K, value: State[K]): void {
    arg[key] = value;
};

export interface Actions extends ActionCreatorsMapObject {
    changeField: (name: keyof State, value: any) => ReducerAction,
    loadAnalytics: (dateFilter: DateRange) => (dispatch: Dispatch, getState: any) => void,
    loadChatbotAnalytics: (dateFilter: DateRange) => (dispatch: Dispatch, getState: any) => void,
    handleDateSelect: (dateFilter: DateRange) => ReducerAction,
}

export interface ReducerAction {
    type: actionTypes,
    name?: keyof State,
    value?: any,
    allMessagesCount?: number,
    smsCount?: number,
    facebookCount?: number,
    instagramCount?: number,
    webchatCount?: number,
    newTicketsCount?: number,
    continuingTicketsCount?: number,
    uniqueNewTicketsCount?: number,
    dateFilter?: DateRange,
    endUserInfo?: endUserInfo[],
    dayMessages?: DayMessages[],
}

export type Dispatch = (action: ReducerAction | layoutReducerAction) => void

export const initialState: State = {
    allMessagesCount: 0,
    smsCount: 0,
    facebookCount: 0,
    instagramCount: 0,
    webchatCount: 0,
    newTicketsCount: 0,
    continuingTicketsCount: 0,
    uniqueNewTicketsCount: 0,
    dateFilter: { start: addDays(new Date(), -30), end: new Date() },
    endUserInfo: [],
    dayMessages: [],
    topicCounts: [],
    chatbotLoading: false,
}