import * as React from 'react'
import { Typography, Paper } from '@mui/material'
import Layout from './Layout/Layout'
import { LockOutlined as LockIcon } from '@mui/icons-material'

const AccessDenied = () => (
    <Layout title="Access Denied">
        <Paper style={{ maxWidth: 400, margin: 'auto', textAlign: 'center', padding: 20 }}>
            <LockIcon color="disabled" style={{ fontSize: 120, marginBottom: 10 }}></LockIcon>
            <Typography variant="h4" gutterBottom>Access Denied</Typography>
            <Typography variant="body2">You don't have permission to see this page! Please contact the support team.</Typography>
        </Paper>
    </Layout>
)

export default AccessDenied;