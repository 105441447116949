import ajax from '../../helpers/ajax'
import { showAlert, showLoading, hideLoading } from '../Layout/actionCreators'
import { alertTypes } from '../../components/common/Alert'
import { sleep, getFullName, compareValues } from '../../helpers/utils'
import { getLocalTime, getUTCTime } from '../../helpers/date'
import { HubConnectionBuilder, HubConnectionState, HubConnection } from '@microsoft/signalr'
import { Contact, dialogModes, ticketStatus, menuCommands, TicketHistory, User, MessageDelivery, DeliveryStatus, platforms, TicketStatusInfo, ReadReceipt } from '../../models'
import { getServerUrl } from '../../helpers/utils'
import { actionTypes, Actions, Dispatch, State, clientUniqueId } from './types'
import { getUserInfo } from '../../helpers/auth'

const selectContactAction = contactId => ({ type: actionTypes.SELECT_CONTACT, contactId })

const startHubConnection = async (hubConnection: HubConnection, dispatch: Dispatch) => {
    let count = 0

    const tryToStart = async (hubConnection: HubConnection) => {
        try {
            await hubConnection.start()
            console.log('Connection started!')

            return true
        }
        catch (err) {
            console.log('Error while establishing connection :(', err)

            if (count++ > 3) {
                return false
            }

            await sleep(3000)
            return await tryToStart(hubConnection)
        }
    }

    await tryToStart(hubConnection)
}

const loadAllContactsWithMessages = async (dispatch, getState) => {
    const state: State = getState().messages;
    const { contactFilter } = state;
    const { loadOldTickets } = contactFilter;

    const response = await ajax.get(`Ticket/GetAllContactsWithMessages?loadOldTickets=${loadOldTickets || false}`)

    if (await ajax.errorOccured(response, "Load failed.", dispatch, getState))
        return null;

    const result: { contacts: Contact[], companyId: number, agents: User[], ticketHistories: TicketHistory[], readReceipts: ReadReceipt[] } = await response.json();

    return result;
}

const updateLastRead = async (contactId, dispatch) => {
    dispatch({ type: actionTypes.UPDATE_LAST_READ, contactId });

    const response = await ajax.post(`ticket/read`, { userId: getUserInfo().id, ticketId: contactId });

    if (!response.ok) {
        console.warn("Failed to update last read")
    }
}


export default {
    changeField: (name, value) => ({ type: actionTypes.CHANGE_FIELD, name, value }),
    changeFilter: filter => ({ type: actionTypes.CHANGE_FILTER, filter }),
    cancelDialog: () => ({ type: actionTypes.CANCEL_DIALOG }),
    clearContactSelection: () => ({ type: actionTypes.CLEAR_CONTACT_SELECTION }),
    selectContact: contactId => async (dispatch, getState) => {
        dispatch(selectContactAction(contactId))
    },
    setupHubConnection: () => async (dispatch, getState) => {
        const companyId = getUserInfo()?.companyId;

        const hubConnection = new HubConnectionBuilder()
            .withUrl(`${getServerUrl()}/chat`)
            .withAutomaticReconnect()
            .build();

        hubConnection.onreconnected(connectionId => {
            console.log('Hub reconnected. ', connectionId);
        });

        (global as any)._hubConnection = hubConnection;

        hubConnection.on('ReceiveMessage_' + companyId, async (receivedMessage) => {
            receivedMessage.messages.forEach(m => {
                m.createdDate = m.createdDate.replace("Z", "");
            });

            dispatch({ type: actionTypes.CHAT_RECEIVED, newContact: receivedMessage })

            const state: State = getState().messages,
                { selectedContactId } = state;

            if (receivedMessage.id === selectedContactId) {
                updateLastRead(selectedContactId, dispatch);
            }
        });

        hubConnection.on('MessageStatus_' + companyId, (statusInfo) => {
            const messageDelivery: MessageDelivery = statusInfo;

            dispatch({ type: actionTypes.CHAT_STATUS_UPDATE, messageDelivery })
        });

        hubConnection.on('TicketStatus_' + companyId, (statusInfo) => {
            const ticketStatusInfo: TicketStatusInfo = statusInfo;

            dispatch({ type: actionTypes.TICKET_STATUS_UPDATE, ticketStatusInfo })
        });

        hubConnection.on('close', async error => {
            console.log('Connection has been closed. ', error)

            // if (getPageVisibility() === "visible") {
            //     console.log('page is visible is connection is closed. So trying to reconnect.')
            //     await startHubConnection(hubConnection, dispatch)
            // }
        });

        await startHubConnection(hubConnection, dispatch);

        dispatch({ type: actionTypes.CHANGE_FIELD, name: 'hubConnection', value: hubConnection });

    },
    loadContacts: () => async (dispatch, getState) => {
        dispatch(showLoading());

        const result = await loadAllContactsWithMessages(dispatch, getState);

        if (!result) return;

        dispatch(hideLoading());

        const contacts = result.contacts.sort(compareValues("lastMessageDate", "desc"));
        contacts.forEach(c => {
            const receipt = result.readReceipts.find(e => e.ticketId === c.id);
            c.lastRead = receipt?.lastRead;
        });

        dispatch({
            type: actionTypes.CONTACTS_LOADED,
            contacts,
            chatListenerCode: result.companyId,
            companyId: result.companyId,
            agents: result.agents,
            ticketHistories: result.ticketHistories,
        });
    },
    stopHub: () => (dispatch, getState) => {
        const state: State = getState().messages,
            { hubConnection } = state;

        if (hubConnection)
            state.hubConnection
                .stop()
                .then(() => console.log('Connection stopped!'))
                .catch(err => console.log('Error while terminating connection :(', err));
    },
    sendMessage: () => async (dispatch, getState) => {
        const messageId = getLocalTime().getTime(),
            state: State = getState().messages,
            { selectedContactId, composeMessage } = state;

        if (selectedContactId === 0) return;

        dispatch({ type: actionTypes.SEND_MESSAGE, messageId });

        const response = await ajax.post('Ticket/SendMessage', { ticketId: selectedContactId, message: composeMessage, clientUniqueId });

        if (await ajax.errorOccured(response, "Send message failed.", dispatch, getState)) {
            dispatch({ type: actionTypes.MESSAGE_SEND_FAILED, messageId });
            return;
        }

        const newMessageId = await response.json();

        dispatch({ type: actionTypes.MESSAGE_SENT, messageId, newMessageId });
    },
    resendSms: (messageId) => async (dispatch, getState) => {
        const state: State = getState().messages;
        const selectedContactId = state.selectedContactId;

        if (selectedContactId === 0) return;

        const messageDelivery: MessageDelivery = {
            ticketId: selectedContactId,
            messageId,
            deliveryStatus: DeliveryStatus.Pending
        };
        dispatch({ type: actionTypes.CHAT_STATUS_UPDATE, messageDelivery })

        const response = await ajax.post('Ticket/ResendSms', messageId);

        if (await ajax.errorOccured(response, "Send message failed.", dispatch, getState)) {
            dispatch({ type: actionTypes.MESSAGE_SEND_FAILED, messageId });
            return;
        }
    },
    contactMenuItemClick: (contactId, command) => async (dispatch, getState) => {
        switch (command) {
            case menuCommands.claim:
                dispatch(showLoading());
                const response = await ajax.get(`Ticket/Claim/${contactId}`);

                if (await ajax.errorOccured(response, "Claim ticket failed.", dispatch, getState))
                    return;

                const claimResult: { success: boolean, status: ticketStatus, agentId: number, agentName: string } = await response.json();

                dispatch(hideLoading());

                if (claimResult.success) {
                    dispatch({ type: actionTypes.CONTACT_CLAIMED, contactId, agentId: claimResult.agentId, agentName: claimResult.agentName })
                    dispatch(selectContactAction(contactId))
                }
                else {
                    dispatch(showAlert('The ticket has been taken by another user.', alertTypes.warning));
                    dispatch({ type: actionTypes.CLAIM_CONTACT_FAILED, contactId, status: claimResult.status, agentId: claimResult.agentId, agentName: claimResult.agentName });
                }

                break;
            case menuCommands.close:
                dispatch({ type: actionTypes.SET_ACTIVE_CONTACT_FOR_DIALOG, contactId });
                dispatch({ type: actionTypes.OPEN_CLOSE_TICKET_DIALOG });
                break;
            case menuCommands.editName:
                dispatch({ type: actionTypes.SET_ACTIVE_CONTACT_FOR_DIALOG, contactId });
                dispatch({ type: actionTypes.OPEN_EDIT_NAME_DIALOG });
                break;
            case menuCommands.info:
                dispatch({ type: actionTypes.SET_ACTIVE_CONTACT_FOR_DIALOG, contactId });
                dispatch({ type: actionTypes.OPEN_INFO_DIALOG });
                break;
            case menuCommands.transfer:
                dispatch({ type: actionTypes.SET_ACTIVE_CONTACT_FOR_DIALOG, contactId });
                dispatch({ type: actionTypes.OPEN_TRANSFER_DIALOG });
                break;
            case menuCommands.comment:
                dispatch({ type: actionTypes.SET_ACTIVE_CONTACT_FOR_DIALOG, contactId });
                dispatch({ type: actionTypes.OPEN_COMMENT_DIALOG });
                break;
            default:
        }
    },
    submitDialog: () => async (dispatch, getState) => {
        const state: State = getState().messages,
            { dialogMode, dialogComment, dialogContactName, agents } = state,
            contact = state.contacts.find(c => c.isActiveForDialog);

        dispatch(showLoading());

        switch (dialogMode) {
            case dialogModes.transfer: {
                var selectedAgent = agents.find(a => a.selected);

                if (dialogComment === '' || !selectedAgent)
                    break;

                const response = await ajax.post(`Ticket/Transfer`, { ticketId: contact.id, comment: dialogComment, agentId: selectedAgent.id });

                if (await ajax.errorOccured(response, "Transfer ticket failed.", dispatch, getState)) {
                    break;
                }

                dispatch({ type: actionTypes.CONTACT_TRANSFERRED });
                break;
            }
            case dialogModes.close: {
                if (dialogComment === '')
                    break;

                const response = await ajax.post(`Ticket/Close`, { ticketId: contact.id, comment: dialogComment });

                if (await ajax.errorOccured(response, "Close ticket failed.", dispatch, getState)) {
                    break;
                }

                dispatch({ type: actionTypes.CLOSE_CONTACT });
                break;
            }
            case dialogModes.comment: {
                if (dialogComment === '')
                    break;

                const response = await ajax.post(`Ticket/Comment`, { ticketId: contact.id, comment: dialogComment });

                if (await ajax.errorOccured(response, "Comment failed.", dispatch, getState)) {
                    break;
                }

                let user = getUserInfo();
                dispatch({
                    type: actionTypes.COMMENT_ADDED, ticketComment: {
                        ticketId: contact.id, comment: dialogComment, createdDate: getUTCTime(), agentName: getFullName(user), agentId: user.id, status: ticketStatus.comment
                    }
                });
                break;
            }
            case dialogModes.editName: {
                if (dialogContactName === '')
                    break;

                const response = await ajax.post(`Ticket/editContactName`, { ticketId: contact.id, contactName: dialogContactName });

                if (await ajax.errorOccured(response, "Edit contact name failed.", dispatch, getState)) {
                    break;
                }

                dispatch({ type: actionTypes.CONTACT_NAME_UPDATED });
                break;
            }
            default:
                break;
        }

        dispatch({ type: actionTypes.CANCEL_DIALOG });
        dispatch(hideLoading());

    },
    checkHubConnection: () => async (dispatch, getState) => {
        // if (getPageVisibility() !== "visible")
        //     return

        const state: State = getState().messages,
            { hubConnection } = state

        if (hubConnection && hubConnection.state === HubConnectionState.Disconnected) {
            //https://docs.microsoft.com/en-us/aspnet/signalr/overview/guide-to-the-api/handling-connection-lifetime-events

            //reload information
            const result = await loadAllContactsWithMessages(dispatch, getState)

            if (!result)
                return

            await startHubConnection(hubConnection, dispatch);

            const contacts = result.contacts.sort(compareValues("lastMessageDate", "desc"));
            contacts.forEach(c => {
                const receipt = result.readReceipts.find(e => e.ticketId === c.id);
                c.lastRead = receipt?.lastRead;
            });

            dispatch({
                type: actionTypes.CONTACTS_RELOADED,
                contacts,
                agents: result.agents,
                ticketHistories: result.ticketHistories
            })
        }

    },
    updateLastRead: (contactId) => async (dispatch, getState) => {
        updateLastRead(contactId, dispatch);
    },
} as Actions