import * as React from "react";
import { Grid } from "@mui/material";

interface Props {
    name: string,
    amount: string,
}

const ReceiptItem = (props: Props) => {
    const { name, amount } = props;

    return (
        <>
            <Grid item xs={8}>
                {name}
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "right" }}>
                {amount}
            </Grid>
        </>
    );
}

export default ReceiptItem;