import React, { useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserPageLayout from './UserPageLayout';
import actionCreators from '../../store/SignUp/actionCreators';
import { State, Actions } from '../../store/SignUp/types';
import { FormControl, InputLabel, Select, MenuItem, Grid, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import GooglePlace from '../common/GooglePlace';
import Recaptcha from '../Recaptcha'
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../helpers/utils";
import PhoneNumberInput from "../common/PhoneNumberInput";

interface Props { }

const SignUp = (props: State & Actions & Props) => {
    const { t } = useTranslation();

    useEffect(() => {
        setPageTitle(t, "signUp");

        props.loadForm();
    }, [])

    const handleChange = (e) => {
        props.changeField(e.target.name, e.target.value);
    }

    const handleAddressChange = (e) => {
        props.changeAddressField(e.target.name, e.target.value);
    }

    const handleRecaptcha = (value) => {
        props.changeField('reCaptcha', value);
    }

    const { loading, isSubmitted, done, firstName, lastName, phone, emailAddress, companyName, companyType,
        address, message, alertType, companyTypes, legalTermAccepted, unitNumber } = props;

    return (
        <UserPageLayout
            title={t("signUp")}
            signupLayout
            buttonText={t("submit")}
            loading={loading}
            buttonDisabled={isSubmitted && loading || done}
            alertMessage={message}
            alertType={alertType}
            onAlertClose={props.onAlertClose}
            onSubmit={props.submit}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        error={isSubmitted && firstName === ''}
                        name="firstName"
                        value={firstName}
                        label={t("firstName")}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        error={isSubmitted && lastName === ''}
                        name="lastName"
                        value={lastName}
                        label={t("lastName")}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        error={isSubmitted && companyName === ''}
                        name="companyName"
                        value={companyName}
                        label={t("companyName")}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl required fullWidth error={isSubmitted && companyType === ''}>
                        <InputLabel htmlFor="companyType">{t("companyType")}</InputLabel>
                        <Select
                            name="companyType"
                            value={companyType}
                            onChange={handleChange}
                            label={t("companyType")}
                        >
                            {
                                companyTypes.map((c, i) => (
                                    <MenuItem key={i} value={c.id}>{c.title}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        error={isSubmitted && emailAddress === ''}
                        name="emailAddress"
                        value={emailAddress}
                        label={t("emailAddress")}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControl required fullWidth error={isSubmitted && phone === ''}>
                        <InputLabel htmlFor="phone">{t("phoneNumber")}</InputLabel>
                        <PhoneNumberInput
                            error={isSubmitted && phone === ''}
                            value={phone}
                            onChange={handleChange}
                            name="phone"
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <GooglePlace
                        label={t("address")}
                        required={true}
                        error={isSubmitted && !address}
                        onPlaceSelected={place => props.changeAddress(place)}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        name="streetNumber"
                        type="number"
                        disabled={!address}
                        value={address ? address.streetNumber : ''}
                        label={t("streetNumber")}
                        onChange={handleAddressChange}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        name="route"
                        disabled={!address}
                        value={address ? address.route : ''}
                        label={t("address")}
                        onChange={handleAddressChange}
                    />
                </Grid>
                <Grid item xs={4} sx={{ padding: '3px' }}>
                    <TextField
                        fullWidth
                        name="unitNumber"
                        disabled={!address}
                        label={t("unitNumber")}
                        onChange={handleChange}
                        value={unitNumber}
                    />
                </Grid>
                <Grid item xs={4} sx={{ padding: '3px' }}>
                    <TextField
                        fullWidth
                        name="city"
                        disabled={!address}
                        value={address ? address.city : ''}
                        label={t("city")}
                        onChange={handleAddressChange}
                    />
                </Grid>
                <Grid item xs={4} sx={{ padding: '3px' }}>
                    <TextField
                        fullWidth
                        name="province"
                        disabled={!address}
                        value={address ? address.province : ''}
                        label={t("province")}
                        onChange={handleAddressChange}
                    />
                </Grid>
                <Grid item xs={8} sx={{ padding: '3px' }}>
                    <TextField
                        fullWidth
                        name="country"
                        disabled={!address}
                        value={address ? address.country : ''}
                        label={t("country")}
                        onChange={handleAddressChange}
                    />
                </Grid>
                <Grid item xs={4} sx={{ padding: '3px' }}>
                    <TextField
                        fullWidth
                        name="postalCode"
                        disabled={!address}
                        value={address ? address.postalCode : ''}
                        label={t("postalCode")}
                        onChange={handleAddressChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Recaptcha onChange={handleRecaptcha} />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <FormControlLabel
                        label={
                            <Typography
                                variant='body2'
                                color={isSubmitted && !legalTermAccepted ? "error" : "inherit"}
                            >
                                {t("iHaveReadAndAcceptThe")}
                                {' '}
                                <a href="https://www.spreevel.com/terms-of-use.html" target='_blank'>{t("termsAndConditions")}</a>
                            </Typography>
                        }
                        control={
                            <Checkbox
                                checked={legalTermAccepted}
                                name="legalTermAccepted"
                                onChange={e => props.changeField(e.target.name, e.target.checked)}
                                color="primary"
                            />
                        }
                    />
                </Grid>
            </Grid>
        </UserPageLayout>
    );
}

const signupWithStore = connect(
    (state: any) => state.signUp,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SignUp);

export default signupWithStore;