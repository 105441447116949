import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Button, DialogActions, DialogContent, Dialog, DialogTitle, FormControlLabel, Checkbox, FormControl } from "@mui/material";
import { Box } from '@mui/system';
import { JsonField, TemplateContent } from '../../models';
import { isEmpty } from '../../helpers/utils';

interface Props {
    errors: any,
    visible: boolean,
    content: TemplateContent,
    changeContentField: (name: keyof TemplateContent, value: any) => void,
    validate: () => void,
    close: () => void,
    submit: () => object,
    changeJsonField: (infoFieldId: number, name: keyof JsonField, value: any) => void,
    addField: () => void,
    deleteField: (infoFieldId: number) => void,
}


const TemplateContentInfo = (props: Props) => {
    const { t } = useTranslation();
    const { visible, errors, content, close, changeContentField, validate, changeJsonField, addField } = props;

    if (!visible || !content) return null;

    const { id, title, message, infoFields, children } = content;

    const editMode = id > 0;

    const handleChange = (e) => {
        changeContentField(e.target.name, e.target.value);

        if (!isEmpty(errors)) validate();
    }

    const handleJsonChange = (id: number, e) => {
        changeJsonField(id, e.target.name, e.target.value);

        if (!isEmpty(errors)) validate();
    }

    const submit = async () => {
        const errors = await props.submit();
        if (!isEmpty(errors)) return;
        close();
    }

    const handleCheckbox = (e) => {
        changeContentField(e.target.name, e.target.checked);
    }

    const finalNode = content.finalNode && (!children || children.length === 0);

    return (
        <Dialog fullWidth maxWidth="md" open={visible} onClose={close}>
            <DialogTitle>{editMode ? t("content") : t("addContent")}</DialogTitle>
            <DialogContent>
                <TextField
                    margin='normal'
                    fullWidth
                    required
                    inputProps={{ maxLength: 256 }}
                    error={!!errors["title"]}
                    helperText={!!errors["title"] && t(errors["title"])}
                    name="title"
                    value={title}
                    label={t("title")}
                    onChange={handleChange}
                />
                <TextField
                    margin='normal'
                    fullWidth
                    required
                    inputProps={{ maxLength: 1024 }}
                    error={!!errors["message"]}
                    helperText={!!errors["message"] && t(errors["message"])}
                    name="message"
                    value={message}
                    label={t("message")}
                    onChange={handleChange}
                />
                {(!children || children.length === 0) && <FormControl fullWidth margin='normal'>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="finalNode"
                                color="primary"
                                checked={finalNode}
                                onClick={handleCheckbox}
                            />
                        }
                        label={t('finalNode') as any}
                    />
                </FormControl>}
                {finalNode &&
                    <Button onClick={addField} color='primary'>{t('addOtherInfo')}</Button>}
                {finalNode && infoFields && infoFields.map(f =>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }} key={f.id}>
                        <Box sx={{ width: '30%' }}>
                            <TextField
                                margin='normal'
                                fullWidth
                                required
                                inputProps={{ maxLength: 256 }}
                                name="key"
                                value={f.key}
                                label={t("name")}
                                onChange={(e) => handleJsonChange(f.id, e)}
                            />
                        </Box>
                        <Box sx={{ paddingLeft: 16, width: '66%' }}>
                            <TextField
                                margin='normal'
                                fullWidth
                                required
                                inputProps={{ maxLength: 1024 }}
                                name="value"
                                value={f.value}
                                label={t("value")}
                                onChange={(e) => handleJsonChange(f.id, e)}
                            />
                        </Box>
                    </Box>)}


            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={close}>
                    {t("cancel")}
                </Button>
                <Button color="primary" variant="contained" onClick={submit}>
                    {editMode ? t("update") : t("add")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TemplateContentInfo;