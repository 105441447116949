import { ActionCreatorsMapObject } from 'redux'
import { Role } from '../../models'

export enum actionTypes {
    LOADED = 'UPDATEUSER_LOADED',
    CHANGE_FIELD = 'UPDATEUSER_CHANGE_FIELD',
    ADD_ROLE = 'UPDATEUSER_ADD_ROLE',
    REMOVE_ROLE = 'UPDATEUSER_REMOVE_ROLE',
    UPDATED_USER = 'UPDATEUSER_ADDED_USER',
    ROLES_LOADED = 'UPDATEUSER_ROLES_LOADED',
    FILE_CHANGED = 'UPDATEUSER_FILE_CHANGED',
    SUBMIT = 'UPDATEUSER_SUBMIT',
    RESET_PAGE = 'UPDATEUSER_RESET_PAGE'
}

export interface State {
    isSubmitted: boolean,
    id: number,
    firstName: string,
    lastName: string,
    phone: string,
    emailAddress: string,
    gender: number,
    picture: string,
    picFile: File,
    currentRoles: Role[],
    roles: Role[]
}

export interface Actions extends ActionCreatorsMapObject {
    loadUserInfoUserRoles: (selectedUserid: string) => (dispatch: Dispatch, getState: any) => void,
    addRole: (role: Role) => ReducerAction,
    removeRole: (role: Role) => ReducerAction,
    submit: (isAdd: boolean) => (dispatch: Dispatch, getState: any) => void,
    changeField: (name: any, value: any) => ReducerAction,
    onFileChange?: (file: File) => ReducerAction,
    loadRoles: () => (dispatch: Dispatch, getState: any) => void
}

export const initialState: State = {
    isSubmitted: false,
    id: 0,
    firstName: '',
    lastName: '',
    phone: '',
    emailAddress: '',
    gender: 0,
    picture: '',
    picFile: null,
    currentRoles: [],
    roles: []
}

export interface ReducerAction {
    type: actionTypes | string,
    id?: number,
    firstName?: string,
    lastName?: string,
    phone?: string,
    emailAddress?: string,
    gender?: number,
    picture?: string,
    picFile?: File,
    currentRoles?: Role[],
    roles?: Role[],
    role?: Role,
    name?: string,
    value?: string
}

export type Dispatch = (action: ReducerAction) => void