import * as React from "react";
import { Route } from "react-router";
import PrivateRoute from "./components/common/PrivateRoute";
import CssBaseline from "@mui/material/CssBaseline";
import Home from "./components/Home/Home";
import Login from "./components/UserComponents/Login";
import CreatePassword from "./components/UserComponents/CreatePassword";
import ForgotPassword from "./components/UserComponents/ForgotPassword";
import AddAutoReplies from "./components/ChatAccounts/AddAutoReplies";
import ManageAutoReplies from "./components/ChatAccounts/ManageAutoReplies";
import Invoices from "./components/Subscription/Invoices";
import Dashboard from "./components/Dashboard";
import Messages from "./components/Messages/Messages";
import UploadPowerBIDoc from "./components/UploadPowerBIDoc";
import ManageUser from "./components/ManageUser";
import UpdateUser from "./components/UpdateUser";
import Profile from "./components/Profile";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import BuyBandwidthNumber from "./components/ChatAccounts/BuyBandwidthNumber";
import ChatAccounts from "./components/ChatAccounts/ChatAccounts";
import AccessDenied from "./components/AccessDenied";
import Subscription from "./components/Subscription/Subscription";
import SignUp from "./components/UserComponents/SignUp";
import MessagingSettings from "./components/MessagingSettings/MessagingSettings";
import ManageKnowledge from "./components/ChatAI/ManageKnowledge";
import TemplateContent from "./components/ChatAI/TemplateContent";
import Analytics from "./components/Analytics";
import CompanyProfile from "./components/CompanyProfile";
import { withTranslation } from "react-i18next";

const theme = createTheme({
    palette: {
        primary: {
            main: '#00AEFF',
            contrastText: '#FFFFFF'
        }
    },
    components: {
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },
        // MuiSwitch: {
        //     styleOverrides: {
        //         switchBase: {
        //             color: '#fff',
        //             '&.Mui-checked': {
        //                 color: green[500],
        //             },
        //             '&.Mui-checked +  .MuiSwitch-track': {
        //                 backgroundColor: green[500],
        //             },
        //         },
        //     }
        // }
    }
});

const App = () => (
    <ThemeProvider theme={theme}>
        <CssBaseline />

        <Route path="/login" component={Login} />
        <Route path="/accessDenied" component={AccessDenied} />
        <Route path="/signup" component={SignUp} />
        <Route path="/resetPassword" component={CreatePassword} />
        <Route path="/ForgotPassword" component={ForgotPassword} />

        <PrivateRoute path="/Adduser" component={UpdateUser} />
        <PrivateRoute path="/AddAutoReplies" component={AddAutoReplies} />
        <PrivateRoute path="/AutoReplies" component={ManageAutoReplies} />
        <PrivateRoute path="/Invoices" component={Invoices} />
        <PrivateRoute path="/Dashboard/:id" component={Dashboard} />
        <PrivateRoute path="/Messages" component={Messages} />
        <PrivateRoute path="/UploadDoc" component={UploadPowerBIDoc} />
        <PrivateRoute path="/ManageUser" component={ManageUser} />
        <PrivateRoute path="/UpdateUser/:id" component={UpdateUser} />
        <PrivateRoute path="/Profile" component={Profile} />
        <PrivateRoute path="/BuyNumberB" component={BuyBandwidthNumber} />
        <PrivateRoute path="/ChatAccounts" component={ChatAccounts} />
        <PrivateRoute path="/Subscription" component={Subscription} />
        <PrivateRoute path="/MessagingSettings" component={MessagingSettings} />
        <PrivateRoute path="/ManageKnowledge" component={ManageKnowledge} />
        <PrivateRoute path="/Template/:id" component={TemplateContent} />
        <PrivateRoute path="/Analytics" component={Analytics} />
        <PrivateRoute path="/CompanyProfile" component={CompanyProfile} />
        <PrivateRoute exact path="/" component={Home} />

    </ThemeProvider>
);

export default withTranslation()(App);
