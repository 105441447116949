import React, { useState, useEffect } from 'react'

const issDocumentVisible = () => !document["hidden"];

const usePageVisibility = () => {
    const [isVisible, setIsVisible] = useState(issDocumentVisible());
    const onVisibilityChange = () => setIsVisible(issDocumentVisible());

    useEffect(() => {
        const visibilityChange = "visibilitychange";

        document.addEventListener(visibilityChange, onVisibilityChange, false);

        return () => {
            document.removeEventListener(visibilityChange, onVisibilityChange);
        }
    })

    return isVisible;
}

export default usePageVisibility;