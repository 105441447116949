import { isSafari, getFullName, setUnreadMessagesCount, interestedInTicket, isUnreadMessage } from '../../helpers/utils'
import { getTime, getUTCTime } from '../../helpers/date'
import { Contact, dialogModes, ticketStatus, platforms, ContactFilter, TicketHistory, MessageType, DeliveryStatus } from '../../models'
import { getUserInfo } from '../../helpers/auth'
import { State, initialState, actionTypes, clientUniqueId, ReducerAction } from './types'
import { produce, Draft } from 'immer'

let messageSound: HTMLAudioElement = new Audio("/message.mp3");
const playSound = () => !isSafari() && messageSound.play();

const reducer: (state: State, action: ReducerAction) => State = (state, action) => {
    const filterContacts = (newState: Draft<State>, contacts: Contact[], filter?: ContactFilter) => {
        filter = filter || state.contactFilter;

        const contactShouldBeShown = (c: Contact) => {
            const { searchText, platformsFacebookSelected, platformsInstagramSelected, platformsWebchatSelected, platformsSMSSelected, statusesNewSelected,
                statusesAssignedSelected: statusesAssigendSelected, statusesTransferredSelected, statusesResolvedSelected,
                ownershipMineSelected, ownershipOthersSelected } = filter,
                text = searchText.toLowerCase(),
                currentUserId = getUserInfo().id,
                isMine = c.agentId === currentUserId;

            switch (c.platform) {
                case platforms.facebook:
                    if (!platformsFacebookSelected) return false;
                    break;
                case platforms.instagram:
                    if (!platformsInstagramSelected) return false;
                    break;
                case platforms.webChat:
                    if (!platformsWebchatSelected) return false;
                    break;
                case platforms.bandwidth:
                case platforms.twilio:
                    if (!platformsSMSSelected) return false;
                    break;
            }

            if (c.status !== ticketStatus.open) {
                if (!ownershipMineSelected && isMine) return false;
                if (!ownershipOthersSelected && !isMine) return false;
            }

            switch (c.status) {
                case ticketStatus.open:
                    if (!statusesNewSelected) return false;
                    break;
                case ticketStatus.transferred:
                    if (!statusesTransferredSelected) return false;
                    break;
                case ticketStatus.assigned:
                    if (!statusesAssigendSelected) return false;
                    break;
                case ticketStatus.resolved:
                    if (!statusesResolvedSelected) return false;
                    break;
            }

            return c.endUserId.toLowerCase().indexOf(text) >= 0 || (c.endUserName || '').toLowerCase().indexOf(text) >= 0;
        }

        //filter and unselect the filtered contact
        contacts.forEach(c => {
            c.show = contactShouldBeShown(c);

            if (!c.show && c.selected){
                c.selected = false;
                newState.selectedContactId = 0;
            }
        })

        return contacts;
    }

    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.CHANGE_FIELD: {
                if (action.name === "agent")
                    newState.agents.forEach(a => a.selected = action.value && a.id === parseInt(action.value));
                else
                    newState[action.name] = action.value;
                break;
            }
            case actionTypes.CONTACTS_LOADED: {
                newState.contacts = filterContacts(newState, action.contacts);
                newState.agents = action.agents;
                newState.chatListenerCode = action.chatListenerCode;
                newState.companyId = action.companyId;
                newState.ticketHistories = action.ticketHistories;
                break;
            }
            case actionTypes.CONTACTS_RELOADED: {
                newState.contacts = filterContacts(newState, action.contacts);
                newState.agents = action.agents;
                newState.ticketHistories = action.ticketHistories;
                break;
            }
            case actionTypes.SELECT_CONTACT: {
                newState.selectedContactId = action.contactId;

                const contact = newState.contacts.find(c => c.id === action.contactId);

                if (!contact.selected) {
                    newState.contacts.forEach(contact => contact.selected = contact.id === action.contactId);
                    newState.composeMessage = '';
                }
                break;
            }
            case actionTypes.CLEAR_CONTACT_SELECTION: {
                newState.selectedContactId = 0;
                newState.contacts.forEach(contact => contact.selected = false);
                break;
            }
            case actionTypes.SEND_MESSAGE: {
                let contact = newState.contacts.find(c => c.selected);
                contact.messages.push({
                    id: action.messageId,
                    ticketId: contact.id,
                    messageType: MessageType.Outbound,
                    deliveryStatus: DeliveryStatus.Pending,
                    body: state.composeMessage,
                    createdDate: getUTCTime()
                });
                contact.lastMessageDate = getUTCTime();

                newState.contacts.forEach(function (c, i) {
                    if (c.id === contact.id) {
                        newState.contacts.splice(i, 1);
                        newState.contacts.unshift(c);
                    }
                });

                newState.composeMessage = '';
                break;
            }
            case actionTypes.MESSAGE_SENT: {
                let contact = newState.contacts.find(c => c.selected);
                let message = contact.messages.find(m => m.id === action.messageId);
                message.id = action.newMessageId;
                message.deliveryStatus = DeliveryStatus.Delivered;
                break;
            }
            case actionTypes.MESSAGE_SEND_FAILED: {
                let contact = newState.contacts.find(c => c.selected);
                let message = contact.messages.find(m => m.id === action.messageId);
                message.deliveryStatus = DeliveryStatus.Failed;
                break
            }
            case actionTypes.CONTACT_CLAIMED: {
                let contact = newState.contacts.find(c => c.id === action.contactId);
                contact.status = ticketStatus.assigned;
                contact.agentId = action.agentId;
                contact.agentName = action.agentName;
                newState.contacts = filterContacts(newState, newState.contacts);
                break;
            }
            case actionTypes.CLAIM_CONTACT_FAILED: {
                let contact = newState.contacts.find(c => c.id === action.contactId);

                contact.agentId = action.agentId;
                contact.agentName = action.agentName;
                contact.status = action.status;
                break;
            }
            case actionTypes.SET_ACTIVE_CONTACT_FOR_DIALOG: {
                newState.contacts.forEach(c => c.isActiveForDialog = c.id === action.contactId);
                break;
            }
            case actionTypes.OPEN_CLOSE_TICKET_DIALOG: {
                newState.dialogOpen = true;
                newState.dialogMode = dialogModes.close;
                newState.dialogComment = '';
                break;
            }
            case actionTypes.CANCEL_DIALOG: {
                newState.dialogOpen = false;
                break;
            }
            case actionTypes.CLOSE_CONTACT: {
                let activeContact = newState.contacts.find(c => c.isActiveForDialog);
                if (activeContact) activeContact.status = ticketStatus.resolved;
                break;
            }
            case actionTypes.OPEN_EDIT_NAME_DIALOG: {
                let contact = newState.contacts.find(c => c.isActiveForDialog),
                    { endUserName } = contact;

                newState.dialogOpen = true;
                newState.dialogMode = dialogModes.editName;
                newState.dialogContactName = endUserName || '';
                break;
            }
            case actionTypes.CONTACT_NAME_UPDATED: {
                let contact = newState.contacts.find(c => c.isActiveForDialog);
                contact.endUserName = state.dialogContactName;
                newState.contacts = filterContacts(newState, newState.contacts);
                break;
            }
            case actionTypes.OPEN_INFO_DIALOG: {
                newState.dialogOpen = true;
                newState.dialogMode = dialogModes.info;
                break;
            }
            case actionTypes.OPEN_TRANSFER_DIALOG: {
                newState.agents.forEach(a => a.selected = false);

                newState.dialogOpen = true;
                newState.dialogMode = dialogModes.transfer;
                newState.dialogComment = '';
                break;
            }
            case actionTypes.OPEN_COMMENT_DIALOG: {
                newState.dialogOpen = true;
                newState.dialogMode = dialogModes.comment;
                newState.dialogComment = '';
                break;
            }
            case actionTypes.CONTACT_TRANSFERRED: {
                let contact = newState.contacts.find(c => c.isActiveForDialog),
                    selectedAgent = state.agents.find(a => a.selected);

                contact.status = ticketStatus.transferred;
                contact.agentId = selectedAgent.id;
                contact.agentName = getFullName(selectedAgent);
                break;
            }
            case actionTypes.CHAT_RECEIVED: {
                let newContact: Contact = action.newContact,
                    newTicketHistories: TicketHistory[] = [...state.ticketHistories];

                if (newContact["ticketHistory"]) {
                    newTicketHistories.push({ ...newContact["ticketHistory"] });
                    newContact["ticketHistory"] = null;
                }

                let contact = newState.contacts.find(c => c.id === newContact.id);
                if (contact) {
                    if (clientUniqueId !== newContact.clientUniqueId) {
                        contact.status = newContact.status;
                        contact.agentId = newContact.agentId;
                        contact.agentName = newContact.agentName;
                        contact.endUserName = newContact.endUserName;

                        if (newContact.messages[0].body !== '') {
                            contact.messages.push(newContact.messages[0]);
                            contact.lastMessageDate = newContact.messages[0].createdDate;

                            newState.contacts.forEach(function (c, i) {
                                if (c.id === contact.id) {
                                    newState.contacts.splice(i, 1);
                                    newState.contacts.unshift(c);
                                }
                            });

                            if (newContact.status === ticketStatus.assigned && newContact.agentId === getUserInfo().id)
                                playSound();
                        }
                    }
                }
                else {
                    if (newContact.messages.length > 1) newContact.messages.sort((m1, m2) => m1.id - m2.id);
                    newState.contacts.unshift(newContact);
                    playSound();
                }

                newState.contacts = filterContacts(newState, newState.contacts);
                newState.ticketHistories = newTicketHistories;

                if (newContact.id !== newState.selectedContactId) updateUnreadCount(newState.contacts);
                break;
            }
            case actionTypes.CHAT_STATUS_UPDATE: {
                const contact = newState.contacts.find(c => c.id === action.messageDelivery.ticketId);

                if (contact) {
                    const message = contact.messages.find(m => m.id === action.messageDelivery.messageId);
                    message.deliveryStatus = action.messageDelivery.deliveryStatus;
                }
                break;
            }
            case actionTypes.TICKET_STATUS_UPDATE: {
                const contact = newState.contacts.find(c => c.id === action.ticketStatusInfo.ticketId);

                if (contact) {
                    contact.status = action.ticketStatusInfo.status;
                    newState.contacts = filterContacts(newState, newState.contacts, state.contactFilter);
                }
                break;
            }
            case actionTypes.CHANGE_FILTER: {
                const filter: ContactFilter = action.filter;
                let newFilter: ContactFilter = state.contactFilter;
                newFilter = { ...newFilter, ...filter };

                localStorage.setItem('contactFilter', JSON.stringify(newFilter));

                newState.contactFilter = newFilter;
                newState.contacts = filterContacts(newState, newState.contacts, newFilter);
                break;
            }
            case actionTypes.COMMENT_ADDED: {
                if (state.ticketHistories && state.ticketHistories.length > 0)
                    newState.ticketHistories.unshift(action.ticketComment);
                else
                    newState.ticketHistories = [action.ticketComment];
                break;
            }
            case actionTypes.UPDATE_LAST_READ: {
                const contact = newState.contacts.find(c => c.id === action.contactId);

                if (contact) contact.lastRead = getUTCTime();

                updateUnreadCount(newState.contacts);
                break;
            }
        }
    })
}


const updateUnreadCount = (contacts: Contact[]) => {
    let unreadCount = 0;
    contacts
        .filter(c => interestedInTicket(c))
        .forEach(c => {
            unreadCount += c.messages.filter(m => isUnreadMessage(c, m)).length;
        });

    setUnreadMessagesCount(unreadCount);
}

export default reducer;