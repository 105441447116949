import { Notification } from '../../models'
import { HubConnection } from '@microsoft/signalr';
import { alertTypes } from '../../components/common/Alert';
import { ActionCreatorsMapObject } from 'redux';
import { isMobileMode } from '../../helpers/utils';

export enum actionTypes {
    LOADING = 'LAYOUT_LOADING',
    HIDE_LOADING = 'LAYOUT_HIDE_LOADING',
    SET_OPEN_DRAWER = 'LAYOUT_SET_OPEN_DRAWER',
    SHOW_ALERT = 'LAYOUT_SHOW_ALERT',
    CLOSE_ALERT = 'LAYOUT_CLOSE_ALERT',
    TOGGLE_DASHBOARD_MENU = 'LAYOUT_TOGGLE_DASHBOARD_MENU',
    TOGGLE_NOTIFICATION_READ = 'LAYOUT_TOGGLE_NOTIFICATION_READ',
    NOTIFICATIONS_LOADED = 'LAYOUT_NOTIFICATIONS_LOADED',
    NOTIFICATION_RECEIVED = 'LAYOUT_NOTIFICATION_RECEIVED'
}

export interface State {
    loading?: boolean,
    drawerOpen?: boolean,
    mobileMode?: boolean,
    dashboardsMenuOpen?: boolean,
    notifications?: Notification[],
    hubConnection?: HubConnection,
    alert?: {
        open?: boolean,
        type?: alertTypes,
        message?: string
    }
}

//we need to run it here to make sure it will render the whole layout in mobile mode for the first time
let isMobile = false,
    drawerIsOpen = localStorage.getItem('LAYOUT_DRAWER_IS_OPEN'),
    defaultDrawerOpen = !drawerIsOpen || drawerIsOpen === "true";

if (isMobileMode()) {
    isMobile = true;
    defaultDrawerOpen = false
}

export const initialState: State = {
    loading: false,
    drawerOpen: defaultDrawerOpen,
    mobileMode: isMobile,
    dashboardsMenuOpen: false,
    notifications: null,
    hubConnection: null,
    alert: {
        open: false,
        type: alertTypes.success,
        message: ''
    }
}

export interface ReducerActionAny {
    type: string,
    [x: string]: any
}

export interface ReducerAction {
    type: actionTypes,
    message?: string,
    alertType?: alertTypes,
    open?: boolean,
    id?: number,
    newNotification?: Notification,
    notifications?: Notification[],
    hubConnection?: HubConnection
}

export type Dispatch = (action: ReducerAction) => void
export type DispatchAny = (action: ReducerActionAny) => void

export interface Actions extends ActionCreatorsMapObject {
    closeAlert?: () => ReducerAction,
    openDrawer?: () => (dispatch: Dispatch) => void,
    closeDrawer?: () => (dispatch: Dispatch) => void,
    logout?: () => (dispatch: DispatchAny) => void,
    toggleDashboardMenu?: () => ReducerAction,
    toggleNotificationRead?: (id: number) => (dispatch: Dispatch) => void,
    handleNotificationRedirect?: (notification: Notification) => (dispatch: DispatchAny) => void,
    loadNotifications?: () => (dispatch: Dispatch, getState: any) => void,
    gotoMenu?: (url: string) => (dispatch: DispatchAny) => void
}