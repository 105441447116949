import { produce } from 'immer'
import { actionTypes, State, initialState, ReducerAction } from './types'


export default (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.LOADING_NUMBERS:
                newState.isLoaded = false;
                break;
            case actionTypes.LOADED_NUMBERS:
                newState.isLoaded = true;
                newState.numbers = action.numbers
                break;
            case actionTypes.GET_SUBSCRIPTION_STATUS:
                newState.hasActiveSubscription = action.hasActiveSubscription;
                break;
        }
    })
}