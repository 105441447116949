import React, { useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from '../../store/Subscription/actionCreators';
import { State, Actions, PageMode } from '../../store/Subscription/types';
import Layout from '../Layout/Layout';
import { isMobileMode, setPageTitle } from "../../helpers/utils";
import Plans from './Plans/Plans';
import Progress from './Progress';
import PaymentInfo from './PaymentInfo/PaymentInfo';
import PayResult from "./PayResult";
import { useTranslation } from "react-i18next";

interface Props { }

const Subscription = (props: State & Actions & Props) => {
    const { t } = useTranslation();

    useEffect(() => {
        setPageTitle(t, "subscription");

        props.loadPage();
    }, [])

    const { productPlans, pageMode, showProgress, prevPlan, currentPlan, promoCode,
        appliedDiscounts, currentCard, creditCardSetupFinished, creditCardSubmitted,
        monerisProfileId, monerisTestMode, taxes, payResultInvoice } = props,
        mobileMode = isMobileMode();

    let layoutTitle = t("subscription"),
        content = null;

    switch (pageMode) {
        case PageMode.Accounts: layoutTitle = t("chatAccounts"); break;
        case PageMode.PayResult:
            layoutTitle = t("paymentConfirmation");
            content = <PayResult
                resultInvoice={payResultInvoice}
                onGoBack={() => props.goToPayment()}
                onGoNext={() => props.goToAccounts()}
            />;
            break;
        case PageMode.PaymentInfo:
            layoutTitle = t("paymentInfo");
            content = (
                <PaymentInfo
                    appliedDiscounts={appliedDiscounts}
                    creditCardSetupFinished={creditCardSetupFinished}
                    creditCardSubmitted={creditCardSubmitted}
                    monerisProfileId={monerisProfileId}
                    monerisTestMode={monerisTestMode}
                    taxes={taxes}
                    onError={props.showError}
                    onSubmitCreditCardRequest={props.setCreditCardSubmitted}
                    onSubmitToken={props.creditCardSaved}
                    currentCard={currentCard}
                    prevPlan={prevPlan}
                    currentPlan={currentPlan}
                    promoCode={promoCode}
                    productPlans={productPlans}
                    onChangePlan={props.showPlans}
                    onPay={props.pay}
                    onPromoCodeChange={props.changePromoCode}
                    onSubmitPromoCode={props.submitPromoCode}
                    cancelPlan={props.cancelPlan}
                    reactivatePlan={props.reactivatePlan}
                    cancellable={props.cancellable}
                    nextBillingDate={props.nextBillingDate}
                />
            );
            break;
        case PageMode.SelectPlan:
            layoutTitle = t("selectPlan");
            content = (
                <Plans
                    plans={productPlans}
                    onSelectPlan={p => props.selectPlan(p)}
                />
            );
            break;
    }

    return (
        <Layout title={layoutTitle}>
            <Progress
                pageMode={pageMode}
                show={showProgress}
            />

            {content}


            {mobileMode && showProgress &&//for progress bar at the bottom
                <div style={{ height: 73 }}></div>
            }
        </Layout>
    );
}

const SubscriptionWithStore = connect(
    (state: any) => state.subscription,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Subscription);

export default SubscriptionWithStore;