import React from 'react';
import InputMask from 'react-input-mask';
import { Input as MuiInput, InputProps } from '@mui/material'

const PhoneNumberInput = (props: InputProps) => {
    return (
        <InputMask mask="(999) 999-9999" value={props.value} onChange={props.onChange}>
            {(inputProps) => <MuiInput
                {...inputProps}
                type="tel"
                name={props.name}
                error={props.error}
                fullWidth={props.fullWidth}
            />}
        </InputMask>
    );
}

export default PhoneNumberInput;