import { State, actionTypes, initialState, ReducerAction } from './types';
import { produce } from 'immer'

export default (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.LOADED:
                newState.autoReplies = action.autoReplies
                break
            case actionTypes.UPDATED_REPLY_STATUS:
                newState.autoReplies.find(u => u.autoResponseId === action.autoResponseId).active = action.status
                break
            case actionTypes.REPLY_DELETED:
                newState.autoReplies = newState.autoReplies.filter(u => u.autoResponseId !== action.autoResponseId);
                break
        }
    })
}