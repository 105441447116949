import ajax from '../../helpers/ajax';
import { i18n } from "../../Localization/i18n"
import { showLoading, hideLoading, showAlert } from '../Layout/actionCreators';
import { push } from 'connected-react-router';
import { Actions, actionTypes } from './types'
import { alertTypes } from 'src/components/common/Alert';

export default {
    loadNumbers: (areaCode) => async (dispatch, getState) => {
        dispatch({ type: actionTypes.LOADING_NUMBERS });

        dispatch(showLoading());

        const response = await ajax.post("Bandwidth/GetTollFreeNumbers", areaCode);

        if (await ajax.errorOccured(response, "Failed to load phone numbers.", dispatch, getState))
            return;

        try {
            const numbersArray = await response.json();
            dispatch(hideLoading());

            dispatch({ type: actionTypes.LOADED_NUMBERS, numbers: numbersArray });
        } catch (err) {
            console.log(err);
        }
    },
    handleSubmit: (number, displayName) => async (dispatch, getState) => {
        const response = await ajax.post("Bandwidth/BuyNumber", { number, displayName });

        if (await ajax.errorOccured(response, "An error has occurred, please try again later.", dispatch, getState)) return

        dispatch(showAlert(i18n.t("phoneNumberAcquired"), alertTypes.success));
    },
    getSubscriptionStatus: () => async (dispatch) => {
        const response = await ajax.get('Payment/ActiveSubscription');
        if (!response.ok) {
            return;
        }

        const hasActiveSubscription = await response.json();
        dispatch({ type: actionTypes.GET_SUBSCRIPTION_STATUS, hasActiveSubscription });
    },
    goBack: () => dispatch => {
        dispatch(push('/ChatAccounts'))
    }

} as Actions