import { HubConnection } from '@microsoft/signalr'
import { ActionCreatorsMapObject } from 'redux'
import { Contact, dialogModes, ticketStatus, platforms, menuCommands, User, ContactFilter, TicketHistory, MessageDelivery, TicketStatusInfo } from '../../models'
import { getLocalTime } from '../../helpers/date'
import { ReducerAction as layoutReducerAction } from '../Layout/types'
import { TFunction } from 'i18next'
import { getUserInfo, hasPermission, PermissionKey } from '../../helpers/auth'

export const clientUniqueId = getLocalTime().getTime() % 100000

export enum actionTypes {
    CONTACTS_LOADED = 'MESSAGES_CONTACTS_LOADED',
    CONTACTS_RELOADED = 'MESSAGES_CONTACTS_RELOADED',
    CHANGE_FIELD = 'MESSAGES_CHANGE_FIELD',
    SELECT_CONTACT = 'MESSAGES_SELECT_CONTACT',
    SEND_MESSAGE = 'MESSAGES_SEND_MESSAGE',
    MESSAGE_SENT = 'MESSAGES_MESSAGE_SENT',
    CONTACT_CLAIMED = 'MESSAGES_CONTACT_CLAIMED',
    CLAIM_CONTACT_FAILED = 'MESSAGES_CLAIM_CONTACT_FAILED',
    SET_ACTIVE_CONTACT_FOR_DIALOG = 'MESSAGES_SET_ACTIVE_CONTACT_FOR_DIALOG',
    OPEN_CLOSE_TICKET_DIALOG = 'MESSAGES_OPEN_CLOSE_TICKET_DIALOG',
    OPEN_EDIT_NAME_DIALOG = 'MESSAGES_OPEN_EDIT_NAME_DIALOG',
    CANCEL_DIALOG = 'MESSAGES_CANCEL_DIALOG',
    CLOSE_CONTACT = 'MESSAGES_CLOSE_CONTACT',
    CONTACT_NAME_UPDATED = 'MESSAGES_CONTACT_NAME_UPDATED',
    OPEN_INFO_DIALOG = 'MESSAGES_OPEN_INFO_DIALOG',
    CHAT_RECEIVED = 'MESSAGES_CHAT_RECEIVED',
    CHAT_STATUS_UPDATE = 'MESSAGES_CHAT_STATUS_UPDATE',
    TICKET_STATUS_UPDATE = 'MESSAGES_TICKET_STATUS_UPDATE',
    CLEAR_CONTACT_SELECTION = 'MESSAGES_CLEAR_CONTACT_SELECTION',
    OPEN_TRANSFER_DIALOG = 'MESSAGES_OPEN_TRANSFER_DIALOG',
    OPEN_COMMENT_DIALOG = 'MESSAGES_OPEN_COMMENT_DIALOG',
    CHANGE_FILTER = 'MESSAGES_CHANGE_FILTER',
    CONTACT_TRANSFERRED = 'MESSAGES_CONTACT_TRANSFERRED',
    MESSAGE_SEND_FAILED = 'MESSAGES_MESSAGE_SEND_FAILED',
    COMMENT_ADDED = 'MESSAGES_COMMENT_ADDED',
    UPDATE_LAST_READ = 'MESSAGES_UPDATE_LAST_READ',
}

export interface ReducerAction {
    type: actionTypes,
    name?: string,
    value?: any,
    newContact?: Contact,
    contacts?: Contact[],
    messageDelivery?: MessageDelivery,
    ticketStatusInfo?: TicketStatusInfo,
    chatListenerCode?: number,
    companyId?: number,
    hubConnection?: HubConnection,
    agents?: User[],
    ticketHistories?: TicketHistory[],
    ticketComment?: TicketHistory,
    messageId?: number,
    newMessageId?: number,
    contactId?: number,
    agentId?: number,
    status?: ticketStatus,
    agentName?: string,
    filter?: ContactFilter,
}

export type Dispatch = (action: ReducerAction | layoutReducerAction) => void

export interface Actions extends ActionCreatorsMapObject {
    changeField: (name: string, value: string) => ReducerAction,
    setupHubConnection: () => (dispatch: Dispatch, getState: any) => void,
    loadContacts: () => (dispatch: Dispatch, getState: any) => void,
    stopHub: () => (dispatch: Dispatch, getState: any) => void,
    selectContact: (contactId: number) => (dispatch: Dispatch, getState: any) => void,
    sendMessage: () => (dispatch: Dispatch, getState: any) => void,
    resendSms: (messageId: number) => (dispatch: Dispatch, getState: any) => void,
    contactMenuItemClick: (contactId: number, command: menuCommands) => (dispatch: Dispatch, getState: any) => void,
    cancelDialog: () => ReducerAction,
    submitDialog: () => (dispatch: Dispatch, getState: any) => void,
    clearContactSelection: () => ReducerAction,
    changeFilter: (filter: ContactFilter) => ReducerAction,
    checkHubConnection: () => (dispatch: Dispatch, getState: any) => void,
    updateLastRead: (contactId: number) => (dispatch: Dispatch, getState: any) => void,
}

export interface State {
    chatListenerCode: number,
    companyId: number,
    selectedContactId: number,
    hubConnection: HubConnection,
    composeMessage: string,
    dialogComment: string,
    dialogOpen: boolean,
    dialogMode: dialogModes,
    dialogContactName: string,
    contacts: Array<Contact>,
    agents: User[],
    contactFilter: ContactFilter,
    ticketHistories: TicketHistory[],
}

export const initialState: State = {
    chatListenerCode: 0,
    companyId: 0,
    selectedContactId: 0,
    hubConnection: null,
    composeMessage: '',
    dialogComment: '',
    dialogContactName: '',
    dialogOpen: false,
    dialogMode: dialogModes.info,
    contacts: [],
    ticketHistories: [],
    agents: [],
    contactFilter: (() => {
        const savedFilter = localStorage.getItem('contactFilter');

        if (savedFilter) {
            let parsedFilter: ContactFilter = JSON.parse(savedFilter);

            if (parsedFilter.platformsInstagramSelected === undefined)
                parsedFilter.platformsInstagramSelected = true;

            return parsedFilter
        }

        return {
            platformsFacebookSelected: true,
            platformsInstagramSelected: true,
            platformsSMSSelected: true,
            platformsWebchatSelected: true,
            searchText: '',
            statusesAssignedSelected: true,
            statusesTransferredSelected: false,
            statusesNewSelected: true,
            statusesResolvedSelected: false,
            ownershipMineSelected: true,
            ownershipOthersSelected: false,
            loadOldTickets: false,
        }
    })()
}

export const getTicketStatusName = (t: TFunction, status: ticketStatus) => {
    switch (status) {
        case ticketStatus.open: return t("ticketStatus_Open");
        case ticketStatus.assigned: return t("ticketStatus_Assigned");
        case ticketStatus.resolved: return t("ticketStatus_Resolved");
        case ticketStatus.chatbotResolved: return t("ticketStatus_SystemClosed");
        case ticketStatus.transferred: return t("ticketStatus_Transfer");
        case ticketStatus.bppvChatbotAssigned:
        case ticketStatus.templateChatbotAssigned:
            return t("ticketStatus_ChatbotAssigned");
        case ticketStatus.reopened: return t("ticketStatus_reopened");
        case ticketStatus.comment: return t("comment");
        default: return '';
    }
}

export const getPlatformName = (t: TFunction, platform: platforms) => {
    switch (platform) {
        case platforms.facebook: return t("facebook")
        case platforms.webChat: return t("webchat")
        case platforms.twilio:
        case platforms.bandwidth: return t("sms")
        case platforms.instagram: return t("instagram")
        default: throw new Error(`Invalid platform ${platform}.`)
    }
}

export const canClaimTicket = (contact: Contact) => {
    if(!contact) return false;

    const isOpen = contact.status === ticketStatus.open;
    const isTransferred = contact.status === ticketStatus.transferred;
    const isAssignedToMe = contact.agentId === getUserInfo().id;
    const isAssignedToAnother = contact.status === ticketStatus.assigned && contact.agentId !== getUserInfo().id;
    const canTakeOverTickets = hasPermission(PermissionKey.TakeoverTicket);

    return isOpen ||
        (isTransferred && (isAssignedToMe || canTakeOverTickets)) ||
        (isAssignedToAnother && canTakeOverTickets);
}