import React, { useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserPageLayout from './UserPageLayout';
import { State, Actions } from '../../store/CreatePassword/types';
import actionCreators from '../../store/CreatePassword/actionCreators';
import { Button, FormControl, Input, InputLabel } from '@mui/material';
import Recaptcha from '../Recaptcha'
import { getQueryStringValue, setPageTitle } from '../../helpers/utils';
import { useTranslation } from "react-i18next";

const CreatePassword = (props: State & Actions) => {
    const { t } = useTranslation();

    useEffect(() => {
        setPageTitle(t, "resetPassword");

        props.validateCode(getQueryStringValue('c'))
    }, [])

    const changeField = e => {
        props.changeField(e.target.name, e.target.value);
    }

    const handleRecaptcha = (value) => {
        props.changeField('reCaptcha', value);
    }

    const updatePassword = () => {
        const { password, confirmPassword, reCaptcha } = props;

        const code = getQueryStringValue('c');

        props.updatePassword(code, password, confirmPassword, reCaptcha);
    }

    const { password, confirmPassword, alert, alertType, submitted, updatePasswordInvalid, loading, showLoginLink } = props;

    let loginButton = null;
    if (showLoginLink)
        loginButton = (
            <Button key="login" color="inherit" size="small" onClick={() => props.goToLogin()}>
                {t("login")}
            </Button>);

    return (
        <UserPageLayout
            alertMessage={alert}
            alertType={alertType}
            alertButtons={[loginButton]}
            buttonDisabled={submitted || updatePasswordInvalid}
            buttonText={t("submit")}
            loading={loading}
            onAlertClose={() => props.closeMessage()}
            onSubmit={updatePassword}
            title={t("resetPassword")}
        >
            <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">{t("password")}</InputLabel>
                <Input
                    name="password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={e => changeField(e)}
                />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="confirmPassword">{t("confirmPassword")}</InputLabel>
                <Input
                    name="confirmPassword"
                    type="password"
                    id="confirmPassword"
                    autoComplete="current-password"
                    value={confirmPassword}
                    onChange={e => changeField(e)}
                />
            </FormControl>
            <Recaptcha onChange={handleRecaptcha} />
        </UserPageLayout>
    );
}

const CreatePasswordWithStore = connect(
    (state: any) => state.createPassword,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(CreatePassword);

export default CreatePasswordWithStore;