import * as React from 'react';
import RouterLink from '../common/RouterLink';
import { useTranslation } from "react-i18next";
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        maxHeight: 36,
    },
    logo: {
        marginRight: theme.spacing(1.5),
        marginLeft: theme.spacing(1.5),
        width: 32,
    },
    titleLink: {
        display: 'flex'    
    }
}));

interface Props {
}

const LogoTitleDrawer = (props: Props) => {
    const { t } = useTranslation();

    const { classes } = useStyles();

    return <div className={classes.root}>
        <RouterLink to="/" className={classes.titleLink}>
            <img alt="logo" src="/logo_white.png" className={classes.logo} />
            <Typography variant="h6" color="white" noWrap>
                {t("projectTitle")}
            </Typography>
        </RouterLink>
    </div>;
}

export default LogoTitleDrawer;
