import { Actions, Dispatch, State, actionTypes } from './types';
import ajax from '../../helpers/ajax';
import { i18n } from "../../Localization/i18n"
import { push } from 'connected-react-router';
import { getQueryStringValue } from '../../helpers/utils';

const goToLoginPage = dispatch => dispatch(push('/login', getQueryStringValue('s') === 'y' ? { from: { pathname: '/subscription' } } : {}));

const actionCreators: Actions = {
    validateCode: (code) => async (dispatch) => {

        if (!code) {
            dispatch({ type: actionTypes.INVALID_LINK, message: i18n.t("invalidLink") });
            return;
        }

        dispatch({ type: actionTypes.LOADING });

        const response = await ajax.post("User/validateCode", { code });

        if (!response.ok) {
            let errorMessage = await ajax.errorMessage(response)

            dispatch({ type: actionTypes.INVALID_CODE, message: i18n.t("updatePasswordFailed") + (errorMessage || '') })
            return;
        }

        const email = await response.json();

        dispatch({ type: actionTypes.CODE_ISVALID, email });
    },
    updatePassword: (code, password, confirmPassword, reCAPTCHA) => async (dispatch) => {

        if (confirmPassword === '' || password === '' || confirmPassword !== password || reCAPTCHA === '')
            return;

        dispatch({ type: actionTypes.LOADING });

        const response = await ajax.post("User/updatePassword", { code, password, confirmPassword, reCAPTCHA });

        if (!response.ok) {
            let errorMessage = await ajax.errorMessage(response);

            dispatch({ type: actionTypes.UPDATEPASSWORD_FAILED, message: i18n.t("updatePasswordFailed") + (errorMessage || '') });
            return;
        }

        dispatch({ type: actionTypes.PASSWORD_UPDATED, message: i18n.t("passwordUpdatedSuccessfully") });
    },
    changeField: (name, value) => ({ type: actionTypes.CHANGE_FIELD, name, value }),
    goToLogin: () => goToLoginPage,
    closeMessage: () => (dispatch: Dispatch, getState) => {
        const state: State = getState().createPassword;
        if (state.showLoginLink) {
            goToLoginPage(dispatch);
            return;
        }

        dispatch({ type: actionTypes.CLOSE_MESSAGE });
    }
}

export default actionCreators