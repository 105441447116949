import ajax from '../../helpers/ajax';
import { State, Actions, actionTypes, templateSchema } from './types';
import { showLoading, hideLoading, showAlert } from '../Layout/actionCreators';
import { alertTypes } from '../../components/common/Alert';
import { i18n } from "../../Localization/i18n"
import { isEmpty, validator } from '../../helpers/utils'

export default {
    changeField: (name, value) => ({ type: actionTypes.CHANGE_FIELD, name, value }),
    getChatbotMode: () => async (dispatch, getState) => {
        dispatch(showLoading());

        const response = await ajax.get("ChatBot/Settings");

        if (await ajax.errorOccured(response, "", dispatch, getState))
            return;

        const { chatbotMode } = await response.json();

        dispatch(hideLoading());

        dispatch({ type: actionTypes.CHANGE_FIELD, name: 'mode', value: chatbotMode });
    },
    getTemplates: () => async (dispatch, getState) => {
        dispatch(showLoading());

        const response = await ajax.get("ChatBot/Template");

        if (await ajax.errorOccured(response, "Data load failed.", dispatch, getState)) return;

        const templates = await response.json();

        dispatch(hideLoading());

        dispatch({ type: actionTypes.CHANGE_FIELD, name: 'templates', value: templates });
    },
    deleteTemplate: templateId => async (dispatch, getState) => {
        dispatch(showLoading());

        const response = await ajax.del(`ChatBot/Template/${templateId}`, '');

        if (await ajax.errorOccured(response, "Delete failed.", dispatch, getState)) return;

        dispatch({ type: actionTypes.DELETE_TEMPLATE, templateId });

        dispatch(hideLoading());

        dispatch(showAlert(i18n.t("dataDeleted"), alertTypes.success));
    },
    publishTemplate: templateId => async (dispatch, getState) => {
        dispatch(showLoading());

        const response = await ajax.post(`ChatBot/Template/Publish`, templateId);

        if (await ajax.errorOccured(response, "", dispatch, getState)) return;

        const templates = await response.json();

        dispatch(hideLoading());

        dispatch({ type: actionTypes.CHANGE_FIELD, name: 'templates', value: templates });
    },
    submitTemplate: () => async (dispatch, getState) => {
        const state: State = getState().manageKnowledge;
        const template = state.template ;

        const addMode = template.id === 0;

        const errors = await validator(templateSchema)(template)

        dispatch({ type: actionTypes.CHANGE_FIELD, name: "errors", value: errors });
        if (!isEmpty(errors)) return errors;

        dispatch(showLoading());

        let response = await ajax.post("Chatbot/Template", template);

        if (await ajax.errorOccured(response, "", dispatch, getState)) return errors;

        const templateResult = await response.json();

        if (addMode) dispatch({ type: actionTypes.ADD_TEMPLATE, template: templateResult });
        else dispatch({ type: actionTypes.UPDATE_TEMPLATE, template: templateResult });

        dispatch(hideLoading());
        dispatch({
            type: actionTypes.CHANGE_FIELD, name: 'template', value: {
                id: 0,
                name: '',
                language: 'en',
            }
        });

        return errors;
    },
    changeTemplateField: (templateName, value) => ({ type: actionTypes.CHANGE_TEMPLATE_FIELD, templateName, value }),
    validateTemplate: () => async (dispatch, getState) => {
        const state: State = getState().manageKnowledge;
        const { template } = state;

        const errors = await validator(templateSchema)(template)

        dispatch({ type: actionTypes.CHANGE_FIELD, name: "errors", value: errors });
    },
} as Actions