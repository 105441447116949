import React, { useEffect } from 'react';
import { Grid, IconButton, Tooltip, InputBase } from '@mui/material';
import { styled } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';
import UpIcon from '@mui/icons-material/ArrowDropUpRounded';
import DownIcon from '@mui/icons-material/ArrowDropDownRounded';
import SpellcheckIcon from '@mui/icons-material/SpellcheckRounded';
import { useTranslation } from "react-i18next";
import { isMobileMode } from '../../helpers/utils';

const ComposeWrapper = styled(Grid)<{ isExpanded: boolean }>(({ theme, isExpanded }) => ({
    height: isExpanded ? theme.spacing(32) : (isMobileMode() ? '64px' : '74px'),
    borderTop: `1px solid ${theme.palette.divider}`
}))

const ComposeGrid = styled(Grid)(({ theme }) => ({
    height: '100%',
    padding: '10px',
    paddingTop: '16px',
    backgroundColor: theme.palette.grey[50]
}))

const InputWrapper = styled(Grid)(({ theme }) => ({
    position: 'relative',
    '& > .expandButtonWrapper': {
        position: "absolute",
        right: theme.spacing(2),
        display: 'none',
        zIndex: 1500
    },
    '&:hover > .expandButtonWrapper': {
        display: 'block'
    }
}))

const Input = styled(InputBase)({
    width: '100%',
    height: '44px'
})

const SendWrapper = styled(Grid)({
    width: '40px',
    textAlign: 'center',
    alignItems: 'center'
})

const iconSx = {
    fontSize: '30px',
    opacity: 0.9,
    margin: '7px',
    cursor: 'pointer'
};

interface Props {
    text: string,
    onSend: () => void,
    onTextChange: (name: string, value: string) => void,
}

export const Compose = (props: Props) => {

    const antidoteEnabled = document.getElementById('antidoteapi_jsconnect_actif') != null;

    const ref = React.useRef<HTMLTextAreaElement>();

    useEffect(() => {
        if (antidoteEnabled) {
            (window as any).activeAntidoteAPI_JSConnect();

            const interval = setInterval(() => {
                handleTextChange(ref.current.value);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            return undefined;
        }
    }, []);

    const { t } = useTranslation();

    const { text } = props,
        [expanded, setExpanded] = React.useState(false)

    function sendMessage() {
        if (text !== '')
            props.onSend();
    }

    const handleTextChange = (newValue: string) => {

        if (newValue.indexOf('\n') >= 0 && !expanded)
            setExpanded(true)

        props.onTextChange("composeMessage", newValue)
    }

    const inputKeyPress = (e: React.KeyboardEvent) => {
        if (e.charCode === 10 || e.keyCode === 10 || e.which === 10 || (e.ctrlKey && (e.charCode === 13 || e.keyCode === 13 || e.key === "Enter"))) {//ctrl+enter 
            handleTextChange(props.text + '\n')
            e.preventDefault()
            return false
        }

        if (e.charCode === 13 || e.keyCode === 13 || e.key === "Enter") {
            e.preventDefault()
            sendMessage()
            return false
        }

        return false
    }

    return (
        <ComposeWrapper item isExpanded={expanded}>
            <ComposeGrid container item>
                <InputWrapper item xs>
                    <div className="expandButtonWrapper">
                        <IconButton onClick={() => setExpanded(!expanded)} size="small" color="primary">
                            {
                                expanded ? <DownIcon /> : <UpIcon />
                            }
                        </IconButton>
                    </div>
                    <Input
                        inputRef={ref}
                        value={text}
                        autoFocus
                        placeholder={t("typeYourMessageHere")}
                        inputProps={{ maxLength: 2048, 'data-antidoteapi_jsconnect_groupe_id': "01" }}
                        onChange={e => handleTextChange(e.target.value)}
                        onKeyPress={e => inputKeyPress(e)}
                        autoComplete="off"
                        multiline
                        rows={expanded ? 12 : 2}
                    />
                </InputWrapper>
                {antidoteEnabled && <SendWrapper item>
                    <IconButton
                        data-antidoteapi_jsconnect_groupe_id="01"
                        data-antidoteapi_jsconnect_lanceoutil="C"
                        style={{ padding: 0 }}
                        size="large">
                        <Tooltip title={t("checkSpelling")}>
                            <SpellcheckIcon
                                sx={iconSx}
                                color="primary"
                            />
                        </Tooltip>
                    </IconButton>
                </SendWrapper>}
                <SendWrapper item>
                    <SendIcon
                        sx={iconSx}
                        color="primary"
                        onClick={() => sendMessage()}
                    />
                </SendWrapper>
            </ComposeGrid>
        </ComposeWrapper>
    );
}

export default Compose;
