import { ActionCreatorsMapObject } from 'redux';
import * as yup from 'yup'
import { ChatbotMode, RawData, Template } from '../../models';
import { ReducerAction as layoutReducerAction } from '../Layout/types'

export enum actionTypes {
    CHANGE_FIELD = "MANAGEKNOWLEDGE_CHANGE_FIELD",

    ADD_TEMPLATE = 'MANAGEKNOWLEDGE_ADD_TEMPLATE',
    UPDATE_TEMPLATE = 'MANAGEKNOWLEDGE_UPDATE_TEMPLATE',
    DELETE_TEMPLATE = 'MANAGEKNOWLEDGE_DELETE_TEMPLATE',
    CHANGE_TEMPLATE_FIELD = 'MANAGEKNOWLEDGE_CHANGE_TEMPLATE_FIELD',
}

export const templateSchema: yup.SchemaOf<Template> = yup.object().shape({
    name: yup.string().required("fieldRequired"),
    language: yup.string().required("fieldRequired"),
    transferMessage: yup.string(),
}).required();

export interface State {
    errors: any,
    mode: ChatbotMode,

    template: Template,
    templates: Template[],
}

export function write<K extends keyof State>(arg: State, key: K, value: State[K]): void {
    arg[key] = value;
};

export function writeTemplate<K extends keyof Template>(arg: Template, key: K, value: Template[K]): void {
    arg[key] = value;
};

export interface Actions extends ActionCreatorsMapObject {
    changeField: (name: keyof State, value: any) => ReducerAction,
    getChatbotMode: () => (dispatch: Dispatch, getState: any) => void,
    getTemplates: () => (dispatch: Dispatch, getState: any) => void,
    validateTemplate: () => (dispatch: Dispatch, getState: any) => void,
    changeTemplateField: (quizName: keyof Template, value: any) => ReducerAction,
    submitTemplate: () => (dispatch: Dispatch, getState: any) => object,
    publishTemplate: (templateId: number) => (dispatch: Dispatch, getState: any) => void,
    deleteTemplate: (templateId: number) => (dispatch: Dispatch, getState: any) => void,
}

export interface ReducerAction {
    type: any,
    name?: keyof State,
    templateName?: keyof Template,
    value?: any
    templateId?: number,
    template?: Template,
}

export type Dispatch = (action: ReducerAction | layoutReducerAction) => void

export const initialState: State = {
    errors: {},
    mode: 0,

    template: {
        id: 0,
        name: '',
        language: 'en',
        transferMessage: '',
    },
    templates: [],
}