import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Switch } from 'react-router';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './store/configureStore';
import App from './App';
import 'typeface-roboto';
import { I18nextProvider } from 'react-i18next';
import { i18n } from './Localization/i18n';

const store = configureStore();

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Switch>
                    <App />
                </Switch>
            </ConnectedRouter>
        </Provider>
    </I18nextProvider>,
    document.getElementById('root'));