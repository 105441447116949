import * as React from "react";
import { Theme } from '@mui/material/styles';
import { Paper, Switch, InputLabel, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        width: 1000,
        maxWidth: '100%',
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(3),
    },
    header: {
        fontWeight: 500,
        marginBottom: theme.spacing(1),
    },
    label: {
        fontSize: 18,
        marginTop: theme.spacing(1),
        color: 'rgba(0, 0, 0, 0.87)',
    },
}));

interface Props {
    newTicketEmail: boolean,
    handleTicketEmail: (newTicketEmail: boolean) => void,
}

const EmailSettings = (props: Props) => {
    const { t } = useTranslation();

    const { classes } = useStyles();

    const { newTicketEmail, handleTicketEmail } = props;

    const handleChange = () => handleTicketEmail(!newTicketEmail);

    return (
        <div className={classes.root}>
            <h2 className={classes.header}>{t("notificationSettings")}</h2>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <InputLabel className={classes.label} htmlFor="status-switch">{t("newTicketEmail")}:</InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                        <Switch color="primary" inputProps={{ id: "status-switch" }} checked={newTicketEmail} onClick={handleChange} />
                    </Grid>
                </Grid>
            </Paper>
        </div >
    );

}

export default EmailSettings;