import React from 'react';
import { ResponsivePie } from '@nivo/pie'
import { PieDatum } from '../../models';

export type Props = {
    data: PieDatum[],
};

const Pie = (props: Props) => {

    const { data } = props;

    return (
        <ResponsivePie
            data={data}
            margin={{ top: 12, right: 20, bottom: 80, left: 20 }}
            theme={{
                fontSize: 13,
            }}
            enableArcLabels={false}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            colors={{ scheme: "set1" }}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            enableArcLinkLabels={false}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 32,
                    itemsSpacing: 0,
                    itemWidth: 124,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 16,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ]}
        />
    )
}

export default Pie;