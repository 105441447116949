import * as React from "react";

const BottomButtonWrapper: React.StatelessComponent = props => {
    return (
        <React.Fragment>
            <div style={{ height: 50 }}>
            </div>
            <div style={{ textAlign: 'center', width: '100%', bottom: 10, position: 'absolute' }}>
                {props.children}
            </div>
        </React.Fragment>
    );
}

export default BottomButtonWrapper;