import ajax from '../../helpers/ajax';
import { Actions, actionTypes } from './types';
import { showLoading, hideLoading } from '../Layout/actionCreators';

export default {
    changeField: (name, value) => ({ type: actionTypes.CHANGE_FIELD, name, value }),
    loadAnalytics: (dateFilter) => async (dispatch, getState) => {
        dispatch(showLoading());
        const response = await ajax.post("Ticket/Analytics", dateFilter);

        if (await ajax.errorOccured(response, "Chat info load failed.", dispatch, getState)){
            dispatch(hideLoading());
            return;
        }
            
        let { allMessagesCount,
            smsCount,
            facebookCount,
            instagramCount,
            webchatCount,
            newTicketsCount,
            continuingTicketsCount,
            uniqueNewTicketsCount,
            endUserInfo,
            dayMessages } = await response.json();

        dispatch({
            type: actionTypes.LOADED, 
            allMessagesCount,
            smsCount,
            facebookCount,
            instagramCount,
            webchatCount,
            newTicketsCount,
            continuingTicketsCount,
            uniqueNewTicketsCount,
            endUserInfo,
            dayMessages
        });
        dispatch(hideLoading());

    },
    loadChatbotAnalytics: (dateFilter) => async (dispatch, getState) => {
        dispatch({ type: actionTypes.CHANGE_FIELD,  name: 'chatbotLoading', value: true });

        const response = await ajax.post("Ticket/ChatbotAnalytics", dateFilter);

        if (await ajax.errorOccured(response, "", dispatch, getState)){
            dispatch({ type: actionTypes.CHANGE_FIELD,  name: 'chatbotLoading', value: false });
            return;
        }
            
        let { topicCounts } = await response.json();

        dispatch({ type: actionTypes.CHANGE_FIELD,  name: 'topicCounts', value: topicCounts });
        dispatch({ type: actionTypes.CHANGE_FIELD,  name: 'chatbotLoading', value: false });

    },
    handleDateSelect: (dateFilter) => ({ type: actionTypes.SELECTED_DATE, dateFilter }),
} as Actions