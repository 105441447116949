import { ActionCreatorsMapObject } from 'redux';
import { AutoReply } from '../../models';
import { ReducerAction as layoutReducerAction } from '../Layout/types'

export enum actionTypes {
    LOADED = 'MANAGEAUTOREPLIES_LOADED',
    UPDATED_REPLY_STATUS = 'MANAGEAUTOREPLIES_UPDATED_REPLY_STATUS',
    REPLY_DELETED = 'MANAGEAUTOREPLIES_REPLY_DELETED',
}

export interface State {
    autoReplies: AutoReply[]
}

export interface Actions extends ActionCreatorsMapObject {
    loadAutoReplies: () => (dispatch: Dispatch, getState: any) => void,
    updateReplyStatus: (autoResponseId: number) => (dispatch: Dispatch, getState: any) => void,
    editReply: (autoResponseId: number) => (dispatch: Dispatch) => void,
    deleteReply: (autoResponseId: number) => (dispatch: Dispatch, getState: any) => void,
}

export interface ReducerAction {
    type: any,
    autoReplies?: AutoReply[],
    autoResponseId?: number,
    status?: boolean
}

export type Dispatch = (action: ReducerAction | layoutReducerAction) => void

export const initialState: State = {
    autoReplies: []
}