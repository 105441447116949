import React, { useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { HubConnectionState } from '@microsoft/signalr'
import actionCreators from '../../store/Messages/actionCreators';
import { Actions, State } from '../../store/Messages/types';
import { Message, ticketStatus, TicketHistory } from '../../models';
import { Theme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import Layout from '../Layout/Layout';
import Compose from './Compose';
import ChatList from './ChatList';
import Contacts from './Contacts';
import { platforms } from '../../models';
import { validateFacebookMessages, isMobileMode, setPageTitle, compareValues, last } from '../../helpers/utils';
import FormDialog from './FormDialog';
import { PermissionKey, getUserInfo } from '../../helpers/auth';
import Grid from '@mui/material/Grid';
import { Paper, Avatar, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '../../styles/makeStyles'
import usePageVisibility from "../hooks/usePageVisibility";

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        height: `calc(100% - ${isMobileMode() ? 64 : 74}px)`
    },
    side: {
        borderRight: `1px solid ${theme.palette.divider}`,
        height: '100%'
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column !important' as any
    },
    chatListWrapper: {
        flex: 1,
        position: 'relative'
    },
    chatTitle: {
        display: 'flex',
        paddingTop: 10,
        paddingBottom: 2,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    back: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        margin: 10
    }
}));

const Messages = (props: Actions & State) => {
    const { t } = useTranslation();

    const isPageVisible = usePageVisibility();

    useEffect(() => {
        setPageTitle(t, "messages");

        props.loadContacts();
        props.setupHubConnection();

        return () => { props.stopHub() };
    }, [])

    useEffect(() => {
        if (isPageVisible) props.checkHubConnection();
    }, [isPageVisible])

    const { classes } = useStyles();

    const { contacts, composeMessage, dialogOpen, dialogMode, dialogComment, dialogContactName, agents, contactFilter, ticketHistories, hubConnection } = props;

    const mobileMode = isMobileMode();

    const selectedContact = contacts.find(contact => contact.selected);

    let showCompose = selectedContact && selectedContact.status === ticketStatus.assigned && selectedContact.agentId === getUserInfo().id;

    if (showCompose && selectedContact.platform === platforms.facebook) showCompose = validateFacebookMessages(selectedContact.messages, t);

    let chatListMessages: Array<Message> = [],
        selectTicketHistories: TicketHistory[] = [],
        lastTicketHistory: TicketHistory = null;

    if (selectedContact) {
        //load all old messages from the same contact
        let contactsFromSameUser = contacts
            .filter(c => c.platform === selectedContact.platform &&
                c.id <= selectedContact.id &&
                c.endUserId === selectedContact.endUserId);

        contactsFromSameUser = contactsFromSameUser.sort(compareValues('id', 'asc'));

        contactsFromSameUser.forEach(c => chatListMessages = chatListMessages.concat(c.messages));

        selectTicketHistories = ticketHistories.filter(th => th.ticketId === selectedContact.id).sort(compareValues("createdDate", "asc"));

        lastTicketHistory = last(selectTicketHistories);
    }

    return (
        <Layout noPadding permissionKey={PermissionKey.ViewMessages} title={t("messages")}>
            {hubConnection && hubConnection.state === HubConnectionState.Disconnected &&
                <Typography>You are disconnected, refresh the page.</Typography>
            }

            <Grid container className={classes.root}>
                {((mobileMode && !selectedContact) || !mobileMode) &&
                    <Grid item xs={mobileMode ? 12 : 3} className={classes.side}>
                        <Contacts
                            contacts={contacts}
                            filter={contactFilter}
                            loadContacts={props.loadContacts}
                            onContactSelect={props.selectContact}
                            onContactMenuItemClick={props.contactMenuItemClick}
                            onFilterChange={props.changeFilter}
                        />
                    </Grid>
                }

                {(mobileMode && selectedContact || !mobileMode) &&
                    <Grid item xs={mobileMode ? 12 : 9} className={classes.content}>
                        {mobileMode &&
                            <Paper elevation={4} className={classes.chatTitle}>
                                <Avatar className={classes.back} onClick={() => props.clearContactSelection()}>
                                    <ArrowBackIcon />
                                </Avatar>
                                <Typography variant="body1" color="primary" style={{ alignSelf: 'center' }}>
                                    {selectedContact.endUserName || selectedContact.endUserId}
                                </Typography>
                            </Paper>
                        }
                        <Grid item className={classes.chatListWrapper}>
                            <ChatList
                                messages={chatListMessages}
                                contact={selectedContact}
                                lastTicketHistory={lastTicketHistory}
                                resendMessage={props.resendSms}
                                onContactMenuItemClick={props.contactMenuItemClick}
                                updateLastRead={props.updateLastRead}
                            />
                        </Grid>
                        {showCompose &&
                            <Compose
                                text={composeMessage}
                                onTextChange={props.changeField}
                                onSend={props.sendMessage}
                            />
                        }
                    </Grid>
                }
            </Grid>

            <FormDialog
                open={dialogOpen}
                mode={dialogMode}
                comment={dialogComment}
                contacts={contacts}
                agents={agents}
                contactName={dialogContactName}
                ticketHistories={ticketHistories}
                onChangeField={props.changeField}
                onCancel={props.cancelDialog}
                onSubmit={props.submitDialog}
            />
        </Layout>
    );
}

const messagesWithStore = connect(
    (state: any) => state.messages,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Messages);

export default messagesWithStore;
