import { alertTypes } from '../../components/common/Alert';
import { initialState, State, actionTypes, ReducerAction } from './types';
import { produce } from 'immer'

export default (state: State, action: ReducerAction) => {

    if (action.type === actionTypes.RESET_FORM) return initialState;

    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.SET_SUBMITTED:
                newState.submitted = true
                break
            case actionTypes.LOADING:
                newState.loading = true
                break
            case actionTypes.LOGIN_FAILED:
                newState.loading = false
                newState.alert = action.message
                newState.alertType = alertTypes.error
                newState.submitted = false
                break
            case actionTypes.CHANGE_FIELD:
                newState[action.name] = action.value
                break
            case actionTypes.CLOSE_ERROR:
                newState.alert = ''
        }
    })
}