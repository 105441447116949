import React, { useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RouterLink from './common/RouterLink';
import actionCreators from '../store/UpdateUser/actionCreators';
import { State, Actions } from '../store/UpdateUser/types';
import Layout from './Layout/Layout';
import { Button, TextField, InputLabel, Paper, Checkbox, Grid, FormControl, Input, MenuItem, Select, Theme } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { PermissionKey } from "../helpers/auth";
import PhoneNumberInput from "./common/PhoneNumberInput";
import ImageUpload from "./common/ImageUpload";
import { useTranslation, withTranslation } from "react-i18next";
import { setPageTitle } from "../helpers/utils";
import { GenderEnum } from "../models";

enum PageMode {
    Unknown,
    Add,
    Edit
}

interface Props {
    match: any
}

const UpdateUser = (props: State & Actions & Props) => {
    const { t } = useTranslation();

    useEffect(() => {
        setPageTitle(t, getPageMode() == PageMode.Add ? "addUser" : "updateUser");

        if (getPageMode() == PageMode.Edit)
            props.loadUserInfoUserRoles(props.match.params.id);
        else
            props.loadRoles();

        props.changeField("phone", ''); // hack to fix phone label

    }, [])

    const getPageMode = () => {
        if (window.location.href.toLowerCase().indexOf('adduser') >= 0)
            return PageMode.Add;
        else
            return PageMode.Edit;
    }


    const handleChange = (e) => {
        props.changeField(e.target.name, e.target.value);
    }

    const handleCheckbox = (e, role) => {
        if (e.target.checked)
            props.addRole(role);
        else
            props.removeRole(role);
    }

    const handleSubmit = () => {
        props.submit(getPageMode() == PageMode.Add);
    }

    const { firstName, lastName, phone, emailAddress, gender, picture, picFile, roles, isSubmitted } = props;
    const pageMode = getPageMode();


    return (
        <Layout
            title={pageMode == PageMode.Add ? t("addUser") : t("updateUser")}
            permissionKey={PermissionKey.ManagerUsers}
        >
            <Paper sx={{ display: 'flex', p: 3 }}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            error={isSubmitted && firstName === ''}
                            name="firstName"
                            label={t("firstName")}
                            value={firstName}
                            onChange={handleChange}
                            required
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            error={isSubmitted && lastName === ''}
                            name="lastName"
                            label={t("lastName")}
                            value={lastName}
                            onChange={handleChange}
                            required
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl required fullWidth variant="standard">
                            <InputLabel
                                error={isSubmitted && phone === ''}
                                htmlFor="phoneNumber"
                            >
                                {t("phoneNumber")}
                            </InputLabel>
                            <PhoneNumberInput
                                error={isSubmitted && phone === ''}
                                value={phone}
                                name="phone"
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            error={isSubmitted && emailAddress === ''}
                            name="emailAddress"
                            label="Email Address"
                            value={emailAddress}
                            onChange={handleChange}
                            required
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="gender" id="gender-label">{t("gender")}</InputLabel>
                            <Select
                                label="gender-label"
                                id="gender"
                                name="gender"
                                value={gender}
                                onChange={handleChange}
                            >
                                <MenuItem value={GenderEnum.Unknown}>{t("select")}</MenuItem>
                                <MenuItem value={GenderEnum.Male}>{t("male")}</MenuItem>
                                <MenuItem value={GenderEnum.Female}>{t("female")}</MenuItem>
                                <MenuItem value={GenderEnum.Other}>{t("other")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <ImageUpload defaultImage={picture || '/default-image-profile.png'} onChange={props.onFileChange} file={picFile} label={t('profileSpecs')} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>{t("roles")}</InputLabel>
                        <FormGroup row sx={{ flexDirection: "column" }}>
                            {roles.map(role => (
                                <FormControlLabel
                                    key={role.id}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={props.currentRoles.find(r => r.id == role.id) !== undefined}
                                            onClick={e => handleCheckbox(e, role)}
                                        />
                                    }
                                    label={role.title}
                                />
                            ))}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "right" }}>
                        <RouterLink to="/ManageUser">
                            <Button color="primary">
                                {t("back")}
                            </Button>
                        </RouterLink>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: 16 }}
                            onClick={handleSubmit}
                        >
                            {getPageMode() === PageMode.Add ? t("addUser") : t("updateUser")}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Layout>
    );
}

const updateUserWithStore = connect(
    (state: any) => state.updateUser,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(UpdateUser);

export default withTranslation()(updateUserWithStore);