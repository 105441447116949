import { ActionCreatorsMapObject } from 'redux';
import { Invoice, DateRange, Discount, Tax, ProductPlan, Company } from '../../models';
import { addMonths } from '../../helpers/date';
import { ReducerAction as layoutReducerAction } from '../Layout/types'

export enum actionTypes {
    LOADED = 'INVOICES_LOADED',
    SELECTED_DATE = 'INVOICES_SELECTED_DATE',
    SORT_INVOICES = 'INVOICES_SORT_INVOICES',

    INVOICE_LOADED = 'INVOICES_INVOICE_LOADED',
}

export interface State {
    invoices: Invoice[],
    sortKey: string,
    sortDir: "desc" | "asc",
    dateFilter: DateRange,

    invoice: Invoice,
    company: Company,
    discounts: Discount[],
    taxes: Tax[],
    plan: ProductPlan,
}

export interface Actions extends ActionCreatorsMapObject {
    loadInvoices: () => (dispatch: Dispatch, getState: any) => void,
    handleDateSelect: (dateFilter: DateRange) => ReducerAction,
    sortInvoices: (key: string, dir: string) => ReducerAction,

    loadInvoiceInfo: (id: number) => (dispatch: Dispatch, getState: any) => void,
    resendEmail: (id: number) => (dispatch: Dispatch, getState: any) => void,
}

export interface ReducerAction {
    type: any,
    invoices?: Invoice[],
    dateFilter?: DateRange,
    sortKey?: string,
    sortDir?: "desc" | "asc",

    invoiceId?: number,
    invoice?: Invoice,
    company?: Company,
    discounts?: Discount[],
    taxes?: Tax[],
    plan?: ProductPlan,
}

export type Dispatch = (action: ReducerAction | layoutReducerAction) => void

export const initialState: State = {
    invoices: [],
    dateFilter: { start: addMonths(new Date(), -12), end: new Date() },
    sortKey: "billDate",
    sortDir: "asc",
    
    invoice: null,
    company: null,
    discounts: [],
    taxes: [],
    plan: null
}