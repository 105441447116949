import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import ContactItem from './ContactItem';
import { List, InputBase, IconButton, Divider, Menu, MenuItem, FormControlLabel, Switch, Typography } from '@mui/material';
import { Box } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import FilterIcon from '@mui/icons-material/FilterList';
import { Contact, menuCommands, ticketStatus, ContactFilter } from '../../models';
import { useTranslation } from "react-i18next";
import { getTicketStatusName } from '../../store/Messages/types';
import { isEqualContact, asStylesType } from '../../helpers/utils';

const classes = asStylesType({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    search: {
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid`,
        borderColor: `divider`,
        boxShadow: '0px 4px 3px -1px rgba(0,0,0,0.1)',
        zIndex: 2
    },
    input: {
        ml: 1,
        flex: 1,
    },
    divider: {
        height: 28,
        m: .5,
    },
    list: {
        display: 'flex',
        flexGrow: 1
    },
    contactList: {
        backgroundColor: 'background.paper',
        p: 0
    },
    menuTitle: {
        pl: 1
    }
});

interface Props {
    contacts: Array<Contact>,
    filter: ContactFilter,
    loadContacts: () => void,
    onContactSelect: (contactId: number) => void,
    onContactMenuItemClick: (contactId: number, command: menuCommands) => void,
    onFilterChange: (contactFilter: ContactFilter) => void
}


function isEqualProps(prev: Props, next: Props) {
    if (!prev.contacts && !next.contacts) return true;
    if (!prev.contacts || !next.contacts) return false;
    if (prev.contacts.length !== next.contacts.length) return false;

    for (let i = 0; i < prev.contacts.length; i++) {
        const equal = isEqualContact(prev.contacts[i], next.contacts[i]);
        if (!equal) return false;
    }

    if (JSON.stringify(prev.filter) !== JSON.stringify(next.filter)) return false;

    return true;
}

export const Contacts = (props: Props) => {
    const { t } = useTranslation();

    const { contacts, filter, onFilterChange, loadContacts } = props;

    const { searchText, platformsFacebookSelected, platformsInstagramSelected, platformsSMSSelected, platformsWebchatSelected, statusesAssignedSelected: statusesAssigendSelected,
        statusesTransferredSelected, statusesNewSelected, statusesResolvedSelected, ownershipMineSelected, ownershipOthersSelected, loadOldTickets } = filter;

    const [filterMenuAnchor, setFilterMenuAnchor] = React.useState<null | HTMLElement>(null),
        setSerchText = (text: string) => onFilterChange({ searchText: text }),
        setPlatformsFacebookSelected = (value: boolean) => onFilterChange({ platformsFacebookSelected: value }),
        setPlatformsInstagramSelected = (value: boolean) => onFilterChange({ platformsInstagramSelected: value }),
        setPlatformsSMSSelected = (value: boolean) => onFilterChange({ platformsSMSSelected: value }),
        setPlatformsWebchatkSelected = (value: boolean) => onFilterChange({ platformsWebchatSelected: value }),

        setStatusesNewSelected = (value: boolean) => onFilterChange({ statusesNewSelected: value }),
        setStatusesAssignedSelected = (value: boolean) => onFilterChange({ statusesAssignedSelected: value }),
        setStatusesTransferredSelected = (value: boolean) => onFilterChange({ statusesTransferredSelected: value }),
        setStatusesResolvedSelected = (value: boolean) => onFilterChange({ statusesResolvedSelected: value }),

        setOwnershipMineSelected = (value: boolean) => onFilterChange({ ownershipMineSelected: value }),
        setOwnershipOthersSelected = (value: boolean) => onFilterChange({ ownershipOthersSelected: value }),

        filteredContacts = contacts.filter(c => c.show);

        const setLoadOldTickets = (value: boolean) => {
            onFilterChange({ loadOldTickets: value });
            loadContacts();
        };
        

    let showingContacts: Contact[] = [];
    //show contacts from the same end user in a single contact
    filteredContacts.forEach(contact => {
        const foundContact: Contact = showingContacts.find(sc => sc.endUserId === contact.endUserId);
        if (foundContact) {
            if (contact.status === ticketStatus.assigned || contact.status === ticketStatus.open)
                showingContacts.push(contact)
        }
        else
            showingContacts.push(contact)
    })

    return (
        <Box sx={classes.root}>
            <Box
                sx={classes.search}>
                <SearchIcon color="disabled" />
                <InputBase
                    value={searchText}
                    onChange={e => setSerchText(e.target.value)}
                    sx={classes.input}
                    placeholder={t("search")}
                />
                <Divider sx={classes.divider} orientation="vertical" />
                <IconButton
                    color="primary"
                    sx={{ p: 1 }}
                    onClick={e => setFilterMenuAnchor(e.currentTarget)}
                    size="large">
                    <FilterIcon />
                </IconButton>
                <Menu
                    open={Boolean(filterMenuAnchor)}
                    onClose={() => setFilterMenuAnchor(null)}
                    anchorEl={filterMenuAnchor}
                >
                    <Typography sx={classes.menuTitle} variant="caption">{t("platform")}</Typography>
                    <MenuItem onClick={e => setPlatformsFacebookSelected(!platformsFacebookSelected)}>
                        <FormControlLabel
                            label={t("facebook") as any}
                            onClick={e => e.preventDefault()}
                            control={<Switch color="primary" size="small" checked={platformsFacebookSelected} />}
                        />
                    </MenuItem>
                    {/* <MenuItem onClick={e => setPlatformsInstagramSelected(!platformsInstagramSelected)}>
                        <FormControlLabel
                            label={t("instagram") as any}
                            onClick={e => e.preventDefault()}
                            control={<Switch color="primary" size="small" checked={platformsInstagramSelected} />}
                        />
                    </MenuItem> */}
                    <MenuItem onClick={() => setPlatformsSMSSelected(!platformsSMSSelected)}>
                        <FormControlLabel
                            label={t("sms") as any}
                            onClick={e => e.preventDefault()}
                            control={<Switch color="primary" size="small" checked={platformsSMSSelected} />}
                        />
                    </MenuItem>
                    <MenuItem onClick={() => setPlatformsWebchatkSelected(!platformsWebchatSelected)}>
                        <FormControlLabel
                            label={t("webchat") as any}
                            onClick={e => e.preventDefault()}
                            control={<Switch color="primary" size="small" checked={platformsWebchatSelected} />}
                        />
                    </MenuItem>
                    <Typography sx={classes.menuTitle} variant="caption">{t("ownership")}</Typography>
                    <MenuItem onClick={() => setOwnershipMineSelected(!ownershipMineSelected)}>
                        <FormControlLabel
                            label={t("myTickets") as any}
                            onClick={e => e.preventDefault()}
                            control={<Switch color="primary" size="small" checked={ownershipMineSelected} />}
                        />
                    </MenuItem>
                    <MenuItem onClick={() => setOwnershipOthersSelected(!ownershipOthersSelected)}>
                        <FormControlLabel
                            label={t("otherAgentsTickets") as any}
                            onClick={e => e.preventDefault()}
                            control={<Switch color="primary" size="small" checked={ownershipOthersSelected} />}
                        />
                    </MenuItem>
                    <Typography sx={classes.menuTitle} variant="caption">{t("ticketStatus")}</Typography>
                    <MenuItem onClick={() => setStatusesNewSelected(!statusesNewSelected)}>
                        <FormControlLabel
                            label={getTicketStatusName(t, ticketStatus.open)}
                            onClick={e => e.preventDefault()}
                            control={<Switch color="primary" size="small" checked={statusesNewSelected} />}
                        />
                    </MenuItem>
                    <MenuItem onClick={() => setStatusesAssignedSelected(!statusesAssigendSelected)}>
                        <FormControlLabel
                            label={getTicketStatusName(t, ticketStatus.assigned)}
                            onClick={e => e.preventDefault()}
                            control={<Switch color="primary" size="small" checked={statusesAssigendSelected} />}
                        />
                    </MenuItem>
                    <MenuItem onClick={() => setStatusesTransferredSelected(!statusesTransferredSelected)}>
                        <FormControlLabel
                            label={getTicketStatusName(t, ticketStatus.transferred)}
                            onClick={e => e.preventDefault()}
                            control={<Switch color="primary" size="small" checked={statusesTransferredSelected} />}
                        />
                    </MenuItem>
                    <MenuItem onClick={() => setStatusesResolvedSelected(!statusesResolvedSelected)}>
                        <FormControlLabel
                            label={getTicketStatusName(t, ticketStatus.resolved)}
                            onClick={e => e.preventDefault()}
                            control={<Switch color="primary" size="small" checked={statusesResolvedSelected} />}
                        />
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => setLoadOldTickets(!loadOldTickets)}>
                        <FormControlLabel
                            label={t("loadOldTickets") as any}
                            onClick={e => e.preventDefault()}
                            control={<Switch color="primary" size="small" checked={loadOldTickets} />}
                        />
                    </MenuItem>
                </Menu>
            </Box>
            <Box sx={classes.list}>
                <Scrollbars autoHide style={{ height: 'unset !important' }}>
                    <List sx={classes.contactList}>
                        {showingContacts.map((c, i) => (<ContactItem
                            key={i}
                            contact={c}
                            isLast={contacts.length === i + 1}
                            onSelect={props.onContactSelect}
                            onMenuItemClick={props.onContactMenuItemClick}
                        />))}
                    </List>
                </Scrollbars>
            </Box>
        </Box>
    );
};

export default React.memo(Contacts, isEqualProps);