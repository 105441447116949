import { ActionCreatorsMapObject } from 'redux'
import { UserInfo } from '../../helpers/auth'

export enum actionTypes {
    LOADING = 'PRIVATE_ROUTE_LOADING',
    HIDE_LOADING = 'PRIVATE_ROUTE_HIDE_LOADING',
    LOADED = 'PRIVATE_ROUTE_LOADED',
    CLEAR_USER_INFO = 'PRIVATE_ROUTE_CLEAR_USER_INFO'
};

export interface State {
    showSplash: boolean,
    loading: boolean,
    userInfo: UserInfo
}

export const initialState: State = {
    showSplash: true,
    loading: true,
    userInfo: undefined
}

export interface ReducerAction {
    type: actionTypes,
    userInfo?: UserInfo
}

export type Dispatch = (action: ReducerAction) => void

export interface Actions extends ActionCreatorsMapObject {
    loadUserInfo: () => (dispatch: Dispatch, getState: any) => void,
    clearUserInfo: () => ReducerAction
}