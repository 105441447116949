import * as React from "react";
import { Typography, Button, Chip } from "@mui/material";
import { ProductPlan, PlanSubsciption, ProductPlanDescription, getPlanSubscriptionStatusName, PlanSubscriptionStatus } from "../../../models";
import { addDays, getLongDate, getPrettyDate } from "../../../helpers/date";
import BottomButtonWrapper from './BottomButtonWrapper';
import Confirm from '../../common/ConfirmDialog'
import CancelSurvey from "../CancelSurvey";
import { useTranslation } from "react-i18next";
import { makeStyles } from '../../../styles/makeStyles'

const useStyles = makeStyles()(() => ({
    cancelInfo: {
        color: 'rgba(0, 0, 0, 0.54)',
        padding: 0,
        margin: '8px 16px'
    },
    cancelButton: {
        marginLeft: 8,
        color: 'rgba(0,0,0,0.54)',
        fontSize: 12,
    },
    statusContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 20,
        marginBottom: 12
    },
}));

interface Props {
    productPlans: ProductPlan[],
    currentPlan: PlanSubsciption,
    nextBillingDate: Date,
    cancellable: boolean,
    currentCard: string,
    onChangePlan: () => void,
    cancelPlan: (planId: number, message: string) => void,
    reactivatePlan: (planId: number) => void,
}

const CurrentPlan = (props: Props) => {
    const { classes } = useStyles();

    const { productPlans, currentPlan, cancellable, nextBillingDate, currentCard } = props,
        currentProductPlan = productPlans.find(p => p.selected);

    let desc: ProductPlanDescription = null;
    if (currentProductPlan)
        desc = JSON.parse(currentProductPlan.description);

    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [surveyOpen, setSurveyOpen] = React.useState(false);

    const { t } = useTranslation();

    return (
        <>
            {!currentProductPlan ?
                (
                    <Typography
                        variant='body2'
                        component='div'
                        color="textSecondary"
                    >
                        {t("noPlanSelected")}
                    </Typography>
                ) :
                (
                    <>
                        <Typography variant="body1">
                            {currentProductPlan.title} ${currentProductPlan.price}
                        </Typography>
                        <div className={classes.statusContainer}>
                            <Chip
                                color="secondary"
                                label={getPlanSubscriptionStatusName(t, currentPlan.status)}
                            />
                            {cancellable && <Button className={classes.cancelButton} onClick={() => setConfirmOpen(true)}>{t("cancel")}</Button>}
                            <Confirm
                                open={confirmOpen}
                                handleClose={() => setConfirmOpen(false)}
                                onConfirm={currentPlan.status !== PlanSubscriptionStatus.Active
                                    ? () => props.cancelPlan(currentPlan.id, '')
                                    : () => setSurveyOpen(true)}
                                title={t("cancelSub")}
                            >
                                {t("confirmCancelSub")}
                                {currentPlan.status === PlanSubscriptionStatus.Active &&
                                    <ul className={classes.cancelInfo}>
                                        <li><Typography variant="body2" dangerouslySetInnerHTML={{ __html: t("cancellationDate", { x: getLongDate(t, addDays(nextBillingDate, -1)) }) }} /></li>
                                        <li><Typography variant="body2">{t("creditCardDeleted")}</Typography></li>
                                    </ul>
                                }
                            </Confirm>
                            <CancelSurvey
                                open={surveyOpen}
                                handleClose={() => setSurveyOpen(false)}
                                onConfirm={(message) => props.cancelPlan(currentPlan.id, message)} />
                        </div>
                        <Typography variant="body1">
                            {t("startedFrom")}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ marginLeft: 20, marginBottom: 12 }}
                        >
                            {getPrettyDate(t, currentPlan.startDate)}
                        </Typography>
                        <Typography variant="body1">
                            {t("features")}
                        </Typography>
                        {
                            desc.features.map((f, i) => (
                                <Chip
                                    key={i}
                                    label={f}
                                    style={{ marginLeft: i === 0 ? 20 : 4, marginBottom: 12 }}
                                />
                            ))
                        }
                    </>
                )
            }
            <BottomButtonWrapper>
                {currentPlan.status === PlanSubscriptionStatus.Cancelled && currentCard !== '' &&
                    <Button
                        style={{ marginRight: 16 }}
                        variant="contained"
                        color="primary"
                        onClick={() => props.reactivatePlan(currentPlan.id)}
                    >
                        {t("reactivatePlan")}
                    </Button>
                }

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => props.onChangePlan()}
                >
                    {currentProductPlan ? t("changePlan") : t("selectPlan")}
                </Button>
            </BottomButtonWrapper>
        </>
    );
}

export default CurrentPlan;