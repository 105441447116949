import React, { useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Paper, Snackbar, SnackbarContent, IconButton, Typography, Theme } from "@mui/material";
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import Layout from "./Layout/Layout";
import { State, Actions } from '../store/UploadPowerBIDoc/types';
import actionCreators from '../store/UploadPowerBIDoc/actionCreators';
import { useTranslation, withTranslation } from "react-i18next";
import { setPageTitle } from "../helpers/utils";
import { makeStyles } from '../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    uploaddoc: {
        width: "auto",
        height: "100%",
        display: "block",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up("md")]: {
            width: "80%",
            height: "60%",
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    paper: {
        marginTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    container: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    reportSelectLabel: {
        paddingRight: 16
    },
    button: {
        margin: theme.spacing(1),
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    success: {
        backgroundColor: "#4BB543"
    },
    input: {
        display: 'none',
    }
}));

interface Props {
}

const UploadPowerBIDoc = (props: Props & State & Actions) => {
    const { t } = useTranslation();

    useEffect(() => {
        setPageTitle(t, "uploadDoc");

        props.loadReports();
    }, [])

    // Check whether the checkbox of the reportId is selected or not.
    const isSelected = (reportId) => {
        const selectedReportIdArr = props.selectedReportIdArr;

        return selectedReportIdArr.indexOf(reportId) !== -1;
    }

    // Update selected report Id array based on current click and current selected report Id.
    const handleCheck = (selectedReportId) => {
        const { selectedReportIdArr, file } = props;

        const selectedIndex = selectedReportIdArr.indexOf(selectedReportId);

        let newSelectedReportIdArr = [];

        // selectedReportId is currently not in selected Report Id array.
        if (selectedIndex === -1) {
            newSelectedReportIdArr = newSelectedReportIdArr.concat(selectedReportIdArr, selectedReportId);
        } else if (selectedIndex === 0) {
            // selectedReportId is already in selectedReportIdArr. selectedReportId is the first element of selectedReportIdArr.
            newSelectedReportIdArr = newSelectedReportIdArr.concat(selectedReportIdArr.slice(1));
        } else if (selectedIndex === selectedReportIdArr.length - 1) {
            // selectedReportId is the last element of selectedReportIdArr.
            newSelectedReportIdArr = newSelectedReportIdArr.concat(selectedReportIdArr.slice(0, -1));
        } else if (selectedIndex > 0) {
            // selectedReportId is the middle of selectedReportIdArr.
            newSelectedReportIdArr = newSelectedReportIdArr.concat(
                selectedReportIdArr.slice(0, selectedIndex),
                selectedReportIdArr.slice(selectedIndex + 1),
            );
        }

        let isRequiredFilled = false;

        // If isRequiredFilled is false, 'Upload File' becomes disabled.
        if (file !== null && newSelectedReportIdArr.length > 0) {
            isRequiredFilled = true;
        }

        props.handleCheckReport(newSelectedReportIdArr, isRequiredFilled);
    }

    const setFile = (event) => {
        const selectedReportIdArr = props.selectedReportIdArr;

        const chosenFile = event.target.files[0];

        let isRequiredFilled = false;

        // If isRequiredFilled is false, 'Upload File' becomes disabled.
        if (chosenFile !== null && selectedReportIdArr.length > 0) {
            isRequiredFilled = true;
        }

        props.setFile(chosenFile, isRequiredFilled);
    }

    const toFormData = (file) => {
        const data = new FormData();

        data.append("file", file);

        return data;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const { selectedReportIdArr, file } = props;

        // file becomes 'undefined' when user clicks 'cancel' with no file selection.
        if (file === undefined) {
            props.handleEmptyFile();
            return;
        }

        const fileData = toFormData(file);

        props.uploadDoc(selectedReportIdArr, fileData);
    }

    const closeError = () => {
        props.closeError();
    }

    const closeSuccess = () => {
        props.closeSuccess();
        window.location.reload();
    }

    const { classes } = useStyles();
    const { reportArr, file, isRequiredFilled, errMsg, successMsg } = props;

    return (
        <Layout>
            <div className={classes.uploaddoc}>
                <Typography component="h1" variant="h4">
                    Report Documentation
                </Typography>
                <Paper className={classes.paper}>
                    <Snackbar
                        open={errMsg !== ""}
                        autoHideDuration={null}
                        onClose={() => closeError()}
                    >
                        <SnackbarContent
                            className={classes.error}
                            message={
                                <span>
                                    <span id="errorMessage">{errMsg}</span>
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    onClick={() => closeError()}
                                    size="large">
                                    <CloseIcon />
                                </IconButton>
                            ]}
                        />
                    </Snackbar>
                    <Snackbar
                        open={successMsg !== ""}
                        autoHideDuration={6000}
                        onClose={() => closeSuccess()}
                    >
                        <SnackbarContent
                            className={classes.success}
                            message={
                                <span>
                                    <span id="successMessage">{successMsg}</span>
                                </span>
                            }
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    onClick={() => closeSuccess()}
                                    size="large">
                                    <CloseIcon />
                                </IconButton>
                            ]}
                        />
                    </Snackbar>
                    <form
                        className={classes.container}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <br />
                        <input
                            className={classes.input}
                            id="button-file"
                            multiple
                            type="file"
                            onChange={e => setFile(e)}
                            required
                        />
                        <label htmlFor="button-file">
                            <Button variant="contained" component="span" className={classes.button}>
                                Choose File
                            </Button>
                        </label>{file && file.name}
                        <br />
                        <br />
                        <Button variant="contained" color="primary" type="submit" className={classes.button} disabled={!isRequiredFilled}>
                            Upload File
                        </Button>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                    </TableCell>
                                    <TableCell align="left">Report Id</TableCell>
                                    <TableCell align="left">Report Name</TableCell>
                                    <TableCell align="left">Data Source</TableCell>
                                    <TableCell align="left">Workspace Id</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportArr.map(report => (
                                    <TableRow
                                        hover
                                        aria-checked={isSelected(report.ReportId)}
                                        key={report.ReportId}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                onClick={() => handleCheck(report.ReportId)}
                                            />
                                        </TableCell>
                                        <TableCell align="left" component="th" scope="row">
                                            {report.ReportId}
                                        </TableCell>
                                        <TableCell align="left">{report.ReportName}</TableCell>
                                        <TableCell align="left">{report.DataSource}</TableCell>
                                        <TableCell align="left">{report.workspaceId}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </form>
                </Paper>
            </div>
        </Layout>
    );
}

const uploadPowerBIdocWithStore = connect(
    (state: any) => state.uploadPowerBIdoc,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(UploadPowerBIDoc);

export default withTranslation()(uploadPowerBIdocWithStore);