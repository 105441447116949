import ajax, { Method } from '../../helpers/ajax';
import { Actions, actionTypes } from './types'

export default {
    loadReports: () => async (dispatch) => {
        dispatch({ type: actionTypes.LOADING });

        const response = await ajax.get("powerbi/GetReports");

        if (!response.ok) {
            let errorMessage = await ajax.errorMessage(response);

            dispatch({ type: actionTypes.LOAD_FAILED, errMsg: "Load reports failed. " + (errorMessage || '') });
            return;
        }

        const responseObject = await response.json();

        let reportArr = responseObject.reports;
        const workspaceIdArr = responseObject.workspaceIdArray;

        for (let i = 0; i < reportArr.length; ++i) {
            reportArr[i].workspaceId = workspaceIdArr[i];
        }

        dispatch({
            type: actionTypes.LOADED,
            reportArr: reportArr
        });
    },
    uploadDoc: (selectedReportIdArr, fileData) => async (dispatch) => {
        let stringfiedReportIds = '';

        selectedReportIdArr.forEach((reportId) => {
            stringfiedReportIds += reportId + ' ';
        });

        const uploadResponse = await ajax.call(
            "powerbi/UploadDoc/" + stringfiedReportIds,
            Method.POST,
            fileData,
            true
        );

        if (!uploadResponse.ok) {
            let errorMessage = await ajax.errorMessage(uploadResponse);
            console.log(errorMessage);

            dispatch({ type: actionTypes.UPLOAD_DOC_FAILED, errMsg: "Load reports failed. " + (errorMessage || '') });
            return;
        }

        dispatch({
            type: actionTypes.UPLOADED_DOC,
            successMsg: "File uploaded successfully."
        });
    },
    handleCheckReport: (newSelectedReportIdArr, isRequiredFilled) => ({
        type: actionTypes.REPORT_CHECKED_UNCHECKED,
        selectedReportIdArr: newSelectedReportIdArr,
        isRequiredFilled: isRequiredFilled
    }),
    handleEmptyFile: () => ({ type: actionTypes.EMPTY_FILE, errMsg: "Please choose a file.", isRequiredFilled: false }),
    setFile: (chosenFile, isRequiredFilled) => ({ type: actionTypes.SET_FILE, file: chosenFile, isRequiredFilled: isRequiredFilled }),
    closeSuccess: () => ({ type: actionTypes.CLOSE_SUCCESS }),
    closeError: () => ({ type: actionTypes.CLOSE_ERROR })

} as Actions