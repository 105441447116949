import React, { useState, useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from '../../store/Invoices/actionCreators';
import { State, Actions } from '../../store/Invoices/types';
import Layout from '../Layout/Layout';
import { Theme } from '@mui/material/styles';
import { Button, Paper, TextField, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, FormGroup } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useTranslation } from "react-i18next";
import { PermissionKey } from "../../helpers/auth";
import { isMobileMode, setPageTitle } from '../../helpers/utils';
import { getLongDate } from '../../helpers/date';
import { Invoice, DateRange } from '../../models';
import ViewInvoice from './ViewInvoice'
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: isMobileMode() ? 400 : 800,
    },
    filter: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        display: 'inline-block'
    },
    timepicker: {
        width: 220,
        marginRight: theme.spacing(2),
        marginBottom: isMobileMode() ? theme.spacing(2) : 0,
    },
    timepicker2: {
        width: 220,
        marginRight: theme.spacing(2),
    },
}));

interface Props { }

const Invoices = (props: State & Actions & Props) => {
    const { t } = useTranslation();

    const { classes } = useStyles();

    const [viewOpen, setViewOpen] = useState(false);

    const { invoices, dateFilter, sortKey, sortDir,
        invoice, company, discounts, taxes, plan, resendEmail } = props;

    const onClose = () => setViewOpen(false);

    const viewProps = { isOpen: viewOpen, invoice, company, discounts, taxes, plan, onClose, resendEmail };

    useEffect(() => {
        setPageTitle(t, "invoices");

        props.loadInvoices();
    }, [])

    const sortInvoices = (key) => {
        let dir;
        if (key === sortKey) {
            dir = sortDir === 'asc' ? 'desc' : 'asc';
        } else {
            dir = 'asc';
        }

        props.sortInvoices(key, dir);
    }

    const handleStartDate = (e) => {
        if (e !== null && !isNaN(e.getTime())) {
            props.handleDateSelect({
                start: e,
                end: dateFilter.end
            });
        }
    }

    const handleEndDate = (e) => {
        if (e !== null && !isNaN(e.getTime())) {
            props.handleDateSelect({
                start: dateFilter.start,
                end: e
            });
        }
    }

    const filterInvoices = (invoices: Invoice[], dateFilter: DateRange) => {
        if (invoices === null || invoices.length === 0) return [];

        if (dateFilter.start !== null && dateFilter.end !== null) {
            invoices = invoices.filter(i => new Date(i.billDate) >= dateFilter.start && new Date(i.billDate) <= dateFilter.end);
        }
        return invoices;
    }

    const filteredInvoices = filterInvoices(invoices, dateFilter);

    const viewInvoice = async (e) => {
        const invoiceId = Number.parseInt(e.currentTarget.value);

        setViewOpen(true);

        await props.loadInvoiceInfo(invoiceId);
    }

    return (
        <Layout
            title={t("invoices")}
            permissionKey={PermissionKey.ManagePayments}
        >

            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormGroup className={classes.filter}>
                    <DatePicker
                        className={classes.timepicker}
                        format="dd MMM yyyy"
                        label={t("from")}
                        value={dateFilter.start}
                        onChange={handleStartDate}
                    />
                    <DatePicker
                        className={classes.timepicker2}
                        format="dd MMM yyyy"
                        label={t("To")}
                        value={dateFilter.end}
                        onChange={handleEndDate}
                    />
                </FormGroup>
            </LocalizationProvider>

            <Paper className={classes.root}>

                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={sortKey === 'invoiceNumber'}
                                    direction={sortDir}
                                    onClick={() => sortInvoices('invoiceNumber')}
                                >
                                    {t("invoiceNumber")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="left">
                                <TableSortLabel
                                    active={sortKey === 'billDate'}
                                    direction={sortDir}
                                    onClick={() => sortInvoices('billDate')}
                                >
                                    {t("billDate")}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    active={sortKey === 'invoiceAmount'}
                                    direction={sortDir}
                                    onClick={() => sortInvoices('invoiceAmount')}
                                >
                                    {t("total")}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredInvoices.map(invoice => (
                            <TableRow key={invoice.id}>
                                <TableCell>
                                    <Button value={invoice.id} onClick={viewInvoice}>{invoice.invoiceNumber}</Button>
                                </TableCell>
                                <TableCell align="left">{getLongDate(t, invoice.billDate)}</TableCell>
                                <TableCell align="center">${invoice.invoiceAmount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>

            <ViewInvoice {...viewProps} />

        </Layout>
    );
}

const invoicesWithStore = connect(
    (state: any) => state.invoices,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Invoices);

export default invoicesWithStore;