import * as React from 'react'
import { isValidEmail, padNumber, urlRegEx } from '../../helpers/utils'
import { getLocalTime } from '../../helpers/date'
import { Paper, Typography, Link, IconButton, Tooltip } from '@mui/material'
import { SystemStyleObject, Theme, styled } from '@mui/system';
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CheckIcon from '@mui/icons-material/Check'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import RefreshIcon from '@mui/icons-material/Refresh';
import { blue, red, green } from '@mui/material/colors'
import { Message, MessageType, DeliveryStatus } from '../../models'
import LinkPreview from './LinkPreview'
import { useTranslation } from 'react-i18next'

const StyledPaper = styled(Paper)<{ isInbound: boolean, isComment: boolean, isSystem: boolean }>(({ theme, isInbound, isComment, isSystem }) => ({
    margin: '4px',
    padding: '8px',
    position: 'relative',
    overflow: 'hidden',
    wordBreak: 'break-word',
    maxWidth: 'min(75%, 575px)',
    minWidth: '64px',
    alignSelf: isComment ? 'center' : (isInbound ? 'flex-start' : 'flex-end'),
    ...(!isInbound && { backgroundColor: blue[100] }),
    ...(isSystem && {
        backgroundColor: "#f2f1bf",
        minHeight: '50px',
        lineHeight: '20px'
    }),
}))

interface StyleProps {
    inboundSecondaryText: SystemStyleObject<Theme>,
    outboundSecondaryText: SystemStyleObject<Theme>,
    sending: SystemStyleObject<Theme>,
    failed: SystemStyleObject<Theme>,
    resend: SystemStyleObject<Theme>,
}

const classes: StyleProps = {
    inboundSecondaryText: {
        position: 'absolute',
        right: '4px',
        bottom: 0
    },
    outboundSecondaryText: {
        position: 'absolute',
        right: '4px',
        bottom: 0
    },
    sending: {
        position: 'absolute',
        fontSize: '14px',
        color: green[800],
        right: '2px',
        top: '2px'
    },
    failed: {
        color: red[600],
        fontSize: '15px',
    },
    resend: {
        position: 'absolute',
        right: '18px',
        top: 0,
        padding: 0,
        color: green[800],
    },
};

interface Props {
    message: Message,
    isComment?: boolean,
    onChatItemLoaded?: () => void,
    resendMessage?: (messageId: number) => void,
}

const ChatItem = (props: Props) => {
    const { t } = useTranslation();

    const { message, isComment } = props,
        splitedBody = message.body.split('\n'),
        time = getLocalTime(message.createdDate),
        timeDisplay = `${padNumber(time.getHours())}:${padNumber(time.getMinutes())}`

    let sendingStatus: JSX.Element = null

    if (message.messageType !== MessageType.Inbound && !isComment) {
        if (message.deliveryStatus === DeliveryStatus.Pending)
            sendingStatus = <AccessTimeIcon sx={classes.sending} />
        else {
            if (message.deliveryStatus === DeliveryStatus.Failed)
                sendingStatus = <Tooltip title={t("notDelivered")}><ErrorIcon sx={[classes.sending, classes.failed]} /></Tooltip>
            else
                sendingStatus = <Tooltip title={t("sent")}><CheckIcon sx={classes.sending} /></Tooltip>
        }
    }

    const replaceLinks = (text: string) => {
        let links: { href: string, text: string }[] = []
        const result = text.replace(urlRegEx, url => {

            const text = url;

            if (isValidEmail(url))
                url = 'mailto:' + url;
            else if (url.toLowerCase().indexOf('http://') !== 0 && url.toLowerCase().indexOf('https://') !== 0)
                url = 'https://' + url;

            links.push({ href: url, text })
            return '<a>'
        })

        const linkSplitted = result.split('<a>')

        return linkSplitted.map((text, i) => (
            <React.Fragment key={i}>
                {text}
                {i < linkSplitted.length - 1 && <Link target="_blank" href={links[i].href}>{links[i].text}</Link>}
            </React.Fragment>
        ))
    }

    const isInbound = message.messageType === MessageType.Inbound;

    return (
        <StyledPaper isComment={isComment} isInbound={isInbound} isSystem={message.system}>
            {<>
                <Typography
                    component="span"
                    variant="body2"
                    sx={{ display: 'inline-block', mr: '32px' }}
                >
                    {splitedBody.map((t, i) => (
                        <React.Fragment key={i}>
                            {replaceLinks(t)}
                            {i < splitedBody.length - 1 && <br />}
                        </React.Fragment>
                    ))}
                </Typography>
                <LinkPreview
                    text={message.body}
                    inbound={isInbound}
                    onPreviewLoaded={props.onChatItemLoaded}
                />
                <Typography
                    component="span"
                    variant="caption"
                    color="textSecondary"
                    sx={isInbound ? classes.inboundSecondaryText : classes.outboundSecondaryText}
                >
                    {timeDisplay}
                </Typography>
                {sendingStatus}
                {message.messageType === MessageType.Outbound && message.deliveryStatus === DeliveryStatus.Failed &&
                    <IconButton
                        onClick={() => props.resendMessage(message.id)}
                        sx={classes.resend}
                        size="large"><Tooltip title={t("resend")}><RefreshIcon style={{ fontSize: 18 }} /></Tooltip></IconButton>
                }
            </>}
        </StyledPaper>
    );
}

export default ChatItem;
