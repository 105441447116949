import { i18n } from '../Localization/i18n'
import { getToken, logout } from './auth';
import { push, getLocation } from 'connected-react-router';
import * as fetchAll from 'isomorphic-fetch';
import fetchDefault from 'isomorphic-fetch';
import { hideLoading, showAlert } from '../store/Layout/actionCreators';
import { getServerUrl } from './utils';

let fetch = fetchDefault

if (typeof fetchAll === 'function')
    fetch = (fetchAll as any)

export enum Method {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE"
}

const apiUrl = `${getServerUrl()}/api/`;

const call = async (url: string, method: Method = Method.GET, body?: any, sendRaw = false) => {
    const callURL = apiUrl + (url || '');

    let token = getToken();

    var headers = new Headers({
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'pragma': 'no-cache',
        'cache-control': 'no-cache'
    });

    headers.append('lang', i18n.language);

    if (!sendRaw) {
        headers.append('Content-Type', 'application/json');
        body = JSON.stringify(body);
    }

    const fetchResult = await fetch(callURL, {
        headers,
        method,
        body
    });

    return fetchResult;
};

const get = async (url : string) => await call(url);
const post = async (url: string, body: any) => await call(url, Method.POST, body);
const put = async (url: string, body: any) => await call(url, Method.PUT, body);
const del = async (url: string, body: any) => await call(url, Method.DELETE, body);

const errorMessage = async (response: Response) => {
    if (response.ok)
        return ''

    let message = '',
        responseError = await response.text()

    try {
        const responseErrorJSON = JSON.parse(responseError);

        for (let key in responseErrorJSON)
            message += responseErrorJSON[key];
    } catch (e) {
        message += responseError;
    }

    return message;
};

const errorOccured = async (response: Response, message: string, dispatch, getState) => {
    if (response.ok)
        return false;

    if (!response.ok && response.status === 401) {
        logout();
        const location = getLocation(getState());

        if (location.pathname !== '/login') {
            let state = {};
            if (location.pathname !== '/')
                state = { from: location };

            console.log('ajax redirect to login: ' + location.pathname);

            dispatch(push(`/login`, state));
        }
        return true;
    }

    let msg = await errorMessage(response);

    dispatch(hideLoading());
    dispatch(showAlert(`${message} ${msg || ''}`));
    return true;
};

export default {
    call,
    get,
    post,
    put,
    del,
    errorMessage,
    errorOccured
};
