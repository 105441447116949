import React, { useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    Paper, Button, FormControlLabel, TextField, FormControl, InputLabel,
    Select, MenuItem, FormGroup, Checkbox, Typography, Link, Menu, Theme
} from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Layout from "../../Layout/Layout";
import RouterLink from '../../common/RouterLink';
import { State, Actions } from '../../../store/AddAutoReplies/types';
import { replyType } from '../../../models';
import actionCreators from '../../../store/AddAutoReplies/actionCreators';
import { setPageTitle, truncate } from '../../../helpers/utils';
import { useTranslation } from "react-i18next";
import WeeklySchedule from "./WeeklySchedule";
import { makeStyles } from '../../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    doc: {
        width: "auto",
        height: "auto",
        display: "block"
    },
    paper: {
        padding: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2)
        }
    },
    formControl: {
        marginLeft: 0,
        margin: theme.spacing(1),
        minWidth: 238,
    },
    textField: {
        display: "block",
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(2),
        width: "645px",
        maxWidth: "100%",
    },
    backButton: {
        marginRight: theme.spacing(2),
        paddingLeft: 8,
    },
    header: {
        color: "rgba(0, 0, 0, 0.65)",
        marginTop: "20px",
        marginBottom: "-10px",
    },
    tooltip: {
        fontSize: "16px"
    },
    row: {
        marginTop: "16px",
        marginBottom: "8px",
        display: "flex",
        flexDirection: "row",
        flex: 1
    },
    checkbox2: {
        marginTop: 10,
        marginBottom: 10,
    },
    input: {
        borderRadius: 0,
    },
    linkContainer: {
        border: '1px solid rgba(0, 0, 0, .25)',
        width: "645px",
        maxWidth: "100%",
        padding: '4px 8px',
        borderTop: "none",
    },
    timepicker: {
        [theme.breakpoints.up('md')]: {
            marginRight: "16px",
        }
    },
    helperText: {
        color: "rgba(0, 0, 0, 0.54)",
        margin: 0,
        fontWeight: 400,
        letterSpacing: "0.04em",
        marginTop: 10,
        display: "block",
    },
}));

interface Props { }

const AddAutoReplies = (props: State & Actions & Props) => {
    const { t } = useTranslation();

    const { classes } = useStyles();

    const { isLoaded, autoResponseId, selectedType, includeOldTickets, replyMessage, dateRange, weeklySchedule, platformParameters } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handlePersonalizationOpen = e => setAnchorEl(e.currentTarget);
    const handlePersonalizationClose = () => setAnchorEl(null);

    const reset = () => {
        props.resetReplyPage();
    }

    useEffect(() => {
        setPageTitle(t, "autoReplies");

        if (!isLoaded) {
            props.loadChatAccounts();
        }

        return reset;
    }, [])

    useEffect(() => {
        if (autoResponseId > 0) {
            props.loadAutoReply(autoResponseId);
        }
    }, [autoResponseId])

    const handleTypeSelect = (e) => {
        props.handleTypeSelect(e.target.value);
    }

    const handlePlatformParameter = (e) => {
        props.handlePlatformParameter(e.target.value);
    }

    const handleStartDate = (e) => {
        let { dateRange } = props;
        props.handleDateSelect({
            start: e,
            end: dateRange.end
        });
    }

    const handleEndDate = (e) => {
        let { dateRange } = props;
        props.handleDateSelect({
            start: dateRange.start,
            end: e
        });
    }

    const handleIncludeOldTickets = (event) => {
        props.handleIncludeOldTickets(event.target.checked);
    }

    const handleReplyMessage = (event) => {
        props.handleReplyMessage(event.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        await props.handleSubmit(props);
    }

    const insertAtCursor = (insertStr) => (event) => {
        handlePersonalizationClose();

        let textbox: any = document.getElementById("replyMessage");
        textbox.focus();
        let pos = textbox.selectionStart;

        let message = replyMessage.slice(0, pos) + insertStr + replyMessage.slice(pos);
        props.handleReplyMessage(message);
    }

    let platformParameterId = props.platformParameterId;
    if (platformParameterId === null) platformParameterId = 0;

    return (
        <Layout title={t("autoReplies")}>
            <div className={classes.doc}>
                <Paper className={classes.paper}>

                    <FormControl required margin="normal" variant="standard" className={classes.formControl}>
                        <InputLabel htmlFor="reply-type" id="reply-type-label">
                            {t("replyType")}
                        </InputLabel>
                        <Select
                            labelId="reply-type-label"
                            id="reply-type"
                            name="replyType"
                            value={selectedType}
                            onChange={handleTypeSelect}
                        >
                            <MenuItem value={replyType.Welcome}>{t("welcome")}</MenuItem>
                            <MenuItem value={replyType.Vacation}>{t("vacation")}</MenuItem>
                            <MenuItem value={replyType.OutsideOfficeHours}>{t("outsideOfficeHours")}</MenuItem>
                        </Select>
                    </FormControl>
                    <br />
                    <FormControl required margin="normal" variant="standard" className={classes.formControl}>
                        <InputLabel htmlFor="messaging-accounts" id="messaging-accounts-label">
                            {t("messagingAccounts")}
                        </InputLabel>
                        <Select
                            labelId="messaging-accounts-label"
                            id="messaging-accounts"
                            name="platformParameters"
                            value={platformParameterId}
                            onChange={handlePlatformParameter}
                        >
                            <MenuItem key={0} value={0}>{t("all")}</MenuItem>
                            {platformParameters && platformParameters.map(pp => (
                                <MenuItem key={pp.id} value={pp.id}>{pp.displayName !== null ? pp.displayName : truncate(pp.parameters, 30)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormGroup className={classes.row}>
                            <DateTimePicker
                                className={classes.timepicker}
                                label={t("startDate")}
                                value={dateRange.start}
                                onChange={handleStartDate}
                            />
                            <DateTimePicker
                                label={t("endDate")}
                                value={dateRange.end}
                                onChange={handleEndDate}
                            />
                        </FormGroup>
                    </LocalizationProvider>

                    {selectedType === replyType.OutsideOfficeHours && (
                        <FormControlLabel
                            className={classes.checkbox2}
                            control={<Checkbox name="includeOld" color='primary' checked={includeOldTickets}
                                onChange={handleIncludeOldTickets} />} label={t("includeOngoingTickets") as any} />
                    )}

                    <TextField
                        name="replyMessage"
                        id="replyMessage"
                        label={t("message")}
                        className={classes.textField}
                        value={replyMessage}
                        onChange={handleReplyMessage}
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        required
                        InputProps={{
                            className: classes.input,
                        }}
                    />

                    <div className={classes.linkContainer}>
                        <Link component="button" onClick={handlePersonalizationOpen}>{t("addPersonalization")}</Link>
                        <Menu
                            keepMounted
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handlePersonalizationClose}
                            MenuListProps={{ style: { padding: 0 } }}
                        >
                            <MenuItem onClick={insertAtCursor('{firstName}')}>{t("firstNameRecipient")}</MenuItem>
                            <MenuItem onClick={insertAtCursor('{lastName}')}>{t("lastNameRecipient")}</MenuItem>
                            <MenuItem onClick={insertAtCursor('{fullName}')}>{t("fullNameRecipient")}</MenuItem>
                        </Menu>
                    </div>

                    {selectedType === replyType.OutsideOfficeHours &&
                        <>
                            <Typography variant="h5" className={classes.header}>
                                {t("workSchedule")}
                            </Typography>
                            <p className={classes.helperText}>{t("workScheduleDesc")}</p>

                            <WeeklySchedule weeklySchedule={weeklySchedule} handleWorkdaySelect={props.handleWorkdaySelect} handleWorkdayTime={props.handleWorkdayTime} />
                        </>
                    }

                    <div style={{ textAlign: "right" }}>
                        <RouterLink to="./AutoReplies">
                            <Button color="primary" className={classes.backButton}>
                                {t("back")}
                            </Button>
                        </RouterLink>

                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={replyMessage === ""}
                            onClick={handleSubmit}
                        >
                            {autoResponseId > 0 ? t("save") : t("submit")}
                        </Button>
                    </div>
                </Paper>
            </div>
        </Layout>
    );
}

const addAutoRepliesWithStore = connect(
    (state: any) => state.addAutoReplies,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(AddAutoReplies);

export default addAutoRepliesWithStore;