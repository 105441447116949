import React, { useEffect } from "react";
import { Theme } from "@mui/material/styles";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, IconButton } from "@mui/material";
import { Add as AddIcon, DeleteOutline as DeleteIcon } from '@mui/icons-material';
import { FacebookPage } from "../../models";
import { isMobileMode } from '../../helpers/utils';
import { getLongDate } from '../../helpers/date';
import { useTranslation } from "react-i18next";
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    tableWrapper: {
        width: '100%',
        overflowX: 'auto',
    },
    addButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    table: {
        minWidth: isMobileMode() ? 400 : 800,
    },
    deleteButton: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    emptyMessage: {
        margin: theme.spacing(1),
        marginLeft: 0,
        backgroundColor: "#ffffff",
        maxWidth: 800,
        color: "#0a0a0a",
        fontSize: "16px",
        lineHeight: "20px"
    }
}));

interface Props {
    pages: Array<FacebookPage>,
    facebookInit: () => void,
    facebookLogin: () => void,
    getSubscribedPages: () => void,
    deletePage: (pageId: string) => void
}

const FacebookSetup = (props: Props) => {
    const { t } = useTranslation();

    useEffect(() => {
        props.facebookInit()
        props.getSubscribedPages()
    }, [])

    const { classes } = useStyles();

    const { pages } = props;

    return <>
        <Button
            variant="contained"
            color="primary"
            className={classes.addButton}
            onClick={() => props.facebookLogin()}
        >
            <AddIcon />
            {t("linkFacebook")}
        </Button>
        <div className={classes.tableWrapper}>
            <Table size="small" className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("name")}</TableCell>
                        <TableCell>{t("pageId")}</TableCell>
                        <TableCell>{t("expirationDate")}</TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        pages.length === 0 &&
                        <TableRow>
                            <TableCell colSpan={3} align="center">{t("noData")}</TableCell>
                        </TableRow>
                    }
                    {
                        pages.length > 0 &&
                        pages.map(page => {
                            const expired = new Date(page.expirationDate) <= new Date();
                            const expiration = expired ? t("expired") : getLongDate(t, page.expirationDate);
                            const disabled = Boolean(page.linkedParameterId);
                            return (
                                <TableRow key={page.PageID}>
                                    <TableCell>{page.name}</TableCell>
                                    <TableCell>{page.PageID}</TableCell>
                                    <TableCell style={{ color: expired ? 'red' : 'inherit' }}>{expiration}</TableCell>
                                    <TableCell align="center">
                                        <Tooltip title={t("delete")}>
                                            <IconButton
                                                onClick={() => props.deletePage(page.PageID)}
                                                disabled={disabled}
                                                size="large">
                                                <DeleteIcon color={disabled ? "disabled" : "primary"} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        }
                        )
                    }
                </TableBody>
            </Table>
        </div>
    </>;
}

export default FacebookSetup;
