import { alertTypes } from '../../components/common/Alert';
import { ActionCreatorsMapObject } from 'redux';

export enum actionTypes {
    LOADING = 'LOGIN_LOADING',
    LOGIN_FAILED = 'LOGIN_FAILED',
    CHANGE_FIELD = 'LOGIN_CHANGE_FIELD',
    CLOSE_ERROR = 'LOGIN_CLOSE_ERROR',
    RESET_FORM = 'LOGIN_RESET_FORM',
    SET_SUBMITTED = 'LOGIN_SET_SUBMITTED'
}

export type Dispatch = (action: ReducerAction) => void

export interface ReducerAction {
    type: actionTypes,
    message?: string,
    name?: string,
    value?: string
}

export interface State {
    alert: string,
    alertType: alertTypes,
    email: string,
    loading: boolean,
    password: string,
    serializedJwtToken: string,
    submitted: boolean,
    reCaptcha: string
}

export const initialState: State = {
    alert: '',
    alertType: alertTypes.error,
    email: '',
    loading: false,
    password: '',
    serializedJwtToken: '',
    submitted: false,
    reCaptcha: ''
};

export interface Actions extends ActionCreatorsMapObject {
    goToApp: () => (dispatch: Dispatch, getState: any) => void,
    login: (email: string, password: string, reCAPTCHA: string) => (dispatch: Dispatch, getState: any) => void,
    changeField: (name: string, value: any) => ReducerAction,
    closeError: () => ReducerAction,
}