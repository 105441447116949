import { produce } from 'immer';
import { initialState, State, ReducerAction, actionTypes, write, _ENGLISH_LABELS, _FRENCH_LABELS, _CUSTOM_LABELS } from './types'
import { StatusEnum } from '../../models';

const generateNewAPIKey = () => (Math.random() * (Math.random() * 100)).toString(36).replace('.', '')

const reducer: (state: State, action: ReducerAction) => State = (state, action) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.CHANGE_FIELD:
                write(newState, action.name, action.value);
                break;
            case actionTypes.FACEBOOK_PAGE_DELETED:
                newState.facebookPages = newState.facebookPages.filter(p => p.PageID !== action.pageId);
                break;
            case actionTypes.INSTAGRAM_PAGE_DELETED:
                newState.instagramPages = newState.instagramPages.filter(p => p.accountId !== action.accountId);
                break;
            case actionTypes.NUMBER_DELETED:
                newState.numbers = newState.numbers.filter(n => n.platformParameterId !== action.number.platformParameterId);
                break;
            case actionTypes.CLOSE_WEBCHAT_DIALOG:
                newState.webchatDialogIsOpen = false;
                break;
            case actionTypes.EDIT_WEBCHAT_OPEN_DIALOG:
                newState.webchatDialogIsOpen = true;
                newState.profileForm = action.profile;
                newState.profileFormLabels = action.profile.labels;
                break;
            case actionTypes.ADD_WEBCHAT_OPEN_DIALOG:
                newState.webchatDialogIsOpen = true;
                newState.profileForm = {
                    apiKey: generateNewAPIKey(),
                    domain: '',
                    id: 0,
                    useIcon: true,
                    icon: '01',
                    language: 'EN',
                    name: '',
                    labels: { ..._ENGLISH_LABELS },
                    chatColor: '',
                    status: StatusEnum.Active,
                    useOptional: false,
                    useIconImage: false,
                    backgroundColor: ''
                };
                newState.profileFormLabels = newState.profileForm.labels;
                break;
            case actionTypes.WEBCHAT_CHANGE_FIELD:
                if (action.webchatName.startsWith('labels_')) {
                    const name = action.webchatName.replace('labels_', '');
                    newState.profileFormLabels[name] = action.value;
                }
                else {
                    if (action.webchatName === "apiKey")
                        newState.profileForm.apiKey = generateNewAPIKey();
                    else
                        newState.profileForm[action.webchatName as any] = action.value;

                    if (action.webchatName === "language") {
                        if (action.value === 'EN') newState.profileForm.labels = { ..._ENGLISH_LABELS };
                        else if (action.value === 'FR') newState.profileForm.labels = { ..._FRENCH_LABELS };
                        else newState.profileForm.labels = { ..._CUSTOM_LABELS };
                    }
                }
                break;
            case actionTypes.WEBCHAT_PROFILE_RESET_LABELS:
                newState.profileFormLabels = newState.profileForm.labels;
                break;
            case actionTypes.WEBCHAT_PROFILE_SUBMIT_LABELS:
                newState.profileForm.labels = newState.profileFormLabels;
                break;
            case actionTypes.SUBMIT_WEBCHAT_PROFILE:
                if (newState.profileForm.id === 0)
                    newState.profiles.push({ ...state.profileForm, id: action.profileId });
                else {
                    let profileToEdit = newState.profiles.find(p => p.id === newState.profileForm.id);

                    for (var key in newState.profileForm)
                        profileToEdit[key] = newState.profileForm[key];
                }

                newState.webchatDialogIsOpen = false;
                break;
            case actionTypes.WEBCHAT_PROFILE_DELETED:
                newState.profiles = newState.profiles.filter(p => p.id !== action.profile.id);
                break;
        }
    })
};


export default reducer;