import React from 'react';
import { getPrettyTime, getTime } from '../../helpers/date';
import { getTicketStatusName } from '../../store/Messages/types';
import { ticketStatus, Contact, menuCommands, MessageType } from '../../models';
import PlatformIcon from './PlatformIcon';
import ContactItemMenu from './ContactItemMenu';
import { Badge, Box, ListItem, ListItemAvatar, ListItemText, Divider, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { getUserInfo } from '../../helpers/auth';
import { useTranslation } from 'react-i18next';
import { grey } from '@mui/material/colors';
import { interestedInTicket, isUnreadMessage } from '../../helpers/utils';

const StatusLabel = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    borderRadius: 6,
    padding: "2px 4px",
    display: 'inline',
    marginRight: 4,
    fontSize: 10,
    fontWeight: 'bold'
}))

const Text = styled(ListItemText)<{ isBold: boolean }>(({ isBold }) => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '& .MuiListItemText-primary': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: '4px',
        ...(isBold && { fontWeight: 'bold' })
    }
}))

const Time = styled(Typography)({
    display: 'inline-flex',
    color: grey[400]
})

const InfoGrid = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
})

interface Props {
    contact: Contact,
    isLast: boolean,
    onSelect: (contactId: number) => void,
    onMenuItemClick: (contactId: number, command: menuCommands) => void
}

const ContactItem = (props: Props) => {
    const { t } = useTranslation();

    const { contact, isLast } = props;
    const lastMessage = contact.messages[contact.messages.length - 1];

    let unreadCount = 0;
    if (interestedInTicket(contact)) {
        unreadCount = contact.messages.filter(m => isUnreadMessage(contact, m)).length;
    } else {
        unreadCount = 0;
    }

    const showStatusLabel = contact.status == ticketStatus.resolved || contact.status == ticketStatus.transferred;

    const isBold = contact.status == ticketStatus.open || (contact.status === ticketStatus.transferred && contact.agentId === getUserInfo().id);

    return (
        <>
            <ListItem
                alignItems="flex-start"
                button
                selected={contact.selected}
                onClick={() => !contact.selected ? props.onSelect(contact.id) : null}
            >
                <ListItemAvatar>
                    <PlatformIcon platform={contact.platform} />
                </ListItemAvatar>
                <Text isBold={isBold}
                    primary={contact.endUserName || contact.endUserId}
                    secondary={(
                        <>
                            {showStatusLabel && <StatusLabel>{getTicketStatusName(t, contact.status)}</StatusLabel>}
                            {lastMessage.body}
                        </>
                    )}
                />
                <InfoGrid item sx={{ width: "54px" }}>
                    <Time variant="body2" color="textPrimary">
                        {getPrettyTime(t, lastMessage.createdDate, true)}
                    </Time>

                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center',}}>
                        <Badge badgeContent={unreadCount} color="error" sx={{mr: 1}}></Badge>

                        <ContactItemMenu
                            contact={contact}
                            onMenuItemClick={props.onMenuItemClick}
                        />
                    </Box>
                </InfoGrid>
            </ListItem>
            {!isLast && <Divider variant="inset" />}
        </>
    );
};

export default ContactItem;
