import React, { useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from '../store/Profile/actionCreators';
import { State, Actions } from '../store/Profile/types';
import Layout from './Layout/Layout';
import { Button, TextField, InputLabel, Paper, Grid, FormControl, Input, Select, MenuItem } from "@mui/material";
import { hasPermission, PermissionKey } from "../helpers/auth";
import PhoneNumberInput from "./common/PhoneNumberInput";
import ImageUpload from "./common/ImageUpload";
import { useTranslation, withTranslation } from "react-i18next";
import { setPageTitle } from "../helpers/utils";
import { GenderEnum } from "../models";

interface Props { }

const Profile = (props: State & Actions & Props) => {
    const { t } = useTranslation();

    useEffect(() => {
        setPageTitle(t, "profile");

        props.changeField("phone", ''); // hack to fix phone label

        props.loadUserInfo();
    }, [])

    const handleChange = (e) => {
        props.changeField(e.target.name, e.target.value);
    }

    const handleSubmit = () => {
        props.submit();
    }

    const { firstName, lastName, phone, emailAddress, gender, picture, picFile, isSubmitted } = props;

    return (
        <Layout title={t("profile")}>
            <Paper sx={{ display: 'flex', p: 3 }}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            error={isSubmitted && firstName === ''}
                            name="firstName"
                            label={t("firstName")}
                            value={firstName}
                            onChange={handleChange}
                            required
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            error={isSubmitted && lastName === ''}
                            name="lastName"
                            label={t("lastName")}
                            value={lastName}
                            onChange={handleChange}
                            required
                            fullWidth
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl required fullWidth  variant="standard">
                            <InputLabel
                                error={isSubmitted && phone === ''}
                                htmlFor="phoneNumber"
                            >
                                {t("phoneNumber")}
                            </InputLabel>
                            <PhoneNumberInput
                                error={isSubmitted && phone === ''}
                                value={phone}
                                name="phone"
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Grid>
                    {hasPermission(PermissionKey.ManagerUsers) && <Grid item xs={6}>
                        <TextField
                            error={isSubmitted && emailAddress === ''}
                            name="emailAddress"
                            label="Email Address"
                            value={emailAddress}
                            onChange={handleChange}
                            required
                            fullWidth
                            variant="standard"
                        />
                    </Grid>}
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel htmlFor="gender" id="gender-label">{t("gender")}</InputLabel>
                            <Select
                                label="gender-label"
                                id="gender"
                                name="gender"
                                value={gender}
                                onChange={handleChange}
                            >
                                <MenuItem value={GenderEnum.Unknown}>{t("select")}</MenuItem>
                                <MenuItem value={GenderEnum.Male}>{t("male")}</MenuItem>
                                <MenuItem value={GenderEnum.Female}>{t("female")}</MenuItem>
                                <MenuItem value={GenderEnum.Other}>{t("other")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <ImageUpload defaultImage={picture || '/default-image-profile.png'} onChange={props.onFileChange} file={picFile} label={t('profileSpecs')} />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "right" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: 16 }}
                            onClick={handleSubmit}
                        >
                            {t("update")}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Layout>
    );
}

const profileWithStore = connect(
    (state: any) => state.profile,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Profile);

export default withTranslation()(profileWithStore);