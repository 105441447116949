import React from 'react';
import { ResponsiveHeatMap } from '@nivo/heatmap'
import { DefaultHeatMapDatum, HeatMapSerie } from "@nivo/heatmap";

export type Props = {
    data: HeatMapSerie<DefaultHeatMapDatum, object>[],
    yLabel?: string,
    indexBy?: string,
    keys?: string[]
};

const HeatMap = (props: Props) => {

    const { data, yLabel } = props;
    let keys = props.keys;

    if(!data || data.length === 0) return null;
    
    console.log({data})

    return (
    <ResponsiveHeatMap
        data={data}
        enableLabels={false}
        colors={{
            type: 'diverging',
            scheme: 'greens',
            divergeAt: 0.5,
        }}
        emptyColor="#555555"
        margin={{ top: 40, right: 12, bottom: 32, left: 36 }}
        forceSquare={true}
        axisTop={{ tickSize: 5, tickPadding: 5, tickRotation: -90, legend: '', legendOffset: 36 }}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: yLabel,
            legendPosition: 'middle',
            legendOffset: -40
        }}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.8 ] ] }}
        animate={true}
        hoverTarget="cell"  
    />
)}

export default HeatMap;