import { ActionCreatorsMapObject } from 'redux'

export enum actionTypes {
    LOADING_NUMBERS = "BUYBANDWIDTHNUMBER_LOADING_NUMBERS",
    LOADED_NUMBERS = "BUYBANDWIDTHNUMBER_LOADED_NUMBERS",
    GET_SUBSCRIPTION_STATUS = "BUYBANDWIDTHNUMBER_GET_SUBSCRIPTION_STATUS",
};

export interface State {
    numbers: Number[];
    isLoaded: Boolean;
    hasActiveSubscription: Boolean;
}

export interface ReducerAction {
    type: actionTypes | string;
    areaCode?: string;
    numbers?: Number[];
    selectedNumber?: string;
    displayName?: string;
    isLoaded?: Boolean;
    hasActiveSubscription?: Boolean;
}

export interface Actions extends ActionCreatorsMapObject {
    loadNumbers: (areaCode: string) => (dispatch: Dispatch, getState: any) => void;
    handleSubmit: (number: string, displayName: string) => (dispatch: Dispatch, getState: any) => void;
    getSubscriptionStatus: () => (dispatch: Dispatch) => void;
    goBack: () => void;
}

export const initialState = {
    numbers: [],
    isLoaded: false,
    hasActiveSubscription: false,
};

export type Dispatch = (action: ReducerAction) => void