import React, { useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from '../../store/MessagingSettings/actionCreators';
import { State, Actions } from '../../store/MessagingSettings/types';
import Layout from '../Layout/Layout';
import { useTranslation } from "react-i18next";
import { PermissionKey, hasPermission } from "../../helpers/auth";
import PushSettings from "./PushSettings"
// import ChatbotSettings from "./ChatbotSettings"
import { setPageTitle } from "../../helpers/utils";
import EmailSettings from "./EmailSettings";

interface Props { }

const MessagingSettings = (props: State & Actions & Props) => {
    const { t } = useTranslation();

    useEffect(() => {
        setPageTitle(t, "messagingSettings");

        props.loadInfo()
    }, [])

    return (
        <Layout
            title={t("pushNotification")}
            permissionKey={PermissionKey.ViewMessages}
        >
            <PushSettings
                publicKey={props.publicKey}
                device={props.device}
                notificationPermission={props.notificationPermission}
                browserSupport={props.browserSupport}
                autoSetup={props.autoSetup}
                installEvent={props.installEvent}
                requestPushNotificationPermission={props.requestPushNotificationPermission}
                addDevice={props.addDevice}
                testSend={props.testSend}
                enableOrDisableDevice={props.enableOrDisableDevice}
                installApp={props.installApp}
            />

            {/* {hasPermission(PermissionKey.ManageChatBot) && (
                <ChatbotSettings
                    status={props.chatbotStatus}
                    mode={props.chatbotMode}
                    handleStatus={props.handleChatbotStatus}
                    handleMode={props.handleChatbotMode}
                />
            )} */}

            {hasPermission(PermissionKey.ManageChatBot) && (
                <EmailSettings
                    newTicketEmail={props.newTicketEmail}
                    handleTicketEmail={props.handleTicketEmail}
                />
            )}
        </Layout>
    );
}

const settingsWithStore = connect(
    (state: any) => state.messagingSettings,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(MessagingSettings);

export default settingsWithStore;