import React, { useEffect } from "react";
import RouterLink from '../common/RouterLink';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from '../../store/Login/actionCreators';
import { State, Actions } from '../../store/Login/types';
import { FormControl, Input, InputAdornment, Link, Typography } from '@mui/material';
import { userIsAuthenticated } from '../../helpers/auth';
import { useTranslation } from "react-i18next";
import UserPageLayout from './UserPageLayout';
import Recaptcha from '../Recaptcha'
import { setPageTitle } from '../../helpers/utils';
import UserIcon from '@mui/icons-material/PersonOutlineOutlined';
import PasswordIcon from '@mui/icons-material/LockOutlined';
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()(() => ({
    input: {
        '& ::placeholder': {
            color: 'black',
            opacity: 0.7
        },
    },
}));

interface Props { }

const Login = (props: State & Actions & Props) => {
    const { t } = useTranslation();

    useEffect(() => {
        setPageTitle(t, "login");

        if (userIsAuthenticated())
            props.goToApp();
    }, [])

    const changeField = e => {
        props.changeField(e.target.name, e.target.value);
    }

    const handleRecaptcha = (value) => {
        props.changeField('reCaptcha', value);
    }

    const doLogin = () => {
        const { email, password, reCaptcha } = props;
        props.login(email, password, reCaptcha);
    }

    const { classes } = useStyles();

    const { email, password, alert, alertType, submitted, loading } = props;

    return (
        <UserPageLayout
            alertMessage={alert}
            alertType={alertType}
            buttonDisabled={submitted && loading}
            buttonText={t("login")}
            loading={loading}
            onAlertClose={() => props.closeError()}
            onSubmit={doLogin}
        >
            <FormControl margin="normal" required fullWidth>
                <Input
                    className={classes.input}
                    placeholder={t("emailAddress") + ' *'}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={changeField}
                    startAdornment={
                        <InputAdornment position="start">
                            <UserIcon />
                        </InputAdornment>
                    }
                />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
                <Input
                    className={classes.input}
                    placeholder={t("password") + ' *'}
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={changeField}
                    startAdornment={
                        <InputAdornment position="start">
                            <PasswordIcon />
                        </InputAdornment>
                    }
                />
            </FormControl>
            <Recaptcha onChange={handleRecaptcha} />
            <FormControl margin="normal" fullWidth>
                <RouterLink to='/forgotPassword'>
                    <Link component="span" variant="body1" sx={{ mt: 1 }}>
                        {t("forgotPassword")}
                    </Link>
                </RouterLink>
            </FormControl>
            <Typography align="center" variant='body2'>
                {t("byClickingTermsAndConditions")}
                {' '}
                <a href="https://www.spreevel.com/terms-of-use.html" target="_blank" rel="noopener noreferrer">{t("termsAndConditions")}</a>
            </Typography>

        </UserPageLayout>
    )
}

const loginWithStore = connect(
    (state: any) => state.login,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Login);

export default loginWithStore;