import { State, initialState, actionTypes, ReducerAction } from './types'
import { produce } from 'immer'

export default (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.LOADED:
                newState.configLoaded = true
                newState.dataSource = action.dataSource
                newState.documentationUrl = action.documentationUrl
                newState.accessToken = action.accessToken
                newState.embedUrl = action.embedUrl
                newState.id = action.id
                break
            case actionTypes.RENDERED:
                newState.dashboardFinishedRendering = true
                break
            case actionTypes.RESET_STATE:
                newState.configLoaded = initialState.configLoaded
                newState.dashboardFinishedRendering = initialState.dashboardFinishedRendering
                newState.dataSource = initialState.dataSource
                newState.documentationUrl = initialState.documentationUrl
                newState.accessToken = initialState.accessToken
                newState.embedUrl = initialState.embedUrl
                newState.id = initialState.id
                break
        }
    })
}