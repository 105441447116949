import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from "react-i18next";

type Props = {
    title: string,
    children?: any,
    open: boolean,
    handleClose: () => void,
    onConfirm: () => void
}

const ConfirmDialog = (props: Props) => {

    const { t } = useTranslation();

    const { title, children, open, handleClose, onConfirm } = props;
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => {
                        handleClose();
                        onConfirm();
                    }}
                >
                    {t("ok")}
                </Button>
                <Button
                    onClick={handleClose}
                    color="primary"
                >
                    {t("cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmDialog;