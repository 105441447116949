import { produce } from 'immer'
import { actionTypes, State, initialState, ReducerAction } from './types'
import { getTimeOfDayInSeconds } from '../../helpers/date';
import { replyType } from '../../models';

export default (state: State, action: ReducerAction) => {
    if (action.type === actionTypes.RESET) return initialState;

    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.SET_AUTOREPLY_ID:
                newState.autoResponseId = action.autoResponseId
                break
            case actionTypes.SELECTED_TYPE:
                newState.selectedType = action.selectedType
                break
            case actionTypes.SELECTED_INCLUDE_OLD_TICKETS:
                newState.includeOldTickets = action.includeOldTickets
                break
            case actionTypes.SELECTED_PLATFORM_PARAMETER:
                newState.platformParameterId = action.platformParameterId
                break
            case actionTypes.ENTERED_REPLY:
                newState.replyMessage = action.replyMessage
                break
            case actionTypes.SELECTED_DATE:
                newState.dateRange = action.dateRange
                break
            case actionTypes.SELECTED_WORK_HOURS:
                if (action.isStartTime) {
                    newState.weeklySchedule[action.day].timeRange.start = getTimeOfDayInSeconds(action.time);
                } else {
                    newState.weeklySchedule[action.day].timeRange.end = getTimeOfDayInSeconds(action.time);
                }
                break
            case actionTypes.SELECTED_WORK_DAY:
                newState.weeklySchedule[action.day].included = action.checked
                break
            case actionTypes.LOADED_PLATFORM_PARAMETERS:
                newState.platformParameters = action.platformParameters
                newState.isLoaded = true
                break
            case actionTypes.LOADED_AUTOREPLY:
                const { autoReply } = action;
                newState.replyMessage = autoReply.replyMessage;
                newState.selectedType = autoReply.selectedType;
                newState.includeOldTickets = autoReply.includeOldTickets;
                newState.platformParameterId = autoReply.platformParameterId;
                newState.dateRange = autoReply.dateRange;

                if (autoReply.selectedType === replyType.OutsideOfficeHours) {
                    newState.weeklySchedule = autoReply.weeklySchedule;
                } else {
                    const nineAM = 9 * 3600;
                    const fivePM = 17 * 3600;
                    newState.weeklySchedule = {
                        monday: { included: true, timeRange: { start: nineAM, end: fivePM } },
                        tuesday: { included: true, timeRange: { start: nineAM, end: fivePM } },
                        wednesday: { included: true, timeRange: { start: nineAM, end: fivePM } },
                        thursday: { included: true, timeRange: { start: nineAM, end: fivePM } },
                        friday: { included: true, timeRange: { start: nineAM, end: fivePM } },
                        saturday: { included: false, timeRange: { start: nineAM, end: fivePM } },
                        sunday: { included: false, timeRange: { start: nineAM, end: fivePM } },

                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    };
                }
                break;
        }
    })
}