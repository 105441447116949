import { alertTypes } from '../../components/common/Alert';
import { ActionCreatorsMapObject } from 'redux';

export enum actionTypes {
    LOADING = 'FORGOTPASSWORD_LOADING',
    CHANGE_FIELD = 'FORGOTPASSWORD_CHANGE_FIELD',
    CLOSE_MESSAGE = 'FORGOTPASSWORD_CLOSE_MESSAGE',
    SEND_EMAIL_FAILED = 'FORGOTPASSWORD_SEND_EMAIL_FAILED',
    EMAIL_SENT = 'FORGOTPASSWORD_EMAIL_SENT'
}

export interface ReducerAction {
    type: actionTypes,
    name?: string,
    value?: string,
    message?: string,
    successMessage?: string
}

export type Dispatch = (action: ReducerAction) => void

export interface State {
    loading: boolean,
    email: string,
    alertType: alertTypes,
    alert: string,
    submitted: boolean,
    reCaptcha: string
}

export const initialState: State = {
    loading: false,
    email: '',
    alertType: alertTypes.success,
    alert: '',
    submitted: false,
    reCaptcha: ''
};

export interface Actions extends ActionCreatorsMapObject {
    sendResetPasswordEmail: (email: string, reCAPTCHA: string) => (dispatch: Dispatch) => void,
    changeField: (name: string, value: string) => ReducerAction,
    closeMessage: () => ReducerAction
}