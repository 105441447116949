import { State, actionTypes, initialState, ReducerAction, write } from './types'
import { produce } from 'immer'

export default (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.SET_PUBLIC_KEY:
                newState.publicKey = action.publicKey;
                break;
            case actionTypes.SET_DEVICE:
                newState.device = action.device;
                break;
            case actionTypes.SET_NOTIFICATION_PERMISSION:
                newState.notificationPermission = action.notificationPermission;
                break;
            case actionTypes.SET_BROWSER_SUPPORT:
                newState.browserSupport = action.browserSupport;
                break;
            case actionTypes.SET_AUTO_SETUP:
                newState.autoSetup = action.autoSetup;
                break;
            case actionTypes.TOGGLE_DEVICE_ENABLED:
                newState.device.enabled = !newState.device.enabled;
                break;
            case actionTypes.SET_INSTALL_EVENT:
                newState.installEvent = action.installEvent;
                break;
            case actionTypes.CHANGE_FIELD:
                write(newState, action.name, action.value);
                break;
            case actionTypes.LOAD_SETTINGS:
                newState.chatbotStatus = action.chatbotStatus;
                newState.chatbotMode = action.chatbotMode;
                newState.newTicketEmail = action.newTicketEmail;
                break;
        }
    })
}