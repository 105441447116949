import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import actionCreators from '../../store/Layout/actionCreators';
import { State, Actions } from '../../store/Layout/types';
import classNames from 'classnames';
import { Theme } from '@mui/material/styles';
import { getUserInfo, hasPermission, hasPermissions, PermissionKey } from '../../helpers/auth';
import Alert from '../common/Alert';
import Menu from './Menu';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuBar from './MenuBar';
import LogoTitleDrawer from './LogoTitleDrawer';
import { getFullName } from '../../helpers/utils';
import { makeStyles } from '../../styles/makeStyles'

const drawerWidth = 264;

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        display: 'flex'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...(theme.mixins.toolbar as any)
    },
    mobileDrawerHeader: {
        backgroundColor: theme.palette.primary.main
    },
    drawerPaper: {
        position: 'relative !important' as any,
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: 58
        },
    },
    appBarSpacer: theme.mixins.toolbar as any,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        height: '100vh',
        overflow: 'auto',
        backgroundColor: "#fafafa"
    },
    mobileContent: {
        padding: theme.spacing(1),
    },
    loadingBlock: {
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.2)",
        paddingTop: (window.innerHeight - 100) / 2,
        textAlign: "center",
        zIndex: 1600
    }
}));

interface Props {
    noPadding?: boolean,
    title?: string,
    permissionKey?: PermissionKey,
    permissionKeys?: PermissionKey[],
}

const Layout = (props: State & Actions & Props) => {
    const { classes } = useStyles();

    const { drawerOpen, mobileMode, loading, alert, noPadding, permissionKey, permissionKeys, dashboardsMenuOpen, title, notifications } = props;

    useEffect(() => {
        if (notifications === null) props.loadNotifications();
    }, []);

    let fullName = '',
        picture: string = null,
        dashboards = [];

    const userInfo = getUserInfo();

    if (userInfo) {
        fullName = getFullName(userInfo);
        picture = userInfo.picture;
        dashboards = userInfo.dashboards;

        if (permissionKey && !hasPermission(permissionKey))
            return <Redirect to="/accessDenied" />;

        if (permissionKeys?.length > 0 && !hasPermissions(permissionKeys))
            return <Redirect to="/accessDenied" />;
    }

    return <>
        <div className={classes.root}>
            <MenuBar
                drawerOpen={drawerOpen}
                picture={picture}
                fullName={fullName}
                title={title}
                mobileMode={mobileMode}
                onCloseDrawer={props.closeDrawer}
                onGotoMenu={menu => props.gotoMenu(menu)}
                onLogout={props.logout}
                onOpenDrawer={props.openDrawer}
                notifications={props.notifications}
                toggleNotificationRead={props.toggleNotificationRead}
                handleNotificationRedirect={props.handleNotificationRedirect}
            />

            <Drawer
                variant={mobileMode ? "temporary" : "permanent"}
                classes={{
                    paper: classNames([classes.drawerPaper, !drawerOpen && classes.drawerPaperClose])
                }}
                open={drawerOpen}
                onClose={props.closeDrawer}
            >
                <div className={`${classes.drawerHeader} ${mobileMode ? classes.mobileDrawerHeader : ''}`}>

                    {mobileMode && <LogoTitleDrawer />}

                    <IconButton onClick={props.closeDrawer}>
                        <ChevronLeftIcon style={mobileMode ? { color: 'white' } : {}} />
                    </IconButton>
                </div>
                <Divider />
                <Menu
                    mobileMode={mobileMode}
                    dashboards={dashboards}
                    dashboardsOpen={dashboardsMenuOpen}
                    toggleDashboardMenu={props.toggleDashboardMenu}
                    onCloseDrawer={props.closeDrawer}
                />
            </Drawer>

            <div className={`${classes.content} ${mobileMode ? classes.mobileContent : ''}`} style={noPadding ? { padding: 0 } : {}}>
                <div className={classes.appBarSpacer} />
                {props.children}
            </div>

            <Alert
                open={alert.open}
                type={alert.type}
                message={alert.message}
                onClose={props.closeAlert}
            />
        </div>
        <Fade
            in={loading}
            style={{
                transitionDelay: loading ? '800ms' : '0ms',
            }}
            unmountOnExit
        >
            <div className={classes.loadingBlock}>
                <CircularProgress thickness={2} size={70} />
            </div>
        </Fade>
    </>;
}

const layoutWithStore = connect<any, any, any>(
    (state: any) => state.layout,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Layout);


export default layoutWithStore;