import { State, actionTypes, initialState, ReducerAction, write, writeTemplate } from './types';
import { produce } from 'immer'

export default (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.CHANGE_FIELD:
                write(newState, action.name, action.value)
                break
            case actionTypes.ADD_TEMPLATE:
                newState.templates.push(action.template)
                break
            case actionTypes.UPDATE_TEMPLATE: {
                const index = newState.templates.findIndex(q => q.id == action.template.id);
                newState.templates[index] = action.template;
                break
            }
            case actionTypes.DELETE_TEMPLATE:
                newState.templates = newState.templates.filter(t => t.id !== action.templateId);
                break
            case actionTypes.CHANGE_TEMPLATE_FIELD:
                writeTemplate(newState.template, action.templateName, action.value);
                break
        }
    })
}