import React from "react";
import { FormControlLabel, TextField, FormGroup, Checkbox, Theme } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { getDateFromTimeOfDay } from '../../../helpers/date';
import { useTranslation } from "react-i18next";
import { WeeklySchedule } from "../../../models";
import { makeStyles } from '../../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        marginTop: "16px",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flex: 1
    },
    checkbox: {
        marginTop: "32px",
        minWidth: "128px"
    },
    timepicker: {
        [theme.breakpoints.up('md')]: {
            marginRight: "16px",
        }
    },
}));

interface Props {
    weeklySchedule: WeeklySchedule,
    handleWorkdaySelect: (day: string, checked: boolean) => void,
    handleWorkdayTime: (day: string, isStartTime: boolean, time: Date) => void,
}

const WeeklyScheduleComponent = (props: Props) => {
    const { t } = useTranslation();

    const { classes } = useStyles();

    const { weeklySchedule } = props;

    const handleWorkdaySelect = (event) => {
        props.handleWorkdaySelect(event.target.name, event.target.checked);
    }

    const handleWorkdayStartTime = (day) => (e) => {
        props.handleWorkdayTime(day, true, e);
    }

    const handleWorkdayEndTime = (day) => (e) => {
        props.handleWorkdayTime(day, false, e);
    }

    return (
        <div className={classes.container}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormGroup className={classes.row}>
                <FormControlLabel
                    className={classes.checkbox}
                    control={<Checkbox name={"monday"} color='primary' checked={weeklySchedule.monday?.included} onChange={handleWorkdaySelect} />} label={t("monday") as any} />
                <TimePicker
                    label={t("startTime")}
                    value={getDateFromTimeOfDay(weeklySchedule.monday?.timeRange.start)}
                    onChange={handleWorkdayStartTime("monday")}
                    disabled={!weeklySchedule.monday?.included}
                    className={classes.timepicker}
                />
                <TimePicker
                    label={t("endTime")}
                    value={getDateFromTimeOfDay(weeklySchedule.monday?.timeRange.end)}
                    onChange={handleWorkdayEndTime("monday")}
                    disabled={!weeklySchedule.monday?.included}
                />
            </FormGroup>
            <FormGroup className={classes.row}>
                <FormControlLabel
                    className={classes.checkbox}
                    control={<Checkbox name={"tuesday"} color='primary' checked={weeklySchedule.tuesday?.included} onChange={handleWorkdaySelect} />} label={t("tuesday") as any} />
                <TimePicker
                    label={t("startTime")}
                    value={getDateFromTimeOfDay(weeklySchedule.tuesday?.timeRange.start)}
                    onChange={handleWorkdayStartTime("tuesday")}
                    disabled={!weeklySchedule.tuesday?.included}
                    className={classes.timepicker}
                />
                <TimePicker
                    label={t("endTime")}
                    value={getDateFromTimeOfDay(weeklySchedule.tuesday?.timeRange.end)}
                    onChange={handleWorkdayEndTime("tuesday")}
                    disabled={!weeklySchedule.tuesday?.included}
                />
            </FormGroup>
            <FormGroup className={classes.row}>
                <FormControlLabel
                    className={classes.checkbox}
                    control={<Checkbox name={"wednesday"} color='primary' checked={weeklySchedule.wednesday?.included} onChange={handleWorkdaySelect} />} label={t("wednesday") as any} />
                <TimePicker
                    label={t("startTime")}
                    value={getDateFromTimeOfDay(weeklySchedule.wednesday?.timeRange.start)}
                    onChange={handleWorkdayStartTime("wednesday")}
                    disabled={!weeklySchedule.wednesday?.included}
                    className={classes.timepicker}
                />
                <TimePicker
                    label={t("endTime")}
                    value={getDateFromTimeOfDay(weeklySchedule.wednesday?.timeRange.end)}
                    onChange={handleWorkdayEndTime("wednesday")}
                    disabled={!weeklySchedule.wednesday?.included}
                />
            </FormGroup>
            <FormGroup className={classes.row}>
                <FormControlLabel
                    className={classes.checkbox}
                    control={<Checkbox name={"thursday"} color='primary' checked={weeklySchedule.thursday?.included} onChange={handleWorkdaySelect} />} label={t("thursday") as any} />
                <TimePicker
                    label={t("startTime")}
                    value={getDateFromTimeOfDay(weeklySchedule.thursday?.timeRange.start)}
                    onChange={handleWorkdayStartTime("thursday")}
                    disabled={!weeklySchedule.thursday?.included}
                    className={classes.timepicker}
                />
                <TimePicker
                    label={t("endTime")}
                    value={getDateFromTimeOfDay(weeklySchedule.thursday?.timeRange.end)}
                    onChange={handleWorkdayEndTime("thursday")}
                    disabled={!weeklySchedule.thursday?.included}
                />
            </FormGroup>
            <FormGroup className={classes.row}>
                <FormControlLabel
                    className={classes.checkbox}
                    control={<Checkbox name={"friday"} color='primary' checked={weeklySchedule.friday?.included} onChange={handleWorkdaySelect} />} label={t("friday") as any} />
                <TimePicker
                    label={t("startTime")}
                    value={getDateFromTimeOfDay(weeklySchedule.friday?.timeRange.start)}
                    onChange={handleWorkdayStartTime("friday")}
                    disabled={!weeklySchedule.friday?.included}
                    className={classes.timepicker}
                />
                <TimePicker
                    label={t("endTime")}
                    value={getDateFromTimeOfDay(weeklySchedule.friday?.timeRange.end)}
                    onChange={handleWorkdayEndTime("friday")}
                    disabled={!weeklySchedule.friday?.included}
                />
            </FormGroup>
            <FormGroup className={classes.row}>
                <FormControlLabel
                    className={classes.checkbox}
                    control={<Checkbox name={"saturday"} color='primary' checked={weeklySchedule.saturday?.included} onChange={handleWorkdaySelect} />} label={t("saturday") as any} />
                <TimePicker
                    label={t("startTime")}
                    value={getDateFromTimeOfDay(weeklySchedule.saturday?.timeRange.start)}
                    onChange={handleWorkdayStartTime("saturday")}
                    disabled={!weeklySchedule.saturday?.included}
                    className={classes.timepicker}
                />
                <TimePicker
                    label={t("endTime")}
                    value={getDateFromTimeOfDay(weeklySchedule.saturday?.timeRange.end)}
                    onChange={handleWorkdayEndTime("saturday")}
                    disabled={!weeklySchedule.saturday?.included}
                />
            </FormGroup>
            <FormGroup className={classes.row}>
                <FormControlLabel
                    className={classes.checkbox}
                    control={<Checkbox name={"sunday"} color='primary' checked={weeklySchedule.sunday?.included} onChange={handleWorkdaySelect} />} label={t("sunday") as any} />
                <TimePicker
                    label={t("startTime")}
                    value={getDateFromTimeOfDay(weeklySchedule.sunday?.timeRange.start)}
                    onChange={handleWorkdayStartTime("sunday")}
                    disabled={!weeklySchedule.sunday?.included}
                    className={classes.timepicker}
                />
                <TimePicker
                    label={t("endTime")}
                    value={getDateFromTimeOfDay(weeklySchedule.sunday?.timeRange.end)}
                    onChange={handleWorkdayEndTime("sunday")}
                    disabled={!weeklySchedule.sunday?.included}
                />
            </FormGroup>
        </LocalizationProvider>
        </div>
    );
}

export default WeeklyScheduleComponent;