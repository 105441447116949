import * as React from 'react';
import { grey } from '@mui/material/colors';
import { Paper, Typography, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { ProductPlan, ProductPlanDescription } from '../../../models';
import { useTranslation } from 'react-i18next';

interface Props {
    plan: ProductPlan,
    onSelect: (plan: ProductPlan) => void
}

const PlanItem = (props: Props) => {
    const { t } = useTranslation();

    const { plan } = props,
        desc: ProductPlanDescription = JSON.parse(plan.description);

    return (
        <Paper
            elevation={plan.selected ? 10 : 1}
            sx={{ p: '40px', m: '10px', flex: 1 }}
        >
            <Typography
                component="h3"
                variant="h4"
                sx={{ textAlign: 'center' }}
            >
                {plan.title}
            </Typography>
            <Typography
                variant="body2"
                color="textSecondary"
                sx={{ mt: '20px', mb: '20px' }}
            >
                {desc.text}
            </Typography>
            <List sx={{ mb: '20px' }}>
                {desc.features.map((f, i) => (
                    <ListItem key={i} sx={{ pl: 0, borderBottomWidth: '1px', borderBottomStyle: "solid", borderBottomColor: grey[200] }}>
                        <ListItemIcon>
                            <CheckIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText primary={f} />
                    </ListItem>
                ))}
            </List>
            <Typography
                variant="h5"
                sx={{ textAlign: 'center' }}
            >
                ${plan.price} USD / {t("month")}
            </Typography>
            <div>
                <Button
                    fullWidth
                    color="secondary"
                    variant={plan.selected ? "outlined" : "contained"}
                    sx={{ mt: '20px' }}
                    onClick={() => props.onSelect(plan)}
                >
                    {plan.selected ? t("selected") : t("selectPlan")}
                </Button>
            </div>
        </Paper>
    );
};

export default PlanItem;
