import React, { useState } from 'react';
import { MenuItem, Menu, IconButton } from '@mui/material';
import { storeLang, getStoredLang } from '../../Localization/i18n';
import LangIcon from '@mui/icons-material/Translate';
import ArrowDown from '@mui/icons-material/ExpandMoreSharp';
import { useTranslation } from 'react-i18next';

interface Props { }

const LangMenu = (props: Props) => {

    const [anchorEl, setAnchorEl] = useState<Element>(null);
    const [lang, setLang] = useState(getStoredLang())

    const { t, i18n } = useTranslation();

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getLangLabel = () => {
        switch (lang) {
            case 'en':
                return t("lang_English");
            case 'fr':
                return t("lang_French");
            default:
                return t("lang_English");
        }
    }

    const handleLang = async (e) => {
        const { value } = e.target.dataset;
        storeLang(value);
        await i18n.changeLanguage(value);
        setLang(value);
        handleClose();
    }

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
    };

    return <>
        <IconButton color='inherit' onClick={handleOpen} sx={{ borderRadius: 5, ml: 1  }}>
            <LangIcon />
            <ArrowDown />
        </IconButton>

        <Menu
            keepMounted
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleClose}
        >
            <MenuItem selected={lang === 'en'} onClick={handleLang} data-value={'en'}>{t('lang_English')}</MenuItem>
            <MenuItem selected={lang === 'fr'} onClick={handleLang} data-value={'fr'}>{t('lang_French')}</MenuItem>
        </Menu>
    </>;
};
export default LangMenu;