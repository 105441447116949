import ajax from '../../helpers/ajax';
import { i18n } from "../../Localization/i18n"
import { State, Actions, actionTypes, contentSchema } from './types';
import { showLoading, hideLoading, showAlert } from '../Layout/actionCreators';
import { alertTypes } from '../../components/common/Alert';
import { fieldsToJson, isEmpty, validator } from '../../helpers/utils';


export default {
    changeField: (name, value) => ({ type: actionTypes.CHANGE_FIELD, name, value }),
    getContent: (templateId) => async (dispatch, getState) => {
        dispatch(showLoading());

        const response = await ajax.get(`ChatBot/Template/${templateId}`);

        if (await ajax.errorOccured(response, "Data load failed.", dispatch, getState)) return;

        const { name, templateContent } = await response.json();

        dispatch(hideLoading());

        dispatch({ type: actionTypes.CHANGE_FIELD, name: 'name', value: name });
        dispatch({ type: actionTypes.CHANGE_FIELD, name: 'contents', value: templateContent });
    },
    deleteContent: contentId => async (dispatch, getState) => {
        dispatch(showLoading());

        const response = await ajax.del(`ChatBot/Template/Content/${contentId}`, '');

        if (await ajax.errorOccured(response, "Delete failed.", dispatch, getState)) return;

        dispatch({ type: actionTypes.DELETE_CONTENT, contentId });

        dispatch(hideLoading());

        dispatch(showAlert(i18n.t("dataDeleted"), alertTypes.success));
    },
    submit: () => async (dispatch, getState) => {
        const state: State = getState().templateContent;
        const content = { ...state.content };
        const templateId = state.templateId;

        const addMode = content.id === 0;

        const errors = await validator(contentSchema)(content)
        
        dispatch({ type: actionTypes.CHANGE_FIELD, name: "errors", value: errors });
        if (!isEmpty(errors)) return errors;

        dispatch(showLoading());

        content.info = fieldsToJson(content.infoFields);

        const response = await ajax.post(`ChatBot/Template/${templateId}/Content`, content);

        if (await ajax.errorOccured(response, "", dispatch, getState)) return;

        if (addMode) {
            content.id = await response.json();
        }

        if (addMode) dispatch({ type: actionTypes.ADD_CONTENT, content });
        else dispatch({ type: actionTypes.UPDATE_CONTENT, content });

        dispatch(hideLoading());
        dispatch({
            type: actionTypes.CHANGE_FIELD, name: 'content', value: {
                id: 0,
                name: '',
                language: 'en',
            }
        });

        return errors;
    },
    changeContentField: (contentName, value) => ({ type: actionTypes.CHANGE_CONTENT_FIELD, contentName, value }),
    validate: () => async (dispatch, getState) => {
        const state: State = getState().templateContent;
        const { content } = state;

        const errors = await validator(contentSchema)(content)

        dispatch({ type: actionTypes.CHANGE_FIELD, name: "errors", value: errors });
    },
    changeJsonField: (infoFieldId, fieldName, value) => ({ type: actionTypes.CHANGE_JSON_FIELD, infoFieldId, fieldName, value }),
    addField: () => ({ type: actionTypes.ADD_FIELD }),
    deleteField: (infoFieldId) => ({ type: actionTypes.DELETE_FIELD, infoFieldId }),
} as Actions