import { ActionCreatorsMapObject } from 'redux'
import { addMonths } from '../../helpers/date';
import { PlatformParameter, replyType, DateRange, WeeklySchedule, AutoReply } from '../../models';

export enum actionTypes {
    SET_AUTOREPLY_ID = "ADDAUTOREPLIES_SET_AUTOREPLY_ID",
    SELECTED_TYPE = "ADDAUTOREPLIES_SELECTED_TYPE",
    ENTERED_REPLY = "ADDAUTOREPLIES_ENTERED_REPLY",
    SELECTED_DATE = "ADDAUTOREPLIES_SELECTED_DATE",
    SELECTED_PLATFORM_PARAMETER = "ADDAUTOREPLIES_SELECTED_PLATFORM_PARAMETER",
    SELECTED_INCLUDE_OLD_TICKETS = "ADDAUTOREPLIES_SELECTED_INCLUDE_OLD_TICKETS",
    SELECTED_WORK_HOURS = "ADDAUTOREPLIES_SELECTED_WORK_HOURS",
    SELECTED_WORK_DAY = "ADDAUTOREPLIES_SELECTED_WORK_DAY",
    RESET = "ADDAUTOREPLIES_RESET",
    LOADED_PLATFORM_PARAMETERS = "ADDAUTOREPLIES_LOADED_PLATFORM_PARAMETERS",
    LOADED_AUTOREPLY = "ADDAUTOREPLIES_LOADED_AUTOREPLY",
};


export interface State {
    autoResponseId: number;
    selectedType: replyType;
    replyMessage: string;
    dateRange: DateRange;
    weeklySchedule: WeeklySchedule;
    includeOldTickets: boolean;
    isLoaded: boolean;
    platformParameters: PlatformParameter[];
    platformParameterId: number;
}

export interface ReducerAction {
    type: actionTypes | string;
    autoResponseId?: number;
    selectedType?: replyType;
    replyMessage?: string;
    dateRange?: DateRange;
    includeOldTickets?: boolean
    day?: string;
    time?: Date;
    isStartTime?: boolean;
    checked?: boolean;
    platformParameters?: PlatformParameter[];
    platformParameterId?: number;
    autoReply?: AutoReply;
}

export interface Actions extends ActionCreatorsMapObject {
    loadAutoReply: (autoResponseId: number) => (dispatch: Dispatch, getState: any) => void,
    loadChatAccounts: () => (dispatch: Dispatch, getState: any) => void,
    handleSubmit: (details: State) => (dispatch: Dispatch, getState: any) => void,
    handleDateSelect: (dateRange: DateRange) => ReducerAction,
    handleTypeSelect: (selectedType: replyType) => ReducerAction,
    handlePlatformParameter: (platformParameterId: number) => ReducerAction,
    handleIncludeOldTickets: (checked: boolean) => ReducerAction,
    handleWorkdaySelect: (day: string, checked: boolean) => ReducerAction,
    handleWorkdayTime: (day: string, isStartTime: boolean, time: Date) => ReducerAction,
    handleReplyMessage: (replyMessage: string) => ReducerAction,
    resetReplyPage: () => (dispatch: Dispatch) => void,
}

const nineAM = 9 * 3600;
const fivePM = 17 * 3600;

export const initialState = {
    autoResponseId: 0,
    selectedType: replyType.Welcome,
    replyMessage: "",
    dateRange: { start: new Date(), end: addMonths(new Date(), 3) },
    weeklySchedule: {
        monday: { included: true, timeRange: { start: nineAM, end: fivePM } },
        tuesday: { included: true, timeRange: { start: nineAM, end: fivePM } },
        wednesday: { included: true, timeRange: { start: nineAM, end: fivePM } },
        thursday: { included: true, timeRange: { start: nineAM, end: fivePM } },
        friday: { included: true, timeRange: { start: nineAM, end: fivePM } },
        saturday: { included: false, timeRange: { start: nineAM, end: fivePM } },
        sunday: { included: false, timeRange: { start: nineAM, end: fivePM } },

        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    includeOldTickets: false,
    isLoaded: false,
    platformParameters: null,
    platformParameterId: 0,
};

export type Dispatch = (action: ReducerAction) => void