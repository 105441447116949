import * as React from 'react';
import PlanItem from './PlanItem';
import { Container, Grid } from '@mui/material';

import { ProductPlan } from '../../../models';
import { isMobileMode } from '../../../helpers/utils';


interface Props {
    plans: ProductPlan[],
    onSelectPlan: (plan: ProductPlan) => void,
}

const Plans = (props: Props) => {

    const { plans } = props;
    const sortedPlans = [...plans].sort((a, b) => a.showOrder - b.showOrder);

    return (
        <Container maxWidth="lg" style={{ padding: 0 }}>
            <Grid container>
                {
                    sortedPlans.map((plan, i) => (
                        <Grid item xs key={i}>
                            <PlanItem
                                plan={plan}
                                onSelect={plan => props.onSelectPlan(plan)}
                            />
                        </Grid>
                    ))
                }
            </Grid>
        </Container>
    );
};

export default Plans;
