import { alertTypes } from '../../components/common/Alert'
import { State, actionTypes, initialState, ReducerAction } from './types'
import { produce } from 'immer'

export default (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.CHANGE_FIELD:
                newState[action.name] = action.value
                break
            case actionTypes.CHANGE_ADDRESS_FIELD:
                if (!newState.address)
                    newState.address = {
                        city: '',
                        country: '',
                        fullAddress: '',
                        postalCode: '',
                        province: '',
                        route: '',
                        streetNumber: ''
                    }

                newState.address[action.name] = action.value
                break
            case actionTypes.SHOW_LOADING:
                newState.loading = true
                break
            case actionTypes.SUBMIT:
                newState.isSubmitted = true
                break
            case actionTypes.SUBMITTED:
                newState.loading = false
                newState.message = action.message
                newState.alertType = alertTypes.success
                newState.done = true
                break
            case actionTypes.FAILED:
                newState.loading = false
                newState.message = action.message
                newState.alertType = alertTypes.error
                break
            case actionTypes.CLOSE_ALERT:
                newState.message = ''
                break
            case actionTypes.CHANGE_ADDRESS:
                newState.address = action.address
                break
            case actionTypes.COMPANY_TYPES_LOADED:
                newState.loading = false
                newState.companyTypes = action.companyTypes
                break
        }
    })
}