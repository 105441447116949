import * as React from "react";
import { Paper, Typography } from "@mui/material";

const PaymentInfoItem = props => (
    <Paper style={{ padding: 16, margin: 10, minWidth: 250, height: 'calc(100% - 20px)', position: 'relative' }}>
        <Typography variant='h4' style={{ textAlign: 'center', fontSize: "1.75rem" }} gutterBottom>{props.title}</Typography>
        {props.children}
    </Paper>
)

export default PaymentInfoItem;