import ajax from '../../helpers/ajax';
import { i18n } from "../../Localization/i18n"
import { Actions, actionTypes } from './types';
import { showLoading, hideLoading, showAlert } from '../Layout/actionCreators';
import { alertTypes } from '../../components/common/Alert';

export default {
    loadChatAccounts: () => async (dispatch, getState) => {
        dispatch(showLoading());

        const response = await ajax.get("AutoResponse/PlatformParameters");

        if (await ajax.errorOccured(response, i18n.t("autoReplyLoadFail"), dispatch, getState)) return;

        try {
            const platformParameters = await response.json();
            dispatch({ type: actionTypes.LOADED_PLATFORM_PARAMETERS, platformParameters });
            dispatch(hideLoading());
        } catch (err) {
            console.log(err);
        }
    },
    loadAutoReply: (autoResponseId) => async (dispatch, getState) => {
        dispatch(showLoading());

        const response = await ajax.get(`AutoResponse/${autoResponseId}`);

        if (await ajax.errorOccured(response, i18n.t("autoReplyLoadFail"), dispatch, getState)) return;

        try {
            const autoReply = await response.json();
            dispatch({ type: actionTypes.LOADED_AUTOREPLY, autoReply });
            dispatch(hideLoading());
        } catch (err) {
            console.log(err);
        }
    },
    handleDateSelect: (dateRange) => ({ type: actionTypes.SELECTED_DATE, dateRange }),
    handleTypeSelect: (selectedType) => ({ type: actionTypes.SELECTED_TYPE, selectedType }),
    handleIncludeOldTickets: (includeOldTickets) => ({ type: actionTypes.SELECTED_INCLUDE_OLD_TICKETS, includeOldTickets }),
    handlePlatformParameter: (platformParameterId) => ({ type: actionTypes.SELECTED_PLATFORM_PARAMETER, platformParameterId }),
    handleWorkdaySelect: (day, checked) => ({ type: actionTypes.SELECTED_WORK_DAY, day, checked }),
    handleWorkdayTime: (day, isStartTime, time) => ({ type: actionTypes.SELECTED_WORK_HOURS, day, isStartTime, time }),
    handleReplyMessage: (replyMessage) => ({ type: actionTypes.ENTERED_REPLY, replyMessage }),
    handleSubmit: (replyDetails) => async (dispatch, getState) => {
        dispatch(showLoading());
        const { autoResponseId, selectedType, includeOldTickets, replyMessage, dateRange, weeklySchedule } = replyDetails;

        let platformParameterId = replyDetails.platformParameterId;
        if (platformParameterId === 0) platformParameterId = null;

        const info = { autoResponseId, selectedType, includeOldTickets, replyMessage, dateRange, weeklySchedule, platformParameterId };

        let response;
        if (autoResponseId > 0) {
            response = await ajax.put("AutoResponse", info);
        } else {
            response = await ajax.post("AutoResponse", info);
        }

        if (await ajax.errorOccured(response, "An error has occurred, please try again later.", dispatch, getState)) return

        dispatch(hideLoading());
        dispatch(showAlert(i18n.t("autoReplySaved"), alertTypes.success));
    },
    resetReplyPage: () => async (dispatch) => {
        dispatch({ type: actionTypes.RESET });
    },
} as Actions