import * as React from 'react';
import RouterLink from '../common/RouterLink';
import { Badge, Box, Card, CardContent, Typography } from '@mui/material';
import { isMobileMode } from '../../helpers/utils';

interface Props {
    title: string,
    link: string,
    icon: any,
    badgeCount?: number,
}

const HomeIcon = (props: Props) => {
    const { title, icon, link, badgeCount } = props,
        isMobile = isMobileMode()

    return (
        <RouterLink to={link} style={{ margin: '10px', minWidth: '100px' }}>
            <Card>
                <CardContent sx={isMobile ? { display: 'flex', padding: '10px !important' } : { textAlign: 'center' }}>
                    <Box sx={{ textAlign: 'center', backgroundColor: '#FFF', borderRadius: '50%', height: '32px', margin: 'auto', mt: isMobileMode() ? '4px' : '16px', mb: '4px', } && isMobileMode() ? { width: "70px" } : {}}>
                        <Badge badgeContent={badgeCount} color="error">{icon}</Badge>
                    </Box>
                    <Typography variant="body2" component="span" sx={isMobile ? { display: 'flex', alignSelf: 'center', pl: 0.5, width: 'calc(100% - 70px)' } : {}}>
                        {title}
                    </Typography>
                </CardContent>
            </Card>
        </RouterLink>
    );
}

export default HomeIcon;