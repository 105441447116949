import { State, actionTypes, initialState, ReducerAction } from './types'
import { produce } from 'immer'

export default (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.LOADING:
                newState.loading = true;
                break;
            case actionTypes.LOADED:
                newState.showSplash = false;
                newState.loading = false;
                newState.userInfo = action.userInfo;
                break;
            case actionTypes.HIDE_LOADING:
                newState.loading = false;
                break;
            case actionTypes.CLEAR_USER_INFO:
                newState.userInfo = undefined;
                break;
        }
    })
}