import { alertTypes } from '../../components/common/Alert';
import { ActionCreatorsMapObject } from 'redux';

export interface State {
    loading: boolean,
    email: string,
    password: string,
    confirmPassword: string,
    alert: string,
    alertType: alertTypes,
    submitted: boolean,
    updatePasswordInvalid: boolean,
    showLoginLink: boolean,
    reCaptcha: string
}

export enum actionTypes {
    LOADING = 'CREATEPASSWORD_LOADING',
    FAILED='CREATEPASSWORD_FAILED' ,
    CHANGE_FIELD ='CREATEPASSWORD_CHANGE_FIELD' ,
    CLOSE_MESSAGE ='CREATEPASSWORD_CLOSE_MESSAGE' ,
    INVALID_LINK=    'CREATEPASSWORD_INVALID_LINK' ,
    INVALID_CODE = 'CREATEPASSWORD_INVALID_CODE' ,
    PASSWORD_UPDATED ='CREATEPASSWORD_PASSWORD_UPDATED' ,
    UPDATEPASSWORD_FAILED ='CREATEPASSWORD_UPDATEPASSWORD_FAILED' ,
    CODE_ISVALID ='CREATEPASSWORD_CODE_ISVALID'
}

export interface ReducerAction {
    type: actionTypes,
    name?: string,
    value?: string,
    message?: string,
    email?: string
}

export type Dispatch = (action: ReducerAction) => void

export const initialState: State = {
    loading: false,
    email: '',
    password: '',
    confirmPassword: '',
    alert: '',
    alertType: alertTypes.error,
    submitted: false,
    updatePasswordInvalid: true,
    showLoginLink: false,
    reCaptcha: ''
};

export interface Actions extends ActionCreatorsMapObject {
    validateCode: (code: string) => (dispatch: Dispatch) => void,
    updatePassword: (code: string, password: string, confirmPassword: string, reCAPTCHA: string) => (dispatch: Dispatch) => void,
    changeField: (name: string, value: string) => ReducerAction,
    goToLogin: () => any,
    closeMessage: () => (dispatch: Dispatch, getState) => void
}