import ajax, { Method } from '../../helpers/ajax';
import { showLoading, hideLoading, showAlert } from '../Layout/actionCreators';
import { User } from '../../models';
import { Actions, actionTypes, State } from './types'
import { i18n } from "../../Localization/i18n"
import { getUserInfo, setUserInfo } from '../../helpers/auth'

import { alertTypes } from '../../components/common/Alert';

export default {
    loadUserInfo: () => async (dispatch, getState) => {
        dispatch(showLoading());

        const userInfoResponse = await ajax.get('User/getUserInfo');

        if (await ajax.errorOccured(userInfoResponse, "Load user info failed.", dispatch, getState)) return;

        let userInfo = await userInfoResponse.json();

        dispatch({
            type: actionTypes.LOADED,
            id: userInfo.id,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            phone: userInfo.phone,
            gender: userInfo.gender,
            emailAddress: userInfo.emailAddress,
            picture: userInfo.picture,
        });

        dispatch(hideLoading());
    },
    submit: () => async (dispatch, getState) => {
        dispatch({ type: actionTypes.SUBMIT });
        const state: State = getState().profile;
        const { id, firstName, lastName, phone, gender, emailAddress, picFile } = state;

        if (firstName == '' ||
            lastName == '' ||
            phone == '' ||
            emailAddress == '')
            return;

        let picture = state.picture;
        if (picFile !== null) {
            const data = new FormData();
            data.append("file", picFile);

            const uploadResponse = await ajax.call(
                'User/Picture',
                Method.POST,
                data,
                true
            );

            if (await ajax.errorOccured(uploadResponse, "Picture upload failed.", dispatch, getState)) return;

            picture = await uploadResponse.json();
            dispatch({ type: actionTypes.CHANGE_FIELD, name: 'picture', value: picture })
            dispatch({ type: actionTypes.CHANGE_FIELD, name: 'picFile', value: null })
        }

        const userInfo: User = {
            id: id,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            gender,
            emailAddress: emailAddress,
            picture,
        };

        console.log(userInfo)

        const response = await ajax.post("User/Profile", userInfo);

        if (await ajax.errorOccured(response, "Profile update failed.", dispatch, getState)) return;

        if(picture){
            const userInfo = getUserInfo();
            let info = {...userInfo, firstName, lastName, picture};
            setUserInfo(info);
        }


        dispatch(showAlert(i18n.t("userUpdated"), alertTypes.success));
    },
    onFileChange: (picFile) => ({ type: actionTypes.FILE_CHANGED, picFile }),
    changeField: (name, value) => ({ type: actionTypes.CHANGE_FIELD, name, value })
} as Actions