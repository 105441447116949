//Login and token

const USERTOKEN = 'USER_TOKEN';

export const userIsAuthenticated = () => {
    return getToken() !== null;
};

export const getToken = () => {
    return localStorage.getItem(USERTOKEN);
};

export const login = (token: string) => {
    localStorage.setItem(USERTOKEN, token);
};

export const logout = () => {
    setUserInfo(undefined);
    localStorage.removeItem(USERTOKEN);
};

//Permissions

export enum PermissionKey {
    ViewDashboards = "ViewDashboards",
    ViewMessages = "ViewMessages",
    ClaimTicket = "ClaimTicket",
    ViewAllTickets = "ViewAllTickets",
    ManagerUsers = "ManagerUsers",
    ManagePayments = "ManagePayments",
    SetupFacebook = "SetupFacebook",
    SetupPhoneNumbers = "SetupPhoneNumbers",
    SetupWebChat = "SetupWebChat",
    ManageChatBot = "ManageChatBot",
    TakeoverTicket = "TakeoverTicket"
}

export interface Permission {
    id: number,
    title: string,
    permissionKey: PermissionKey
}

export interface Dashboard {
    id: number,
    title: string
}

export interface UserInfo {
    id?: number;
    firstName?: string;
    lastName?: string;
    picture?: string;
    companyName?: string;
    companyId?: number;
    dashboards?: Array<Dashboard>;
    permissions: Array<Permission>;
}

let _userInfo: UserInfo;

export const hasPermission = (permissionKey: PermissionKey) => {
    if (!_userInfo) 
    return false;

    return _userInfo.permissions.findIndex(p => p.permissionKey === permissionKey) > -1;
};

export const hasPermissions = (permissions: PermissionKey[]) => {
    const userPermissions = _userInfo?.permissions?.map(e => e.permissionKey);
    if (!userPermissions) return false;

    return permissions.some(e => userPermissions.includes(e));;
};

export const setUserInfo = (userinfo: UserInfo) => _userInfo = userinfo;
export const getUserInfo = () => _userInfo;