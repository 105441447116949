import * as React from 'react';
import { Notification } from '../../models'
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import { Toolbar, Typography } from '@mui/material';
import LogoTitle from './LogoTitle';
import Settings from './Settings';
import Notifications from './Notifications'
import LangMenu from '../common/LangMenu';
import RouterLink from '../common/RouterLink';

const drawerWidth = 264;

const StyledAppBar = styled(AppBar)<{ isMobile: boolean, isDrawerOpen: boolean }>(({ theme, isMobile, isDrawerOpen }) => ({
    zIndex: isMobile ? 1200 : 1201,
    transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    height: isMobile ? '56px' : '64px',
    ...(isDrawerOpen && {
        marginLeft: isMobile ? 0 : drawerWidth,
        width: isMobile ? '100%' : `calc(100% - ${drawerWidth}px)`,
        transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
    }),
}))

const ProfileImg = styled('img')({
    width: '36px',
    height: '36px',
    objectFit: 'cover',
    borderRadius: '50%'
})

interface Props {
    drawerOpen: boolean,
    mobileMode: boolean,
    title: string,
    picture: string,
    fullName: string,
    onOpenDrawer: () => void,
    onCloseDrawer: () => void,
    onGotoMenu: (menu: string) => void,
    onLogout: () => void,
    notifications: Notification[],
    toggleNotificationRead: (id: number) => void,
    handleNotificationRedirect?: (notification: Notification) => void,
}

const MenuBar = (props: Props) => {

    const { drawerOpen, title, fullName, picture, mobileMode } = props;

    return (
        <StyledAppBar position="absolute" isMobile={mobileMode} isDrawerOpen={drawerOpen}>
            <Toolbar disableGutters={!mobileMode && !drawerOpen} sx={{ p: 0 }}>

                {!drawerOpen && <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={props.onOpenDrawer}
                    sx={{ ml: 1.5, mr: 1.5 }}>
                    <MenuIcon />
                </IconButton>}

                <LogoTitle title={title} mobileMode={mobileMode} />

                {!mobileMode && picture && <ProfileImg src={picture} />}

                {!mobileMode &&
                    <RouterLink to='/profile'>
                        <Typography color="inherit" noWrap sx={{ p: '12px' }}>
                            {fullName}
                        </Typography>
                    </RouterLink>}

                <Notifications
                    notifications={props.notifications}
                    toggleNotificationRead={props.toggleNotificationRead}
                    handleNotificationRedirect={props.handleNotificationRedirect}
                />

                <LangMenu />

                <Settings
                    picture={picture}
                    fullName={fullName}
                    mobileMode={mobileMode}
                    onLogout={() => props.onLogout()}
                    onGotoMenu={m => props.onGotoMenu(m)}
                />
            </Toolbar>
        </StyledAppBar >
    );
}

export default MenuBar;
