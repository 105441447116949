import React, { useEffect, useRef, useState } from "react";
import FlipMove from 'react-flip-move';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { Theme } from '@mui/material/styles';
import { isMobileMode } from '../../helpers/utils';
import { Button, FormLabel, Paper } from '@mui/material';
import { useTranslation } from "react-i18next";
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()(() => ({
    fileUploader: {
        maxWidth: 500,
        border: 'none',
    },
    fileContainer: {
        width: 240,
        height: 160,
        background: '#fff',
        position: 'relative',
        borderRadius: '10px',
        padding: '20px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginLeft: 12,
        transition: 'all 0.3s ease-in'
    },
    fileContainerInput: {
        opacity: 0,
        position: 'absolute',
        zIndex: -1
    },
    fileContainerP: {
        fontSize: 12,
        margin: '8px 0 4px',
        color: 'rgba(0,0,0,0.6)'
    },
    errorsContainer: {
        maxWidth: '300px',
        fontSize: '12px',
        color: 'red',
        textAlign: 'left'
    },
    errorMessage: {
        fontWeight: 500,
    },
    chooseFileButton: {
        padding: '6px 23px',
        borderRadius: '30px',
        marginTop: 6,
    },
    uploadIcon: {
        width: '50px',
        height: '50px'
    },
    uploadPicturesWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    uploadPictureContainer: {
        width: isMobileMode() ? 120 : 180,
        height: isMobileMode() ? 120 : 180,
        padding: 10,
        //background: '#edf2f6',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    uploadPicture: {
        width: isMobileMode() ? 100 : 160,
        height: isMobileMode() ? 100 : 160,
        objectFit: 'cover',
        borderRadius: '50%'
    },
    deleteImage: {
        position: 'absolute',
        top: isMobileMode() ? -8 : -12,
        right: isMobileMode() ? -8 : -12,
        color: '#fff',
        background: 'rgba(0,0,0,0.6)',
        borderRadius: '50%',
        cursor: 'pointer',
        width: 24,
        height: 24
    },
}));


interface Props {
    file: File,
    onChange: (file: File) => void,
    defaultImage?: string,
    label?: string,
}

const ERROR = {
    NOT_SUPPORTED_EXTENSION: 'NOT_SUPPORTED_EXTENSION',
    FILESIZE_TOO_LARGE: 'FILESIZE_TOO_LARGE'
}

interface Error {
    name?: string,
    type?: string
}

const ImageUpload = (props: Props) => {
    const { t } = useTranslation();

    const inputRef = useRef<HTMLInputElement>();

    const [picture, setPicture] = useState(props.defaultImage);
    const [fileErrors, setFileErrors] = useState<Error[]>([]);
    ;
    const maxFileSize = 512000;

    useEffect(() => {
        setPicture(props.defaultImage);
    }, [props.defaultImage])

    /*
       Check file extension (onDropFile)
       */
    const hasExtension = (fileName) => {
        const extensions = ['.jpg', '.jpeg', '.gif', '.png'];
        const pattern = '(' + extensions.join('|').replace(/\./g, '\\.') + ')$';
        return new RegExp(pattern, 'i').test(fileName);
    }

    /*
     Handle file validation
     */
    const onDropFile = async (e) => {
        let file = e.target.files[0];
        const fileErrors = [];

        // Iterate over all uploaded files
        let fileError = {
            name: file.name,
        };

        if (!hasExtension(file.name)) {
            fileError = Object.assign(fileError, {
                type: ERROR.NOT_SUPPORTED_EXTENSION
            });
            fileErrors.push(fileError);
        } else if (file.size > maxFileSize) {
            fileError = Object.assign(fileError, {
                type: ERROR.FILESIZE_TOO_LARGE
            });
            fileErrors.push(fileError);
        }

        setFileErrors(fileErrors)

        if (fileErrors.length > 0) return;

        const newFileData: any = await readFile(file);
        const dataURL = newFileData.dataURL;
        file = newFileData.file;

        setPicture(dataURL);

        props.onChange(file);
    }

    const onUploadClick = (e) => {
        // Fixes https://github.com/JakeHartnell/react-images-upload/issues/55
        e.target.value = null;
    }

    /*
       Read a file and return a promise that when resolved gives the file itself and the data URL
     */
    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Read the image via FileReader API and save image result in state.
            reader.onload = function (e) {
                // Add the file name to the data URL
                let dataURL = e.target.result as string;
                dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
                resolve({ file, dataURL });
            };

            reader.readAsDataURL(file);
        });
    }

    /*
     Remove the image from state
     */
    const removeImage = () => {
        setPicture(props.defaultImage);

        props.onChange(null);
    }

    /*
     On button click, trigger input file to open
     */
    const triggerFileUpload = () => {
        if (inputRef.current) inputRef.current.click();
    }

    const clearPictures = () => {
        setPicture(props.defaultImage);
    }

    const { classes } = useStyles();

    const renderErrors = () => {
        return fileErrors.map((fileError, index) => {
            return (
                <div className={classes.errorMessage} key={index}>
                    * {fileError.type === ERROR.FILESIZE_TOO_LARGE ? t("fileTooBig") : t("fileNotSupported")}
                </div>
            );
        });
    };

    return (
        <Paper variant="outlined" square className={classes.fileUploader}>
            <FormLabel style={{ display: 'block', marginBottom: 8 }}>Profile Picture</FormLabel>
            <div style={{ display: 'flex' }}>
                {picture !== '' && <div className={classes.uploadPicturesWrapper}>
                    <FlipMove enterAnimation="fade" leaveAnimation="fade" style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        width: "100%"
                    }}>
                        <Paper variant="outlined" square className={classes.uploadPictureContainer}>
                            {props.file && <CloseIcon className={classes.deleteImage} onClick={removeImage} />}
                            <img src={picture} className={classes.uploadPicture} alt="preview" />
                        </Paper>
                    </FlipMove>
                </div>}
                <div className={classes.fileContainer}>
                    <UploadIcon style={{ fontSize: 50, color: '#00AEFF' }} />
                    <p className={classes.fileContainerP}>{props.label}</p>
                    <div className={classes.errorsContainer}>
                        {renderErrors()}
                    </div>
                    <Button variant="contained" color="primary" size="small" className={classes.chooseFileButton} onClick={triggerFileUpload}>
                        {t("choose")}
                    </Button>
                    <input
                        className={classes.fileContainerInput}
                        type="file"
                        ref={inputRef}
                        name="picture"
                        accept='image/png, image/jpeg, image/gif'
                        onChange={onDropFile}
                        onClick={onUploadClick}
                    />
                </div>
            </div>
        </Paper>
    )
}

export default ImageUpload;