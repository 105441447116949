import * as React from 'react';
import { InputLabel, Typography, Button } from "@mui/material";
import { WithTranslation, withTranslation } from "react-i18next";
import BottomButtonWrapper from './BottomButtonWrapper';
import { Box } from '@mui/system';

interface Props extends WithTranslation {
    monerisProfileId: string,
    monerisTestMode: boolean,
    currentCard: string,
    creditCardSubmitted: boolean,
    creditCardSetupFinished: boolean,
    onSubmitCreditCardRequest: () => void,
    onSubmitToken: (token: string) => void,
    onError: (message: string) => void
}

class CreditCard extends React.Component<Props, any> {
    monerisIframe: HTMLIFrameElement;

    getMonerisDomain = () => this.props.monerisTestMode ? "https://esqa.moneris.com" : "https://www3.moneris.com";

    doMonerisSubmit = () => {
        this.monerisIframe.contentWindow.postMessage(
            "tokenize",
            `${this.getMonerisDomain()}/HPPtoken/index.php`
        );
        this.props.onSubmitCreditCardRequest();
        return false;
    };

    componentDidMount() {
        window.addEventListener("message", this.handleResponse);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handleResponse);
    }

    handleResponse = e => {
        const { creditCardSubmitted, creditCardSetupFinished } = this.props;

        if (creditCardSubmitted && !creditCardSetupFinished) {
            if (e.origin === this.getMonerisDomain()) {
                let respData = JSON.parse(e.data);

                if (respData.responseCode[0] !== "001") {
                    this.props.onError(respData.errorMessage);
                    return;
                }

                this.props.onSubmitToken(respData.dataKey);
            }
        }
    };

    render() {
        const { t, monerisProfileId, currentCard } = this.props;

        const monerisUrl = `${this.getMonerisDomain()}/HPPtoken/index.php?id=${monerisProfileId}`;

        let css_body = "background:transparent;";
        let css_textbox =
            "border:1px solid rgba(0, 0, 0, 0.35);border-radius:4px;padding: 6px 2px 7px;font-size:16px;";
        let css_textbox_pan = "width:100%;margin-top:30px;";
        let css_textbox_exp = "width:25%; min-width: 50px;margin-top:40px;";
        let css_textbox_cvd = "width:25%; min-width: 50px;margin-top:40px;";

        const url = `${monerisUrl}&pmmsg=true&css_body=${css_body}&css_textbox=${css_textbox}&css_textbox_pan=${css_textbox_pan}&enable_exp=1&css_textbox_exp=${css_textbox_exp}&enable_cvd=1&css_textbox_cvd=${css_textbox_cvd}`;

        return (
            <Box sx={{ height: 'calc(100% - 40px)' }}>
                {currentCard !== "" && (
                    <div>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            {t("currentCreditCard")}
                        </Typography>
                        <Typography variant="h6">
                            {currentCard}
                        </Typography>
                    </div>
                )}

                <Box sx={{ position: "relative", mt: "8px", pb: "20px" }}>
                    <InputLabel sx={{ mt: "12px", mb: '48px' }} required>
                        {t("cardNumber")}
                    </InputLabel>
                    <InputLabel sx={{ mb: '54px' }} required>
                        {t("expDate")}
                    </InputLabel>
                    <InputLabel sx={{ mb: '36px' }} required>
                        {t("cvd")}
                    </InputLabel>
                    <iframe
                        ref={f => (this.monerisIframe = f)}
                        src={url}
                        frameBorder="0"
                        scrolling="no"
                        width="100%"
                        height="230px"
                        style={{ top: 0, left: 0, position: "absolute" }}
                        title="Credit card details"
                    />
                </Box>
                <BottomButtonWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.doMonerisSubmit()}
                    >
                        {currentCard === '' ? t("submitCreditCard") : t("update")}
                    </Button>
                </BottomButtonWrapper>
            </Box>
        );
    }
}

export default withTranslation()(CreditCard);