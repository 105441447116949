import ajax from '../../helpers/ajax';
import { Actions, actionTypes } from './types';
import { actionTypes as AddAutoRepliesActions } from '../AddAutoReplies/types'
import { showLoading, hideLoading, showAlert } from '../Layout/actionCreators';
import { alertTypes } from '../../components/common/Alert';
import { i18n } from "../../Localization/i18n"

export default {
    loadAutoReplies: () => async (dispatch, getState) => {
        dispatch(showLoading());

        const response = await ajax.get("AutoResponse");

        if (await ajax.errorOccured(response, "Messages load failed.", dispatch, getState))
            return;

        let autoReplies = await response.json();

        dispatch(hideLoading());
        dispatch({ type: actionTypes.LOADED, autoReplies });
    },
    updateReplyStatus: autoResponseId => async (dispatch, getState) => {
        dispatch(showLoading());

        const response = await ajax.post("AutoResponse/UpdateStatus", autoResponseId);

        if (await ajax.errorOccured(response, "Reply status update failed.", dispatch, getState)) return;

        const status = await response.json();

        dispatch(hideLoading());
        dispatch(showAlert(i18n.t("autoReplyStatusUpdatedSuccessfully"), alertTypes.success));
        dispatch({ type: actionTypes.UPDATED_REPLY_STATUS, autoResponseId, status });
    },
    deleteReply: autoResponseId => async (dispatch, getState) => {
        dispatch(showLoading());

        const response = await ajax.del(`AutoResponse/${autoResponseId}`, '');

        if (await ajax.errorOccured(response, "Failed to delete.", dispatch, getState)) return;

        dispatch(hideLoading());
        dispatch(showAlert(i18n.t("autoReplyDeleted"), alertTypes.success));
        dispatch({ type: actionTypes.REPLY_DELETED, autoResponseId });
    },
    editReply: (autoResponseId) => async (dispatch) => {
        dispatch({ type: AddAutoRepliesActions.SET_AUTOREPLY_ID, autoResponseId });
    },
} as Actions