import React, { useEffect, useState } from "react";
import ajax from '../../helpers/ajax'
import { Theme } from '@mui/material/styles';
import { Typography, Link } from '@mui/material'
import { isValidEmail, urlRegEx } from '../../helpers/utils'
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    card: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        borderLeftColor: theme.palette.primary.main,
        borderLeftStyle: 'solid',
        borderLeftWidth: 2,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5
    },
    head: {
        display: 'flex',
        alignItems: 'center',
    },
    media: {
        maxWidth: 80,
        maxHeight: 80,
        marginRight: theme.spacing(1)
    }
}));

interface PreviewResult {
    title: string,
    description: string,
    image: string,
    siteName: string,
    url: string
}

interface Props {
    text: string,
    inbound: boolean,
    onPreviewLoaded?: () => void
}

const LinkPreview = (props: Props) => {

    const [previewResult, setPreviewResult] = useState<PreviewResult>({
        title: '', description: '', image: '', siteName: '', url: ''
    });

    useEffect(() => {
        const urls = props.text.match(urlRegEx)?.filter(u => !isValidEmail(u));

        if (!urls || urls.length === 0) return;

        let url = urls[0];

        if (url.toLowerCase().indexOf('http://') !== 0 && url.toLowerCase().indexOf('https://') !== 0)
            url = 'https://' + url;

        ajax.post('webchat/getlinkpreview', url).then(async response => {

            if (response.ok) {
                const result = await response.json();

                setPreviewResult(result);
            }
        })
    }, [])

    useEffect(() => {
        if (props.onPreviewLoaded)
            props.onPreviewLoaded()
    }, [previewResult])

    const { title, description, image, siteName, url } = previewResult;

    const { classes } = useStyles();

    if (!title || !description)
        return null

    return <div className={classes.card}>
        <div className={classes.head}>
            {image &&
                <img
                    className={classes.media}
                    src={image}
                    alt={title}
                />
            }
            
            <div>
                <Typography gutterBottom variant="caption">{siteName}</Typography>
                <Link href={url} target="_blank">
                    <Typography gutterBottom variant="body1" color="primary">{title}</Typography>
                </Link>
            </div>
        </div>
        <Typography variant="body2">{description}</Typography>
    </div>
}

export default LinkPreview;