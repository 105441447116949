import { alertTypes } from '../../components/common/Alert';
import { State, actionTypes, initialState, ReducerAction } from './types'
import { produce } from 'immer'

const reducer = (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.LOADING:
                newState.loading = true
                break
            case actionTypes.SET_OPEN_DRAWER:
                newState.drawerOpen = action.open
                break
            case actionTypes.TOGGLE_DASHBOARD_MENU:
                newState.dashboardsMenuOpen = !state.dashboardsMenuOpen
                break
            case actionTypes.HIDE_LOADING:
                newState.loading = false
                break
            case actionTypes.SHOW_ALERT:
                newState.alert = {
                    open: true,
                    type: action.alertType || alertTypes.error,
                    message: action.message
                }
                break
            case actionTypes.CLOSE_ALERT:
                newState.alert.open = false
                break
            case actionTypes.TOGGLE_NOTIFICATION_READ:
                let index = newState.notifications.findIndex(n => n.id == action.id)
                newState.notifications[index].read = !newState.notifications[index].read
                break
            case actionTypes.NOTIFICATIONS_LOADED:
                newState.notifications = action.notifications
                newState.hubConnection = action.hubConnection
                break
            case actionTypes.NOTIFICATION_RECEIVED:
                newState.notifications.unshift(action.newNotification)
                break
        }
    })
}

export default reducer