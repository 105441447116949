import { ActionCreatorsMapObject } from 'redux';
import { ProductPlan, PlanSubsciption, Discount, Tax, Invoice } from '../../models';
import {actionTypes as layoutActionTypes} from '../Layout/types'

export enum PageMode {
    SelectPlan = 0,
    PaymentInfo = 1,
    PayResult = 2,
    Accounts = 3
}

export enum actionTypes {
    CHANGE_PROMO_CODE = 'SUBSCRIPTION_CHANGE_PROMO_CODE',
    GO_TO_PAYMENT = 'SUBSCRIPTION_GO_TO_PAYMENT',
    PAGE_LOADED = 'SUBSCRIPTION_PAGE_LOADED',
    PROMO_CODE_ADDED = 'SUBSCRIPTION_PROMO_CODE_ADDED',
    SELECT_PLAN = 'SUBSCRIPTION_SELECT_PLAN',
    CANCEL_PLAN = 'SUBSCRIPTION_CANCEL_PLAN',
    REACTIVATE_PLAN = 'SUBSCRIPTION_REACTIVATE_PLAN',
    SET_CREDIT_CARD_SUBMITTED = 'SUBSCRIPTION_SET_CREDIT_CARD_SUBMITTED',
    SET_CURRENT_CREDIT_CARD = 'SUBSCRIPTION_SET_CURRENT_CREDIT_CARD',
    SHOW_SELECT_PLAN = 'SUBSCRIPTION_SHOW_SELECT_PLAN',
    SHOW_PAY_RESULT = 'SUBSCRIPTION_SHOW_PAY_RESULT',
}

export interface State {
    appliedDiscounts: Discount[],
    creditCardSetupFinished: boolean,
    creditCardSubmitted: boolean,
    currentCard: string,
    prevPlan: PlanSubsciption,
    currentPlan: PlanSubsciption,
    cancellable: boolean,
    nextBillingDate: Date,
    monerisProfileId: string,
    monerisTestMode: boolean,
    pageMode: PageMode,
    payResultInvoice: Invoice,
    productPlans: ProductPlan[],
    promoCode: string,
    showProgress: boolean,
    taxes: Tax[],
}

export interface ReducerAction {
    type: actionTypes | layoutActionTypes,
    prevPlan?: PlanSubsciption,
    currentPlan?: PlanSubsciption,
    resetCreditCard?: boolean,
    cancellable?: boolean,
    nextBillingDate?: Date,
    plans?: ProductPlan[],
    appliedDiscounts?: Discount[],
    pageMode?: PageMode,
    showProgress?: boolean,
    monerisProfileId?: string,
    monerisTestMode?: boolean,
    promoCode?: string,
    taxes?: Tax[],
    currentCard?: string,
    invoice?: Invoice,
    newValue?: boolean,
    planId?: number,
    discount?: Discount,
}

export type Dispatch = (action: ReducerAction) => void

export interface Actions extends ActionCreatorsMapObject {
    changePromoCode: (promoCode: string) => ReducerAction,
    creditCardSaved: (tokan: string) => (dispatch: Dispatch, getState: any) => void,
    loadPage: () => (dispatch: Dispatch, getState: any) => void,
    goToAccounts: () => any,
    goToPayment: () => ReducerAction,
    pay: () => (dispatch: Dispatch, getState: any) => void,
    selectPlan: (plan: ProductPlan) => (dispatch: Dispatch, getState: any) => void,
    cancelPlan: (planId: number, message: string) => (dispatch: Dispatch, getState: any) => void,
    reactivatePlan: (planId: number) => (dispatch: Dispatch, getState: any) => void,
    setCreditCardSubmitted: () => (dispatch: Dispatch) => void,
    showError: (message: string) => (dispatch: Dispatch) => void,
    showPlans: () => ReducerAction,
    submitPromoCode: () => (dispatch: Dispatch, getState: any) => void,
}

export const initialState: State = {
    appliedDiscounts: [],
    creditCardSetupFinished: false,
    creditCardSubmitted: false,
    currentCard: '',
    prevPlan: null,
    currentPlan: null,
    cancellable: false,
    nextBillingDate: null,
    monerisProfileId: '',
    monerisTestMode: true,
    pageMode: PageMode.SelectPlan,
    payResultInvoice: null,
    productPlans: [],
    promoCode: '',
    showProgress: false,
    taxes: [],
}