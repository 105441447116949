import { alertTypes } from '../../components/common/Alert'
import { Place, CompanyType } from '../../models'
import { ActionCreatorsMapObject } from 'redux'

export enum actionTypes {
    CHANGE_FIELD = 'SIGNUP_CHANGE_FIELD',
    SUBMIT = 'SIGNUP_SUBMIT',
    SUBMITTED = 'SIGNUP_SUBMITED',
    SHOW_LOADING = 'SIGNUP_SHOW_LOADING',
    FAILED = 'SIGNUP_FAILED',
    CLOSE_ALERT = 'SIGNUP_CLOSE_ALERT',
    CHANGE_ADDRESS = 'SIGNUP_CHANGE_ADDRESS',
    COMPANY_TYPES_LOADED = 'SIGNUP_COMPANY_TYPES_LOADED',
    CHANGE_ADDRESS_FIELD = 'SIGNUP_CHANGE_ADDRESS_FIELD'
}

export interface State {
    loading: boolean,
    isSubmitted: boolean,
    done: boolean,
    message: string,
    alertType: alertTypes,
    firstName: string,
    lastName: string,
    phone: string,
    emailAddress: string,
    companyName: string,
    companyType: string,
    address: Place,
    companyTypes: CompanyType[],
    reCaptcha: string,
    legalTermAccepted: boolean,
    unitNumber: string
}

export interface Actions extends ActionCreatorsMapObject {
    changeField: (name: any, value: any) => ReducerAction,
    changeAddressField: (name: any, value: any) => ReducerAction,
    loadForm: () => (dispatch: Dispatch, getState: any) => void,
    submit: () => (dispatch: Dispatch, getState: any) => void,
    onAlertClose: () => ReducerAction,
    changeAddress: (address: Place) => ReducerAction
}

export const initialState: State = {
    loading: false,
    isSubmitted: false,
    done: false,
    message: '',
    alertType: alertTypes.success,
    emailAddress: '',
    firstName: '',
    lastName: '',
    phone: '',
    companyName: '',
    companyType: '',
    address: null,
    companyTypes: [],
    reCaptcha: '',
    legalTermAccepted: false,
    unitNumber: ''
}

export interface ReducerAction {
    type: actionTypes,
    message?: string,
    companyTypes?: CompanyType[],
    address?: Place,
    name?: string,
    value?: string
}

export type Dispatch = (action: ReducerAction) => void