import * as React from 'react';
import RouterLink from '../common/RouterLink';
import { useTranslation } from "react-i18next";
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        maxHeight: 36,
    },
    logo: {
        marginRight: theme.spacing(1.5),
        marginLeft: theme.spacing(1.5),
        width: 32,
    },
    titleLink: {
        display: 'flex'
    }
}));

interface Props {
    title: string,
    mobileMode: boolean
}

const LogoTitle = (props: Props) => {
    const { t } = useTranslation();

    const { classes } = useStyles();

    const { mobileMode, title } = props;

    let icon = <img alt="logo" src="/logo_white.png" className={classes.logo} />,
        text = '';

    if (mobileMode) {
        icon = null;
        text = title;
    } else
        text = `${t("projectTitle")} ${(title ? ` - ${title}` : '')}`;

    return <div className={classes.root}>
        <RouterLink to="/" className={classes.titleLink}>
            {icon}
            <Typography variant="h6" color="white" noWrap>
                {text}
            </Typography>
        </RouterLink>
    </div>;
}

export default LogoTitle;
