import { ActionCreatorsMapObject } from 'redux'
import * as yup from 'yup'
import { TemplateContent, RawNodeDatum, JsonField } from '../../models';
import { ReducerAction as layoutReducerAction } from '../Layout/types'

export enum actionTypes {
    CHANGE_FIELD = "TEMPLATECONTENT_CHANGE_FIELD",
    CHANGE_CONTENT_FIELD = "TEMPLATECONTENT_CHANGE_CONTENT_FIELD",
    ADD_CONTENT = 'TEMPLATECONTENT_ADD_CONTENT',
    UPDATE_CONTENT = 'TEMPLATECONTENT_UPDATE_CONTENT',
    DELETE_CONTENT = 'TEMPLATECONTENT_DELETE_CONTENT',

    CHANGE_JSON_FIELD = "TEMPLATECONTENT_CHANGE_JSON_FIELD",
    ADD_FIELD = 'TEMPLATECONTENT_ADD_FIELD',
    DELETE_FIELD = 'TEMPLATECONTENT_DELETE_FIELD',
};

export function write<K extends keyof State>(arg: State, key: K, value: State[K]): void {
    arg[key] = value;
};

export function writeContent<K extends keyof TemplateContent>(arg: TemplateContent, key: K, value: TemplateContent[K]): void {
    arg[key] = value;
};

export function writeJson<K extends keyof JsonField>(arg: JsonField, key: K, value: JsonField[K]): void {
    arg[key] = value;
};

export interface State {
    errors: any,
    templateId: number,
    name: string,
    content: TemplateContent,
    contents: TemplateContent[],
    contentsTree: RawNodeDatum
}

export const contentSchema: yup.SchemaOf<TemplateContent> = yup.object().shape({
    title: yup.string().required("fieldRequired"),
    message: yup.string().required("fieldRequired"),
}).required();

export interface ReducerAction {
    type: any,
    name?: keyof State,
    contentName?: keyof TemplateContent,
    fieldName?: keyof JsonField,
    value?: any
    contentId?: number,
    content?: TemplateContent,
    infoFieldId?: number,
}

export interface Actions extends ActionCreatorsMapObject {
    changeField: (name: keyof State, value: any) => ReducerAction,
    getContent: (templateId: number) => (dispatch: Dispatch, getState: any) => void,
    validate: () => (dispatch: Dispatch, getState: any) => void,
    changeContentField: (contentName: keyof TemplateContent, value: any) => ReducerAction,
    submit: () => (dispatch: Dispatch, getState: any) => object,
    deleteContent: (templateContentId: number) => (dispatch: Dispatch, getState: any) => void,
    changeJsonField: (infoFieldId: number, fieldName: keyof JsonField, value: any) => ReducerAction,
    addField: () => ReducerAction,
    deleteField: (infoFieldId: number) => ReducerAction,
}

export const initialState: State = {
    errors: {},
    templateId: 0,
    name: '',
    content: null,
    contents: [],
    contentsTree: null,
};

export type Dispatch = (action: ReducerAction | layoutReducerAction) => void