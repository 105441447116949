import Autocomplete from 'react-google-autocomplete';
import { TextFieldProps, TextField } from "@mui/material";
import * as React from 'react';
import { Place } from '../../models';
import { useTranslation } from 'react-i18next';

const GoolgeAddress = props => {
    const { inputRef, ...other } = props;

    return (
        <Autocomplete
            {...other}
            ref={ref => inputRef(ref ? ref : null)}
            options={{
                types: ['geocode']
            }}
        />
    );
}

interface AddressComponent {
    long_name: string,
    short_name: string,
    types: string[]
}

export interface GooglePlaceProps {
    onPlaceSelected: (place: Place) => void
}

const GooglePlace = (props: GooglePlaceProps & TextFieldProps) => {

    const { t } = useTranslation();

    const handleSelect = place => {
        const comps: AddressComponent[] = place.address_components;

        if (!comps)
            return;

        let result: Place = {
            streetNumber: '',
            route: '',
            city: '',
            province: '',
            country: '',
            postalCode: '',
            fullAddress: place.formatted_address
        };

        comps.forEach(comp => {
            if (comp.types.indexOf('street_number') > -1) {
                result.streetNumber = comp.long_name;
                return;
            }
            if (comp.types.indexOf('route') > -1) {
                result.route = comp.long_name;
                return;
            }
            if (comp.types.indexOf('locality') > -1) {
                result.city = comp.long_name;
                return;
            }
            if (comp.types.indexOf('administrative_area_level_1') > -1) {
                result.province = comp.long_name;
                return;
            }
            if (comp.types.indexOf('country') > -1) {
                result.country = comp.long_name;
                return;
            }
            if (comp.types.indexOf('postal_code') > -1) {
                result.postalCode = comp.long_name;
                return;
            }
        });

        props.onPlaceSelected(result);
    }

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.charCode === 13) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }

        return true;
    }

    return (

        <TextField fullWidth
            {...props}
            onKeyPress={handleKeyPress}
            InputProps={{
                inputComponent: GoolgeAddress,
                inputProps: {
                    onPlaceSelected: place => handleSelect(place),
                    placeholder: t("enterLocation")
                }
            }}
        />
    );
};

export default GooglePlace;
