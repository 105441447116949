import * as React from "react";
import { Theme } from '@mui/material/styles';
import { Typography, Button, Grid, Divider, TextField } from "@mui/material";
import { ProductPlan, Tax, Discount, PlanSubscriptionStatus, PlanSubsciption } from "../../../../models";
import { showMoney } from "../../../../helpers/utils";
import { getLongDate, addDays } from "../../../../helpers/date";
import BottomButtonWrapper from '../BottomButtonWrapper';
import ReceiptItem from './ReceiptItem';
import { useTranslation } from "react-i18next";
import { makeStyles } from '../../../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    promo: {
        margin: 0,
        marginRight: 10
    },
}));

interface Props {
    appliedDiscounts: Discount[],
    productPlans: ProductPlan[],
    promoCode: string,
    showPayButton: boolean,
    nextBillingDate: Date,
    planStatus: PlanSubscriptionStatus,
    taxes: Tax[],
    prevPlan: PlanSubsciption,
    onPromoCodeChange: (promoCode) => void,
    onSubmitPromoCode: () => void,
    onPay: () => void,
}

const Receipt = (props: Props) => {
    const { t } = useTranslation();

    const { classes } = useStyles();

    const { productPlans, appliedDiscounts, promoCode, showPayButton, taxes, nextBillingDate, planStatus, prevPlan } = props,
        currentProductPlan = productPlans.find(p => p.selected),
        prevProductPlan = prevPlan ? productPlans.find(p => p.id === prevPlan.productPlanId) : null;

    if (!currentProductPlan)
        return null

    let items: { item: string, amount: string }[] = [],
        total = 0,
        price = prevProductPlan ? currentProductPlan.price - prevProductPlan.price : currentProductPlan.price;

    items.push({
        item: `${currentProductPlan.title} Plan${prevProductPlan !== null
            ? (prevProductPlan?.price <= currentProductPlan?.price ? ' Upgrade' : ' Downgrade')
            : ''}`,
        amount: showMoney(price)
    });
    total += price;

    appliedDiscounts.forEach(d => {
        items.push({
            item: d.isPercentage ? `${d.name} ${d.amount.toFixed(0)}%` : d.name,
            amount: `- ${d.isPercentage ? showMoney(d.amount / 100 * price) : showMoney(d.amount)}`
        });
        total -= d.isPercentage ? (price * d.amount / 100) : d.amount;

        if (total < 0)
            total = 0
    });

    let totalTaxAmount = 0;

    taxes.forEach(t => {
        const taxAmount = t.taxRate * total / 100;
        items.push({
            item: `${t.name} ${t.taxRate}%`,
            amount: showMoney(taxAmount)
        });
        totalTaxAmount += taxAmount;
    });
    total += totalTaxAmount;

    return (
        <>
            <Grid container>
                {items.map((item, i) => <ReceiptItem key={i} name={item.item} amount={item.amount} />)}
            </Grid>

            <Divider variant="fullWidth" className={classes.divider} />
            <Grid container>
                <ReceiptItem name={t("total")} amount={showMoney(total, 'USD')} />
            </Grid>
            <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginTop: 20, marginBottom: 20 }}
            >
                {t("promoCodeText")}
            </Typography>

            <Grid container>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        label={t("promoCode")}
                        value={promoCode}
                        variant="outlined"
                        size="small"
                        className={classes.promo}
                        onChange={e => props.onPromoCodeChange(e.target.value)}
                    />
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'right' }}>
                    <Button color="primary"
                        variant="contained"
                        onClick={() => props.onSubmitPromoCode()}
                    >
                        {t("submit")}
                    </Button>
                </Grid>
            </Grid>

            {nextBillingDate !== null && planStatus !== PlanSubscriptionStatus.Pending &&
                <Grid container style={{ marginTop: 24 }}>
                    {planStatus !== PlanSubscriptionStatus.Cancelled ?
                        <>
                            <Typography variant="body1">
                                <b>{t("nextInvoiceDate")}:</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                style={{ marginLeft: 8 }}
                            >
                                {getLongDate(t, nextBillingDate)}
                            </Typography>
                        </>
                        :
                        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: t("serviceEndDate", { x: getLongDate(t, addDays(nextBillingDate, -1)) }) }} />
                    }
                </Grid>
            }

            {showPayButton &&
                <BottomButtonWrapper>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => props.onPay()}
                    >
                        {t("finishPayment")}
                    </Button>
                </BottomButtonWrapper>
            }
        </>
    );
}

export default Receipt;