import { CompanyType, Company } from '../../models'
import { ActionCreatorsMapObject } from 'redux'
import { ReducerAction as LayoutReducerAction } from '../Layout/types'
import * as yup from 'yup'

export enum actionTypes {
    CHANGE_FIELD = 'COMPANY_PROFILE_CHANGE_FIELD',
    CHANGE_COMPANY_FIELD = 'COMPANY_PROFILE_CHANGE_COMPANY_FIELD'
}

export const schema: yup.SchemaOf<Company> = yup.object().shape({
    businessName: yup.string().required("fieldRequired"),
    emailAddress: yup.string().required("fieldRequired"),
    phone1: yup.string().required("fieldRequired"),
    companyTypeId: yup.number().min(1, "fieldRequired"),
}).required();

export interface State {
    company: Company,
    companyTypes: CompanyType[],
    errors: any
}

export function write<K extends keyof State>(arg: State, key: K, value: State[K]): void {
    arg[key] = value;
};

export function writeCompany<K extends keyof Company>(arg: Company, key: K, value: Company[K]): void {
    arg[key] = value;
};

export interface Actions extends ActionCreatorsMapObject {
    changeField: (name: any, value: any) => ReducerAction,
    changeCompanyField: (name: any, value: any) => ReducerAction,
    loadCompany: () => (dispatch: Dispatch, getState: any) => void,
    validate: () => (dispatch: Dispatch, getState: any) => void,
    submit: () => (dispatch: Dispatch, getState: any) => Promise<boolean>,
}

export const initialState: State = {
    errors: {},
    companyTypes: [],
    company: {
        id: 0,
        businessName: '',
        emailAddress: '',
        phone1: '',
        companyTypeId: 0,
        address: '',
        postalCode: '',
    }
}

export interface ReducerAction {
    type: actionTypes,
    name?: keyof State,
    value?: any,
    companyName?: keyof Company,
}

export type Dispatch = (action: ReducerAction | LayoutReducerAction) => void