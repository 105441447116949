import React, { useState } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { getStoredLang, storeLang } from '../../Localization/i18n';
import { useTranslation } from 'react-i18next';

interface Props { }

const LangSelect = (props: Props) => {
    const initialLang = getStoredLang();
    const [lang, setLang] = useState(initialLang);

    const { t, i18n } = useTranslation();

    const onChange = async (e) => {
        const lang = e.target.value;

        storeLang(lang);
        setLang(lang);
        await i18n.changeLanguage(lang);
    }

    return (
        <FormControl variant="outlined" sx={{ display: 'block', mb: '8px' }}>
            <Select value={lang} onChange={onChange} sx={{ backgroundColor: '#FFF' }} size="small">
                <MenuItem value={'en'}>{t('lang_English')}</MenuItem>
                <MenuItem value={'fr'}>{t('lang_French')}</MenuItem>
            </Select>
        </FormControl>
    );
};
export default LangSelect;