import React, { useEffect, useState } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Paper, Button, FormLabel, TextField, Theme, Box, Grid } from "@mui/material";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ArrowBack as BackIcon } from "@mui/icons-material";
import Layout from "../Layout/Layout";
import { State, Actions } from '../../store/BuyBandwidthNumber/types';
import actionCreators from '../../store/BuyBandwidthNumber/actionCreators';
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../helpers/utils";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
    paper: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2)
        }
    },
    numberButton: {
        margin: theme.spacing(1),
        borderWidth: "2px"
    },
    disabled: {
        backgroundColor: "#00AEFF",
        color: "#FFFFFF !important",
        borderWidth: "0 !important",
        fontSize: "15px"
    },
}));

interface Props { }

const BuyBandwidthNumber = (props: State & Actions & Props) => {
    const { t } = useTranslation();

    const [areaCode, setAreaCode] = useState("");
    const [selectedNumber, setSelectedNumber] = useState("");
    const [displayName, setDisplayName] = useState("");

    const loadNumbers = async (areaCode) => {
        console.log("Loading numbers ..");
        setSelectedNumber("");
        await props.loadNumbers(areaCode);
    }

    useEffect(() => {
        setPageTitle(t, "buyNewNumber");

        props.getSubscriptionStatus();
        loadNumbers(areaCode)
    }, [])

    const handleArea = async (event) => {
        const areaCode = event.target.value;
        const onlyNums = areaCode.replace(/[^0-9]/g, '');
        if (onlyNums.length > 2) return;

        setAreaCode(onlyNums);
        await props.loadNumbers(onlyNums);

    }

    const handleDisplayName = async (event) => {
        setDisplayName(event.target.value);
    }

    const handleSubmit = async () => {
        await props.handleSubmit(selectedNumber, displayName);
        setSelectedNumber("");
        setDisplayName("");
        props.loadNumbers(areaCode);
    }

    const { classes } = useStyles();

    const { isLoaded, hasActiveSubscription, numbers } = props;

    return (
        <Layout title={t("buyNewNumber")}>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={props.goBack}
                        >
                            <BackIcon />
                            {t("back")}
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            name="areaCode"
                            label={t("search")}
                            helperText='First two numbers eg. "84" or "86"'
                            value={areaCode}
                            onChange={handleArea}
                            variant="outlined"
                            size="small"
                            type="number"
                        />
                    </Grid>

                    <Grid item xs={12} sx={{ minHeight: 250 }}>
                        <FormLabel component="legend" sx={{ fontSize: "1.5rem", fontWeight: 600 }}>{t("availablePhones")}</FormLabel>

                        {numbers?.length > 0
                            ? <Scrollbars style={{ maxWidth: 540, height: 485 }}>
                                {numbers?.map(num => (
                                    <Button
                                        variant="outlined"
                                        id={String(num)}
                                        classes={{ root: classes.numberButton, disabled: classes.disabled }}
                                        onClick={() => setSelectedNumber(String(num))}
                                        disabled={selectedNumber === String(num)}
                                    >
                                        {num}
                                    </Button>
                                ))}
                            </Scrollbars>
                            : <>
                                {isLoaded && <Paper elevation={3} sx={{ p: 2, maxWidth: 540 }}>{t("noAvailableSearchNumbers")}</Paper>}
                            </>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                            <TextField
                                name="displayName"
                                label={t("displayName")}
                                onChange={handleDisplayName}
                                value={displayName}
                                disabled={!isLoaded || !selectedNumber || !hasActiveSubscription}
                                variant="outlined"
                                size="small"
                                type="text"
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                id="buy"
                                sx={{ ml: 2 }}
                                type="submit"
                                disabled={!isLoaded || !selectedNumber || !hasActiveSubscription}
                                onClick={handleSubmit}
                            >
                                {t("add")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Layout>
    );
}

const buyBandwidthNumberWithStore = connect(
    (state: any) => state.buyBandwidthNumber,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(BuyBandwidthNumber);

export default buyBandwidthNumberWithStore;