import ajax from '../../helpers/ajax';
import { actionTypes, Actions, PageMode, State } from './types';
import { showAlert, showLoading, hideLoading } from '../Layout/actionCreators';
import { products, Discount, Invoice } from '../../models';
import { push } from 'connected-react-router';
import { alertTypes } from '../../components/common/Alert';
import { i18n } from "../../Localization/i18n"

export default {
    loadPage: () => async (dispatch, getState) => {
        dispatch(showLoading());

        const response = await ajax.get(`Payment/GetUserSubscriptionInfo/${products.chatbot}`);

        if (await ajax.errorOccured(response, "Load page failed.", dispatch, getState))
            return;

        const result = await response.json();

        dispatch(hideLoading());
        dispatch({
            type: actionTypes.PAGE_LOADED,
            plans: result.plans,
            prevPlan: result.prevPlan,
            currentPlan: result.currentPlan,
            cancellable: result.cancellable,
            nextBillingDate: result?.nextBillingDate,
            appliedDiscounts: result.appliedDiscounts,
            showProgress: result.currentPlan === null,
            pageMode: result.currentPlan === null ? PageMode.SelectPlan : PageMode.PaymentInfo,
            monerisProfileId: result.monerisProfile.profileId,
            monerisTestMode: result.monerisProfile.testMode,
            currentCard: result.currentCard,
            taxes: result.taxes
        });
    },
    selectPlan: (plan) => async (dispatch, getState) => {
        if (!plan)
            return;

        dispatch(showLoading());

        const response = await ajax.post(`Payment/SelectPlan`, plan.id);

        if (await ajax.errorOccured(response, "Select plans failed.", dispatch, getState))
            return;
        
        const {currentPlan, cancellable, prevPlan} = await response.json();

        dispatch(hideLoading());
        dispatch({
            type: actionTypes.SELECT_PLAN,
            planId: plan.id,
            pageMode: PageMode.PaymentInfo,
            currentPlan,
            cancellable,
            prevPlan
        });
    },
    cancelPlan: (planId, comment) => async (dispatch, getState) => {

        dispatch(showLoading());

        const response = await ajax.post(`Payment/CancelPlan/${planId}`, comment);

        if (await ajax.errorOccured(response, "Plan cancellation failed..", dispatch, getState))
            return;

        const { currentPlan, prevPlan, resetCreditCard } = await response.json();

        console.log({currentPlan})

        dispatch(hideLoading());
        dispatch({ type: actionTypes.CANCEL_PLAN, currentPlan, prevPlan, resetCreditCard });
    },
    reactivatePlan: (planId) => async (dispatch, getState) => {

        dispatch(showLoading());

        const response = await ajax.post(`Payment/ReactivatePlan`, planId);

        if (await ajax.errorOccured(response, "Plan reactivation failed.", dispatch, getState))
            return;

        dispatch(hideLoading());
        dispatch({ type: actionTypes.REACTIVATE_PLAN });
    },
    submitPromoCode: () => async (dispatch, getState) => {
        const state: State = getState().subscription,
            { promoCode } = state;

        if (!promoCode)
            return;

        dispatch(showLoading());
        const response = await ajax.post('Payment/SubmitPromoCode', promoCode);

        if (await ajax.errorOccured(response, "Submit promotion code failed.", dispatch, getState))
            return;

        const result: { discount: Discount, success: boolean, message: string } = await response.json();

        dispatch(hideLoading());

        if (result.success) {
            dispatch(showAlert(i18n.t("promoCodeAddedSuccessfully"), alertTypes.success));
            dispatch({ type: actionTypes.PROMO_CODE_ADDED, discount: result.discount });
        }
        else
            dispatch(showAlert(result.message, alertTypes.warning));        
    },
    showError: (message) => dispatch => {
        dispatch(hideLoading());
        dispatch(showAlert(message, alertTypes.error));
    },
    setCreditCardSubmitted: () => dispatch => {
        dispatch(showLoading());
        dispatch({ type: actionTypes.SET_CREDIT_CARD_SUBMITTED, newValue: true });
    },
    creditCardSaved: (token: string) => async (dispatch, getState) => {
        const response = await ajax.post('Payment/AddToken', token)

        dispatch(hideLoading())

        if (await ajax.errorOccured(response, "Submit credit card failed.", dispatch, getState)) {
            dispatch({ type: actionTypes.SET_CREDIT_CARD_SUBMITTED, newValue: false })
            return
        }

        const currentCard = await response.json()

        dispatch({ type: actionTypes.SET_CURRENT_CREDIT_CARD, currentCard })
    },
    pay: () => async (dispatch, getState) => {
        dispatch(showLoading());

        const response = await ajax.post(`Payment/Pay`, {});

        if (await ajax.errorOccured(response, "Pay failed.", dispatch, getState))
            return;

        const invoice: Invoice = await response.json();

        dispatch(hideLoading());
        dispatch({ type: actionTypes.SHOW_PAY_RESULT, invoice });
    },
    goToAccounts: () => push('ChatAccounts'),
    goToPayment: () => ({ type: actionTypes.GO_TO_PAYMENT }),
    changePromoCode: (promoCode) => ({ type: actionTypes.CHANGE_PROMO_CODE, promoCode }),
    showPlans: () => ({ type: actionTypes.SHOW_SELECT_PLAN }),
} as Actions