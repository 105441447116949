import ajax, { Method } from "../../helpers/ajax"
import { showLoading, hideLoading, showAlert } from '../Layout/actionCreators'
import { i18n } from "../../Localization/i18n"
import { phonePlatform, WebchatProfile, StatusEnum, PhoneParameter, platforms } from "../../models"
import { push } from 'connected-react-router'
import { alertTypes } from "../../components/common/Alert"
import { confirmation } from "../../components/common/ConfirmModal";
import { Actions, Dispatch, actionTypes, _ENGLISH_LABELS, _FRENCH_LABELS } from './types'

const loadPages = async (dispatch: Dispatch, getState, platform: platforms) => {
    dispatch(showLoading());
    const response = await ajax.get(`facebook/subscribed-pages/${platform}`);

    if (await ajax.errorOccured(response, i18n.t("loadFailed"), dispatch, getState)) return

    const pages = await response.json();

    dispatch(hideLoading());
    dispatch({ type: actionTypes.CHANGE_FIELD, name: platform === platforms.facebook ? 'facebookPages' : 'instagramPages', value: pages });
}

const actionCreators: Actions = {
    facebookInit: () => async () => {
        let appIdResponse = await ajax.get("facebook/facebook-app-id");
        let appId = await appIdResponse.json();

        // Load the required SDK asynchronously for facebook

        (function (d, s, id) {
            if (d.getElementById(id)) return;

            var js,
                firstJS = d.getElementsByTagName(s)[0];

            js = d.createElement(s);
            js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            firstJS.parentNode.insertBefore(js, firstJS);
        })(document, "script", "facebook-jssdk");

        (window as any).fbAsyncInit = function () {
            (window as any).FB.init({
                appId: appId,
                cookie: true, // enable cookies to allow the server to access the session
                xfbml: true, // parse social plugins on this page
                version: "v10.0"
            });
        };
    },
    facebookLogin: () => (dispatch, getState) => {
        const checkLoginResult = loginResponse => {
            let userToken = '';

            if (loginResponse && loginResponse.authResponse)
                userToken = loginResponse.authResponse.accessToken;

            if (userToken !== "") {
                ajax.post("facebook/subscribed-pages/" + platforms.facebook, userToken).then(response => {
                    if (!response.ok) {
                        dispatch(showAlert(i18n.t("facebookVerificationFailed")));
                        return;
                    };

                    dispatch(showAlert(i18n.t("facebookPageSubscribed"), alertTypes.success))
                    loadPages(dispatch, getState, platforms.facebook);
                });
            } else {
                dispatch(showAlert(i18n.t("facebookVerificationFailed")));
            }
        }

        (window as any).FB.login(checkLoginResult, { scope: "public_profile, pages_show_list, pages_messaging" })
    },
    deleteFacebookPage: pageId => async (dispatch, getState) => {
        await confirmation()

        dispatch(showLoading())

        const response = await ajax.del("facebook/subscribed-pages/" + pageId, {})

        if (await ajax.errorOccured(response, i18n.t("facebookDeleteFailed"), dispatch, getState)) return

        dispatch(hideLoading())
        dispatch(showAlert(i18n.t("facebookDeleteSuccess"), alertTypes.success))
        dispatch({ type: actionTypes.FACEBOOK_PAGE_DELETED, pageId })
    },
    deleteInstagramPage: accountId => async (dispatch, getState) => {
        await confirmation()

        dispatch(showLoading())

        const response = await ajax.del("facebook/subscribed-pages/" + accountId, {})

        if (await ajax.errorOccured(response, i18n.t("instagramDeleteFailed"), dispatch, getState)) return

        dispatch(hideLoading())
        dispatch(showAlert(i18n.t("instagramDeleteSuccess"), alertTypes.success))
        dispatch({ type: actionTypes.INSTAGRAM_PAGE_DELETED, accountId })
        loadPages(dispatch, getState, platforms.facebook);
    },
    getFacebookSubscribedPages: () => (dispatch, getState) => loadPages(dispatch, getState, platforms.facebook),
    instagramLogin: () => (dispatch, getState) => {
        const checkLoginResult = loginResponse => {
            let userToken = '';

            if (loginResponse && loginResponse.authResponse)
                userToken = loginResponse.authResponse.accessToken;

            if (userToken !== "") {
                ajax.post("facebook/subscribed-pages/" + platforms.instagram, userToken).then(response => {
                    if (!response.ok) {
                        dispatch(showAlert(i18n.t("facebookVerificationFailed")));
                        return;
                    };

                    dispatch(showAlert(i18n.t("instagramPageSubscribed"), alertTypes.success))
                    loadPages(dispatch, getState, platforms.facebook);
                    loadPages(dispatch, getState, platforms.instagram);
                });
            } else {
                dispatch(showAlert(i18n.t("facebookVerificationFailed")));
            }
        }

        (window as any).FB.login(checkLoginResult, { scope: "instagram_basic, instagram_manage_messages, pages_manage_metadata, public_profile, pages_show_list, pages_messaging" })
    },
    getInstagramSubscribedPages: () => (dispatch, getState) => loadPages(dispatch, getState, platforms.instagram),

    getOwnedNumbers: (platform) => async (dispatch, getState) => {

        dispatch(showLoading());
        let url = ""
        if (platform === phonePlatform.twilio)
            url = "TwilioApi/OwnedNumbers";
        else if (platform === phonePlatform.bandwidth)
            url = "Bandwidth/OwnedNumbers";

        const response = await ajax.get(url)

        if (await ajax.errorOccured(response, i18n.t("loadFailed"), dispatch, getState)) return

        const numbers = await response.json()

        dispatch(hideLoading())
        dispatch({ type: actionTypes.CHANGE_FIELD, name: 'numbers', value: numbers })
    },
    deleteNumber: (platform, number) => async (dispatch, getState) => {
        await confirmation()

        let url = "";
        if (platform === phonePlatform.twilio)
            url = "TwilioApi/OwnedNumbers";
        else if (platform === phonePlatform.bandwidth)
            url = "Bandwidth/OwnedNumbers";

        const response = await ajax.del(url, [number.platformParameterId]);

        if (await ajax.errorOccured(response, i18n.t("phoneNumberDeleteFailed"), dispatch, getState)) return

        dispatch(showAlert(i18n.t("phoneNumberDeletedSuccess"), alertTypes.success))
        dispatch({ type: actionTypes.NUMBER_DELETED, number })
    },
    updateNumber: (id, phoneNumber, displayName) => async (dispatch, getState) => {

        const phoneParameter: PhoneParameter = {
            platformParameterId: id,
            displayName,
            phoneNumber
        }

        const response = await ajax.post("Bandwidth/UpdateNumber", phoneParameter);

        if (await ajax.errorOccured(response, i18n.t("phoneNumberUpdateFailed"), dispatch, getState)) return;

        const numbers: PhoneParameter[] = getState().chatAccounts.numbers.slice();
        const index = numbers.findIndex(n => n.platformParameterId == id);
        let number = Object.assign({}, numbers[index]);
        number.displayName = displayName;
        numbers[index] = number;
        dispatch({ type: actionTypes.CHANGE_FIELD, name: 'numbers', value: numbers })
    },
    buyNumber: (platform) => dispatch => {
        let url = "";
        if (platform === phonePlatform.twilio)
            url = '/BuyNumberT';
        else if (platform === phonePlatform.bandwidth)
            url = '/BuyNumberB';

        dispatch(push(url))
    },

    loadWebchatProfiles: () => async (dispatch, getState) => {
        dispatch(showLoading())
        const response = await ajax.get("webchat/GetProfiles")

        if (await ajax.errorOccured(response, i18n.t("loadFailed"), dispatch, getState)) return

        const platformParameters: { id: number, parameters: string, status: StatusEnum }[] = await response.json(),
            profiles: WebchatProfile[] = platformParameters.map(pp => {
                const parameters: WebchatProfile = JSON.parse(pp.parameters)

                parameters.icon = parameters.icon || '01'
                parameters.language = parameters.language || 'EN'

                var defaultLabels = parameters.language === 'EN' ? _ENGLISH_LABELS : _FRENCH_LABELS;

                Object.keys(defaultLabels).forEach(key => {
                    parameters.labels[key] = parameters.labels[key] || defaultLabels[key]
                });
                parameters.phoneNumber = !parameters.phoneNumber || parameters.phoneNumber === '' ? 'NONE' : parameters.phoneNumber;
                parameters.iconImageUrl = parameters.iconImageUrl || ''
                parameters.useIconImage = parameters.iconImageUrl && true || false
                parameters.useOptional = parameters.optionalLabel !== undefined && parameters.optionalLabel !== '' || false

                return { id: pp.id, status: pp.status, ...parameters }
            })

        dispatch(hideLoading())
        dispatch({ type: actionTypes.CHANGE_FIELD, name: 'profiles', value: profiles })
    },
    addWebchatProfile: () => ({ type: actionTypes.ADD_WEBCHAT_OPEN_DIALOG }),
    editWebchatProfile: (profile: WebchatProfile) => ({ type: actionTypes.EDIT_WEBCHAT_OPEN_DIALOG, profile }),
    closeWebchatDialog: () => ({ type: actionTypes.CLOSE_WEBCHAT_DIALOG }),
    webchatChangeField: (webchatName: keyof WebchatProfile, value: any) => ({ type: actionTypes.WEBCHAT_CHANGE_FIELD, webchatName, value }),
    submitWebchatProfile: () => async (dispatch, getState) => {
        let profile = getState().chatAccounts.profileForm

        dispatch(showLoading())

        let profileCopy = { ...profile };
        profileCopy.phoneNumber = profileCopy.phoneNumber === 'NONE' ? '' : profileCopy.phoneNumber;


        if (profile.iconImage) {
            const data = new FormData();
            data.append("file", profile.iconImage, profile.iconImage.name);

            const fileResponse = await ajax.call("webchat/ChatIcon", Method.POST, data, true);

            if (await ajax.errorOccured(fileResponse, "", dispatch, getState)) return;
            profileCopy.iconImageUrl = await fileResponse.json()
        }

        const response = await ajax.post("webchat/SubmitProfile", { id: profile.id, profile: profileCopy })

        if (await ajax.errorOccured(response, i18n.t("loadFailed"), dispatch, getState)) return

        const profileId = await response.json()

        dispatch(hideLoading())
        dispatch({ type: actionTypes.SUBMIT_WEBCHAT_PROFILE, profileId })
    },
    deleteWebchatProfile: (profile: WebchatProfile) => async (dispatch, getState) => {
        await confirmation(i18n.t("loadFailed"))

        dispatch(showLoading())
        const response = await ajax.post("webchat/DeleteProfile", profile.id)

        if (await ajax.errorOccured(response, i18n.t("areYouSureToDelete", { x: profile.domain }), dispatch, getState)) return

        dispatch(hideLoading())
        dispatch({ type: actionTypes.WEBCHAT_PROFILE_DELETED, profile })
    },
    resetWebchatProfileLabels: () => ({ type: actionTypes.WEBCHAT_PROFILE_RESET_LABELS }),
    submitWebchatProfileLabels: () => ({ type: actionTypes.WEBCHAT_PROFILE_SUBMIT_LABELS }),
}

export default actionCreators