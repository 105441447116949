import ajax from '../../helpers/ajax';
import { Actions, actionTypes, initialState } from './types';
import { userStatus } from '../../models';
import { showLoading, hideLoading, showAlert } from '../Layout/actionCreators';
import { alertTypes } from '../../components/common/Alert';
import { i18n } from "../../Localization/i18n"
import { compareValues } from '../../helpers/utils'

export default {
    loadUsers: () => async (dispatch, getState) => {
        dispatch(showLoading());

        const usersResponse = await ajax.get("User/getUsers");

        if (await ajax.errorOccured(usersResponse, "Load users failed.", dispatch, getState))
            return;

        let users = await usersResponse.json();

        users.sort(compareValues(initialState.sortKey, initialState.sortDir, 'firstName'));

        dispatch(hideLoading());
        dispatch({ type: actionTypes.LOADED, users });
    },
    updateUserStatus: user => async (dispatch, getState) => {
        const status = user.status === userStatus.Active ? userStatus.Inactive : userStatus.Active;

        dispatch(showLoading());
        const response = await ajax.post("User/UpdateUsersStatus", { userId: user.id, status });

        if (await ajax.errorOccured(response, "User status update failed.", dispatch, getState)) return;

        dispatch(hideLoading());
        dispatch(showAlert(i18n.t("userStatusUpdatedSuccessfully"), alertTypes.success));
        dispatch({ type: actionTypes.UPDATED_USER_STATUS, userId: user.id, status });
    },
    sortUsers: (sortKey, sortDir) => ({ type: actionTypes.SORT_USERS, sortKey, sortDir }),
} as Actions