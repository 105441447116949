import { TFunction } from 'i18next';

export enum products {
    analytics = 1,
    chatbot = 2
};

//users and roles
export interface Role {
    id: number,
    title: string
}

export enum userStatus {
    Active = 1,
    Inactive = 2,
    Archived = 3
}

export enum GenderEnum {
    Unknown = 0,
    Male = 1,
    Female = 2,
    Other = 3
}

export interface User {
    id: number;
    firstName: string;
    lastName: string;
    phone: string;
    gender?: GenderEnum;
    emailAddress: string;
    picture?: string;
    selected?: boolean;
    status?: userStatus;
    currentRoles?: Role[];
}

export interface Place {
    streetNumber: string,
    route: string,
    city: string,
    province: string,
    country: string,
    postalCode: string
    fullAddress: string,
}

export interface CompanyType {
    id: number,
    title: string
}

export interface Company {
    id: number,
    businessName: string,
    emailAddress: string,
    address: string,
    postalCode: string,
    phone1: string,
    companyTypeId: number
}

//tickets and messages
export enum menuCommands {
    claim = 'claim',
    close = 'close',
    editName = 'editName',
    info = 'info',
    transfer = 'transfer',
    comment = 'comment'
};

export enum platforms {
    facebook = 1,
    twilio = 2,
    bandwidth = 3,
    instagram = 4,
    webChat = 5
};

export enum ticketStatus {
    open = 1,
    assigned = 2,
    resolved = 3,
    chatbotResolved = 4,
    transferred = 5,
    templateChatbotAssigned = 6,
    reopened = 7,
    comment = 8,
    bppvChatbotAssigned = 9

};

export enum MessageType {
    Inbound = 1,
    Outbound = 2,
    AutoResponse = 3,
    Chatbot = 4
}

export enum DeliveryStatus {
    Pending = 1,
    Delivered = 2,
    Failed = 3
}

export interface Message {
    id: number,
    ticketId: number,
    body: string,
    createdDate: Date,
    messageType: MessageType,
    deliveryStatus?: DeliveryStatus,
    system?: boolean
}

export interface MessageDelivery {
    ticketId: number,
    messageId: number,
    deliveryStatus: DeliveryStatus
}

export interface TicketStatusInfo {
    ticketId: number,
    status: ticketStatus
}

export interface Contact {
    id: number,
    agentId: number,
    endUserId: string,
    endUserName?: string,
    optionalLabelTitle?: string,
    endUserInfo?: endUserInfo,
    status: ticketStatus,
    platform: platforms,
    source: string,
    selected: boolean,
    isActiveForDialog: boolean,
    lastMessageDate: Date,
    agentName?: string,
    clientUniqueId: number,
    show: boolean,

    lastRead?: string | Date,

    messages: Array<Message>
}

export interface TicketHistory {
    ticketId: number,
    createdDate: Date,
    status: ticketStatus,
    agentId: number,
    agentName: string,
    comment: string
}

export interface TicketComment {
    ticketId: number,
    createdDate: Date,
    agentId: number,
    agentName: string,
    comment: string
}

export interface ContactFilter {
    searchText?: string,
    platformsFacebookSelected?: boolean,
    platformsInstagramSelected?: boolean,
    platformsSMSSelected?: boolean,
    platformsWebchatSelected?: boolean,
    statusesNewSelected?: boolean,
    statusesAssignedSelected?: boolean,
    statusesTransferredSelected?: boolean,
    statusesResolvedSelected?: boolean,
    ownershipMineSelected?: boolean,
    ownershipOthersSelected?: boolean,
    loadOldTickets?: boolean,
}

export enum dialogModes {
    close = 1,
    transfer = 2,
    esclate = 3,
    editName = 4,
    info = 5,
    comment = 6
};

//Payments
export enum PlanSubscriptionStatus {
    Pending = 1,
    Archived = 2,
    Failed = 3,
    Active = 4,
    Cancelled = 5,
    PendingEndOfCycle = 6
}

export const getPlanSubscriptionStatusName = (t: TFunction, status: PlanSubscriptionStatus) => {
    switch (status) {
        case PlanSubscriptionStatus.Active: return t("active");
        case PlanSubscriptionStatus.Archived: return t("archived");
        case PlanSubscriptionStatus.Failed: return t("failed");
        case PlanSubscriptionStatus.Pending: return t("pending");
        case PlanSubscriptionStatus.Cancelled: return t("cancelled");
        case PlanSubscriptionStatus.PendingEndOfCycle: return t("pendingEndOfCycle");
        default: return '';
    }
}

export interface ProductPlan {
    id: number,
    title: string,
    description: string,
    price: number,
    selected: boolean,
    showOrder: number,
}

export interface ProductPlanDescription {
    text: string,
    features: string[]
}

export interface PlanSubsciption {
    id: number,
    productPlanId: number,
    price?: number,
    startDate: Date,
    status: PlanSubscriptionStatus
}

export interface Discount {
    isPercentage: boolean,
    amount: number,
    name: string
}

export interface Tax {
    taxRate: number,
    description: string,
    name: string
}

export enum InvoiceStatus {
    Queued = 1,
    Failed = 2,
    Paid = 3
}

export interface Invoice {
    id: number,
    invoiceNumber: string,
    status: InvoiceStatus,
    invoiceAmount: number,
    totalAmount: number,
    totalDiscountAmount: number,
    totalTaxAmount: number,
    invoiceDate: Date,
    billDate: Date,
    info: string
}

export interface Discount {
    name: string,
    isPercentage: boolean,
    amount: number
}

//Social Accounts

export interface FacebookPage {
    platformParameterId: number,
    linkedParameterId?: number,
    accountId?: string,

    PageID: string,
    name: string,
    expirationDate: Date
}

export interface PhoneParameter {
    displayName?: string,
    phoneNumber: string,
    platformParameterId: number
}

export interface PhoneNumberResource {
    friendly_name: string,
    phone_number: string,
    locality: string,
    region: string,
    postal_code: string,
    iso_country: string
}

export enum phonePlatform {
    twilio = 1,
    bandwidth = 2
};

export enum StatusEnum {
    Active = 1,
    Inactive = 2,
    Archived = 3,
    Hold = 4,
    Deleted = 5
}

export interface WebchatProfileLabels {
    formTitle?: string,
    iconText?: string,
    message?: string,
    messagePlaceholder?: string,
    name?: string,
    namePlaceholder?: string,
    send?: string,
    start?: string,
    reset?: string,
    reopen?: string,
    new?: string,
    sms?: string,
    webChat?: string,
}

export interface WebchatProfile {
    id: number,
    status: StatusEnum,
    domain: string,
    language: string,
    name: string,
    apiKey: string,
    useIcon: boolean,
    icon: string,
    chatColor: string,
    useOptional?: boolean,
    optionalLabel?: string,
    phoneNumber?: string,
    optionalPlaceholder?: string,
    useIconImage?: boolean,
    iconImage?: File,
    iconImageUrl?: string,
    backgroundColor?: string,
    menuColor?: string,
    menuBackgroundColor?: string,
    labels: WebchatProfileLabels
}

export interface Notification {
    id: number,
    description: string,
    createdDate: Date,
    read: boolean,
    redirectSection: redirectSection,
    url?: string
}

export enum redirectSection {
    None = 0,
    Messages = 1,
    MessagingAccounts = 2,
    Subscriptions = 3,
    Dashboards = 4,
    Users = 5,
    External = 6
}

export interface PlatformParameter {
    id: number,
    companyId: number,
    platformId: number,
    parameters: string,
    displayName: string
}

export interface DateRange {
    start: Date;
    end: Date;
}
export interface TimeRange {
    start: number;
    end: number;
}

export interface DaySchedule {
    included: boolean;
    timeRange: TimeRange;
}

export interface WeeklySchedule {
    monday: DaySchedule;
    tuesday: DaySchedule;
    wednesday: DaySchedule;
    thursday: DaySchedule;
    friday: DaySchedule;
    saturday: DaySchedule;
    sunday: DaySchedule;

    timeZone: string;
}

export enum replyType {
    Welcome = 0,
    Vacation = 1,
    OutsideOfficeHours = 2
}

export interface AutoReply {
    autoResponseId: number;
    selectedType: replyType;
    includeOldTickets: boolean;
    replyMessage: string;
    dateRange: DateRange;
    weeklySchedule: WeeklySchedule;
    platformParameterId: number;
    active: boolean;
}

export interface Device {
    id: number,
    companyId: number,
    pushEndpoint: string,
    pushP256DH: string,
    pushAuth: string,
    enabled: boolean
}

export enum ChatbotMode {
    Template = 1,
    Supervised = 2,
    BPPV = 3
}

export interface DataBody {
    title: string,
    body: string,
    client_tags?: string[],
    endDate?: Date
}

export enum ProcessingStatus {
    Pending = 1,
    Ready = 2,
    Processed = 3
}

export interface RawData {
    id: number,
    companyId: number,
    body?: DataBody,
    processingStatus: ProcessingStatus,
    createdDate: Date
}

export interface WebsiteData {
    title: string,
    body: Body,
}

export interface Body {
    originalUrl: string,
    data: ProcessedUrl[]
}

export interface ProcessedUrl {
    url: string,
    text: string,
    language: string
}

export interface endUserInfo {
    ip?: string,
    optionalLabel?: string,
    browser?: string,
    language?: string,
    mobile?: string,
    platform?: string,
    sourceUrl?: string,
    sourceTitle?: string,
}

export interface TagsRequest {
    companyId: number,
    docs: string
}

export interface TagsResponse {
    success: boolean,
    message: string,
    topics: string[]
}

export interface Template {
    id: number,
    name: string,
    language: string,
    transferMessage: string,
    status?: StatusEnum,
    templateContent?: TemplateContent[],
    createdDate?: Date
}

export interface TemplateContent {
    id: number,
    templateId: number,
    parentId?: number,
    title: string,
    message: string,
    finalNode?: boolean,
    info?: string,
    infoFields?: JsonField[],
    children?: TemplateContent[],
}

export interface Point {
    x: number;
    y: number;
}

export interface RawNodeDatum {
    name: string;
    attributes?: Record<string, any>;
    children?: RawNodeDatum[];
}

export interface TreeNodeDatum extends RawNodeDatum {
    children?: TreeNodeDatum[];
    __rd3t: {
        id: string;
        depth: number;
        collapsed: boolean;
    };
}

export interface JsonField {
    id: number,
    key: string,
    value: any
}

export enum DayOfWeek {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
}

export interface MessageAnalyticsInfo {
    id: number,
    createdDate: Date,
}

export interface DayMessages {
    day: DayOfWeek,
    messages: MessageAnalyticsInfo[],
}

export interface Topic {
    name: string,
    count: number[],
}

export interface PieDatum {
    id: string,
    label: string,
    value: number,
}

export interface ReadReceipt {
    id: number,
    userId: number,
    ticketId: number,
    lastRead: string | Date,
}