import ajax from '../../helpers/ajax'
import { actionTypes, Actions, schema, State } from './types'
import { i18n } from "../../Localization/i18n"
import { hideLoading, showAlert, showLoading } from '../Layout/actionCreators';
import { getUserInfo } from '../../helpers/auth';
import { alertTypes } from '../../components/common/Alert';
import { isEmpty, validator } from '../../helpers/utils'

export default {
    changeField: (name, value) => ({ type: actionTypes.CHANGE_FIELD, name, value }),
    changeCompanyField: (companyName, value) => ({ type: actionTypes.CHANGE_COMPANY_FIELD, companyName, value }),
    submit: () => async (dispatch, getState) => {
        const { company } = getState().companyProfile;

        const errors = await validator(schema)(company)

        dispatch({ type: actionTypes.CHANGE_FIELD, name: "errors", value: errors });
        if (!isEmpty(errors)) return false;
        
        dispatch(showLoading());

        const response = await ajax.post("company", company);

        if (await ajax.errorOccured(response, i18n.t("errorOccured"), dispatch, getState)) return false;

        dispatch(hideLoading());
        dispatch(showAlert(i18n.t("changesSaved"), alertTypes.success));

        return true;
    },
    loadCompany: () => async (dispatch, getState) => {
        dispatch(showLoading());

        const response = await ajax.get("company/" + getUserInfo().companyId);

        if (await ajax.errorOccured(response, i18n.t("errorOccured"), dispatch, getState)) return;

        const { company, companyTypes } = await response.json();

        dispatch({ type: actionTypes.CHANGE_FIELD, name: 'company', value: company });
        dispatch({ type: actionTypes.CHANGE_FIELD, name: 'companyTypes', value: companyTypes });

        dispatch(hideLoading());
    },
    validate: () => async (dispatch, getState) => {
        const state: State = getState().companyProfile;
        const { company } = state;

        const errors = await validator(schema)(company)

        dispatch({ type: actionTypes.CHANGE_FIELD, name: "errors", value: errors });
    }
} as Actions