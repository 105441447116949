import ajax from '../../helpers/ajax';
import { actionTypes, Actions} from './types'
import { setUserInfo, logout } from '../../helpers/auth';
import { setUnreadMessagesCount } from '../../helpers/utils';

export const clearUserInfo = () => ({ type: actionTypes.CLEAR_USER_INFO });

export default {
    loadUserInfo: () => async (dispatch, getState) => {
        dispatch({ type: actionTypes.LOADING })
        const response = await ajax.get('user/getUserInfo')

        if (await ajax.errorOccured(response, "Load user info failed.", dispatch, getState)) {
            logout()
            dispatch({ type: actionTypes.LOADED })
            return
        }

        const userInfo = await response.json();

        setUserInfo(userInfo);

        console.log({unreadMessagesCount: userInfo.unreadMessagesCount})
        
        setUnreadMessagesCount(userInfo.unreadMessagesCount);

        dispatch({ type: actionTypes.LOADED, userInfo })
    },
    clearUserInfo
} as Actions