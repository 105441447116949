import * as React from 'react';
import { Paper, Typography } from '@mui/material';
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()(() => ({
    root: {
        minWidth: 200,
        maxWidth: 500,
        paddingBottom: "10px !important",
        textAlign: 'center',
        margin: 2
    },
    title: {
        padding: 8,
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: 0.05,
        color: '#818ea3',
        textTransform: 'uppercase'
    },
    value: {
        margin: 10,
        color: '#3d5170',
        fontSize: 36,
    },
}));

interface Props {
    title: string,
    value: number,
    backgroundColor?: string;
}

const NumberTile = (props: Props) => {
    const { classes } = useStyles();

    const { title, value, backgroundColor } = props

    return (
        <Paper className={classes.root} style={{ backgroundColor: backgroundColor || "#FFF" }}>
            <Typography variant="h4" component="h3"  className={classes.title}>
                {title}
            </Typography>
            <Typography variant="h4" component="h3" className={classes.value}>
                {value}
            </Typography>
        </Paper>
    );
}

export default NumberTile;