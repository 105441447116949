import { Message, MessageType, DeliveryStatus, User, JsonField, Contact, ticketStatus } from '../models'
import { getUserInfo, UserInfo } from "./auth";
import { TFunction } from "i18next";
import { addHours, getTime, getUTCTime } from './date';
import { SchemaOf } from 'yup';
import { SystemStyleObject } from '@mui/system';

export const getServerUrl = () => window.location.href.indexOf('3000') > -1 ? "https://localhost:44377" : ''

export const getQueryStringValue = (key: string) => {
    const params = new URLSearchParams(window.location.search)
    const value = params.get(key)
    return value
};

export const truncate = (str, max) => str && str.length > max ? `${str.substring(0, max)} ...` : str;

export const setPageTitle = (t: TFunction, title: string) => {
    document.title = `${t("projectTitle")} - ${t(title)}`;
    updateTitleCount(_unreadMessagesCount);
}

export const padNumber = (num: number, size = 2) => {
    var s = num.toString()
    while (s.length < size) s = "0" + s
    return s
};

export const sleep = m => new Promise(r => setTimeout(r, m));

export const validateFacebookMessages = (messages: Array<Message>, t: TFunction) => {
    if (messages === null || messages.length === 0) return true;

    let receivedMessages = messages.filter(msg => msg.messageType === MessageType.Inbound && !msg.system);
    let lastReceivedMessage = receivedMessages[receivedMessages.length - 1];
    let lastReceivedMessageDate = new Date(lastReceivedMessage.createdDate);
    let diffHours = (Date.now() - lastReceivedMessageDate.getTime()) / 3600000;

    let sentMessages = messages.filter(msg => msg.messageType !== MessageType.Inbound);
    let sentAfter24hours = 0;
    let lastReceivedPlus24 = addHours(lastReceivedMessageDate, 24);
    if (sentMessages.length > 0) {
        sentAfter24hours = sentMessages.filter(msg => msg.createdDate >= lastReceivedPlus24).length;
    }

    if (diffHours < 24)
        return true;
    else {
        if (sentAfter24hours > 10) {
            let warningMsg: Message = {
                body: t("violatedFacebook24Policy"),
                createdDate: getUTCTime(),
                id: lastReceivedMessage.id,
                messageType: MessageType.Inbound,
                ticketId: lastReceivedMessage.ticketId,
                system: true,
                deliveryStatus: DeliveryStatus.Delivered
            };
            if (messages[messages.length - 1].body !== warningMsg.body) {
                messages.push(warningMsg);
            }
            return false;
        }

        return true;
    }
};

let mobileMode = false;

(function (a) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4))) {
        mobileMode = true;
    }
})(navigator.userAgent || navigator.vendor || (window as any).opera);

export const isMobileMode = () => mobileMode;

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const showMoney = (amount: number, unit?: string) => {
    let result = amount.toFixed(3);

    return `$${result.substring(0, result.length - 1)} ${unit || ''}`.trim();
};

export const confirmDialog = (msg: string) => {
    return new Promise((resolve, reject) => {
        if (window.confirm(msg)) resolve(true)
    });
}

export const emailRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (email: string) => emailRegEx.test(email);

// eslint-disable-next-line
export const urlRegEx = /(((https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\S]*))?)/gi
// eslint-disable-next-line
export const urlRegEx2 = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i

export const compareValues = (key: string, order: 'desc' | 'asc' = 'asc', secondaryKey: string = null) => {
    return function (a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            return 0;
        }

        let primaryA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
        let primaryB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

        if (secondaryKey !== null) {
            primaryA += (typeof a[secondaryKey] === 'string') ? a[secondaryKey].toUpperCase() : a[secondaryKey];
            primaryB += (typeof b[secondaryKey] === 'string') ? b[secondaryKey].toUpperCase() : b[secondaryKey];
        }

        let comparison = 0;
        if (primaryA > primaryB) {
            comparison = 1;
        } else if (primaryA < primaryB) {
            comparison = -1;
        }
        return (order === 'desc') ? (comparison * -1) : comparison;
    };
}

export const getFullName = (user: User | UserInfo) => `${user.firstName} ${user.lastName}`;

export const validator = <T>(schema: SchemaOf<T>) => async formValues => {
    try {
        await schema.validate(formValues, { abortEarly: false })
        return {}
    } catch (errors: any) {
        return errors.inner.reduce(
            (errors, err) => ({
                ...errors,
                [!!err.path ? err.path : err.type]: err.message
            }),
            {}
        )
    }
}

export const fieldsToJson = (fields: JsonField[]) => {
    const obj = {};
    fields.forEach(f => {
        if (f.key && f.value) {
            obj[f.key] = f.value;
        }
    });

    if (isEmpty(obj)) return null;

    return JSON.stringify(obj);
}

export const jsonToFields = (obj: string) => {
    if (!obj) return [];

    obj = JSON.parse(obj);

    if (!obj) return [];

    const fields: JsonField[] = [];

    let i = 0;
    for (const [key, value] of Object.entries(obj)) {
        fields.push({
            id: ++i,
            key,
            value
        })
    }

    return fields;
}

export const isEqualContact = (c1: Contact, c2: Contact) => {
    if (!c1 && !c2) return true;
    if (!c1 || !c2) return false;

    const lastMessage1 = c1.messages?.length > 0 && c1.messages[c1.messages.length - 1];
    const lastMessage2 = c2.messages?.length > 0 && c2.messages[c2.messages.length - 1];

    return c1.id === c2.id &&
        c1.endUserName === c2.endUserName &&
        c1.messages?.length === c2.messages?.length &&
        lastMessage1?.deliveryStatus === lastMessage2?.deliveryStatus &&
        c1.show === c2.show &&
        c1.agentId === c2.agentId &&
        c1.selected === c2.selected &&
        c1.lastMessageDate === c2.lastMessageDate &&
        c1.isActiveForDialog === c2.isActiveForDialog &&
        c1.lastRead === c2.lastRead &&
        c1.status === c2.status;
}

export const uniqueBy = (arr: Array<any>, comp: string) => {
    if (!arr || arr.length === 0) return [];

    const seen = new Set();

    return arr.filter(el => {
        const duplicate = seen.has(el[comp]);
        seen.add(el[comp]);
        return !duplicate;
    });
}

export const unique = (arr: Array<any>) => {
    if (!arr || arr.length === 0) return [];

    const seen = new Set();

    return arr.filter(el => {
        const duplicate = seen.has(el);
        seen.add(el);
        return !duplicate;
    });
}

export const last = <T>(arr: Array<T>) => {
    if (!arr || arr.length === 0) return null;

    return arr[arr.length - 1];
}

export const isEmpty = (obj: object) => !obj || !Object.keys(obj).length;

export const intersect = (arr1: number[], arr2: number[]) => arr1.some(e => arr2.includes(e));

export function groupBy<T>(collection: T[], iteratee: PropertyKey) {
    return collection.reduce((result, value) => {
        const key: PropertyKey = value[iteratee];
        if (result.hasOwnProperty(key)) {
            result[key].push(value);
        } else {
            result[key] = [value];
        }
        return result;
    }, {})
}

export const asStylesType = <T>(et: { [K in keyof T]: SystemStyleObject }) => et;

const updateTitleCount = (count: number) => {
    const pattern = /^\(\d+\)/;
    if(count === 0 || pattern.test(document.title)){
        document.title = document.title.replace(pattern,  count === 0 ? "" : "(" + count + ")");
    }else {
        document.title = "(" + count + ") " + document.title;
    }
}

const setBadgeCount = (count: number) => {
    if ((navigator as any).setAppBadge) {
        (navigator as any).setAppBadge(count);
    } 
    if ((navigator as any).setClientBadge) {
        (navigator as any).setClientBadge(count);
    } 
  }

let _unreadMessagesCount = 0;
export const setUnreadMessagesCount = (unreadMessagesCount: number) => {
    _unreadMessagesCount = unreadMessagesCount;
    setBadgeCount(unreadMessagesCount);
    updateTitleCount(unreadMessagesCount);
}

export const getUnreadMessagesCount = () => _unreadMessagesCount;

export const interestedInTicket = (c: Contact) => {
    const isOpen = c.status === ticketStatus.open;
    const isAssignedToMe = (c.status === ticketStatus.assigned || c.status === ticketStatus.transferred) && c.agentId === getUserInfo().id;

    return isOpen || isAssignedToMe;
}

const featureDate = new Date("2022-03-18").getTime();
export const isUnreadMessage = (c: Contact, m: Message) => {
    return getTime(m.createdDate) > featureDate && !m.system && (!c.lastRead || getTime(m.createdDate) > getTime(c.lastRead)) && m.messageType === MessageType.Inbound;
}