import ajax from '../../helpers/ajax';
import { i18n } from '../../Localization/i18n';
import { showLoading, hideLoading } from '../Layout/actionCreators';
import { actionTypes, Actions } from './types';


export default {
    loadEmbedConfig: (dashboardId) => async (dispatch, getState) => {

        dispatch({ type: actionTypes.RESET_STATE });
        dispatch(showLoading());

        const dashboardResponse = await ajax.get(`PowerBI/GetReport/${dashboardId}`);

        if (await ajax.errorOccured(dashboardResponse, i18n.t("failedDashboardLoad"), dispatch, getState))
            return;

        const embedConfig = await dashboardResponse.json();

        dispatch(hideLoading());
        dispatch({
            type: actionTypes.LOADED,
            dataSource: embedConfig.dataSource,
            documentationUrl: embedConfig.documentationUrl,
            accessToken: embedConfig.embedToken.token,
            embedUrl: embedConfig.embedUrl,
            id: embedConfig.id
        });
    },
    dashboardRendered: () => ({ type: actionTypes.RENDERED })
} as Actions