import React, { useCallback, useEffect, useState } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Paper, Button, IconButton, Typography, Theme, Tooltip, Grid } from "@mui/material";
import Layout from "../Layout/Layout";
import {
    AddOutlined as AddIcon, DeleteOutline as DeleteIcon, IndeterminateCheckBoxOutlined as CollapseIcon, AddBoxOutlined as ExpandIcon, Edit as EditIcon
} from '@mui/icons-material';
import { State, Actions } from '../../store/TemplateContent/types';
import actionCreators from '../../store/TemplateContent/actionCreators';
import { useTranslation } from "react-i18next";
import { isMobileMode, setPageTitle } from "../../helpers/utils";
import { PermissionKey } from "../../helpers/auth";
import Confirm from '../common/ConfirmModal'
import TemplateContentInfo from "./TemplateContentInfo";
import Tree from 'react-d3-tree';
import { Point, TreeNodeDatum } from "../../models";
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        maxWidth: isMobileMode() ? 800 : '100%',
        width: '100%',
        display: "block",
        margin: 'auto'
    },
    nodeButtons: {
        display: 'flex',
        flexDirection: 'row',
        border: 'none'
    },
    icon: {
        color: theme.palette.primary.main,
        strokeWidth: 0,
    },
    iconButton: {
        width: '33.3%',
        borderRadius: 0,
        padding: 4,
        position: 'inherit'
    },
    nodeName: {
        textAlign: "center",
        padding: 8,
        backgroundColor: '#f3f3f3',
        width: '100%',
        fontSize: 15,
        fontWeight: 500,
    },
    message: {
        padding: 4,
        fontSize: 13,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));


interface Props {
    match: any
}

const useCenteredTree = (defaultTranslate: Point = { x: 0, y: 0 }): [Point, (elem: HTMLDivElement) => void] => {
    const [translate, setTranslate] = useState<Point>(defaultTranslate);
    const containerRef = useCallback((containerElem: HTMLDivElement) => {
        if (containerElem !== null) {
            const { width } = containerElem.getBoundingClientRect();
            setTranslate({ x: width / 2, y: 24 });
        }
    }, []);
    return [translate, containerRef];
};

// `foreignObject`, which wraps the HTML tags to allow for them to be injected into the SVG namespace.
interface nodeProps {
    nodeDatum: TreeNodeDatum,
    toggleNode: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const TemplateContent = (props: State & Actions & Props) => {
    const { t } = useTranslation();
    const { classes } = useStyles();

    const { templateId, name, content, contents, contentsTree, errors, getContent, changeContentField, changeField, deleteContent, submit, validate, addField, deleteField, changeJsonField } = props;

    setPageTitle(t, "templateContent");

    useEffect(() => {
        changeField('templateId', Number.parseInt(props.match.params.id))
        getContent(props.match.params.id);
    }, [props.match.params.id]);

    const [hover, setHover] = useState(0);
    const stopHover = () => setHover(0);

    const [contentId, setContentId] = useState(0);

    const [deleteVisible, setDeleteVisible] = useState(false);
    const showDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const contentId = Number.parseInt(e.currentTarget.value);
        setDeleteVisible(true);
        setContentId(contentId);
    }
    const handleDelete = (confirmed: boolean) => {
        setDeleteVisible(false);
        if (confirmed) deleteContent(contentId);
    }

    const newContent = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let parentId: number = null;
        if (e.currentTarget.value) {
            parentId = Number.parseInt(e.currentTarget.value);
        }
        props.changeField('content', {
            id: 0,
            templateId,
            parentId,
            title: '',
            message: '',
            infoFields: [],
            info: '',
            finalNode: false,
        });
        props.changeField('errors', {});

        setEditVisible(true);
    }

    const [editVisible, setEditVisible] = useState(false);
    const editContent = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const contentId = Number.parseInt(e.currentTarget.value);
        const content = contents.find(t => t.id === contentId);
        props.changeField('content', content);
        props.changeField('errors', {});

        setEditVisible(true);
    }

    const closeEdit = () => setEditVisible(false);

    const renderForeignObjectNode = ({
        nodeDatum,
        toggleNode
    }: nodeProps) => (
        <g>
            <foreignObject width={200} height={200} x={-100} y={-20}>
                <Paper variant="outlined" square sx={{ borderColor: "#000" }} onMouseEnter={() => setHover(nodeDatum.attributes.id)} onMouseLeave={stopHover}>

                    <Typography className={classes.nodeName}>{nodeDatum.name}</Typography>

                    <Typography className={classes.message}>{nodeDatum.attributes.message}</Typography>

                    {(hover === nodeDatum.attributes.id || (nodeDatum.__rd3t.collapsed && nodeDatum.children.length > 0)) &&
                        <div className={classes.nodeButtons}>
                            {!nodeDatum.attributes.finalNode &&
                                <Tooltip title={t("addContent")}>
                                    <IconButton className={classes.iconButton} value={nodeDatum.attributes.id} onClick={newContent}>
                                        <AddIcon className={classes.icon} />
                                    </IconButton>
                                </Tooltip>}
                            <Tooltip title={t("edit")}>
                                <IconButton className={classes.iconButton} value={nodeDatum.attributes.id} onClick={editContent}>
                                    <EditIcon className={classes.icon} />
                                </IconButton>
                            </Tooltip>
                            {nodeDatum.children.length > 0 ?
                                <Tooltip title={nodeDatum.__rd3t.collapsed ? t("expand") : t("collapse")}>
                                    <IconButton className={classes.iconButton} onClick={toggleNode} >
                                        {nodeDatum.__rd3t.collapsed ?
                                            <ExpandIcon className={classes.icon} />
                                            :
                                            <CollapseIcon className={classes.icon} />}
                                    </IconButton>
                                </Tooltip>
                                :
                                <Tooltip title={t("delete")}>
                                    <IconButton className={classes.iconButton} value={nodeDatum.attributes.id} onClick={showDelete}>
                                        <DeleteIcon className={classes.icon} />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>}
                </Paper>
            </foreignObject>
        </g>
    );

    const [translate, containerRef] = useCenteredTree();

    const contentInfoProps = { content, errors, changeContentField, validate, submit, close: closeEdit, addField, deleteField, changeJsonField }

    const loaded = !!name;

    return (
        <Layout
            title={t("templateContent")}
            permissionKey={PermissionKey.ManageChatBot}>
            <div className={classes.root}>

                <Grid container spacing={1} sx={{ mb: 1 }}>
                    <Grid item xs={12}>
                        <Typography variant="h5" fontWeight={500} display="inline" sx={{ mr: 1 }}>
                            {t("template")}:
                        </Typography>
                        <Typography variant="h5" display="inline">
                            {name}
                        </Typography>
                    </Grid>
                </Grid>

                <Paper sx={{ height: '80vh' }} ref={containerRef}>

                    {loaded && !contentsTree &&
                        <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={newContent}>
                            {t("addContent")}
                        </Button>}

                    {contentsTree && <Tree
                        data={contentsTree}
                        orientation="vertical"
                        depthFactor={140}
                        separation={{ nonSiblings: 2, siblings: 2 }}
                        pathFunc="elbow"
                        translate={translate}
                        renderCustomNodeElement={renderForeignObjectNode}
                    />}

                </Paper>

                {deleteVisible && <Confirm onClose={handleDelete} color='warning' />}
                <TemplateContentInfo {...contentInfoProps} visible={editVisible} />
            </div>
        </Layout>
    );
}

const TemplateContentWithStore = connect(
    (state: any) => state.templateContent,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(TemplateContent);

export default TemplateContentWithStore;