import { State, initialState, ReducerAction, actionTypes } from './types'
import { alertTypes } from '../../components/common/Alert'
import { produce } from 'immer'

const reducer = (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.LOADING:
                newState.loading = true
                newState.submitted = true
                break
            case actionTypes.CHANGE_FIELD:
                newState[action.name] = action.value
                break
            case actionTypes.CLOSE_MESSAGE:
                newState.alert = ''
                break
            case actionTypes.SEND_EMAIL_FAILED:
                newState.loading = false
                newState.alert = action.message
                newState.alertType = alertTypes.error
                newState.submitted = false
                break
            case actionTypes.EMAIL_SENT:
                newState.loading = false
                newState.alert = action.successMessage
                newState.alertType = alertTypes.success
                break
        }
    })
}

export default reducer