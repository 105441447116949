import { ActionCreatorsMapObject } from 'redux'

export enum actionTypes {
    LOADED = 'PROFILE_LOADED',
    CHANGE_FIELD = 'PROFILE_CHANGE_FIELD',
    UPDATED_USER = 'PROFILE_ADDED_USER',
    ROLES_LOADED = 'PROFILE_ROLES_LOADED',
    FILE_CHANGED = 'PROFILE_FILE_CHANGED',
    SUBMIT = 'PROFILE_SUBMIT',
    RESET_PAGE = 'PROFILE_RESET_PAGE'
}

export interface State {
    isSubmitted: boolean,
    id: number,
    firstName: string,
    lastName: string,
    phone: string,
    gender: number,
    emailAddress: string,
    picture: string,
    picFile: File,
}

export interface Actions extends ActionCreatorsMapObject {
    loadUserInfo: () => (dispatch: Dispatch, getState: any) => void,
    submit: () => (dispatch: Dispatch, getState: any) => void,
    changeField: (name: any, value: any) => ReducerAction,
    onFileChange?: (file: File) => ReducerAction,
}

export const initialState: State = {
    isSubmitted: false,
    id: 0,
    firstName: '',
    lastName: '',
    phone: '',
    gender: 0,
    emailAddress: '',
    picture: '',
    picFile: null,
}

export interface ReducerAction {
    type: actionTypes | string,
    id?: number,
    firstName?: string,
    lastName?: string,
    phone?: string,
    gender?: number,
    emailAddress?: string,
    picture?: string,
    picFile?: File,
    name?: string,
    value?: string
}

export type Dispatch = (action: ReducerAction) => void