import * as Auth from '../../helpers/auth';
import ajax from '../../helpers/ajax';
import { getLocation, push } from 'connected-react-router';
import { actionTypes, Actions, Dispatch } from './types';

const setTokenAndGoToApp = (dispatch: Dispatch, getState, token?: any) => {
    //clear the page
    dispatch({ type: actionTypes.RESET_FORM });

    if (token)
        Auth.login(token);

    const location = getLocation(getState());
    let pathname = '';

    if (location.state && (location.state as any).from)
        pathname = (location.state as any).from.pathname;

    (dispatch as any)(push(pathname));
};

export default {
    goToApp: () => async (dispatch, getState) => {
        setTokenAndGoToApp(dispatch, getState);
    },
    login: (email, password, reCAPTCHA) => async (dispatch, getState) => {
        dispatch({ type: actionTypes.SET_SUBMITTED });

        if (email === '' || password === '' || reCAPTCHA === '')
            return;

        dispatch({ type: actionTypes.LOADING });

        const response = await ajax.post("User/Login", { email, password, reCAPTCHA });

        if (!response.ok) {
            let errorMessage = await ajax.errorMessage(response);

            dispatch({ type: actionTypes.LOGIN_FAILED, message: "Login failed. " + (errorMessage || '') });
            return;
        }

        const token = await response.json();

        setTokenAndGoToApp(dispatch, getState, token);
    },
    changeField: (name, value) => ({ type: actionTypes.CHANGE_FIELD, name, value }),
    closeError: () => ({ type: actionTypes.CLOSE_ERROR }),
} as Actions