import * as React from "react";
import { ChromePicker } from 'react-color'
import { Theme } from "@mui/material/styles";
import {
    Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel, InputAdornment,
    Select, Menu, MenuItem, Switch, FormControlLabel, TextField
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { VpnKey as APIKeyIcon, Translate as LangIcon } from '@mui/icons-material';
import { PhoneParameter, WebchatProfile, WebchatProfileLabels } from "../../models";
import { useTranslation } from "react-i18next";
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    popover: {
        position: 'absolute',
        zIndex: 2,
        top: -160,
        right: 30,
        cursor: 'pointer'
    },
    popover2: {
        position: 'absolute',
        zIndex: 2,
        bottom: 30,
        let: 30,
        cursor: 'pointer'
    },
    cover: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(255,255,255,0.4)',
        cursor: 'context-menu',
    },
    startIcon: {
        display: 'inline-flex',
        width: 38,
        height: 38,
        padding: 9,
        borderRadius: '50%',
    },
    startIconWithText: {
        display: 'inline-flex',
        padding: 10,
        borderRadius: '50%',
        color: 'white',
    },
    iconInfo: {
        paddingRight: theme.spacing(2)
    },
    imagePreview: {
        borderRadius: '50%',
        padding: 12,
        marginLeft: 24,
        maxWidth: 84,
        maxHeight: 84
    },
    imageHelperText: {
        fontSize: 12
    },
    pickerSquare: {
        border: '1px solid rgba(0,0,0,0.54)',
        width: 12,
        height: 12
    }
}));

interface rgba {
    r: number,
    g: number,
    b: number,
    a: number
}

const rgbaToString = (c: rgba) => {
    console.log({ c });
    return `rgba(${c.r},${c.g},${c.b},${c.a})`;
}

enum ChatBadge {
    icon,
    image,
    text
}


interface Props {
    isOpen: boolean,
    profile: WebchatProfile,
    profileFormLabels: WebchatProfileLabels,
    phoneNumbers: PhoneParameter[],
    onCancel: () => void,
    onSubmit: () => void,
    onChangeField: (name: keyof WebchatProfile, value: any) => void,
    onSubmitLabels: () => void,
    onResetLabels: () => void,
}

const WebchatProfileDialog = (props: Props) => {
    const { t } = useTranslation();

    const { classes } = useStyles();

    const { profile, isOpen, profileFormLabels, phoneNumbers } = props;

    const [iconMenuAnchor, setIconMenuAnchor] = React.useState<null | HTMLElement>(null),
        [chatColorPicker, setChatColorPicker] = React.useState(false),
        [backgroundColorPicker, setBackgroundColorPicker] = React.useState(false),
        [menuColorPicker, setMenuColorPicker] = React.useState(false),
        [menuBackgroundColorPicker, setMenuBackgroundColorPicker] = React.useState(false),
        [showLangDialog, setShowLangDialog] = React.useState(false),
        _DEFAULT_COLOR = '#5daee8',
        handelChange = e => {
            props.onChangeField(e.target.name, e.target.value)
        },
        handelChangeLabels = e => {
            props.onChangeField("labels_" + e.target.name as any, e.target.value)
        },
        handelCloseIconMenu = () => {
            setIconMenuAnchor(null)
        },
        handleSubmitLabels = () => {
            props.onSubmitLabels()
            setShowLangDialog(false)
        },
        handleUseOptional = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            props.onChangeField("useOptional", checked)
            if (!checked) {
                props.onChangeField("optionalLabel", '');
                props.onChangeField("optionalPlaceholder", '');
            }
        },
        handleChatBadge = e => {
            console.log(e)
            const value = e.target.value;
            if (value === ChatBadge.icon) {
                props.onChangeField('useIcon', true);
                props.onChangeField('useIconImage', false);
            } else if (value === ChatBadge.image) {
                props.onChangeField('useIcon', false);
                props.onChangeField('useIconImage', true);
            } else {
                props.onChangeField('useIcon', false);
                props.onChangeField('useIconImage', false);
            }
        },
        handleChatImage = e => {
            const file = e.target.files[0];
            console.log(file);
            props.onChangeField("iconImage", file);
        }

    if (!profile)
        return null;

    let chatBadge: ChatBadge = ChatBadge.text;
    if (profile.useIcon) chatBadge = ChatBadge.icon;
    else if (profile.useIconImage) chatBadge = ChatBadge.image;

    return (
        <Dialog open={isOpen} onClose={props.onCancel}>
            <DialogTitle>{t("webchatProfile")}</DialogTitle>
            <DialogContent>
                <FormControl required fullWidth margin="normal">
                    <InputLabel htmlFor="domain">{t("domain")}</InputLabel>
                    <Input
                        id="domain"
                        name="domain"
                        autoFocus
                        value={profile.domain}
                        onChange={handelChange}
                    />
                </FormControl>
                <FormControl required fullWidth margin="normal">
                    <InputLabel htmlFor="language">{t("language")}</InputLabel>
                    <Select
                        id="language"
                        name="language"
                        variant="standard"
                        value={profile.language}
                        onChange={handelChange}
                        startAdornment={
                            <InputAdornment position="start">
                                <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() => setShowLangDialog(true)}
                                >
                                    <LangIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        }
                    >
                        <MenuItem value="EN">{t("lang_English")}</MenuItem>
                        <MenuItem value="FR">{t("lang_French")}</MenuItem>
                        <MenuItem value="CUSTOM">{t("lang_Custom")}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl required fullWidth margin="normal">
                    <InputLabel htmlFor="name">{t("name")}</InputLabel>
                    <Input
                        id="name"
                        name="name"
                        value={profile.name || (profile.domain && profile.language ? `${profile.domain} - ${profile.language}` : '')}
                        onChange={handelChange}
                    />
                </FormControl>
                <FormControl required fullWidth margin="normal">
                    <InputLabel htmlFor="apiKey">{t("apiKey")}</InputLabel>
                    <Input
                        id="apiKey"
                        name="apiKey"
                        value={profile.apiKey}
                        readOnly
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton color="primary" size="small" onClick={() => props.onChangeField("apiKey", "")}>
                                    <APIKeyIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                {phoneNumbers.length > 0 && (
                    <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="phoneNumber">{t("phoneNumber")}</InputLabel>
                        <Select
                            id="phoneNumber"
                            name="phoneNumber"
                            type="select"
                            variant="standard"
                            value={profile.phoneNumber}
                            onChange={handelChange}
                        >
                            <MenuItem value='NONE'>{t("none")}</MenuItem>
                            {phoneNumbers.map(number => (
                                <MenuItem key={number.phoneNumber} value={number.phoneNumber}>{number.phoneNumber}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                {profile.phoneNumber && profile.phoneNumber !== 'NONE' && (
                    <>
                        <span style={{ display: 'block', marginTop: 8, fontSize: 16 }}>{t("menuBranding")}</span>
                        <div style={{ display: 'flex', }}>
                            <FormControl margin="normal" style={{ marginTop: 4, marginRight: 16, width: 'calc(50% - 8px)' }}>
                                <InputLabel htmlFor="menuColor">{t("menuTextColor")}</InputLabel>
                                <Input
                                    id="menuColor"
                                    name="menuColor"
                                    value={profile.menuColor || '#ffffff'}
                                    onChange={handelChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton size="small" onClick={() => setMenuColorPicker(true)}>
                                                <div style={{ width: 24, height: 24, padding: 3 }}>
                                                    <div style={{ backgroundColor: 'white', width: 18, height: 18, padding: 3, borderRadius: 4 }}>
                                                        <div className={classes.pickerSquare} style={{ backgroundColor: profile.menuColor || '#ffffff' }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </IconButton>
                                            {
                                                menuColorPicker &&
                                                <div className={classes.popover}>
                                                    <div className={classes.cover} onClick={() => setMenuColorPicker(false)} />
                                                    <ChromePicker disableAlpha color={profile.menuColor} onChange={color => props.onChangeField("menuColor", color.hex)} />
                                                </div>
                                            }
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <FormControl margin="normal" style={{ marginTop: 4, width: 'calc(50% - 8px)' }}>
                                <InputLabel htmlFor="menuBackgroundColor">{t("menuBackgroundColor")}</InputLabel>
                                <Input
                                    id="menuBackgroundColor"
                                    name="menuBackgroundColor"
                                    value={profile.menuBackgroundColor || _DEFAULT_COLOR}
                                    onChange={handelChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton size="small" onClick={() => setMenuBackgroundColorPicker(true)}>
                                                <div style={{ width: 24, height: 24, padding: 3 }}>
                                                    <div style={{ backgroundColor: 'white', width: 18, height: 18, padding: 3, borderRadius: 4 }}>
                                                        <div className={classes.pickerSquare} style={{ backgroundColor: profile.menuBackgroundColor || _DEFAULT_COLOR, }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </IconButton>
                                            {
                                                menuBackgroundColorPicker &&
                                                <div className={classes.popover}>
                                                    <div className={classes.cover} onClick={() => setMenuBackgroundColorPicker(false)} />
                                                    <ChromePicker color={profile.menuBackgroundColor} onChange={color => props.onChangeField("menuBackgroundColor", rgbaToString(color.rgb))} />
                                                </div>
                                            }
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                    </>
                )}
                <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="chatColor">{t("chatColor")}</InputLabel>
                    <Input
                        id="chatColor"
                        name="chatColor"
                        value={profile.chatColor || _DEFAULT_COLOR}
                        onChange={handelChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton size="small" onClick={() => setChatColorPicker(true)}>
                                    <div style={{ width: 24, height: 24, padding: 3 }}>
                                        <div style={{ backgroundColor: 'white', width: 18, height: 18, padding: 3, borderRadius: 4 }}>
                                            <div className={classes.pickerSquare} style={{ backgroundColor: profile.chatColor || _DEFAULT_COLOR, }}>
                                            </div>
                                        </div>
                                    </div>
                                </IconButton>
                                {
                                    chatColorPicker &&
                                    <div className={classes.popover}>
                                        <div className={classes.cover} onClick={() => setChatColorPicker(false)} />
                                        <ChromePicker color={profile.chatColor} onChange={color => props.onChangeField("chatColor", rgbaToString(color.rgb))} />
                                    </div>
                                }
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                    <FormControlLabel
                        label={t("addOptionalField") as any}
                        control={
                            <Switch
                                checked={profile.useOptional}
                                onChange={handleUseOptional}
                                color="primary"
                            />
                        }
                    />
                    {profile.useOptional && (
                        <>
                            <TextField
                                name="optionalLabel"
                                value={profile.optionalLabel || ''}
                                onChange={handelChange}
                                label={t("optionalLabel")}
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                name="optionalPlaceholder"
                                value={profile.optionalPlaceholder || ''}
                                onChange={handelChange}
                                label={t("optionalPlaceholder")}
                            />
                        </>
                    )}

                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                    <FormControl required fullWidth margin="normal" style={{ marginBottom: 16 }}>
                        <InputLabel htmlFor="chatBadge">{t("chatBadge")}</InputLabel>
                        <Select
                            id="chatBadge"
                            name="chatBadge"
                            value={chatBadge}
                            onChange={handleChatBadge}
                            variant="standard"
                        >
                            <MenuItem value={ChatBadge.icon}>{t("icon")}</MenuItem>
                            <MenuItem value={ChatBadge.image}>{t("image")}</MenuItem>
                            <MenuItem value={ChatBadge.text}>{t("text")}</MenuItem>
                        </Select>
                    </FormControl>
                    {
                        chatBadge === ChatBadge.image ?
                            (
                                <>
                                    <span className={classes.imageHelperText}>{t("chatImageDesc")}</span>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                                        <FormControl style={{ marginRight: 16, width: 140 }}>
                                            <InputLabel htmlFor="backgroundColor">{t("backgroundColor")}</InputLabel>
                                            <Input
                                                id="backgroundColor"
                                                name="backgroundColor"
                                                value={profile.backgroundColor || _DEFAULT_COLOR}
                                                onChange={handelChange}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton size="small" onClick={() => setBackgroundColorPicker(true)}>
                                                            <div style={{ width: 24, height: 24, padding: 3 }}>
                                                                <div style={{ backgroundColor: 'white', width: 18, height: 18, padding: 3, borderRadius: 4 }}>
                                                                    <div className={classes.pickerSquare} style={{ backgroundColor: profile.backgroundColor || _DEFAULT_COLOR, }}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </IconButton>
                                                        {
                                                            backgroundColorPicker &&
                                                            <div className={classes.popover2}>
                                                                <div className={classes.cover} onClick={() => setBackgroundColorPicker(false)} />
                                                                <ChromePicker opa color={profile.backgroundColor} onChange={color => props.onChangeField("backgroundColor", rgbaToString(color.rgb))} />
                                                            </div>
                                                        }
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <label htmlFor="iconImage">
                                            <Button variant="contained" color="primary" component="span" style={{ minWidth: 140 }}>
                                                {profile.iconImage || profile.iconImageUrl ? t("changeImage") : t("uploadImage")}
                                            </Button>
                                        </label>
                                        <input
                                            accept="image/x-png,image/svg+xml"
                                            type="file"
                                            name="iconImage"
                                            onChange={handleChatImage}
                                            id="iconImage"
                                            style={{ display: 'none' }} />

                                        {(profile.iconImage || profile.iconImageUrl) && (
                                            <>
                                                <div className={classes.imagePreview} style={{ backgroundColor: profile.backgroundColor || _DEFAULT_COLOR }}>
                                                    {profile.iconImage ?
                                                        (
                                                            <img alt="icon" style={{ width: 60 }} src={URL.createObjectURL(profile.iconImage)} />
                                                        )
                                                        :
                                                        (
                                                            <img alt="icon" style={{ width: 60 }} src={profile.iconImageUrl} />
                                                        )}
                                                </div>
                                                {profile.iconImage &&
                                                    <IconButton
                                                        style={{ padding: 0, alignSelf: 'flex-start', marginLeft: 24, marginTop: 8 }}
                                                        aria-label="Remove"
                                                        color="inherit"
                                                        onClick={() => props.onChangeField('iconImage', null)}
                                                        size="large">
                                                        <CloseIcon fontSize='large' style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                                                    </IconButton>
                                                }
                                            </>
                                        )}
                                    </div>
                                </>
                            )
                            :
                            (
                                <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                                    {
                                        profile.useIcon &&
                                        <span className={classes.iconInfo}>{t("clickToChangeIcon")}</span>
                                    }
                                    <div
                                        style={{ cursor: profile.useIcon ? 'pointer' : '', backgroundColor: profile.chatColor || _DEFAULT_COLOR }}
                                        className={profile.useIcon ? classes.startIcon : classes.startIconWithText}
                                        onClick={e => { if (profile.useIcon) setIconMenuAnchor(e.currentTarget) }}
                                    >
                                        {
                                            profile.useIcon ?
                                                <img alt="icon" style={{ width: 20, height: 20 }} src={`/chat-icons/${profile.icon}-white.svg`} /> :
                                                <span>{profile.labels.iconText}</span>
                                        }
                                    </div>
                                    <Menu
                                        id="icon-menu"
                                        anchorEl={iconMenuAnchor}
                                        open={Boolean(iconMenuAnchor)}
                                        onClose={handelCloseIconMenu}
                                        keepMounted
                                    >
                                        {
                                            [1, 2, 3, 4, 5].map((n, i) => (
                                                <MenuItem
                                                    key={i}
                                                    style={{ display: 'inline-flex' }}
                                                    onClick={() => { handelCloseIconMenu(); props.onChangeField("icon", `0${n}`) }}
                                                >
                                                    <img alt="icon" style={{ width: 40, height: 40 }} src={`/chat-icons/0${n}-black.svg`} />
                                                </MenuItem>
                                            ))
                                        }
                                    </Menu>
                                </div>
                            )
                    }


                </div>

                <Dialog
                    open={showLangDialog}
                    onClose={() => setShowLangDialog(false)}
                >
                    <DialogTitle>{t("labelsTranslations")}</DialogTitle>
                    <DialogContent>
                        <FormControl required fullWidth margin="normal">
                            <InputLabel htmlFor="iconText">{t("label_iconText")}</InputLabel>
                            <Input
                                name="iconText"
                                autoFocus
                                value={profileFormLabels.iconText}
                                onChange={handelChangeLabels}
                            />
                        </FormControl>
                        <FormControl required fullWidth margin="normal">
                            <InputLabel htmlFor="formTitle">{t("label_formTitle")}</InputLabel>
                            <Input
                                name="formTitle"
                                value={profileFormLabels.formTitle}
                                onChange={handelChangeLabels}
                            />
                        </FormControl>
                        <FormControl required fullWidth margin="normal">
                            <InputLabel htmlFor="name">{t("label_name")}</InputLabel>
                            <Input
                                name="name"
                                value={profileFormLabels.name}
                                onChange={handelChangeLabels}
                            />
                        </FormControl>
                        <FormControl required fullWidth margin="normal">
                            <InputLabel htmlFor="namePlaceholder">{t("label_namePlaceholder")}</InputLabel>
                            <Input
                                name="namePlaceholder"
                                value={profileFormLabels.namePlaceholder}
                                onChange={handelChangeLabels}
                            />
                        </FormControl>
                        <FormControl required fullWidth margin="normal">
                            <InputLabel htmlFor="message">{t("label_message")}</InputLabel>
                            <Input
                                name="message"
                                value={profileFormLabels.message}
                                onChange={handelChangeLabels}
                            />
                        </FormControl>
                        <FormControl required fullWidth margin="normal">
                            <InputLabel htmlFor="messagePlaceholder">{t("label_messagePlaceholder")}</InputLabel>
                            <Input
                                name="messagePlaceholder"
                                value={profileFormLabels.messagePlaceholder}
                                onChange={handelChangeLabels}
                            />
                        </FormControl>
                        <FormControl required fullWidth margin="normal">
                            <InputLabel htmlFor="start">{t("label_start")}</InputLabel>
                            <Input
                                name="start"
                                value={profileFormLabels.start}
                                onChange={handelChangeLabels}
                            />
                        </FormControl>
                        <FormControl required fullWidth margin="normal">
                            <InputLabel htmlFor="send">{t("label_send")}</InputLabel>
                            <Input
                                name="send"
                                value={profileFormLabels.send}
                                onChange={handelChangeLabels}
                            />
                        </FormControl>
                        <FormControl required fullWidth margin="normal">
                            <InputLabel htmlFor="reset">{t("label_reset")}</InputLabel>
                            <Input
                                name="reset"
                                value={profileFormLabels.reset}
                                onChange={handelChangeLabels}
                            />
                        </FormControl>
                        <FormControl required fullWidth margin="normal">
                            <InputLabel htmlFor="reopen">{t("label_reopen")}</InputLabel>
                            <Input
                                name="reopen"
                                value={profileFormLabels.reopen}
                                onChange={handelChangeLabels}
                            />
                        </FormControl>
                        <FormControl required fullWidth margin="normal">
                            <InputLabel htmlFor="new">{t("label_new")}</InputLabel>
                            <Input
                                name="new"
                                value={profileFormLabels.new}
                                onChange={handelChangeLabels}
                            />
                        </FormControl>
                        <FormControl required fullWidth margin="normal">
                            <InputLabel htmlFor="sms">{t("label_sms")}</InputLabel>
                            <Input
                                id="sms"
                                name="sms"
                                value={profileFormLabels.sms}
                                onChange={handelChangeLabels}
                            />
                        </FormControl>
                        <FormControl required fullWidth margin="normal">
                            <InputLabel htmlFor="webChat">{t("label_webChat")}</InputLabel>
                            <Input
                                id="webChat"
                                name="webChat"
                                value={profileFormLabels.webChat}
                                onChange={handelChangeLabels}
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onResetLabels} color="secondary">
                            {t("reset")}
                        </Button>
                        <Button onClick={() => handleSubmitLabels()} color="primary">
                            {t("submit")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancel} color="primary">
                    {t("cancel")}
                </Button>
                <Button onClick={props.onSubmit} color="primary">
                    {t("submit")}
                </Button>
            </DialogActions>
        </Dialog >
    );
}

export default WebchatProfileDialog;
