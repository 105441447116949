import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from '../../store/PrivateRoute/actionCreators';
import { Actions, State } from '../../store/PrivateRoute/types';
import { Route, Redirect } from 'react-router';
import { userIsAuthenticated } from '../../helpers/auth';
import Splash from '../Splash';

export interface Props {
    path?: string,
    component?: any,
    exact?: boolean
}

const PrivateRoute = (props: Props & Actions & State) => {

    const { component: Component, userInfo, ...rest } = props;

    return <Route
        {...rest}
        render={routeProps => {
            if (userIsAuthenticated() === true) {

                if (!userInfo) {
                    props.loadUserInfo();
                    return <Splash />;
                }

                return <Component {...routeProps} />;
            }

            props.clearUserInfo();
            console.log(`PrivateRoute: unauthenticated user.`);
            return <Redirect to={{ pathname: `/login`, state: { from: routeProps.location } }} />;
        }}
    />;
}

export default connect<any, any, Props>(
    (state: any) => state.privateRoute,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(PrivateRoute)