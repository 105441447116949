import { State, actionTypes, initialState, ReducerAction } from './types';
import { compareValues } from '../../helpers/utils'
import { produce } from 'immer'

export default (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.LOADED:
                newState.users = action.users;
                break;
            case actionTypes.UPDATED_USER_STATUS:
                newState.users.find(u => u.id === action.userId).status = action.status;
                break;
            case actionTypes.SORT_USERS:
                newState.sortKey = action.sortKey;
                newState.sortDir = action.sortDir;

                let secondaryKey = 'firstName';
                if (action.sortKey === secondaryKey) {
                    secondaryKey = 'lastName';
                }

                newState.users.sort(compareValues(action.sortKey, action.sortDir, secondaryKey));
                break;
        }
    })
}