import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Layout from "../Layout/Layout";
import { Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { State, Actions } from "../../store/ChatAccounts/types";
import actionCreators from "../../store/ChatAccounts/actionCreators";
import FacebookSetup from "./FacebookSetup";
import PhoneSetup from "./PhoneSetup";
import WebchatSetup from "./WebchatSetup";
import { hasPermission, PermissionKey } from "../../helpers/auth";
import { phonePlatform } from "../../models";
import { setPageTitle } from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import InstagramSetup from "./InstagramSetup";

interface Props { }

const ChatAccounts = (props: State & Actions & Props) => {
    const { t } = useTranslation();

    const [expanded, setExpanded] = useState('');

    const checkExpanded= () => {
        if (hasPermission(PermissionKey.SetupFacebook)) setExpanded('Facebook')
        else if (hasPermission(PermissionKey.SetupPhoneNumbers)) setExpanded('SMS')
        else if (hasPermission(PermissionKey.SetupWebChat)) setExpanded('Webchat')
    }

    useEffect(() => {
        setPageTitle(t, "chatAccounts");

        checkExpanded();
    }, [])

    const handleExpanded = (panel: string) => (event, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : '');
    };

    const { webchatDialogIsOpen, profileForm, profiles, profileFormLabels } = props;

    return (
        <Layout title={t("messagingAccounts")}>
            {hasPermission(PermissionKey.SetupFacebook) && (
                <Accordion expanded={expanded === 'Facebook'} onChange={handleExpanded('Facebook')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="facebook content"
                        id="facebook-header"
                    >
                        <Typography>
                            {t("facebookPages")}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FacebookSetup
                            pages={props.facebookPages}
                            facebookInit={props.facebookInit}
                            facebookLogin={props.facebookLogin}
                            getSubscribedPages={props.getFacebookSubscribedPages}
                            deletePage={props.deleteFacebookPage}
                        />
                    </AccordionDetails>
                </Accordion>
            )}

            {false && (
                <Accordion expanded={expanded === 'Instagram'} onChange={handleExpanded('Instagram')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="instagram content"
                        id="instagram-header"
                    >
                        <Typography>
                            {t("instagramPages")}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InstagramSetup
                            pages={props.instagramPages}
                            instagramLogin={props.instagramLogin}
                            getSubscribedPages={props.getInstagramSubscribedPages}
                            deletePage={props.deleteInstagramPage}
                        />
                    </AccordionDetails>
                </Accordion>
            )}

            {hasPermission(PermissionKey.SetupPhoneNumbers) && (
                <Accordion expanded={expanded === 'SMS'} onChange={handleExpanded('SMS')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="phone-content"
                        id="phone-header"
                    >
                        <Typography>
                            {t("phoneNumbers")}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PhoneSetup
                            phonePlatform={phonePlatform.bandwidth}
                            numbers={props.numbers}
                            getOwnedNumbers={props.getOwnedNumbers}
                            deleteNumber={props.deleteNumber}
                            buyNumber={props.buyNumber}
                            updateNumber={props.updateNumber}
                        />
                    </AccordionDetails>
                </Accordion>
            )}

            {hasPermission(PermissionKey.SetupWebChat) && (
                <Accordion expanded={expanded === 'Webchat'} onChange={handleExpanded('Webchat')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="webchat-content"
                        id="webchat-header"
                    >
                        <Typography>
                            {t("webchatProfiles")}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <WebchatSetup
                            loadWebchatProfiles={props.loadWebchatProfiles}
                            profiles={profiles}
                            profileForm={profileForm}
                            profileFormLabels={profileFormLabels}
                            webchatDialogIsOpen={webchatDialogIsOpen}
                            onAddWebchatProfile={props.addWebchatProfile}
                            onEditWebchatProfile={props.editWebchatProfile}
                            onCloseWebchatDialog={props.closeWebchatDialog}
                            onChangeField={props.webchatChangeField}
                            onSubmitWebchatProfie={props.submitWebchatProfile}
                            onDeleteProfile={props.deleteWebchatProfile}
                            onResetLabels={props.resetWebchatProfileLabels}
                            onSubmitLabels={props.submitWebchatProfileLabels}
                            phoneNumbers={props.numbers}
                        />
                    </AccordionDetails>
                </Accordion>
            )}
        </Layout>
    );
}

const ChatAccountsWithStore = connect(
    (state: any) => state.chatAccounts,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ChatAccounts);

export default ChatAccountsWithStore;
