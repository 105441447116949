import * as React from 'react';
import { IconButton, Snackbar, SnackbarContent, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import { orange, green } from '@mui/material/colors';

export enum alertTypes {
    error = 1,
    warning = 2,
    success = 3
}

interface Props {
    message: string,
    open: boolean,
    type: alertTypes,
    buttons?: any[],
    onClose?: () => void
}

const Alert = (props: Props) => {
    const { open, message, type } = props;

    let backgroundColor = '',
        icon: JSX.Element = null;

    switch (type) {
        case alertTypes.error:
            backgroundColor = 'error.dark'
            icon = <ErrorIcon sx={{ fontSize: 20, opacity: 0.9, mr: 1 }} />
            break;
        case alertTypes.warning:
            backgroundColor = orange[500];
            icon = <WarningIcon sx={{ fontSize: 20, opacity: 0.9, mr: 1 }} />
            break;
        case alertTypes.success:
        default:
            backgroundColor = green[500];
            icon = <SuccessIcon sx={{ fontSize: 20, opacity: 0.9, mr: 1 }} />
            break;
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            open={open}
            autoHideDuration={4000}
            onClose={props.onClose}
        >
            <SnackbarContent
                sx={{ backgroundColor }}
                message={
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                        {icon}
                        <span>{message}</span>
                    </Typography>}
                action={[
                    props.buttons,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={props.onClose}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                ]}
            />
        </Snackbar>
    );
}

export default Alert;
