import { State, actionTypes, initialState, ReducerAction } from './types';
import { compareValues } from '../../helpers/utils'
import { produce } from 'immer'

export default (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.LOADED:
                newState.invoices = action.invoices;
                break
            case actionTypes.SELECTED_DATE:
                newState.dateFilter = action.dateFilter;
                break;
            case actionTypes.SORT_INVOICES:
                newState.sortKey = action.sortKey;
                newState.sortDir = action.sortDir;

                let secondaryKey = 'invoiceNumber';
                if (action.sortKey === secondaryKey) {
                    secondaryKey = 'billDate';
                }

                newState.invoices.sort(compareValues(action.sortKey, action.sortDir, secondaryKey))
                break;
            case actionTypes.INVOICE_LOADED:
                newState.invoice = newState.invoices.find(i => i.id === action.invoiceId);
                newState.company = action.company;
                newState.discounts = action.discounts;
                newState.taxes = action.taxes;
                newState.plan = action.plan;
                break;
        }
    })
}