import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import thunk from 'redux-thunk';
import login from './Login/reducer';
import privateRoute from './PrivateRoute/reducer';
import layout from './Layout/reducer';
import forgotPassword from './ForgotPassword/reducer';
import createPassword from './CreatePassword/reducer';
import messages from './Messages/reducer';
import manageUser from './ManageUser/reducer';
import updateUser from './UpdateUser/reducer';
import profile from './Profile/reducer';
import dashboard from './Dashboard/reducer';
import uploadPowerBIdoc from './UploadPowerBIDoc/reducer';
import buyBandwidthNumber from './BuyBandwidthNumber/reducer';
import chatAccounts from './ChatAccounts/reducer';
import subscription from './Subscription/reducer';
import signUp from './SignUp/reducer';
import addAutoReplies from './AddAutoReplies/reducer';
import manageAutoReplies from './ManageAutoReplies/reducer';
import invoices from './Invoices/reducer';
import messagingSettings from './MessagingSettings/reducer';
import manageKnowledge from './ManageKnowledge/reducer';
import templateContent from './TemplateContent/reducer';
import analytics from './Analytics/reducer';
import companyProfile from './CompanyProfile/reducer';

export const history = createBrowserHistory();

export default function configureStore(preloadedState?: any) {
    const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const reducers = {
        buyBandwidthNumber,
        chatAccounts,
        createPassword,
        dashboard,
        forgotPassword,
        layout,
        login,
        manageUser,
        messages,
        privateRoute,
        router: connectRouter(history),
        signUp,
        subscription,
        updateUser,
        profile,
        uploadPowerBIdoc,
        addAutoReplies,
        manageAutoReplies,
        messagingSettings,
        invoices,
        manageKnowledge,
        templateContent,
        analytics,
        companyProfile
    };

    const store = createStore(
        combineReducers(reducers),
        preloadedState,
        composeEnhancer(
            applyMiddleware(
                routerMiddleware(history),
                thunk
            )
        )
    );

    return store;
}
