import React from 'react';
import { BarDatum, ResponsiveBar } from '@nivo/bar'

export type Props = {
    data: BarDatum[],
    indexBy: string,
    xLabel?: string,
    yLabel?: string,
    layout?: "horizontal" | "vertical",
    colorScheme?: "nivo" | "category10" | "accent" | "dark2" | "paired" | "pastel1" | "pastel2" | "set1" | "set2" | "set3" | "brown_blueGreen" | "purpleRed_green" | "pink_yellowGreen" | "purple_orange" |
    "red_blue" | "red_grey" | "red_yellow_blue" | "red_yellow_green" | "spectral"
};

const BarGraph = (props: Props) => {

    const { data, indexBy, xLabel, yLabel, layout = "horizontal", colorScheme = "nivo" } = props;

    if(!data || data.length === 0) return null;

    const keys = Object.keys(data[0]).filter(key => key !== indexBy);

    return (
        <ResponsiveBar
            data={data}
            layout={layout}
            keys={keys}
            indexBy={indexBy}
            theme={{
                fontSize: 12,
            }}
            enableLabel={false}
            margin={{ top: 8, right: 12, bottom: 36, left: 56 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: colorScheme }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: xLabel,
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: yLabel,
                legendPosition: 'middle',
                legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            animate={true}
        />
    )
}

export default BarGraph;