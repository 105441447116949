import { FacebookPage, phonePlatform, PhoneParameter, WebchatProfile, WebchatProfileLabels } from '../../models';
import { PermissionKey } from '../../helpers/auth';
import { ActionCreatorsMapObject } from 'redux';
import { ReducerAction as layoutReducerAction } from '../Layout/types'

export enum actionTypes {
    CHANGE_FIELD = "CHAT_CHANGE_FIELD",
    FACEBOOK_PAGE_DELETED = "CHAT_FACEBOOK_PAGE_DELETED",
    INSTAGRAM_PAGE_DELETED = "CHAT_INSTAGRAM_PAGE_DELETED",
    NUMBER_DELETED = "CHAT_NUMBER_DELETED",
    CLOSE_WEBCHAT_DIALOG = 'CHAT_CLOSE_WEBCHAT_DIALOG',
    ADD_WEBCHAT_OPEN_DIALOG = 'CHAT_ADD_WEBCHAT_OPEN_DIALOG',
    EDIT_WEBCHAT_OPEN_DIALOG = 'CHAT_EDIT_WEBCHAT_OPEN_DIALOG',
    WEBCHAT_CHANGE_FIELD = 'CHAT_WEBCHAT_CHANGE_FIELD',
    SUBMIT_WEBCHAT_PROFILE = 'CHAT_SUBMIT_WEBCHAT_PROFILE',
    WEBCHAT_PROFILE_DELETED = 'CHAT_WEBCHAT_PROFILE_DELETED',
    WEBCHAT_PROFILE_RESET_LABELS = 'CHAT_WEBCHAT_PROFILE_RESET_LABELS',
    WEBCHAT_PROFILE_SUBMIT_LABELS = 'CHAT_WEBCHAT_PROFILE_SUBMIT_LABELS',
}

export interface ReducerAction {
    type: actionTypes,
    name?: keyof State,
    pageId?: string,
    accountId?: string,
    number?: PhoneParameter,
    profile?: WebchatProfile,
    webchatName?: keyof WebchatProfile,
    value?: any,
    profileId?: number
}

export type Dispatch = (action: ReducerAction | layoutReducerAction) => void

export interface State {
    facebookPages: FacebookPage[],
    instagramPages: FacebookPage[],

    phonePlatform: phonePlatform,
    numbers: PhoneParameter[],

    profiles: WebchatProfile[],
    profileForm: WebchatProfile,
    profileFormLabels: WebchatProfileLabels,
    webchatDialogIsOpen: boolean,
}

export function write<K extends keyof State>(arg: State, key: K, value: State[K]): void {
    arg[key] = value;
};

export const initialState: State = {
    facebookPages: [],
    instagramPages: [],

    phonePlatform: phonePlatform.bandwidth,
    numbers: [],

    profiles: [],
    profileForm: null,
    profileFormLabels: null,
    webchatDialogIsOpen: false,
};

export interface Actions extends ActionCreatorsMapObject {
    facebookInit: () => void,
    facebookLogin: () => (dispatch: Dispatch, getState: any) => void,
    getFacebookSubscribedPages: () => (dispatch: Dispatch, getState: any) => void,
    deleteFacebookPage: (pageId: string) => (dispatch: Dispatch, getState: any) => void,

    instagramLogin: () => (dispatch: Dispatch, getState: any) => void,
    getInstagramSubscribedPages: () => (dispatch: Dispatch, getState: any) => void,
    deleteInstagramPage: (accountId: string) => (dispatch: Dispatch, getState: any) => void,

    getOwnedNumbers: (platform: phonePlatform) => (dispatch: Dispatch, getState: any) => void,
    deleteNumber: (platform: phonePlatform, number: PhoneParameter) => (dispatch: Dispatch, getState: any) => void,
    buyNumber: (platform: phonePlatform) => void,
    updateNumber: (id: number, number: string, displayName: string) => (dispatch: Dispatch, getState: any) => void,

    loadWebchatProfiles: () => (dispatch: Dispatch, getState: any) => void,
    addWebchatProfile: () => ReducerAction,
    closeWebchatDialog: () => ReducerAction,
    webchatChangeField: (name: keyof WebchatProfile, value: string) => ReducerAction,
    submitWebchatProfile: () => (dispatch: Dispatch, getState: any) => void,
    editWebchatProfile: (profile: WebchatProfile) => ReducerAction,
    deleteWebchatProfile: (profile: WebchatProfile) => (dispatch: Dispatch, getState: any) => void,
    resetWebchatProfileLabels: () => ReducerAction,
    submitWebchatProfileLabels: () => ReducerAction,
}

export const _ENGLISH_LABELS: WebchatProfileLabels = {
    iconText: 'Start chat',
    name: 'Name',
    namePlaceholder: 'e.g. John',
    message: 'Message',
    messagePlaceholder: 'Enter your message here...',
    start: 'Start',
    send: 'Send',
    formTitle: 'How can I help you?',
    reset: 'Reset',
    reopen: "Reopen Ticket",
    new: "New Ticket",
    sms: 'SMS',
    webChat: 'Webchat'
}

export const _FRENCH_LABELS: WebchatProfileLabels = {
    iconText: 'Demarrer la chat',
    name: 'Nom',
    namePlaceholder: 'e.g. John',
    message: 'Message',
    messagePlaceholder: 'Ecrivez votre message ici...',
    start: 'Commencer',
    send: 'Envoyer',
    formTitle: 'Comment puis-je vous aider?',
    reset: 'Reinitialiser',
    reopen: "Rouvrir le ticket",
    new: "Nouveau ticket",
    sms: 'SMS',
    webChat: 'Webchat'
}

export const _CUSTOM_LABELS: WebchatProfileLabels = {
    iconText: '',
    name: '',
    namePlaceholder: '',
    message: '',
    messagePlaceholder: '',
    start: '',
    send: '',
    formTitle: '',
    reset: '',
    sms: '',
    webChat: ''
}