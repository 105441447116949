import { State, actionTypes, initialState, ReducerAction, write, writeCompany } from './types'
import { produce } from 'immer'

export default (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.CHANGE_FIELD:
                write(newState, action.name, action.value);
                break;
            case actionTypes.CHANGE_COMPANY_FIELD:
                writeCompany(newState.company, action.companyName, action.value);
                break;
        }
    })
}