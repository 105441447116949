import React, { useEffect, useState } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from '../../store/ManageAutoReplies/actionCreators';
import { State, Actions } from '../../store/ManageAutoReplies/types';
import Layout from '../Layout/Layout';
import RouterLink from '../common/RouterLink';
import { Theme } from '@mui/material/styles';
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Switch, Tooltip } from "@mui/material";
import { Add as AddIcon, Edit as EditIcon, DeleteOutline as DeleteIcon } from '@mui/icons-material';
import { useTranslation, withTranslation } from "react-i18next";
import { PermissionKey } from "../../helpers/auth";
import { replyType } from '../../models';
import { isMobileMode, truncate, setPageTitle } from "../../helpers/utils";
import { getLongDate } from "../../helpers/date";
import Confirm from '../common/ConfirmModal'
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: isMobileMode() ? 400 : 800,
    },
    button: {
        minWidth: 50,
        marginTop: -16,
    },
}));

interface Props {

}

const ManageAutoReplies = (props: State & Actions & Props) => {
    const { t } = useTranslation();

    const { classes } = useStyles();

    const { autoReplies, deleteReply, loadAutoReplies } = props;

    useEffect(() => {
        loadAutoReplies();
    }, []);

    const getReplyTypeLabel = (type: replyType) => {
        switch (type) {
            case replyType.Welcome:
                return t("welcome")
            case replyType.Vacation:
                return t("vacation")
            case replyType.OutsideOfficeHours:
                return t("outsideOfficeHours")
            default:
                return ""
        }
    }

    const [deleteVisible, setDeleteVisible] = useState(false);
    const [replyId, setReplyId] = useState(0);

    const showDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const replyId = Number.parseInt(e.currentTarget.value);
        setDeleteVisible(true);
        setReplyId(replyId);
    }

    const handleDelete = (confirmed: boolean) => {
        setDeleteVisible(false);
        if (confirmed) deleteReply(replyId);
    }

    setPageTitle(t, "manageAutoReplies");

    return (
        <Layout
            title={t("manageAutoReplies")}
            permissionKey={PermissionKey.SetupPhoneNumbers}
        >
            <RouterLink to="./AddAutoReplies">
                <Button variant="contained" color="primary">
                    <AddIcon />
                    {t("newAutoReply")}
                </Button>
            </RouterLink>
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">{t("message")}</TableCell>
                            <TableCell align="left">{t("type")}</TableCell>
                            <TableCell align="left">{t("endDate")}</TableCell>
                            <TableCell align="center">{t("active")}</TableCell>
                            <TableCell align="center" width="145px"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {autoReplies.map(reply => (
                            <TableRow key={reply.autoResponseId}>
                                <TableCell align="left">{truncate(reply.replyMessage, 200)}</TableCell>
                                <TableCell align="left">{getReplyTypeLabel(reply.selectedType)}</TableCell>
                                <TableCell align="left">{getLongDate(t, reply.dateRange?.end)}</TableCell>
                                <TableCell align="center">
                                    <Switch
                                        size="small"
                                        checked={reply.active}
                                        onClick={() => props.updateReplyStatus(reply.autoResponseId)}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <RouterLink to="./AddAutoReplies" onClick={() => props.editReply(reply.autoResponseId)}>
                                        <Tooltip title={t('edit')}>
                                            <EditIcon color="primary" />
                                        </Tooltip>
                                    </RouterLink>
                                    <Tooltip title={t("delete")}>
                                        <Button value={reply.autoResponseId} onClick={showDelete} className={classes.button}>
                                            <DeleteIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>

            {deleteVisible && <Confirm onClose={handleDelete} color='error' />}
        </Layout>
    );
}

const manageAutoRepliesWithStore = connect(
    (state: any) => state.manageAutoReplies,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ManageAutoReplies);

export default withTranslation()(manageAutoRepliesWithStore);