import * as React from "react";
import { Grid, Container } from "@mui/material";
import { ProductPlan, PlanSubsciption, Discount, Tax, PlanSubscriptionStatus } from "../../../models";
import CurrentPlan from "./CurrentPlan";
import CreditCard from "./CreditCard";
import Receipt from "./Receipt/Receipt";
import PaymentInfoItem from "./PaymentInfoItem";
import { useTranslation } from "react-i18next";

interface Props {
    appliedDiscounts: Discount[],
    creditCardSetupFinished: boolean,
    creditCardSubmitted: boolean,
    currentCard: string,
    prevPlan: PlanSubsciption,
    currentPlan: PlanSubsciption,
    cancellable: boolean,
    nextBillingDate: Date,
    monerisProfileId: string,
    monerisTestMode: boolean,
    onChangePlan: () => void,
    onError: (message: string) => void,
    onPay: () => void,
    cancelPlan: (planId: number, message: string) => void,
    reactivatePlan: (planId: number) => void,
    onPromoCodeChange: (promoCode) => void,
    onSubmitCreditCardRequest: () => void,
    onSubmitPromoCode: () => void,
    onSubmitToken: (tokan: string) => void,
    productPlans: ProductPlan[],
    promoCode: string,
    taxes: Tax[],
}


const PaymentInfo = (props: Props) => {
    const { productPlans, prevPlan, currentPlan, currentCard, promoCode, appliedDiscounts, creditCardSetupFinished,
        creditCardSubmitted, monerisProfileId, monerisTestMode, taxes } = props;

    const { t } = useTranslation();

    const showPayButton = currentCard && currentPlan && currentPlan.status === PlanSubscriptionStatus.Pending;
    
    return (
        <Container maxWidth="lg" style={{ padding: 0 }}>
            <Grid container>
                <Grid item xs>
                    <PaymentInfoItem title={t("planDetail")}>
                        <CurrentPlan
                            productPlans={productPlans}
                            currentPlan={currentPlan}
                            currentCard={currentCard}
                            nextBillingDate={props.nextBillingDate}
                            onChangePlan={props.onChangePlan}
                            cancelPlan={props.cancelPlan}
                            reactivatePlan={props.reactivatePlan}
                            cancellable={props.cancellable}
                        />
                    </PaymentInfoItem>
                </Grid>
                <Grid item xs>
                    <PaymentInfoItem title={t("creditCardInfo")}>
                        <CreditCard
                            creditCardSetupFinished={creditCardSetupFinished}
                            creditCardSubmitted={creditCardSubmitted}
                            currentCard={currentCard}
                            monerisProfileId={monerisProfileId}
                            monerisTestMode={monerisTestMode}
                            onError={props.onError}
                            onSubmitCreditCardRequest={props.onSubmitCreditCardRequest}
                            onSubmitToken={props.onSubmitToken}
                        />
                    </PaymentInfoItem>
                </Grid>
                <Grid item xs>
                    <PaymentInfoItem title={t("monthlyReceipt")}>
                        <Receipt
                            appliedDiscounts={appliedDiscounts}
                            productPlans={productPlans}
                            promoCode={promoCode}
                            taxes={taxes}
                            showPayButton={showPayButton}
                            onPay={props.onPay}
                            onPromoCodeChange={props.onPromoCodeChange}
                            onSubmitPromoCode={props.onSubmitPromoCode}
                            nextBillingDate={props.nextBillingDate}
                            planStatus={currentPlan.status}
                            prevPlan={prevPlan}
                        />
                    </PaymentInfoItem>
                </Grid>
            </Grid>
        </Container>
    );
}

export default PaymentInfo;