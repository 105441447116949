import React, { useEffect, useState } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Theme } from '@mui/material/styles';
import { Paper, FormGroup, Grid, TextField, Typography, FormControl, Select, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Bar from "./Bar"
import Pie from "./Pie"
import HeatMap from "./HeatMap"
import actionCreators from '../../store/Analytics/actionCreators';
import { State, Actions } from '../../store/Analytics/types';
import Layout from '../Layout/Layout';
import NumberTile from './NumberTile';
import { useTranslation, withTranslation } from "react-i18next";
import { PermissionKey } from "../../helpers/auth";
import { groupBy, isMobileMode, setPageTitle, uniqueBy } from '../../helpers/utils';
import { addDays, getLocalTime } from '../../helpers/date';
import { PieDatum, DayMessages, endUserInfo, Topic } from '../../models';
import { makeStyles } from '../../styles/makeStyles'
import { DefaultHeatMapDatum, HeatMapSerie } from "@nivo/heatmap";
import { BarDatum } from "@nivo/bar";

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        maxWidth: isMobileMode() ? 800 : 1366,
        width: '100%',
        overflowX: isMobileMode() ? 'auto' : 'hidden',
        overflowY: isMobileMode() ? 'auto' : 'hidden',
        marginTop: theme.spacing(1),

    },
    filter: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        display: 'inline-block'
    },
    timepicker: {
        backgroundColor: '#FFF',
        width: 180,
        marginRight: theme.spacing(2),
        marginBottom: isMobileMode() ? theme.spacing(2) : 0,
    },
    timepicker2: {
        backgroundColor: '#FFF',
        width: 180,
        marginRight: theme.spacing(2),
    },
    gridItem: {
        padding: 0,
    },
    title: {
        padding: 12,
        fontSize: 18,
        fontWeight: 500,
        color: '#3d5170',
        paddingBottom: 16,
        paddingLeft: 24,
        borderBottom: '1px solid #e1e5eb'
    },
    beta: {
        fontSize: 16,
        fontStyle: "italic",
    },
    formControl: {
        display: 'block',
        marginBottom: 8
    },
    bar: {
        marginLeft: 12,
        marginRight: 8,
        height: 248
    },
    chartPaper: {
        margin: 2
    },
    loading: {
        minHeight: 150,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

interface SourceCount {
    title: string,
    url: string,
    count: number
}

interface Props {
}

const Analytics = (props: State & Actions & Props) => {
    const { t } = useTranslation();

    const { dateFilter, loadAnalytics, loadChatbotAnalytics } = props;

    const [dateSelect, setDateSelect] = useState(30);
    const [pickerVisible, setPickerVisible] = useState(false);

    useEffect(() => {
        loadAnalytics(dateFilter);
        //loadChatbotAnalytics(dateFilter);
    }, [dateFilter])

    const handleStartDate = (e) => {
        if (e !== null && !isNaN(e.getTime())) {
            props.handleDateSelect({
                start: e,
                end: dateFilter.end
            });
        }
    }

    const handleEndDate = (e) => {
        if (e !== null && !isNaN(e.getTime())) {
            props.handleDateSelect({
                start: dateFilter.start,
                end: e
            });
        }
    }

    const handleDateSelect = (e) => {
        const days = Number.parseInt(e.target.value)
        setDateSelect(days);

        if (days > 0) {
            setPickerVisible(false);

            props.handleDateSelect({
                start: addDays(new Date(), -1 * days),
                end: new Date()
            });
        } else {
            setPickerVisible(true);
        }
    }

    const getPlatformData = (smsCount: number, facebookCount: number, instagramCount: number, webchatCount: number) => {
        const { t } = props;

        const data: PieDatum[] = [{
            id: t("sms"),
            label: `${t("sms")} (${smsCount === null ? t("notSubscribed") : smsCount})`,
            value: smsCount,
        },
        {
            id: t("facebook"),
            label: `${t("facebook")} (${facebookCount === null ? t("notSubscribed") : facebookCount})`,
            value: facebookCount,
        },
        // {
        //     id: t("instagram"),
        //     label: `${t("instagram")} (${instagramCount === null ? t("notSubscribed") : instagramCount})`,
        //     value: instagramCount,
        // },
        {
            id: t("webchat"),
            label: `${t("webchat")} (${webchatCount === null ? t("notSubscribed") : webchatCount})`,
            value: webchatCount,
        }];

        return data;
    }

    const getHeatMapTime = (hours: number) => {
        if (hours === 0) return "12am";
        if (hours < 12) return hours + " ";
        if (hours === 12) return "12pm";

        return (hours - 12).toString();
    }

    const getDayMessageData = (dayMessages: DayMessages[]) => {
        const { t } = props;

        const labels: string[] = [t('sunday'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday')];

        const data: HeatMapSerie<DefaultHeatMapDatum, object>[] = [];

        dayMessages.forEach(d => {

            const dayCounts: HeatMapSerie<DefaultHeatMapDatum, object> = { id: labels[d.day].substr(0, 3), data: [] as DefaultHeatMapDatum[] };
            for (let i = 0; i < 24; i++) {
                const time = getHeatMapTime(i);
                dayCounts.data.push({x: time, y: d.messages.filter(m => getLocalTime(m.createdDate).getHours() === i).length})
            }

            data.push(dayCounts);
        });

        return data;
    }

    const getBrowserName = (b: string) => {
        if (b.includes("Internet Explorer"))
            return "IE";
        else
            return b;
    }

    const getSourceData = (endUserInfo: endUserInfo[]) => {
        const sourceInfo = endUserInfo.filter(s => s.sourceUrl).map(s => ({ title: s.sourceTitle, url: s.sourceUrl }));
        const uniqueSources = uniqueBy(sourceInfo, 'url');

        let counts: SourceCount[] = [];
        uniqueSources.forEach(source => {
            const count = sourceInfo.filter(s => s.url === source.url).length;
            counts.push({ title: source.title, url: source.url, count });
        })

        counts.sort((a, b) => b.count - a.count);

        if (counts.length > 8) counts = counts.slice(0, 8);


        return counts;
    }

    // const getTopicsData = (topicCounts: Topic[]) => {
    //     let topicInfo = topicCounts.map(t => ({ name: t.name, count: t.count[0] }));

    //     topicInfo.sort((a, b) => b.count - a.count);

    //     if (topicInfo.length > 8) topicInfo = topicInfo.slice(0, 8);

    //     return topicInfo;
    // }

    const getBrowserData = (endUserInfo: endUserInfo[]) => {
        const { t } = props;
        const browserInfo = endUserInfo.map(userInfo => userInfo.browser);
        const browsers = ["Firefox", "Chrome", "Safari", "Edge", "Opera", "Internet Explorer"];

        const counts: BarDatum[] = [];
        browsers.forEach(b => {
            const frequency = browserInfo.filter(bi => bi && bi.includes(b)).length;
            counts.push({ name: getBrowserName(b), [t("browser")]: frequency });
        })

        return counts;
    }

    const getDeviceData = (endUserInfo: endUserInfo[]) => {
        const { t } = props;

        const mobileCount = endUserInfo.filter(userInfo => userInfo.mobile).length
        const desktopCount = endUserInfo.length - mobileCount

        const counts: BarDatum[] = [{ name: "Mobile", [t("device")]: mobileCount }, { name: "Desktop", [t("device")]: desktopCount }];

        return counts;
    }

    const getLanguageData = (endUserInfo: endUserInfo[]) => {
        const { t } = props;

        const filtered = endUserInfo.filter(i => i.language !== null)
        const grouped: { [key: string]: any[] } = groupBy(filtered, 'language')

        const langs: BarDatum[] = [];
        for (const [language, items] of Object.entries(grouped)) {
            langs.push({ name: language, [t("language")]: items.length });
        }

        return langs;
    }

    const { classes } = useStyles();

    const { allMessagesCount, newTicketsCount, continuingTicketsCount, uniqueNewTicketsCount, smsCount, facebookCount, instagramCount, webchatCount, endUserInfo, dayMessages, topicCounts, chatbotLoading } = props;

    setPageTitle(t, "chatbotAnalytics");

    const platformData = getPlatformData(smsCount, facebookCount, instagramCount, webchatCount);
    const browserData = getBrowserData(endUserInfo);
    const deviceData = getDeviceData(endUserInfo);
    const langData = getLanguageData(endUserInfo);

    const dayMessageData = getDayMessageData(dayMessages);
    const dayMessageKeys = [
        "12am", "1 ", "2 ", "3 ", "4 ", "5 ", "6 ", "7 ", "8 ", "9 ", "10 ", "11 ",
        "12pm", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"
    ];

    const sourceData = getSourceData(endUserInfo);
    // const topicData = getTopicsData(topicCounts);

    return (
        <Layout
            title={t("chatbotAnalytics")}
            permissionKeys={[PermissionKey.SetupFacebook, PermissionKey.SetupPhoneNumbers, PermissionKey.SetupWebChat]}
        >
            <div style={{ maxWidth: 1366, margin: 'auto' }}>

                <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                        value={dateSelect}
                        onChange={handleDateSelect}
                        style={{ backgroundColor: '#FFF', }}
                    >
                        <MenuItem value={1}>{t("lastDay")}</MenuItem>
                        <MenuItem value={7}>{t("lastWeek")}</MenuItem>
                        <MenuItem value={30}>{t("lastMonth")}</MenuItem>
                        <MenuItem value={90}>{t("last3Months")}</MenuItem>
                        <MenuItem value={-1}>{t("custom")}</MenuItem>
                    </Select>
                </FormControl>

                {pickerVisible && <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormGroup className={classes.filter}>
                        <DatePicker
                            className={classes.timepicker}
                            format="dd MMM yyyy"
                            label={t("from")}
                            value={dateFilter.start}
                            onChange={handleStartDate}
                        />
                        <DatePicker
                            className={classes.timepicker}
                            format="dd MMM yyyy"
                            label={t("To")}
                            value={dateFilter.end}
                            onChange={handleEndDate}
                        />
                    </FormGroup>
                </LocalizationProvider>}

                <div className={classes.root}>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4} className={classes.gridItem}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className={classes.gridItem}>
                                    <NumberTile title={t("totalMessages")} value={allMessagesCount} />
                                </Grid>
                                <Grid item xs={12} className={classes.gridItem}>
                                    <NumberTile title={t("newTickets")} value={newTicketsCount} />
                                </Grid>
                                <Grid item xs={12} className={classes.gridItem}>
                                    <NumberTile title={t("uniqueTickets")} value={uniqueNewTicketsCount} />
                                </Grid>
                                <Grid item xs={12} className={classes.gridItem}>
                                    <NumberTile title={t("continuingTickets")} value={continuingTicketsCount} />
                                </Grid>
                                {endUserInfo.length > 0 &&
                                    <Grid item xs={12} className={classes.gridItem} >
                                        <Paper elevation={2} className={classes.chartPaper}>
                                            <Typography variant="h5" className={classes.title}>
                                                {t("browser")}
                                            </Typography>
                                            <div className={classes.bar}><Bar data={browserData} indexBy="name" colorScheme="accent" /></div>
                                        </Paper>
                                    </Grid>
                                }
                                <Grid item xs={12} className={classes.gridItem}>
                                    <Paper elevation={2} className={classes.chartPaper}>
                                        <Typography variant="h5" className={classes.title}>
                                            {t("sourcePages")}
                                        </Typography>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">
                                                        {t("url")}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {t("title")}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {t("frequency")}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {sourceData.map((s, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell align="left">{s.url}</TableCell>
                                                        <TableCell align="left">{s.title}</TableCell>
                                                        <TableCell align="left">{s.count}</TableCell>
                                                    </TableRow>
                                                )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                </Grid>
                                {/* <Grid item xs={12} className={classes.gridItem}>
                                    <Paper elevation={2} className={classes.chartPaper}>
                                        <Typography variant="h5" className={classes.title}>
                                            {t("topTopics")}
                                            <span className={classes.beta}> ({t("beta")})</span>
                                        </Typography>
                                        {!chatbotLoading ?
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            {t("topic")}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {t("frequency")}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {topicData.map((t, i) => (
                                                        <TableRow key={i}>
                                                            <TableCell align="left">{t.name}</TableCell>
                                                            <TableCell align="left">{t.count}</TableCell>
                                                        </TableRow>
                                                    )
                                                    )}
                                                </TableBody>
                                            </Table>
                                            :
                                            <div className={classes.loading}><CircularProgress thickness={2} size={50} /></div>
                                        }
                                    </Paper>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={8} className={classes.gridItem}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className={classes.gridItem}>
                                    <Paper elevation={2} style={{ paddingBottom: 38, height: 500 }}>
                                        <Typography variant="h5" className={classes.title}>
                                            {t("messageDistribution")}
                                        </Typography>
                                        <Pie data={platformData} />
                                    </Paper>
                                </Grid>
                                {endUserInfo.length > 0 &&
                                    <>
                                        <Grid item xs={12} md={6} className={classes.gridItem} >
                                            <Paper elevation={2} className={classes.chartPaper}>
                                                <Typography variant="h5" className={classes.title}>
                                                    {t("language")}
                                                </Typography>
                                                <div className={classes.bar}><Bar data={langData} indexBy="name" colorScheme="pastel1" /></div>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} md={6} className={classes.gridItem} >
                                            <Paper elevation={2} className={classes.chartPaper}>
                                                <Typography variant="h5" className={classes.title}>
                                                    {t("device")}
                                                </Typography>
                                                <div className={classes.bar}><Bar data={deviceData} indexBy="name" colorScheme="set3" /></div>
                                            </Paper>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                            <Grid item xs={12} className={classes.gridItem}>
                                <Paper elevation={2} style={{ marginTop: 18, height: 320, paddingBottom: 32 }}>
                                    <Typography variant="h5" className={classes.title}>
                                        {t("messageTiming")}
                                    </Typography>
                                    <HeatMap data={dayMessageData} indexBy="day" keys={dayMessageKeys} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Layout>
    );
}

const AnalyticsWithStore = connect(
    (state: any) => state.analytics,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Analytics);

export default withTranslation()(AnalyticsWithStore);