import * as React from 'react';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Avatar from '@mui/material/Avatar';
import MessageIcon from '@mui/icons-material/Message';
import WebChatIcon from '@mui/icons-material/Forum';
import { platforms } from '../../models';


interface Props {
    platform: platforms
}

const PlatformIcon = (props: Props) => {
    const { platform } = props;

    let icon: JSX.Element = null,
        iconColor = "";

    switch (platform) {
        case platforms.twilio:
            icon = <MessageIcon />;
            iconColor = "#e91e63";
            break;
        case platforms.bandwidth:
            icon = <MessageIcon />;
            iconColor = "#e91e63";
            break;
        case platforms.facebook:
            icon = <FacebookIcon />;
            iconColor = "#4267b2";
            break;
        case platforms.instagram:
            icon = <InstagramIcon />;
            iconColor = "#e95950";
            break;
        case platforms.webChat:
            icon = <WebChatIcon />;
            iconColor = "#a133ca";
            break;
        default:
            throw new Error(`Invalid platform ${platform}`);
    }

    return (
        <Avatar
            style={{ backgroundColor: iconColor, color: '#fff' }}
        >
            {icon}
        </Avatar>
    );
};

export default PlatformIcon;