import React, { useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { State, Actions } from '../store/Dashboard/types';
import actionCreators from '../store/Dashboard/actionCreators';
import { models } from 'powerbi-client';
import Layout from './Layout/Layout';
import { Typography } from '@mui/material';
import { Help as HelpIcon } from "@mui/icons-material";
import Tooltip from '@mui/material/Tooltip';
import { IEmbedConfiguration } from "embed";
import { PermissionKey } from "../helpers/auth";
import { useTranslation, withTranslation } from 'react-i18next';
import { setPageTitle } from "../helpers/utils";
import { makeStyles } from '../styles/makeStyles'

const useStyles = makeStyles()(() => ({
    dashbaord: {
        height: 'calc(100% - 70px)',
        "& > iframe": {
            border: 'none'
        }
    },
    help: {
        position: 'absolute',
        top: '10%',
        left: '94%'
    }
}));

interface Props {
    match: any
}

const Dashboard = (props: State & Actions & Props) => {
    const { t } = useTranslation();

    setPageTitle(t, "dashboards");

    const { classes } = useStyles();

    useEffect(() => {
        props.loadEmbedConfig(props.match.params.id);
    }, [props.match.params.id])

    const { dataSource, documentationUrl, accessToken, embedUrl, id, dashboardFinishedRendering, configLoaded } = props;

    const dashboardPlaceHolder = React.useRef<HTMLDivElement>();

    const dataSourceStr = dataSource ? ("Data Source: " + dataSource) : '';
    let helpButton = null;

    if (configLoaded && !dashboardFinishedRendering && dashboardPlaceHolder) {
        const layout = window.innerWidth <= 760 ? models.LayoutType.MobilePortrait : models.LayoutType.Master;

        if (documentationUrl !== null && documentationUrl !== "") {
            let urlSplitted = documentationUrl.split("/");
            let fileName = urlSplitted[urlSplitted.length - 1];

            helpButton = (
                <a className={classes.help} href={documentationUrl} target="_blank" rel="noopener noreferrer">
                    <Tooltip title={fileName} placement="bottom">
                        <HelpIcon />
                    </Tooltip>
                </a>
            );
        }

        let config: IEmbedConfiguration = {
            type: "report",
            tokenType: models.TokenType.Embed,
            accessToken: accessToken,
            embedUrl: embedUrl,
            id: id,
            pageView: "fitToWidth",
            settings: {
                filterPaneEnabled: true,
                navContentPaneEnabled: true,
                layoutType: layout
            }
        };

        window.powerbi.embed(dashboardPlaceHolder.current, config);
        props.dashboardRendered();
    }

    return (
        <Layout noPadding permissionKey={PermissionKey.ViewDashboards}>
            <div className={classes.dashbaord} ref={dashboardPlaceHolder}></div>

            <Typography variant='body2' sx={{ p: "8px", width: '100%', textAlign: 'center' }}>
                {dataSourceStr}
            </Typography>

            {helpButton}
        </Layout>
    );
}

const dashboardWithStore = connect(
    (state: any) => state.dashboard,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Dashboard);

export default withTranslation()(dashboardWithStore);