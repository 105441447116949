import ajax from '../../helpers/ajax'
import { Actions, actionTypes } from './types'
import { i18n } from "../../Localization/i18n"

const actionCreators: Actions = {
    sendResetPasswordEmail: (email, reCAPTCHA) => async (dispatch) => {
        if (email === '' || reCAPTCHA === '')
            return;

        dispatch({ type: actionTypes.LOADING });

        const response = await ajax.post(`User/sendResetPasswordEmail`, { email, reCAPTCHA });

        if (!response.ok) {
            let errorMessage = await ajax.errorMessage(response);

            dispatch({ type: actionTypes.SEND_EMAIL_FAILED, message: i18n.t("sendingEmailFailed") + (errorMessage || '') });
            return;
        }

        dispatch({ type: actionTypes.EMAIL_SENT, successMessage: i18n.t("emailSentSuccessfully") });
    },
    changeField: (name, value) => ({ type: actionTypes.CHANGE_FIELD, name, value }),
    closeMessage: () => ({ type: actionTypes.CLOSE_MESSAGE })
}

export default actionCreators