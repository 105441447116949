import React from 'react';
import { useTranslation } from "react-i18next";
import CompanyIcon from '@mui/icons-material/BusinessOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsApplicationsIcon from '@mui/icons-material/Settings';
import ProfileIcon from '@mui/icons-material/Person';
import ChatAccountsIcon from '@mui/icons-material/Chat';
import SubscriptionIcon from '@mui/icons-material/AttachMoney';
import MessagingSettingsIcon from '@mui/icons-material/PhonelinkSetup';
import { hasPermission, PermissionKey } from '../../helpers/auth';
import { IconButton, Menu as MUMenu, MenuItem, ListItemIcon } from '@mui/material';

interface MenuItemType {
    action: Function,
    text: string,
    icon: JSX.Element,
}

interface Props {
    mobileMode: boolean,
    picture: string,
    fullName: string,
    onGotoMenu: (menu: string) => void,
    onLogout: () => void
}

const Settings = (props: Props) => {
    const { t } = useTranslation();

    const { picture, fullName, mobileMode } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const gotoMenu = (menu: string) => {
        props.onGotoMenu(menu);
        handleClose();
    }

    let menuItems: MenuItemType[] = [],
        openMenuIcon = <SettingsApplicationsIcon />;

    if (hasPermission(PermissionKey.ManagerUsers))
        menuItems.push({ action: () => gotoMenu('/CompanyProfile'), text: t("companyProfile"), icon: <CompanyIcon /> });

    if (hasPermission(PermissionKey.ManagePayments))
        menuItems.push({ action: () => gotoMenu('/subscription'), text: t("subscriptions"), icon: <SubscriptionIcon /> });

    if (hasPermission(PermissionKey.SetupPhoneNumbers) || hasPermission(PermissionKey.SetupFacebook) || hasPermission(PermissionKey.SetupWebChat))
        menuItems.push({ action: () => gotoMenu('/ChatAccounts'), text: t("messagingAccounts"), icon: <ChatAccountsIcon /> });

    if (hasPermission(PermissionKey.ManageChatBot) || hasPermission(PermissionKey.ViewMessages))
        menuItems.push({ action: () => gotoMenu('/MessagingSettings'), text: t("messagingSettings"), icon: <MessagingSettingsIcon /> });

    if (mobileMode) {
        menuItems.push({ action: () => gotoMenu('/Profile'), text: fullName, icon: picture ? <img src={picture} style={{ width: 24, height: 24, borderRadius: '50%' }} /> : <ProfileIcon /> });
        menuItems.push({ action: () => props.onLogout(), text: t("logout"), icon: <ExitToAppIcon /> });

        openMenuIcon = <MoreVertIcon />;
    }

    return (
        <>
            <IconButton color="inherit" onClick={handleOpen} sx={{ ml: 1 }}>
                {openMenuIcon}
            </IconButton>

            <MUMenu
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {menuItems.map((menu, i) => (
                    <MenuItem key={i} onClick={() => menu.action()}>
                        <ListItemIcon sx={{ minWidth: 30 }}>
                            {menu.icon}
                        </ListItemIcon>
                        {menu.text}
                    </MenuItem>
                ))}
            </MUMenu>

            {!mobileMode &&
                <IconButton color="inherit" onClick={props.onLogout} sx={{ ml: 1 }}>
                    <ExitToAppIcon />
                </IconButton>}
        </>
    );
}

export default Settings;