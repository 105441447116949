import { produce } from 'immer'
import { actionTypes, State, initialState, ReducerAction } from './types'


export default (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.LOADING:
                newState.loading = true
                break
            case actionTypes.LOADED:
                newState.loading = false
                newState.reportArr = action.reportArr
                break
            case actionTypes.LOAD_FAILED:
                newState.loading = false
                newState.errMsg = action.errMsg
                break
            case actionTypes.REPORT_CHECKED_UNCHECKED:
                newState.selectedReportIdArr = action.selectedReportIdArr
                newState.isRequiredFilled = action.isRequiredFilled
                break
            case actionTypes.SET_FILE:
                newState.file = action.file
                newState.isRequiredFilled = action.isRequiredFilled
                break
            case actionTypes.EMPTY_FILE:
                newState.errMsg = action.errMsg
                newState.isRequiredFilled = action.isRequiredFilled
                break
            case actionTypes.UPLOADED_DOC:
                newState.successMsg = action.successMsg
                break
            case actionTypes.UPLOAD_DOC_FAILED:
                newState.loading = false
                newState.errMsg = action.errMsg
                break
            case actionTypes.CLOSE_SUCCESS:
                newState = initialState
                break
            case actionTypes.CLOSE_ERROR:
                newState.errMsg = ''
        }
    })
}