import { TFunction } from 'i18next';
import { isSafari, padNumber } from './utils';


export const MAX_DATE = new Date(253402318799999); //Dec 31 9999

export const getWeekDayName = (t: TFunction, dayNumber: number, short = false) => {
    let result = '';
    switch (dayNumber) {
        case 0: result = t("sunday"); break;
        case 1: result = t("monday"); break;
        case 2: result = t("tuesday"); break;
        case 3: result = t("wednesday"); break;
        case 4: result = t("thursday"); break;
        case 5: result = t("friday"); break;
        case 6: result = t("saturday"); break;
        default: throw new Error(`Wrong day number ${dayNumber}`);
    }

    return short ? result.substr(0, 3) : result;
};

export const getMonthName = (t: TFunction, dayNumber: number, short = true) => {
    let result = '';
    switch (dayNumber) {
        case 0: result = t('january'); break;
        case 1: result = t('february'); break;
        case 2: result = t('march'); break;
        case 3: result = t('april'); break;
        case 4: result = t('may'); break;
        case 5: result = t('june'); break;
        case 6: result = t('july'); break;
        case 7: result = t('august'); break;
        case 8: result = t('september'); break;
        case 9: result = t('october'); break;
        case 10: result = t('november'); break;
        case 11: result = t('december'); break;
    }

    return short ? result.substr(0, 3) : result;
};

export const getLocalTime = (time?: string | Date) => {
    time = time ? new Date(time) : new Date();
    return isSafari() ? time : new Date(time.getTime() - time.getTimezoneOffset() * 60000);
};

export const getUTCTime = (time?: any) => {
    time = time ? new Date(time) : new Date();
    return new Date(time.getTime() + time.getTimezoneOffset() * 60000);
};

export const getPrettyTime = (t: TFunction, time: Date, shortWeekDays = true) => {

    time = getLocalTime(time);

    if (isNaN(time.getTime()))
        return '-';

    const now = getLocalTime(),
        SecondsDiff = (now.getTime() - time.getTime()) / 1000,
        daysDiff = SecondsDiff / 86400;

    if (time.getDate() === now.getDate())
        return `${time.getHours()}:${padNumber(time.getMinutes())}`;

    if (daysDiff < 7)
        return getWeekDayName(t, time.getDay(), shortWeekDays);

    return `${time.getMonth() + 1}/${time.getDate()}/${time.getFullYear().toString().substr(2)}`;
};

export const getFullTime = (time: Date) => {
    var d = getLocalTime(time);

    return `${d.getFullYear()}-${padNumber(d.getMonth() + 1)}-${padNumber(d.getDate())} ${padNumber(d.getHours())}:${padNumber(d.getMinutes())}`;
};

export const getPrettyDate = (t: TFunction, time: Date) => {
    time = getLocalTime(time);

    if (isNaN(time.getTime()))
        return '-';

    const now = getLocalTime(),
        SecondsDiff = (now.getTime() - time.getTime()) / 1000,
        daysDiff = SecondsDiff / 86400;

    if (time.getDate() === now.getDate())
        return 'Today';

    if (daysDiff < 7)
        return getWeekDayName(t, time.getDay(), false);

    return `${time.getMonth() + 1}/${time.getDate()}/${time.getFullYear().toString().substr(2)}`;
};

export const getLongDate = (t: TFunction, time: Date) => {
    time = getLocalTime(time);

    if (isNaN(time.getTime()))
        return '-';

    return `${time.getDate()} ${getMonthName(t, time.getMonth())} ${time.getFullYear()}`;
};

export const getDaysInMonth = (date: Date) => {
    let year = date.getFullYear();
    let monthIndex = date.getMonth();
    let lastDayOfMonth = new Date(0);
    lastDayOfMonth.setFullYear(year, monthIndex + 1, 0);
    lastDayOfMonth.setHours(0, 0, 0, 0);
    return lastDayOfMonth.getDate();
};

export const addHours = (date: Date, amount: number) => {
    let MILLISECONDS_IN_HOUR = 3600000;
    var timestamp = date.getTime();
    return new Date(timestamp + amount * MILLISECONDS_IN_HOUR);
};

export const addDays = (time: Date, amount: number) => {
    let tempDate = new Date(time);
    tempDate.setDate(tempDate.getDate() + amount);
    return tempDate;
};

export const addMonths = (date: Date, amount: number) => {
    let desiredMonth = date.getMonth() + amount;
    let dateWithDesiredMonth = new Date(0);
    dateWithDesiredMonth.setFullYear(date.getFullYear(), desiredMonth, 1);
    dateWithDesiredMonth.setHours(0, 0, 0, 0);
    let daysInMonth = getDaysInMonth(dateWithDesiredMonth);
    date.setMonth(desiredMonth, Math.min(daysInMonth, date.getDate()));
    return date;
};

export const addYears = (date: Date, amount: number) => {
    return addMonths(date, amount * 12);
};

export const getTimeOfDayInSeconds = (date: Date) => {
    return (date.getHours() * 3600) + (date.getMinutes() * 60) + date.getSeconds();
};

/**
 * @param timeOfDay Time of day is in seconds.
 */
export const getDateFromTimeOfDay = (timeOfDay: number) => {
    const now = new Date();

    const seconds = timeOfDay % 60;
    const minutes = (timeOfDay / 60) % 60;
    const hours = (timeOfDay / (60 * 60)) % 24;

    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes, seconds, 0);
};

export const getTime = (time: string | Date) => {
    if (!time) return -1;

    time = typeof time === 'string' ? new Date(time) : time;
    return time.getTime();
};