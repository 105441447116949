import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Company, CompanyType } from "../../models";

interface Props {
    company: Company,
    companyTypes: CompanyType[],
}

const CompanyDetails = (props: Props) => {
    const { t } = useTranslation();
    
    const { company, companyTypes } = props;

    if(!company) return null;

    const { businessName, emailAddress, phone1, address, companyTypeId } = company;

    const companyType = companyTypes?.find(e => e.id === companyTypeId);

    return (
        <Grid container>
            <Grid item xs={6} sx={{ p: 1 }}>
                <Typography variant="h6">{t("companyName")}</Typography>
                <Typography variant="body1">{businessName}</Typography>
            </Grid>
            <Grid item xs={6} sx={{ p: 1 }}>
                <Typography variant="h6">{t("companyType")}</Typography>
                <Typography variant="body1">{companyType?.title}</Typography>
            </Grid>
            <Grid item xs={6} sx={{ p: 1 }}>
                <Typography variant="h6">{t("emailAddress")}</Typography>
                <Typography variant="body1">{emailAddress}</Typography>
            </Grid>
            <Grid item xs={6} sx={{ p: 1 }}>
                <Typography variant="h6">{t("phoneNumber")}</Typography>
                <Typography variant="body1">{phone1}</Typography>
            </Grid>
            <Grid item xs={6} sx={{ p: 1 }}>
                <Typography variant="h6">{t("address")}</Typography>
                <Typography variant="body1">{address}</Typography>
            </Grid>
        </Grid>
    );
}

export default CompanyDetails;