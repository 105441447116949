import React, { useEffect } from "react";
import { Button, TextField, InputLabel, Grid, FormControl, Input, Select, MenuItem } from "@mui/material";
import PhoneNumberInput from "../common/PhoneNumberInput";
import { useTranslation } from "react-i18next";
import { Company, CompanyType } from "../../models";
import { isEmpty } from "../../helpers/utils";

interface Props {
    companyTypes: CompanyType[],
    company: Company,
    errors: any,
    changeField: (name: string, value: any) => void,
    validate: () => void,
    submit: () => Promise<boolean>,
    onCancel: () => void,
}

const CompanyDetails = (props: Props) => {
    const { t } = useTranslation();

    const { company, companyTypes, errors } = props;

    const { businessName, emailAddress, phone1, companyTypeId } = company;

    useEffect(() => {
        props.changeField('phone1', phone1 + " ");
    }, [])

    const handleChange = (e) => {
        props.changeField(e.target.name, e.target.value);

        if (!isEmpty(errors)) props.validate();
    }

    const submit = async () => {
        const success = await props.submit();
        if(success) props.onCancel();
    }


    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <TextField
                    error={!!errors["businessName"]}
                    helperText={!!errors["businessName"] && t(errors["businessName"])}
                    name="businessName"
                    label={t("companyName")}
                    value={businessName}
                    onChange={handleChange}
                    required
                    fullWidth
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl required fullWidth variant="standard" error={!!errors["companyTypeId"]}>
                    <InputLabel htmlFor="companyType">{t("companyType")}</InputLabel>
                    <Select
                        id="companyTypeId"
                        name="companyTypeId"
                        value={companyTypeId}
                        onChange={handleChange}
                        label={t("companyType")}
                    >
                        {
                            companyTypes.map((c, i) => (
                                <MenuItem key={i} value={c.id}>{c.title}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    error={!!errors["emailAddress"]}
                    helperText={!!errors["emailAddress"] && t(errors["emailAddress"])}
                    name="emailAddress"
                    label={t("emailAddress")}
                    value={emailAddress}
                    onChange={handleChange}
                    required
                    fullWidth
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl required fullWidth variant="standard">
                    <InputLabel
                        error={!!errors["phone1"]}
                        htmlFor="phoneNumber"
                    >
                        {t("phoneNumber")}
                    </InputLabel>
                    <PhoneNumberInput
                        error={!!errors["phone1"]}
                        value={phone1}
                        name="phone1"
                        onChange={handleChange}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
                <Button color="primary" onClick={props.onCancel}>
                    {t("cancel")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 16 }}
                    onClick={submit}
                >
                    {t("save")}
                </Button>
            </Grid>
        </Grid>
    );
}

export default CompanyDetails;