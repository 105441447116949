import * as React from 'react';
import { Paper, Typography, Grid, Divider, Button } from '@mui/material';
import { lightGreen, red } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { showMoney } from '../../helpers/utils';
import { Invoice, InvoiceStatus } from '../../models';
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
        borderWidth: 1,
        borderStyle: 'solid',
        maxWidth: 400,
        margin: 'auto',
        textAlign: 'center'
    },
    rootSuccess: {
        backgroundColor: lightGreen[100],
        color: lightGreen[800],
        borderColor: lightGreen[800],
    },
    rootError: {
        backgroundColor: red[100],
        color: red[800],
        borderColor: red[800],
    },
    itemLabel: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: 'left',
        fontWeight: "bold",
    },
    itemAmount: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "right",
    },
    nextButton: {
        color: theme.palette.getContrastText(lightGreen[700]),
        backgroundColor: lightGreen[700],
        '&:hover': {
            backgroundColor: lightGreen[900],
        },
    }
}));

interface Props {
    resultInvoice: Invoice,
    onGoBack: () => void,
    onGoNext: () => void
}

const PayResult = (props: Props) => {
    const { t } = useTranslation();

    const { classes } = useStyles();

    const { resultInvoice } = props;

    const successful = resultInvoice.status === InvoiceStatus.Paid;

    return (
        <Paper className={`${classes.root} ${successful ? classes.rootSuccess : classes.rootError}`}>
            <Typography variant="h4" gutterBottom>
                {successful ? t("paymentSucceed") : t("paymentFailed")}
            </Typography>
            {successful &&
                <Grid container>
                    <Grid item xs={6} className={classes.itemLabel}>{t("price")}</Grid>
                    <Grid item xs={6} className={classes.itemAmount}>{showMoney(resultInvoice.totalAmount)}</Grid>
                    {resultInvoice.totalDiscountAmount > 0 && <>
                        <Grid item xs={6} className={classes.itemLabel}>{t("totalDiscount")}</Grid>
                        <Grid item xs={6} className={classes.itemAmount}>-{showMoney(resultInvoice.totalDiscountAmount)}</Grid>
                    </>}

                    {resultInvoice.totalTaxAmount > 0 && <>
                        <Grid item xs={6} className={classes.itemLabel}>{t("tax")}</Grid>
                        <Grid item xs={6} className={classes.itemAmount}>{showMoney(resultInvoice.totalTaxAmount)}</Grid>
                    </>}

                    <Grid item xs={12}>
                        <Divider variant="fullWidth" />
                        <Grid container>
                            <Grid item xs={6} className={classes.itemLabel}>{t("total")}</Grid>
                            <Grid item xs={6} className={classes.itemAmount}>{showMoney(resultInvoice.invoiceAmount)}</Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            onClick={props.onGoNext}
                            className={classes.nextButton}
                        >
                            {t("chatAccounts")}
                        </Button>
                    </Grid>
                </Grid>}

            {!successful && <>
                <Typography variant="body2" gutterBottom>
                    {resultInvoice.info}
                </Typography>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={props.onGoBack}
                >
                    {t("back")}
                </Button>
            </>}
        </Paper>
    );
};

export default PayResult;
