import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserPageLayout from './UserPageLayout';
import actionCreators from '../../store/ForgotPassword/actionCreators';
import { State, Actions } from '../../store/ForgotPassword/types';
import { FormControl, Input, InputLabel } from '@mui/material';
import Recaptcha from '../Recaptcha'
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../helpers/utils";

interface Props { }

const ForgotPassword = (props: State & Actions & Props) => {
    const { t } = useTranslation();

    const changeField = e => {
        props.changeField(e.target.name, e.target.value);
    }

    const handleRecaptcha = (value) => {
        props.changeField('reCaptcha', value);
    }

    const sendResetPasswordEmail = () => {
        const { email, reCaptcha } = props;

        props.sendResetPasswordEmail(email, reCaptcha);
    }

    const { email, alert, alertType, submitted, loading } = props;

    setPageTitle(t, "forgotPassword");

    return (
        <UserPageLayout
            alertMessage={alert}
            alertType={alertType}
            buttonDisabled={submitted}
            buttonText={t("submit")}
            loading={loading}
            onAlertClose={() => props.closeMessage()}
            onSubmit={sendResetPasswordEmail}
            title={t("forgotPassword")}
        >
            <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">{t("emailAddress")}</InputLabel>
                <Input
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={e => changeField(e)}
                />
            </FormControl>
            <Recaptcha onChange={handleRecaptcha} />
        </UserPageLayout>
    );
}

const ForgotPasswordWithStore = connect(
    (state: any) => state.forgotPassword,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ForgotPassword);

export default ForgotPasswordWithStore;