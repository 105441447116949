import React, { useRef, useEffect, memo } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import ChatItem from './ChatItem';
import { Paper, Typography, Divider, Button } from '@mui/material';
import { getTime, getPrettyDate } from '../../helpers/date';
import { Message, Contact, TicketHistory, ticketStatus, MessageType, DeliveryStatus, menuCommands } from '../../models';
import { useTranslation } from "react-i18next";
import { asStylesType, interestedInTicket, isEqualContact } from '../../helpers/utils';
import { blue, green } from '@mui/material/colors';
import { Box } from '@mui/system';
import { canClaimTicket } from '../../store/Messages/types';

const classes = asStylesType({
    root: {
        display: 'flex',
        flexDirection: 'column',
        p: .5,
        pr: 1,
        zIndex: 1
    },
    backImage: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: blue[50],
    },
    date: {
        m: '4px',
        p: '2px 8px',
        position: 'relative',
        alignSelf: 'center',
        backgroundColor: blue[300]
    },
    oldTicket: {
        m: .5,
        p: '2px 8px',
        position: 'relative',
        alignSelf: 'center',
        backgroundColor: green[600]
    },
});

interface Props {
    messages: Array<Message>,
    contact?: Contact,
    lastTicketHistory?: TicketHistory,
    resendMessage: (messageId: number) => void,
    onContactMenuItemClick: (contactId: number, command: menuCommands) => void,
    updateLastRead: (contactId: number) => void,
}

function isEqualProps(prev: Props, next: Props) {
    if (!prev.messages && !next.messages) return true;
    if (!prev.messages || !next.messages) return false;
    if (prev.messages.length !== next.messages.length) return false;

    if (!isEqualContact(prev.contact, next.contact)) return false;

    return true;
}

const ChatList = (props: Props) => {
    const { t } = useTranslation();

    const scrollbarRef = useRef<Scrollbars>();

    const scrollToBottom = () => {
        scrollbarRef.current.scrollToBottom()
    }

    useEffect(() => {
        scrollToBottom()
    })

    const { messages, contact, lastTicketHistory, updateLastRead } = props;

    let lastDate = -1;
    let lastTicketId = messages.length > 0 ? messages[0].ticketId : -1;

    const claimTicket = () => props.onContactMenuItemClick(contact?.id, menuCommands.claim);

    useEffect(() => {
        if (contact && interestedInTicket(contact)) {

            if (!contact.lastRead || getTime(contact.lastMessageDate) > getTime(contact.lastRead)) {
                updateLastRead(contact.id);
            }
        }
    }, [contact])

    return (
        <>
            <Box sx={classes.backImage} />

            <Scrollbars autoHide={false} ref={scrollbarRef}>
                <Box sx={classes.root}>
                    {messages.map((message, i) => {
                        const chat = <ChatItem message={message} onChatItemLoaded={scrollToBottom} resendMessage={props.resendMessage} />,
                            date = new Date(message.createdDate);

                        let dateLabel = null,
                            ticketLabel = null;

                        if (lastTicketId !== message.ticketId) {
                            lastTicketId = message.ticketId;
                            ticketLabel = <>
                                <Paper sx={classes.oldTicket}>
                                    <Typography variant='body2' sx={{ color: green[100] }}>
                                        {t("lastTicket")}
                                    </Typography>
                                </Paper>
                                <Divider variant="middle" sx={{ my: 1 }} />
                            </>;
                        }

                        if (lastDate !== date.getDate()) {
                            lastDate = date.getDate();
                            dateLabel = <Paper sx={classes.date}>
                                <Typography variant='body2' sx={{ color: 'white' }}>
                                    {getPrettyDate(t, date)}
                                </Typography>
                            </Paper>;
                        }

                        return (<React.Fragment key={i}>
                            {ticketLabel}
                            {dateLabel}
                            {chat}
                        </React.Fragment>);
                    })}

                    {contact && contact.status === ticketStatus.transferred &&
                        <ChatItem isComment
                            message={{
                                body: `${t("transferredFrom")} ${lastTicketHistory.agentName} ${t("to")} ${contact.agentName}\n\n${lastTicketHistory.comment}`,
                                createdDate: lastTicketHistory.createdDate,
                                messageType: MessageType.Outbound,
                                id: 0,
                                deliveryStatus: DeliveryStatus.Delivered,
                                ticketId: contact.id
                            }}
                        />
                    }

                    {canClaimTicket(contact) && <Box sx={{ textAlign: "center", my: 2 }}><Button variant="contained" onClick={claimTicket}>{t("claim")}</Button></Box>}
                </Box>
            </Scrollbars>
        </>
    );
}

export default memo(ChatList, isEqualProps);
