import ajax from '../../helpers/ajax'
import { actionTypes, Actions } from './types'
import { i18n } from "../../Localization/i18n"

export default {
    changeField: (name, value) => ({ type: actionTypes.CHANGE_FIELD, name, value }),
    changeAddressField: (name, value) => ({ type: actionTypes.CHANGE_ADDRESS_FIELD, name, value }),
    onAlertClose: () => ({ type: actionTypes.CLOSE_ALERT }),
    changeAddress: (address) => ({ type: actionTypes.CHANGE_ADDRESS, address }),
    submit: () => async (dispatch, getState) => {
        dispatch({ type: actionTypes.SUBMIT });

        const { firstName, lastName, phone, emailAddress, companyName, address, companyType, reCaptcha } = getState().signUp;

        if (
            firstName === '' ||
            lastName === '' ||
            phone === '' ||
            emailAddress === '' ||
            companyName === '' ||
            companyType === '' ||
            reCaptcha === '' ||
            !address
        )
            return;

        dispatch({ type: actionTypes.SHOW_LOADING });
        const response = await ajax.post("user/signup", {
            firstName,
            lastName,
            phone,
            emailAddress,
            companyName,
            address,
            companyTypeId: companyType,
            reCaptcha: reCaptcha
        });

        if (!response.ok) {
            let errorMessage = await ajax.errorMessage(response);

            dispatch({ type: actionTypes.FAILED, message: "Signup failed. " + (errorMessage || '') });

            return;
        }

        dispatch({ type: actionTypes.SUBMITTED, message: i18n.t("signUPSuccessfulMessage") });
    },
    loadForm: () => async dispatch => {
        dispatch({ type: actionTypes.SHOW_LOADING });
        const response = await ajax.get("company/CompanyTypes");
        if (!response.ok) {
            let errorMessage = await ajax.errorMessage(response);

            dispatch({ type: actionTypes.FAILED, message: "Load form failed. " + (errorMessage || '') });

            return;
        }

        const companyTypes = await response.json();

        dispatch({ type: actionTypes.COMPANY_TYPES_LOADED, companyTypes });
    }
} as Actions