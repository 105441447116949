import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormControl, FormLabel, RadioGroup, Radio } from '@mui/material';
import { useTranslation } from "react-i18next";

type Props = {
    open: boolean,
    handleClose: () => void,
    onConfirm: (message: string) => void
}

const CancelSurvey = (props: Props) => {

    const [message, setMessage] = React.useState("");
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage((event.target as HTMLInputElement).value);
      };

    const { open, handleClose, onConfirm } = props;

    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{t("cancellationReason")}</DialogTitle>
            <DialogContent>
            <FormControl component="fieldset">
      <FormLabel component="legend"></FormLabel>
        <RadioGroup aria-label="cancellation-reason" value={message} onChange={handleChange}>
            <FormControlLabel value="Too expensive" control={<Radio />} label={t("tooExpensive") as any} />
            <FormControlLabel value="Not user friendly" control={<Radio />} label={t("notFriendly") as any} />
            <FormControlLabel value="No longer need the chat service in our operation" control={<Radio />} label={t("chatNotNeeded") as any} />
        </RadioGroup>
        </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => {
                        handleClose();
                        onConfirm(message);
                    }}
                >
                    {t("ok")}
                </Button>
                <Button
                    onClick={handleClose}
                    color="primary"
                >
                    {t("cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default CancelSurvey;