import React from "react";
import { IconButton, Button, Box, Theme, Table, TableBody, TableCell as MuiTableCell, TableHead, TableRow, Tooltip, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import EmailIcon from '@mui/icons-material/EmailOutlined';
import { isMobileMode } from '../../helpers/utils';
import { getLongDate, addDays } from '../../helpers/date';
import { Invoice, Company, Discount, Tax, ProductPlan } from "../../models";
import { makeStyles, withStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    table: {
        minWidth: isMobileMode() ? 360 : 800,
        marginTop: 16,
    },
    spreevlAddress: {
        minWidth: 230,
        fontWeight: 500,
        width: '50%',
    },
    invoiceNumber: {
        minWidth: '50%',
        marginTop: isMobileMode() ? 16 : 0,
    },
    flex: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: '100%',
        fontSize: 18,
        marginBottom: 16,
    },
}));

const TableCell = withStyles(MuiTableCell, {
    root: {
        borderBottom: "none",
        fontSize: 18,
    },
    head: {
        backgroundColor: '#ececec',
    },
    body: {
        lineHeight: isMobileMode() ? 1.2 : 0.5,
    },
});

interface Props {
    isOpen: boolean,
    invoice: Invoice,
    company: Company,
    discounts: Discount[],
    taxes: Tax[],
    plan: ProductPlan,
    resendEmail: (id: number) => void,
    onClose: () => void,
}

const ViewInvoice = (props: Props) => {
    const { t } = useTranslation();

    const { classes } = useStyles();

    const { isOpen, invoice, company, discounts, taxes, plan } = props;

    if (plan === null) return null;

    const subtotal = invoice.invoiceAmount - invoice.totalTaxAmount;
    const addressArr = company.address.split(',');

    return (
        <Dialog open={isOpen} onClose={props.onClose} fullScreen={isMobileMode()} maxWidth={isMobileMode() ? false : "md"}>
            <DialogContent>
                <Box sx={{ textAlign: 'right' }}>
                    <Tooltip title={t("resendInvoice")} aria-label={t("resendInvoice")}>
                        <IconButton color="primary" onClick={() => props.resendEmail(invoice.id)}>
                            <EmailIcon sx={{ fontSize: 36 }} />
                        </IconButton>
                    </Tooltip>
                </Box>

                <div className={classes.flex}>
                    <div className={classes.spreevlAddress}>
                        Spreevel Inc.
                        <br />308-3301 Uplands Dr
                        <br />Ottawa, Ontario K1V 9V8
                    </div>
                    <div className={classes.invoiceNumber}>
                        <b>{t("invoiceNumber")}</b> {invoice.invoiceNumber}
                        <br /><b>{t("invoiceDate")}:</b> {getLongDate(t, invoice.invoiceDate)}
                        <br /><b>{t("startDate")}:</b> {getLongDate(t, invoice.billDate)}
                        <br /><b>{t("endDate")}:</b> {getLongDate(t, addDays(invoice.billDate, 30))}
                    </div>
                </div>
                <div>
                    <b>{t("soldTo")}:</b>
                    {company && (
                        <Box sx={{ ml: 2 }}>
                            {company.businessName}<br />
                            {company.emailAddress}<br />
                            {addressArr.map((str, i) => (
                                <div key={i}>{str}{i + 1 < addressArr.length ? ',' : ''}</div>
                            ))}
                        </Box>
                    )}
                </div>
                <Box sx={{ mt: 2 }}><b>GST/HST</b> - 73035 5716 RT0001</Box>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("descrtiption")}</TableCell>
                            <TableCell>{t("unitPrice")}</TableCell>
                            <TableCell align="right">{t("amount")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Spreevel Chat: {plan.title}</TableCell>
                            <TableCell>$USD {plan.price.toFixed(2)}</TableCell>
                            <TableCell align="right">$USD {plan.price.toFixed(2)}</TableCell>
                        </TableRow>
                        {discounts?.map((d, i) => (
                            <TableRow key={i}>
                                <TableCell>{t("discount")}: {d.name}</TableCell>
                                <TableCell>{d.isPercentage ? '' : '$'}{(d.amount).toFixed(2)}{d.isPercentage ? '%' : ''}</TableCell>
                                <TableCell align="right">-${(d.isPercentage ? (d.amount / 100 * plan.price) : d.amount).toFixed(2)}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right"><b>{t("subtotal")}:</b> ${subtotal.toFixed(2)}</TableCell>
                        </TableRow>
                        {taxes?.map((t, i) => (
                            <TableRow key={i}>
                                <TableCell>{t.name}</TableCell>
                                <TableCell>{t.taxRate}%</TableCell>
                                <TableCell align="right">{(t.taxRate / 100 * subtotal).toFixed(2)}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right"><b>{t("invoiceAmount")}:</b> ${invoice.invoiceAmount.toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right"><b>{t("amountPaid")}:</b> ${invoice.invoiceAmount.toFixed(2)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    {t("close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ViewInvoice;