import i18nn from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locale/en.json';
import fr from './locale/fr.json';

const LOCALIZATION_LANG = 'SPREEDIX_LOCALIZATION_LANG';

export const getStoredLang = () => {
    const lang = localStorage.getItem(LOCALIZATION_LANG);

    return lang === null ? 'en' : lang;
};

export const storeLang = (lang: string) => {
    localStorage.setItem(LOCALIZATION_LANG, lang);
};

i18nn
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: en},
      fr: { translations: fr},
    },
    lng: getStoredLang(),
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      useSuspense: true,
    },
  });

export const i18n =  i18nn;