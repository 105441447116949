import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    TextField,
    Button,
    DialogActions,
    DialogContent,
    Dialog,
    DialogTitle,
    Select,
    FormControl,
    MenuItem,
} from "@mui/material";
import { Template } from '../../models';
import { isEmpty } from '../../helpers/utils';

interface Props {
    errors: any,
    visible: boolean,
    template: Template,
    changeTemplateField: (name: keyof Template, value: any) => void,
    validateTemplate: () => void,
    closeTemplate: () => void,
    submitTemplate: () => object
}


const TemplateInfo = (props: Props) => {
    const { t } = useTranslation();
    const { visible, errors, template, closeTemplate, submitTemplate, changeTemplateField, validateTemplate } = props;
    const { name, language, transferMessage } = template;

    const editMode = template.id > 0;

    const handleChange = (e) => {
        changeTemplateField(e.target.name, e.target.value);

        if (!isEmpty(errors)) validateTemplate();
    }

    const submit = async () => {
        const errors = await submitTemplate();
        if (!isEmpty(errors)) return;

        closeTemplate();
    }

    return (
        <Dialog fullWidth maxWidth="md" open={visible} onClose={closeTemplate}>
            <DialogTitle>{editMode ? t("template") : t("addTemplate")}</DialogTitle>
            <DialogContent>
                <TextField
                    margin='normal'
                    fullWidth
                    required
                    inputProps={{ maxLength: 256 }}
                    error={!!errors["name"]}
                    helperText={!!errors["name"] && t(errors["name"])}
                    name="name"
                    value={name}
                    label={t("name")}
                    onChange={handleChange}
                />

                <FormControl fullWidth required margin='normal'>
                    <Select
                        name="language"
                        value={language}
                        error={!!errors["language"]}
                        onChange={handleChange}
                    >
                        <MenuItem value={'en'}>{t('lang_English')}</MenuItem>
                        <MenuItem value={'fr'}>{t('lang_French')}</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    margin='normal'
                    fullWidth
                    inputProps={{ maxLength: 512 }}
                    error={!!errors["transferMessage"]}
                    helperText={!!errors["transferMessage"] && t(errors["transferMessage"])}
                    name="transferMessage"
                    value={transferMessage}
                    label={t("transferMessage")}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
            <Button color="primary" onClick={closeTemplate}>
                    {t("cancel")}
                </Button>
                <Button color="primary" variant="contained" onClick={submit}>
                    {editMode ? t("update") : t("add")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TemplateInfo;