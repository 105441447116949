import * as React from 'react';
import { Theme } from '@mui/material/styles';
import Alert, { alertTypes } from '../common/Alert';
import { Button, Paper, Typography, CircularProgress } from "@mui/material";
import classNames from 'classnames';
import { isMobileMode } from '../../helpers/utils';
import LangSelect from '../common/LangSelect';
import { green } from '@mui/material/colors';
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#fafafa',
        height: '100%'
    },
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(5)} ${theme.spacing(10)} ${theme.spacing(5)} ${theme.spacing(10)}`
    },
    paperMobile: {
        marginTop: 0,
        height: '100%',
        padding: `${theme.spacing(2)} ${theme.spacing(5)} ${theme.spacing(2)} ${theme.spacing(5)}`
    },
    avatar: {
        margin: theme.spacing(1),
        width: 120,
        height: 120
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2)
    },
    buttonWrapper: {
        margin: 'auto',
        marginTop: theme.spacing(3),
        position: 'relative',
        maxWidth: 320
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    container: {
        marginTop: theme.spacing(2),
        width: '96%',
    },
}));

interface Props {
    title?: string,
    buttonText: string,
    signupLayout?: boolean,
    loading: boolean,
    buttonDisabled: boolean,
    alertMessage: string,
    alertType: alertTypes,
    alertButtons?: any[],
    onAlertClose: () => void,
    onSubmit: () => void
}

const UserPageLayout: React.FunctionComponent<Props> = (props) => {
    const { classes } = useStyles();
    const { title, buttonText, alertMessage, alertType, alertButtons, loading, buttonDisabled, signupLayout } = props;

    const mobileMode = isMobileMode();

    const handleSubmit = (e) => {
        e.preventDefault();

        props.onSubmit();
    }

    return (
        <div className={classes.root}>
            <div className={classes.container} style={{ maxWidth: signupLayout ? 800 : 500 }}>
                <LangSelect />
                <Paper
                    square={mobileMode}
                    className={classNames(classes.paper, mobileMode ? classes.paperMobile : "")}>
                    <img alt="Logo" src='/logo_text.png' className={classes.avatar} />

                    {title && <Typography variant="h5" fontWeight={400}>
                        {title}
                    </Typography>}

                    <form className={classes.form}>
                        {props.children}
                        <div className={classes.buttonWrapper}>
                            <Button
                                type="submit"
                                disabled={buttonDisabled}
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                {buttonText}
                            </Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </form>
                </Paper>
            </div>

            <Alert
                message={alertMessage}
                open={alertMessage !== ''}
                type={alertType}
                buttons={alertButtons}
                onClose={props.onAlertClose}
            />
        </div>
    );
}

export default UserPageLayout;
