import { produce } from 'immer'
import { actionTypes, State, initialState, ReducerAction } from './types'

export default (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.LOADED:
                newState.id = action.id;
                newState.firstName = action.firstName;
                newState.lastName = action.lastName;
                newState.phone = action.phone;
                newState.gender = action.gender;
                newState.emailAddress = action.emailAddress;
                newState.picture = action.picture;
                break;
            case actionTypes.RESET_PAGE:
                newState.id = 0;
                newState.firstName = '';
                newState.lastName = '';
                newState.phone = '';
                newState.gender = 0;
                newState.emailAddress = '';
                newState.picture = '';
                newState.isSubmitted = false;
                break;
            case actionTypes.SUBMIT:
                newState.isSubmitted = true;
                break;
            case actionTypes.FILE_CHANGED:
                newState.picFile = action.picFile;
                break;
            case actionTypes.CHANGE_FIELD:
                newState[action.name] = action.value;
                break;
        }
    })
}