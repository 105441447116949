import React from 'react';
import RouterLink from '../common/RouterLink';
import { hasPermission, PermissionKey } from '../../helpers/auth';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/BarChartRounded';
import MessageIcon from '@mui/icons-material/MessageOutlined';
import UserIcon from '@mui/icons-material/PeopleOutline';
import AutoReplyIcon from '@mui/icons-material/LibraryBooksOutlined';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import KnowledgeIcon from '@mui/icons-material/LibraryAddOutlined';
import MessagingSettingsIcon from '@mui/icons-material/PhonelinkSetup';
import AnalyticsIcon from '@mui/icons-material/AssessmentOutlined';

interface LinkProps {
    path: string,
    label: string,
    icon: JSX.Element,
    key?: any,
    sx?: SxProps,
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}

interface Props {
    mobileMode: boolean,
    dashboards: Array<any>,
    dashboardsOpen: boolean,
    toggleDashboardMenu: () => void,
    onCloseDrawer: () => void
}

const Menu = (props: Props) => {
    const { dashboards, dashboardsOpen } = props;

    const Link = (props: LinkProps) => <RouterLink to={props.path} key={props.key}>
        <ListItem
            sx={props.sx}
            button
            onClick={props.onClick}
        >
            <ListItemIcon sx={{ minWidth: 40 }}>
                {props.icon}
            </ListItemIcon>
            <ListItemText primary={props.label} />
        </ListItem>
    </RouterLink>

    const handleOnClickMenu = () => {
        if (props.mobileMode)
            props.onCloseDrawer();
    }

    const { t } = useTranslation();

    return <List sx={{ height: '100%', overflowX: 'hidden' }}>
        <Link label={t("home")} icon={<HomeIcon />} path='/' />

        {dashboards && dashboards.length > 0 && hasPermission(PermissionKey.ViewDashboards) &&
            <>
                <ListItem
                    button
                    onClick={props.toggleDashboardMenu}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("dashboards")} />
                    {dashboardsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={dashboardsOpen} timeout="auto" unmountOnExit>
                    {
                        dashboards.map((dashboard, i) => (
                            <Link
                                key={i}
                                sx={{ pl: 4 }}
                                label={dashboard.title} icon={<DashboardIcon />}
                                path={`/Dashboard/${dashboard.id}`} />
                        ))
                    }
                </Collapse>
            </>
        }

        {hasPermission(PermissionKey.ViewMessages) &&
            <Link label={t("messages")} icon={<MessageIcon />} path='/Messages' onClick={handleOnClickMenu} />}

        {hasPermission(PermissionKey.ManagerUsers) &&
            <Link label={t("manageUsers")} icon={<UserIcon />} path='/ManageUser' onClick={handleOnClickMenu} />}

        {hasPermission(PermissionKey.SetupPhoneNumbers) &&
            <Link label={t("autoReplies")} icon={<AutoReplyIcon />} path='/AutoReplies' onClick={handleOnClickMenu} />}

        {hasPermission(PermissionKey.ManagePayments) &&
            <Link label={t("invoices")} icon={<ReceiptIcon />} path='/Invoices' onClick={handleOnClickMenu} />}

        {(hasPermission(PermissionKey.ManageChatBot) || hasPermission(PermissionKey.ViewMessages)) &&
            <Link label={t("messagingSettings")} icon={<MessagingSettingsIcon />} path='/MessagingSettings' onClick={handleOnClickMenu} />}

        {hasPermission(PermissionKey.ManageChatBot) &&
            <Link label={t("manageKnowledge")} icon={<KnowledgeIcon />} path='/ManageKnowledge' onClick={handleOnClickMenu} />}

        {hasPermission(PermissionKey.SetupWebChat) &&
            <Link label={t("chatbotAnalytics")} icon={<AnalyticsIcon />} path='/Analytics' onClick={handleOnClickMenu} />}
    </List>;
}

export default Menu;