import { Device } from '../../models';
import { ReducerAction as layoutReducerAction } from '../Layout/types'
import { ActionCreatorsMapObject } from 'redux'
import { StatusEnum, ChatbotMode } from "../../models";

export enum actionTypes {
    SET_PUBLIC_KEY = 'MESSAGINGSETTINGS_SET_PUBLIC_KEY',
    SET_DEVICE = 'MESSAGINGSETTINGS_SET_DEVICE',
    SET_BROWSER_SUPPORT = 'MESSAGINGSETTINGS_SET_BROWSER_SUPPORT',
    SET_AUTO_SETUP = 'MESSAGINGSETTINGS_SET_AUTO_SETUP',
    SET_NOTIFICATION_PERMISSION = 'MESSAGINGSETTINGS_SET_NOTIFICATION_PERMISSION',
    TOGGLE_DEVICE_ENABLED = "MESSAGINGSETTINGS_TOGGLE_DEVICE_ENABLED",
    SET_INSTALL_EVENT = "MESSAGINGSETTINGS_SET_INSTALL_EVENT",
    CHANGE_FIELD = "MESSAGINGSETTINGS_CHANGE_FIELD",
    LOAD_SETTINGS = "MESSAGINGSETTINGS_LOAD_SETTINGS",
}

export function write<K extends keyof State>(arg: State, key: K, value: State[K]): void {
    arg[key] = value;
};

export interface ReducerAction {
    type: actionTypes,
    name?: keyof State,
    value?: any,
    publicKey?: string,
    device?: Device,
    notificationPermission?: NotificationPermission,
    browserSupport?: boolean,
    autoSetup?: boolean,
    installEvent?: Event,
    chatbotStatus?: StatusEnum,
    chatbotMode?: ChatbotMode,
    newTicketEmail?: boolean
}

export type Dispatch = (action: ReducerAction | layoutReducerAction) => void
export type GetState = () => { messagingSettings: State }

export interface Actions extends ActionCreatorsMapObject {
    loadInfo: () => (dispatch: Dispatch, getState: GetState) => void,
    requestPushNotificationPermission: () => (dispatch: Dispatch, getState: GetState) => void,
    addDevice: () => (dispatch: Dispatch, getState: GetState) => void,
    testSend: () => (dispatch: Dispatch, getState: GetState) => void,
    enableOrDisableDevice: () => (dispatch: Dispatch, getState: GetState) => void,
    installApp: () => (dispatch: Dispatch, getState: GetState) => void,
    handleChatbotStatus: (currentStatus: StatusEnum) => (dispatch: Dispatch, getState: GetState) => void,
    handleChatbotMode: (mode: any) => (dispatch: Dispatch, getState: GetState) => void,
    handleTicketEmail: (newTicketEmail: boolean) => (dispatch: Dispatch, getState: GetState) => void,
}

export interface State {
    publicKey: string,
    device?: Device,
    notificationPermission: NotificationPermission,
    browserSupport: boolean,
    autoSetup: boolean,
    installEvent: Event,
    chatbotStatus: StatusEnum,
    chatbotMode: ChatbotMode,
    newTicketEmail: boolean,
}

export const initialState: State = {
    publicKey: '',
    device: null,
    notificationPermission: "default",
    browserSupport: false,
    autoSetup: true,
    installEvent: null,
    chatbotStatus: StatusEnum.Inactive,
    chatbotMode: ChatbotMode.Supervised,
    newTicketEmail: false
}