import ajax from '../../helpers/ajax';
import { i18n } from "../../Localization/i18n"
import { logout } from '../../helpers/auth';
import { alertTypes } from '../../components/common/Alert';
import { push } from 'connected-react-router';
import { clearUserInfo } from '../PrivateRoute/actionCreators';
import { getServerUrl } from '../../helpers/utils';
import { redirectSection } from '../../models'
import { HubConnectionBuilder } from '@microsoft/signalr';
import { actionTypes, Actions, ReducerAction } from './types'

export const showLoading: () => ReducerAction = () => ({ type: actionTypes.LOADING });

export const hideLoading: () => ReducerAction = () => ({ type: actionTypes.HIDE_LOADING });

export const closeAlert: () => ReducerAction = () => ({ type: actionTypes.CLOSE_ALERT });

export const showAlert: (message: string, alertType?: alertTypes) => ReducerAction = (message, alertType = alertTypes.error) => ({ type: actionTypes.SHOW_ALERT, message, alertType });

const actionCreators: Actions = {
    closeAlert: () => closeAlert(),
    openDrawer: () => dispatch => {
        localStorage.setItem('LAYOUT_DRAWER_IS_OPEN', "true")
        dispatch({ type: actionTypes.SET_OPEN_DRAWER, open: true })
    },
    closeDrawer: () => dispatch => {
        localStorage.setItem('LAYOUT_DRAWER_IS_OPEN', "false")
        dispatch({ type: actionTypes.SET_OPEN_DRAWER, open: false })
    },
    logout: () => dispatch => {
        logout()
        dispatch(clearUserInfo())
        dispatch(push('/login'))
    },
    toggleDashboardMenu: () => ({ type: actionTypes.TOGGLE_DASHBOARD_MENU }),
    toggleNotificationRead: (id) => async (dispatch) => {
        await ajax.post("Notification/ToggleRead", id);

        dispatch({ type: actionTypes.TOGGLE_NOTIFICATION_READ, id });
    },
    handleNotificationRedirect: (notification) => async (dispatch) => {
        const { id, read, url } = notification;
        const section = notification.redirectSection;

        if (!read) {
            await ajax.post("Notification/ToggleRead", id);
            dispatch({ type: actionTypes.TOGGLE_NOTIFICATION_READ, id });
        }

        switch (section) {
            case redirectSection.Dashboards:
                dispatch(push("/"));
                break;
            case redirectSection.Messages:
                dispatch(push("/Messages"));
                break;
            case redirectSection.MessagingAccounts:
                dispatch(push("/ChatAccounts"));
                break;
            case redirectSection.Subscriptions:
                dispatch(push("/subscription"));
                break;
            case redirectSection.Users:
                dispatch(push("/ManageUser"));
                break;
            case redirectSection.External:
                window.open(url, '_blank');
                break;
        }
    },
    loadNotifications: () => async (dispatch, getState) => {
        const response = await ajax.get("Notification/GetAll");

        if (await ajax.errorOccured(response, i18n.t("notificationsLoadFailed"), dispatch, getState))
            return;

        const result = await response.json();

        const hubConnection = new HubConnectionBuilder().withUrl(`${getServerUrl()}/notify`).withAutomaticReconnect().build();

        hubConnection.on('Notification_' + result.userId, (notification) => {
            const newNotification = JSON.parse(notification);

            dispatch({ type: actionTypes.NOTIFICATION_RECEIVED, newNotification });
        });

        hubConnection
            .start()
            .then(() => console.log('Connection started!'))
            .catch(err => console.log('Error while establishing connection :(', err));

        dispatch({ type: actionTypes.NOTIFICATIONS_LOADED, notifications: result.notifications, hubConnection });
    },
    gotoMenu: url => dispatch => {
        dispatch(push(url));
    }
}

export default actionCreators