import { State, actionTypes, initialState, ReducerAction, write } from './types';
import { produce } from 'immer'

export default (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.CHANGE_FIELD:
                write(newState, action.name, action.value);
                break;
            case actionTypes.LOADED:
                newState.allMessagesCount = action.allMessagesCount;
                newState.smsCount = action.smsCount;
                newState.facebookCount = action.facebookCount;
                newState.instagramCount = action.instagramCount;
                newState.webchatCount = action.webchatCount;
                newState.newTicketsCount = action.newTicketsCount;
                newState.continuingTicketsCount = action.continuingTicketsCount;
                newState.uniqueNewTicketsCount = action.uniqueNewTicketsCount;
                newState.endUserInfo = action.endUserInfo;
                newState.dayMessages = action.dayMessages;
                break;
            case actionTypes.SELECTED_DATE:
                newState.dateFilter = action.dateFilter;
                break;
        }
    })
}