import React, { useState, useEffect, useRef } from 'react';
import { IconButton, TextField, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';

type Props = {
    initialValue: string,
    save: (value: string) => void,
}

const EditableLabel = ({ initialValue, save }: Props) => {
    const { t } = useTranslation();

    const [view, setView] = useState('label');
    const [value, setValue] = useState(initialValue);
    const [previous, setPrevious] = useState(initialValue);
    const textInput = useRef(null);

    useEffect(() => {
        if (view === 'text') {
            textInput.current.focus();
        }
    }, [view, textInput]);

    const keyUp = (e) => {
        if (e.key === 'Escape') {
            setValue(previous);
            setView('label');
        } else if (e.key === 'Enter') {
            setValue(e.target.value);
            setPrevious(e.target.value);
            setView('label');

            save(e.target.value);
        }
    }

    const handleEdit = e => {
        setView('text');
    };

    const handleChange = e => {
        setValue(e.target.value);
    };

    const handleSave = e => {
        save(value)
        setValue(value);
        setPrevious(value);
        setView('label');

    };

    const handleCancel = e => {
        setValue(previous);
        setView('label');
    };

    const renderLabel = () =>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <span>{value}</span>
            <Tooltip title={t("edit")}>
                <IconButton onClick={handleEdit} size="large"><EditIcon color="primary" /></IconButton>
            </Tooltip>
        </Box>
        ;

    const renderInput = () =>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <TextField
                value={value}
                ref={textInput}
                onChange={handleChange}
                onKeyUp={keyUp}

            />
            <Tooltip title={t("save")}>
                <IconButton onClick={handleSave} size="large"><CheckIcon color="primary" /></IconButton>
            </Tooltip>
            <Tooltip title={t("cancel")}>
                <IconButton onClick={handleCancel} size="large"><CloseIcon /></IconButton>
            </Tooltip>
        </Box>;

    return view === 'label' ? renderLabel() : renderInput();
};

export default EditableLabel;