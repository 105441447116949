import * as React from 'react';
import * as ReactDOM from 'react-dom'
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import { useTranslation } from "react-i18next";

type confirmColor = "error" | "warning" | "info"

interface Props {
    title?: string,
    message?: string,
    color: confirmColor,
    cancelText?: string,
    confirmText?: string,
    onClose: (result: boolean) => void
}

const ConfirmModal = (props: Props) => {
    const { message, title, color, cancelText, confirmText } = props;

    const { t } = useTranslation();

    function handleClose() {
        if (props.onClose)
            props.onClose(false);
    }

    const handleSubmit = () => {
        if (props.onClose)
            props.onClose(true)
    }

    let buttonColor: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' = 'primary';

    switch (color) {
        default:
        case "error":
            buttonColor = "error";
            break;
        case "warning":
            buttonColor = "warning";
            break;
        case "info":
            buttonColor = "info";
            break;
    }

    return (
        <Dialog
            open={true}
            onClose={() => handleClose()}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>{t("confirmation")}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message || t("areYouSure")}</DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => handleClose()} color="primary">
                    {cancelText || t("no")}
                </Button>
                <Button onClick={() => handleSubmit()} color={buttonColor} variant="contained">
                    {confirmText || t("yes")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmModal;

export const confirmation = (message?: string, color?: confirmColor, title?: string, confirmText?: string, cancelText?: string) => {
    return new Promise(resolve => {
        let el = document.createElement('div');

        const handleResolve = (result: boolean) => {
            ReactDOM.unmountComponentAtNode(el);
            el = null;

            if (result && resolve)
                resolve(result);
        };

        ReactDOM.render(<ConfirmModal
            cancelText={cancelText}
            confirmText={confirmText}
            message={message}
            title={title}
            color={color}
            onClose={handleResolve}
        />, el);
    });
};
