import React, { useEffect } from "react";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PhoneParameter, phonePlatform } from "../../models";
import { isMobileMode } from "../../helpers/utils";
import { Add as AddIcon, DeleteOutline as DeleteIcon } from '@mui/icons-material';
import EditableLabel from '../common/EditableLabel'
import { Box } from "@mui/system";

interface Props {
    phonePlatform: phonePlatform,
    numbers: Array<PhoneParameter>,
    getOwnedNumbers: (platform: phonePlatform) => void,
    deleteNumber: (platform: phonePlatform, number: PhoneParameter) => void,
    buyNumber: (platform: phonePlatform) => void,
    updateNumber: (id: number, number: string, displayName: string) => void,
}

const PhoneSetup = (props: Props) => {
    const { t } = useTranslation();

    useEffect(() => {
        props.getOwnedNumbers(props.phonePlatform);
    }, [])

    const { phonePlatform, numbers } = props;

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                sx={{ mt: 1, mb: 1 }}
                onClick={() => props.buyNumber(phonePlatform)}
            >
                <AddIcon />
                {t("buyPhoneNumber")}
            </Button>
            <Box sx={{ width: '100%', overflowX: 'auto' }}>
                <Table size="small" sx={{ minWidth: isMobileMode() ? 400 : 800 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {t("displayName")}
                            </TableCell>
                            <TableCell>
                                {t("phoneNumber")}
                            </TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            numbers.length == 0 &&
                            <TableRow>
                                <TableCell colSpan={2} align="center">{t("noData")}</TableCell>
                            </TableRow>
                        }
                        {numbers.map((number, i) => (
                            <TableRow key={i}>
                                <TableCell><EditableLabel initialValue={number.displayName ? number.displayName : number.phoneNumber}
                                    save={async (v) => await props.updateNumber(number.platformParameterId, number.phoneNumber, v)} /></TableCell>
                                <TableCell>{number.phoneNumber}</TableCell>
                                <TableCell align="center">
                                    <Tooltip title={t("delete")}>
                                        <IconButton
                                            onClick={() => props.deleteNumber(phonePlatform, number)}
                                            size="large">
                                            <DeleteIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </>
    );
}

export default PhoneSetup;
