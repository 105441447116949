import { alertTypes } from '../../components/common/Alert';
import { produce } from 'immer';
import { ReducerAction, State, initialState, actionTypes } from './types';

const reducer = (state: State, action: ReducerAction) => {
    return produce(state || initialState, (newState: State) => {
        switch (action.type) {
            case actionTypes.LOADING:
                newState.loading = true
                newState.submitted = true
                break
            case actionTypes.CHANGE_FIELD:
                let updatePasswordInvalid = true;

                if (action.name === 'password')
                    updatePasswordInvalid = state.confirmPassword === '' || state.confirmPassword !== action.value;
                else if (action.name === 'confirmPassword')
                    updatePasswordInvalid = state.password === '' || action.value !== state.password;
                else
                    updatePasswordInvalid = state.password === '' || state.password !== state.confirmPassword || action.value === '';

                newState[action.name] = action.value
                newState.updatePasswordInvalid = updatePasswordInvalid
                break
            case actionTypes.CLOSE_MESSAGE:
                newState.alert = ''
                break
            case actionTypes.INVALID_LINK:
                newState.alert = action.message
                newState.alertType = alertTypes.error
                newState.submitted = true
                break
            case actionTypes.INVALID_CODE:
                newState.alert = action.message
                newState.alertType = alertTypes.error
                newState.loading = false
                break
            case actionTypes.PASSWORD_UPDATED:
                newState.alert = action.message
                newState.alertType = alertTypes.success
                newState.loading = false
                newState.submitted = true
                newState.showLoginLink = true
                break
            case actionTypes.UPDATEPASSWORD_FAILED:
                newState.alert = action.message
                newState.alertType = alertTypes.error
                newState.loading = false
                newState.submitted = false
                break
            case actionTypes.CODE_ISVALID:
                newState.email = action.email
                newState.loading = false
                newState.submitted = false
                break
        }
    })
}

export default reducer