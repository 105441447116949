import * as React from "react";
import { Theme } from '@mui/material/styles';
import { Button, Paper, Typography } from "@mui/material";
import { Device } from "../../models";
import { useTranslation } from "react-i18next";
import {
    Block as BlockIcon,
    Settings as SettingsIcon,
    LockOutlined as PermissionIcon,
    NotificationsOutlined as DoneIcon,
    NotificationsOffOutlined as DisabledIcon,
    MobileOff as MobileOffIcon
} from '@mui/icons-material';
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        width: 1000,
        maxWidth: '100%',
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        justifyContent: 'center',
        textAlign: 'center'
    },
    header: {
        fontWeight: 500,
        marginBottom: theme.spacing(1),
    },
    icon: {
        fontSize: 80,
    },
    "@keyframes spin": {
        from: {
            transform: "rotate(0deg)"
        },
        to: {
            transform: "rotate(360deg)"
        }
    },
    spinning: {
        animation: `$spin 3s linear infinite`
    }
}));

interface Props {
    publicKey: string,
    device?: Device,
    notificationPermission: NotificationPermission,
    browserSupport: boolean,
    autoSetup: boolean,
    installEvent: Event
    requestPushNotificationPermission: () => void,
    addDevice: () => void,
    testSend: () => void,
    enableOrDisableDevice: () => void,
    installApp: () => void,
}

const PushSettings = (props: Props) => {
    const { t } = useTranslation();

    const { classes } = useStyles();

    const { device, notificationPermission, browserSupport, autoSetup, installEvent } = props;

    const getContent = () => {
        if (!browserSupport)
            return <React.Fragment>
                <BlockIcon className={classes.icon} color="secondary" />
                <Typography variant="h5">{t("pushNoSupport")}</Typography>
            </React.Fragment>

        if (autoSetup)
            return <React.Fragment>
                <SettingsIcon className={`${classes.icon} ${classes.spinning}`} color="disabled" />
                <Typography variant="h5">{t("pushInProgress")}</Typography>
            </React.Fragment>

        if (notificationPermission === "default")
            return <React.Fragment>
                <PermissionIcon className={classes.icon} color="primary" />
                <Typography variant="h5" gutterBottom>{t("pushGivePermissionDesc")}</Typography>
                <Button color="primary" onClick={() => props.requestPushNotificationPermission()}>{t("pushGivePermission")}</Button>
            </React.Fragment>

        if (notificationPermission === "denied")
            return <React.Fragment>
                <BlockIcon className={classes.icon} color="secondary" />
                <Typography variant="h5">{t("pushDeniedPermission")}
                    {t("pushGivePermissionClick")}</Typography>
            </React.Fragment>

        if (!device)
            return <React.Fragment>
                <DisabledIcon className={classes.icon} color="primary" />
                <Typography variant="h5" gutterBottom>{t("pushEnableDesc")}</Typography>
                <Button color="primary" onClick={() => props.addDevice()}>{t("pushEnable")}</Button>
            </React.Fragment>
        else {
            if (device.enabled)
                return (<React.Fragment>
                    <DoneIcon className={classes.icon} color="primary" />
                    <Typography variant="h5" gutterBottom>{t("pushTestDesc")}</Typography>
                    <Button color="primary" onClick={() => props.testSend()}>{t("pushSendTest")}</Button>
                    <Button color="secondary" onClick={() => props.enableOrDisableDevice()}>{t("pushTurnOff")}</Button>
                </React.Fragment>)
            else
                return (<React.Fragment>
                    <MobileOffIcon className={classes.icon} color="disabled" />
                    <Typography variant="h5" gutterBottom>{t("pushTurnedOff")}</Typography>
                    <Button color="primary" onClick={() => props.enableOrDisableDevice()}>{t("pushTurnOn")}</Button>
                </React.Fragment>)
        }
    }

    return (
        <div className={classes.root}>
            <h2 className={classes.header}>{t("pushNotification")}</h2>
            <Paper className={classes.paper}>
                {getContent()}
                {
                    installEvent &&
                    <Button color="primary" onClick={() => props.installApp()}>{t("installApp")}</Button>
                }
            </Paper>
        </div>
    );

}

export default PushSettings;