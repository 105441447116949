import ajax from '../../helpers/ajax';
import { Actions, actionTypes } from './types';
import { showLoading, hideLoading, showAlert } from '../Layout/actionCreators';
import { alertTypes } from "../../components/common/Alert"
import { i18n } from "../../Localization/i18n"

export default {
    loadInvoices: () => async (dispatch, getState) => {
        dispatch(showLoading());
        const response = await ajax.get("Payment/CompanyInvoices");

        if (await ajax.errorOccured(response, "Invoices load failed.", dispatch, getState))
            return;

        let invoices = await response.json();
        dispatch(hideLoading());
        dispatch({ type: actionTypes.LOADED, invoices });
    },
    handleDateSelect: (dateFilter) => ({ type: actionTypes.SELECTED_DATE, dateFilter }),
    sortInvoices: (sortKey, sortDir) => ({ type: actionTypes.SORT_INVOICES, sortKey, sortDir }),

    loadInvoiceInfo: (id) => async (dispatch, getState) => {
        dispatch(showLoading());
        const response = await ajax.get(`Payment/CompanyInvoices/${id}`);

        if (await ajax.errorOccured(response, "Invoice load failed.", dispatch, getState))
            return;

        let invoiceInfo = await response.json();
        dispatch(hideLoading());


        dispatch({ type: actionTypes.INVOICE_LOADED, invoiceId: id, discounts: invoiceInfo.discounts, taxes: invoiceInfo.taxes, plan: invoiceInfo.plan, company: invoiceInfo.company  });
    },

    resendEmail: (id) => async (dispatch, getState) => {
        dispatch(showLoading());
        const response = await ajax.post('Payment/ResendInvoice', id);

        if (await ajax.errorOccured(response, "Invoice resend failed.", dispatch, getState))
            return;

        dispatch(hideLoading());
        dispatch(showAlert(i18n.t("invoiceSent"), alertTypes.success))
    }
} as Actions