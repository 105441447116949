import { produce } from 'immer'
import { actionTypes, State, initialState, ReducerAction, write, writeContent, writeJson } from './types'
import { TemplateContent, RawNodeDatum } from '../../models';
import { jsonToFields } from '../../helpers/utils';


const list_to_tree = (content: TemplateContent[]) => {
    if (!content || content.length === 0) return null;

    let list: RawNodeDatum[] = content.map(c => ({ name: c.title, children: [], attributes: { id: c.id, parentId: c.parentId, message: c.message, finalNode: c.finalNode } }));

    const map = {};
    let node: RawNodeDatum, root: RawNodeDatum, i;

    for (i = 0; i < list.length; i += 1) {
        map[list[i].attributes.id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.attributes.parentId && node.attributes.parentId !== 0) {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.attributes.parentId]].children.push(node);
        } else {
            root = node;
        }
    }
    return root;
}


export default (state: State, action: ReducerAction) => {
    return produce(state || initialState, newState => {
        switch (action.type) {
            case actionTypes.CHANGE_FIELD:
                write(newState, action.name, action.value);
                if (action.name === 'contents') {
                    newState.contents.forEach(c => {
                        c.infoFields = jsonToFields(c.info);
                        c.finalNode = !newState.contents.some(cn => cn.parentId === c.id) && !!c.info;
                    })
                    newState.contentsTree = list_to_tree(newState.contents);
                }
                break;
            case actionTypes.ADD_CONTENT:
                newState.contents.push(action.content);
                newState.contentsTree = list_to_tree(newState.contents);
                break;
            case actionTypes.UPDATE_CONTENT: {
                const index = newState.contents.findIndex(q => q.id == action.content.id);
                newState.contents[index] = action.content;
                newState.contentsTree = list_to_tree(newState.contents);
                break;
            }
            case actionTypes.DELETE_CONTENT:
                newState.contents = newState.contents.filter(t => t.id !== action.contentId);
                newState.contentsTree = list_to_tree(newState.contents);
                break;
            case actionTypes.CHANGE_CONTENT_FIELD:
                writeContent(newState.content, action.contentName, action.value);
                break;
            case actionTypes.CHANGE_JSON_FIELD:
                const field = newState.content.infoFields.find(f => f.id === action.infoFieldId);
                writeJson(field, action.fieldName, action.value);
                break;
            case actionTypes.ADD_FIELD:
                newState.content.infoFields.push({
                    id: newState.content.infoFields.length + 1,
                    key: '',
                    value: ''
                })
                break;
            case actionTypes.DELETE_FIELD:
                newState.content.infoFields = newState.content.infoFields.filter(f => f.id !== action.infoFieldId);
                break;
        }
    })
}