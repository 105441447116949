import React, { useState, useEffect } from "react";
import WebchatProfileDialog from './WebchatProfileDialog';
import { Theme } from "@mui/material/styles";
import { Add as AddIcon, Edit as EditIcon, DeleteOutline as DeleteIcon, Code as CodeIcon, FileCopyOutlined as CopyIcon } from '@mui/icons-material';
import {
    Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogActions, DialogContent,
    DialogTitle, Tooltip
} from "@mui/material";
import { PhoneParameter, WebchatProfile, WebchatProfileLabels } from "../../models";
import { isMobileMode } from '../../helpers/utils';
import { useTranslation } from "react-i18next";
import { makeStyles } from '../../styles/makeStyles'

const useStyles = makeStyles()((theme: Theme) => ({
    tableWrapper: {
        width: '100%',
        overflowX: 'auto',
    },
    addButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    table: {
        minWidth: isMobileMode() ? 400 : 800,
    },
    code: {
        backgroundColor: theme.palette.grey[100],
        padding: theme.spacing(1),
        borderRadius: 5
    }
}));

interface Props {
    loadWebchatProfiles: () => void,
    profiles: WebchatProfile[],
    profileForm: WebchatProfile,
    profileFormLabels: WebchatProfileLabels,
    webchatDialogIsOpen: boolean,
    phoneNumbers: PhoneParameter[],
    onAddWebchatProfile: () => void,
    onEditWebchatProfile: (profile: WebchatProfile) => void,
    onCloseWebchatDialog: () => void,
    onChangeField: (name: keyof WebchatProfile, value: any) => void,
    onSubmitWebchatProfie: () => void,
    onDeleteProfile: (profile: WebchatProfile) => void,
    onSubmitLabels: () => void,
    onResetLabels: () => void,
}

const WebchatSetup = (props: Props) => {
    const { t } = useTranslation();

    const [codeIsOpen, setCodeIsOpen] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        props.loadWebchatProfiles()
    }, [])

    const setCodeDialogIsOpen = (isOpen: boolean, apiKey?: string) => {
        setCodeIsOpen(isOpen);
        setApiKey(apiKey);
    }

    const { classes } = useStyles();

    const { profiles, profileForm, profileFormLabels, webchatDialogIsOpen } = props,
        embedCode = `<script src="${window.location.origin}/api/webchat/loadApi?apiKey=${apiKey}"></script>`,
        copyToClipboard = () => {
            let tempInput = document.createElement("input")
            tempInput.style.setProperty('position', 'absolute')
            tempInput.style.setProperty('left', '-9999px')
            tempInput.setAttribute('type', 'text')
            tempInput.value = embedCode

            let clipboardButton = document.getElementById("clipboardButton")

            clipboardButton.appendChild(tempInput)
            tempInput.select()
            tempInput.setSelectionRange(0, embedCode.length)

            document.execCommand('copy')

            clipboardButton.removeChild(tempInput)

            setMessage(t("copiedToClipboard"))

            setTimeout(() => setMessage(''), 2000)
        }

    return <>
        <Button variant="contained" className={classes.addButton} color="primary" onClick={() => props.onAddWebchatProfile()}>
            <AddIcon />
            {t("newProfile")}
        </Button>
        <div className={classes.tableWrapper}>
            <Table size="small" className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("name")}</TableCell>
                        <TableCell>{t("domain")}</TableCell>
                        <TableCell>{t("language")}</TableCell>
                        <TableCell>{t("apiKey")}</TableCell>
                        <TableCell align="center">{t("embedCode")}</TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        profiles.length === 0 &&
                        <TableRow>
                            <TableCell colSpan={5} align="center">{t("noData")}</TableCell>
                        </TableRow>
                    }
                    {
                        profiles.map((profile, i) => (
                            <TableRow key={i}>
                                <TableCell>{profile.name || `${profile.domain} - ${profile.language}`}</TableCell>
                                <TableCell>{profile.domain}</TableCell>
                                <TableCell>{profile.language}</TableCell>
                                <TableCell>{profile.apiKey}</TableCell>
                                <TableCell align="center">
                                    <IconButton onClick={() => setCodeDialogIsOpen(true, profile.apiKey)} size="large">
                                        <CodeIcon color="primary" />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center">
                                    <Tooltip title={t("edit")}>
                                        <IconButton onClick={() => props.onEditWebchatProfile(profile)} size="large">
                                            <EditIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t("delete")}>
                                        <IconButton onClick={() => props.onDeleteProfile(profile)} size="large">
                                            <DeleteIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </div>
        <WebchatProfileDialog
            isOpen={webchatDialogIsOpen}
            profile={profileForm}
            profileFormLabels={profileFormLabels}
            onCancel={props.onCloseWebchatDialog}
            onSubmit={props.onSubmitWebchatProfie}
            onChangeField={props.onChangeField}
            onResetLabels={props.onResetLabels}
            onSubmitLabels={props.onSubmitLabels}
            phoneNumbers={props.phoneNumbers}
        />
        <Dialog
            open={codeIsOpen}
            onClose={() => setCodeDialogIsOpen(false)}
            maxWidth="xl"
            fullWidth
        >
            <DialogTitle>{t("embedCode")}</DialogTitle>
            <DialogContent>
                <div>
                    {t("addCodeMessageForWhebChatAPI")}
                    <pre className={classes.code}>
                        {embedCode}
                    </pre>
                    <div id="clipboardButton" style={{ textAlign: 'right', marginTop: -45, marginBottom: 14 }}>
                        {
                            message
                        }
                        <Tooltip title={t("copyToClipboard")} placement="top">
                            <IconButton size="small" color="primary" onClick={() => copyToClipboard()}>
                                <CopyIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    {t("addCodeMessageForWhebChatAPI2")}
                    <pre className={classes.code}>
                        {`
<html>
<head>
    //some header content here
    .
    .
    .
</head>
<body>
    //your page content here
    .
    .
    .
    ${embedCode}
</body>
</html>`}
                    </pre>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setCodeDialogIsOpen(false)} color="primary">
                    {t("close")}
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}

export default WebchatSetup;
